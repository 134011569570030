import Breadcrumbs from "../../../@core/components/breadcrumbs";
import PoolFilterTable from "../../../components/transactions/pool/poolFilterTable";
const PoolFilter = () => {
  return (
    <div>
      <Breadcrumbs
        title="Çanak"
        data={[{ title: "İşlemler" }, { title: "Çanak" }]}
      />
      <PoolFilterTable />
    </div>
  );
};

export default PoolFilter;
