import { useState, Fragment, useEffect } from "react";
import { Plus, Edit, Trash2 } from "react-feather";

import {
  Input,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";

// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss";
//@ts-ignore
import classnames from "classnames";
import debounce from "lodash.debounce";
import ExportDropdownFile from "../../common/ExportDropdownFile";

import SwitchLabel from "../../common/SwitchLabel";
import SwalFire from "../../common/Swal";
import AddPaymentMethod from "./addPaymentMethod";
import EditPaymentMethod from "./editPaymentMethod";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import {
  IEditPaymentMethodModel,
  IPaymentMethodDBModel,
} from "../../../model/DbMdel/paymentMethodDBModel";
import {
  deletePaymentMethodService,
  exportFile,
  getAllPaymentMethodService,
  updatePaymentMethodService,
} from "../../../services/paymentMethod";
import toast from "react-hot-toast";
import { PagedResultDto } from "../../../model/commonModel/pagedResultDto";
import DataTable from "../../common/DataTable/components/ssr";
import moment from "moment";
import dateFormat from "../../../helper/dateFormat";
import { defaultCount, options } from "../../common/DataTable/Constants";

const PaymentMethod: React.FC = () => {
  const [payment, setPayment] =
    useState<PagedResultDto<IPaymentMethodDBModel>>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const {
    data: methodData,
    handlePaginate,
    isLoading,
    handleSearch,
    refetch,
    page,
  } = usePaginateFetch<IPaymentMethodDBModel>({
    callbackService: getAllPaymentMethodService,
    maxResultCount: maxResultCount,
  });

  useEffect(() => {
    setPayment(methodData);
  }, [methodData]);

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
    setFilter(value);
  }, 500);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [maxResultCount]);

  const handleChange = (
    index: number,
    key: keyof any,
    value: any,
    swalText: string
  ) => {
    const methodIndex = methodData.items?.findIndex((e) => e.id === index);

    const { description, name, isActive, id, isCoachMoneySafe } =
      methodData.items[methodIndex];

    const tempData: IEditPaymentMethodModel = {
      description: description,
      name: name,
      isActive: !isActive,
      isCoachMoneySafe: isCoachMoneySafe,
    };

    SwalFire({
      title: "Emin misiniz?",
      text: swalText,
      onOk: async () => {
        await updatePaymentMethodService(tempData, id)
          .then((e) => {
            refetch();
            toast.success(`Ödeme Yöntemi Başarıyla Düzenlendi.`);
          })
          .catch((e) => {
            toast.error(e);
          });
      },
    });
  };

  const caseInsensitiveSort = (
    rowA: IPaymentMethodDBModel,
    rowB: IPaymentMethodDBModel
  ) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const handleDelete = async (id: number) => {
    const result = SwalFire({
      title: "Emin misiniz?",
      text: "Kayıtlı bir ödeme yöntemini kaldırmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: async () => {
        await onSubmit(id);
        refetch();
      },
    });
  };
  const onSubmit = async (id: number) => {
    await deletePaymentMethodService(id)
      .then((e) => {
        toast.success(`Ödeme Yöntemi Başarıyla Silindi.`);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const columns = [
    {
      name: "İSİM",
      sortable: true,
      sortFunction: caseInsensitiveSort,
      cell: (row: IPaymentMethodDBModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center ">
            <h6> {row.name}</h6>
          </div>
        );
      },
    },
    {
      name: "AÇIKLAMA",
      cell: (row: IPaymentMethodDBModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center ">
            {row.description}
          </div>
        );
      },
    },
    {
      name: "AKTİF/PASİF",
      sortField: "isActive",
      cell: (row: IPaymentMethodDBModel) => {
        return (
          <div
            className={classnames("form-switch", {
              [`form-check-warning`]: row.isActive,
            })}
          >
            <Input
              type="switch"
              checked={row.isActive}
              id={`${row.id}_icon-primary_isPanelAccess`}
              name="icon-warning"
              onChange={(e) => {
                //@ts-ignore
                handleChange(
                  row.id,
                  "isPanelAccess",
                  e.target.checked,
                  "Ödeme yönteminin durumunu değiştirmek üzeresiniz. Bu işlem sistemde farklı etkilere sebep olabilir. İşleme devam etmek istediğinize emin misiniz ?"
                );
              }}
            />
            <SwitchLabel htmlFor={`${row.id}_icon-primary_isPanelAccess`} />
          </div>
        );
      },
    },
    {
      name: "KASA TİPİ",
      cell: (row: IPaymentMethodDBModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center ">
            {row.isCoachMoneySafe?"Antrenör Kasası":"Yönetici Kasası"}
          </div>
        );
      },
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      right: true,
      cell: (row: IPaymentMethodDBModel) => {
        return (
          <div>
            <Button
              size="sm"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                position: "relative",
                marginRight: "4px",
              }}
              color="warning"
              onClick={() => setSelectedIndex(row.id)}
            >
              <Edit
                size={18}
                style={{ position: "absolute", top: "3px", left: "5px" }}
              />
            </Button>
            <Button
              size="sm"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                position: "relative",
              }}
              color="danger"
              onClick={() => handleDelete(row.id)}
            >
              <Trash2
                size={18}
                style={{ position: "absolute", top: "3px", left: "5px" }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row className="d-flex pb-2 ">
        <Col
          lg="12"
          md="12"
          className="d-flex justify-content-center  p-0 px-md-1  px-lg-2  justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions gap-1">
            <ExportDropdownFile
              callbackService={exportFile}
              fileName={`ödeme_yöntemi_listesi_${moment(new Date()).format(
                dateFormat
              )}`}
              headers={["Name", "Description", "IsActive"]}
            />

            <Button color="warning" onClick={() => setShow(true)}>
              <Plus style={{ marginRight: "8px" }} size={14} />
              Ödeme Yöntemi Ekle
            </Button>
            <AddPaymentMethod
              setShow={() => setShow(!show)}
              show={show}
              onReload={async () => {
                setShow(false);
                setSelectedIndex(-1);
                await refetch();
              }}
            />
            {methodData.items && selectedIndex > -1 && (
              <EditPaymentMethod
                setShowEdit={() => setSelectedIndex(-1)}
                showEdit={selectedIndex !== -1}
                paymentMethod={
                  methodData.items.find((x) => x.id === selectedIndex)!
                }
                onReload={async () => {
                  setSelectedIndex(-1);
                  await refetch();
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">Göster:</label>
          <Input
            defaultValue={maxResultCount}
            type="select"
            onChange={(e) => setMaxResultCount(parseInt(e.target.value))}
          >
            {options?.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </Col>
        <Col lg="4" md="6" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search">
            Ara:
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <Fragment>
      <Card>
        <CardHeader style={{ borderBottom: "1px solid #4B4B4B" }}>
          <CardTitle tag="h2">Ödeme Yöntemi Listesi</CardTitle>
        </CardHeader>
        <CardBody className="px-0 ">
          <div className="react-dataTable react-dataTable-selectable-rows">
            <DataTable
              subHeaderComponent={ToDetails()}
              columns={columns}
              totalCount={payment?.totalCount}
              data={payment?.items}
              onChangePaginate={handlePaginate}
              havePaginate
              pagination
              isFilter={filter}
              pageNumber={page}
              perPage={maxResultCount}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PaymentMethod;
