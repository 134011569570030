import React from "react";

const locationIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74 0V2.36988C5.02992 2.8854 2.8854 5.02992 2.36988 7.74H0V10.26H2.36988C2.8854 12.9701 5.02992 15.1146 7.74 15.6301V18H10.26V15.6301C12.9701 15.1146 15.1146 12.9701 15.6301 10.26H18V7.74H15.6301C15.1146 5.02992 12.9701 2.8854 10.26 2.36988V0H7.74ZM9 4.05C9.65054 4.04822 10.295 4.17504 10.8964 4.42317C11.4978 4.6713 12.0441 5.03584 12.5042 5.49585C12.9642 5.95585 13.3287 6.50225 13.5768 7.10361C13.825 7.70498 13.9518 8.34946 13.95 9C13.9518 9.65054 13.825 10.295 13.5768 10.8964C13.3287 11.4978 12.9642 12.0441 12.5042 12.5042C12.0441 12.9642 11.4978 13.3287 10.8964 13.5768C10.295 13.825 9.65054 13.9518 9 13.95C8.34946 13.9518 7.70498 13.825 7.10361 13.5768C6.50225 13.3287 5.95585 12.9642 5.49585 12.5042C5.03584 12.0441 4.6713 11.4978 4.42317 10.8964C4.17504 10.295 4.04822 9.65054 4.05 9C4.04822 8.34946 4.17504 7.70498 4.42317 7.10361C4.6713 6.50225 5.03584 5.95585 5.49585 5.49585C5.95585 5.03584 6.50225 4.6713 7.10361 4.42317C7.70498 4.17504 8.34946 4.04822 9 4.05Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default locationIcon;
