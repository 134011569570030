import { Input, Row, Col, Card, Badge, Button } from "reactstrap";
import { useEffect, useState } from "react";
import ExportDropdown from "../common/ExportDropdown";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import getActivities, {
  confrimMultipleActivity,
} from "../../services/activity";
import { Link, useSearchParams } from "react-router-dom";
import {
  IActivityDbModel,
  IActivityInputModel,
  IMultipleConfirmActivityModel,
  IStudentModel,
} from "../../model/DbMdel/activityDBModel";
import _debounce from "lodash/debounce";
import moment from "moment";
//@ts-ignore
import Avatar from "@components/avatar";
import { IStudentDBModel } from "../../model/DbMdel/studentDBModel";
import { convertPaymentType } from "../../utility/Utils";
import DataTable from "../common/DataTable";
import usePaginateFetch from "../../utility/hooks/usePaginateFetch";
import { defaultCount, options } from "../common/DataTable/Constants";
import { handleFilter, handleFiltered } from "../../utility/helper";
import Select from "react-select";
import classnames from "classnames";
import { InfiniteScrollDropdown } from "../InfiniteScroll";
import useInfinityPaginateFetch from "../../utility/hooks/userInfinityPaginateFetch";
import { ICoachDBModel } from "../../model/DbMdel/coachDBModel";
import getCoachs from "../../services/coach";
import { ILocationMethodDBModel } from "../../model/DbMdel/locationMethodDbModel";
import { getAllLocationMethodService } from "../../services/locationMethod";
import {
  getAllStudentsIncludesChild,
  getParentStudents,
} from "../../services/students";
import { getGroups } from "../../services/groups";
import { getAllPaymentMethodService } from "../../services/paymentMethod";
import CustomFlatpicker from "../Flatpickr";
import { CheckSquare } from "react-feather";
import SwalFire from "../common/Swal";
import toast from "react-hot-toast";
import { checkRoles } from "../../helper/permissionHelper";
import { UserRole } from "../../model/commonModel/enums";

interface IStatusLesson {
  color: string;
  text: string;
}

const PaymentTrackerDetail = () => {
  let [searchParams] = useSearchParams();
  const startDate =
    searchParams.get("startDate") || moment(new Date()).format("YYYY-MM-DD");
  const endDate =
    searchParams.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
  const [activitySearchQueries, setActivitySearchQueries] =
    useState<IActivityInputModel>({
      endDate: endDate ?? undefined,
      startDate: startDate ?? undefined,
      maxResultCount: defaultCount,
    });
  const [toggledClearRows, setToggleClearRows] = useState<boolean>(false);
  const [selectableRows, setSelectableRows] = useState<IActivityDbModel[]>([]);

  const {
    data: coachData,
    isLoading: coachLoading,
    handleSearch: coachSearch,
    handlePaginate: coachPaginate,
    page: coachPages,
    searchValue: coachSearchValue,
  } = useInfinityPaginateFetch<ICoachDBModel>({
    callbackService: getCoachs,
  });

  const {
    data: locationData,
    handlePaginate: locationPaginate,
    isLoading: locationLoading,
    handleSearch: locationSearch,
    page: locationPage,
    searchValue: locationSearchValue,
  } = useInfinityPaginateFetch<ILocationMethodDBModel>({
    callbackService: getAllLocationMethodService,
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    handleSearch: studentSearch,
    handlePaginate: studentPaginate,
    page: studentPages,
    searchValue: studentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getParentStudents,
    isActive: true,
  });

  const {
    data: connectedStudentData,
    isLoading: connectedStudentLoading,
    handleSearch: connectedStudentSearch,
    handlePaginate: connectedStudentPaginate,
    page: connectedStudentPages,
    searchValue: connectedStudentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: (params: any) =>
      getAllStudentsIncludesChild({ IsParent: false, ...params }),
    isActive: true,
  });

  const {
    data: groupData,
    isLoading: groupLoading,
    handleSearch: groupSearch,
    handlePaginate: groupPaginate,
    page: groupPages,
    searchValue: groupSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getGroups,
  });

  const {
    data: paymentTypeData,
    isLoading: paymentTypeLoading,
    handleSearch: paymentTypeSearch,
    handlePaginate: paymentTypePaginate,
    page: paymentTypePages,
    searchValue: paymentTypeSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getAllPaymentMethodService,
  });

  const lessonStatusOptions = [
    { value: "isEncashed", label: "Tahsil Edildi" },
    { value: "isEncashedByAdmin", label: "Admin Tarafından Tahsil Edildi" },
    { value: "inEncashed", label: "Tahsil Edilmeyi Bekliyor" },
    { value: "isConfirmed", label: "Onaylandı" },
    { value: "isDeleted", label: "Silindi" },
  ];

  const lessonTypeOptions = [
    { value: "Person", label: "Kişi" },
    { value: "Group", label: "Grup" },
  ];

  const {
    data: detailData,
    handlePaginate,
    isLoading,
    handleSearch,
    refetch: refetchActivities,
    page,
  } = usePaginateFetch<IActivityDbModel>({
    callbackService: (params: any) =>
      getActivities({ ...activitySearchQueries, ...params }),
    maxResultCount: activitySearchQueries.maxResultCount,
  });

  const dateFormat = "YYYY-MM-DD HH:00";
  const dateFormatWithMin = "YYYY-MM-DD HH:mm";

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  useEffect(() => {
    refetchActivities();
  }, [activitySearchQueries]);

  const renderClient = (row: IActivityDbModel) => {
    if (row.trainer.user.photo) {
      return (
        <Link
          to={`/usersandpermissions/users/profile-detail/${row.trainer.user.id}`}
        >
          <Avatar
            className="me-1"
            img={row.trainer.user.photo}
            width="32"
            height="32"
          />
        </Link>
      );
    } else {
      return (
        <Link
          to={`/usersandpermissions/users/profile-detail/${row.trainer.user.id}`}
        >
          <Avatar
            className="me-1"
            color={"light-warning"}
            content={getSummaryName(
              `${row.trainer.user.name} ${row.trainer.user.surname}`
            )}
            title={`${row.trainer.user.name} ${row.trainer.user.surname}`}
          />
        </Link>
      );
    }
  };

  const getStatusLesson = (row: IActivityDbModel): IStatusLesson => {
    const isEncasched = !row.days[0].students.some(
      (s) => s.isEncashed === false
    );
    const encasherUser = row.days[0].students.filter(
      (s) => s.isEncashed === true
    )[0]?.encasherUser;
    const isConfirmed = row.days[0].isConfirmed;

    let statusLesson: IStatusLesson = {
      color: "light-warning",
      text: "Tahsil Edilmeyi Bekliyor",
    };

    if (row.isDeleted) {
      statusLesson.color = "light-danger";
      statusLesson.text = "Silindi";
    } else if (!isConfirmed) {
      if (isEncasched) {
        if (encasherUser) {
          if (row.trainer.user.id === encasherUser.id) {
            statusLesson.color = "light-info";
            statusLesson.text = "Tahsil Edildi";
          } else if (row.trainer.user.id !== encasherUser.id) {
            statusLesson.color = "light-primary";
            statusLesson.text = `${encasherUser.name} ${encasherUser.surname} Admin Tarafından Tahsil Edildi`;
          }
        } else {
          statusLesson.color = "light-info";
          statusLesson.text = "Tahsil Edildi";
        }
      } else if (!isEncasched) {
        statusLesson.color = "light-warning";
        statusLesson.text = "Tahsil Edilmeyi Bekliyor";
      }
    } else if (isConfirmed) {
      statusLesson.color = "light-success";
      statusLesson.text = "Doğrulandı";
    }

    return statusLesson;
  };

  const caseInsensitiveSort = (
    rowA: IActivityDbModel,
    rowB: IActivityDbModel
  ) => {
    const a = rowA.trainer.user.name.toLowerCase();
    const b = rowB.trainer.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const studentsColumns = [
    {
      name: "İSİM",
      sortable: true,
      width: "30%",
      selector: (row: IStudentModel) => row.user.name,
      cell: (row: IStudentModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center">
            <div className="d-flex flex-column">
              <h6 className="user-name d-block mb-0 text-truncate">
                {row.user.name} {row.user.surname}
              </h6>
              <small className="text-truncate text-muted mb-0">
                {"Öğrenci"}
              </small>
            </div>
          </div>
        );
      },
    },
    // {
    //   width: "30%",
    //   name: "ALINAN DERS ÜCRETİ",
    //   cell: (row: IStudentModel) => row.perHourPrice,
    // },
    {
      width: "30%",
      name: "ÖDEME TİPİ",
      cell: (row: IStudentModel) => {
        return row.paymentType;
      },
    },
  ];

  // const ExpandableTableFooterDetails = (price: number) => (
  //   <div className="d-flex align-items-center px-1 w-100">
  //     <span style={{ marginLeft: "30%" }}>
  //       Toplam: <b style={{ color: "#ffff" }}>{price}</b>
  //     </span>
  //   </div>
  // );

  const ExpandableTable = ({ data }: any) => {
    let students: any = [];
    let totalPrice: number;
    if (data.activityType === "Person") {
      data.students.map((student: IStudentDBModel) => {
        const stUser = data.days[0].students.find(
          (x: IStudentModel) => x.studentId === student.id
        );
        if (stUser) {
          student.user.name = student.user?.name;
          student.user.surname = student.user?.surname;
          const paymentType = stUser?.paymentTypes?.name;
          student.paymentType = paymentType;
          students.push(student);
        }
      });
      totalPrice = data.students.reduce(
        (total: number, item: IStudentDBModel) => total + item.perHourPrice,
        0
      );
    } else {
      data.group?.students.map((student: IStudentDBModel) => {
        const stUser = data.days[0].students.find(
          (x: IStudentModel) => x.studentId === student.id
        );
        if (stUser) {
          student.user.name = student.user?.name;
          student.user.surname = student.user?.surname;
          const paymentType = stUser?.paymentTypes?.name;
          student.paymentType = paymentType;
          students.push(student);
        }
      });
      totalPrice = data.group?.students.reduce(
        (total: number, item: IStudentDBModel) => total + item.perHourPrice,
        0
      );
    }

    if (students.length > 0) {
      return (
        <div className="expandable-content p-2">
          <div className="react-dataTable">
            <DataTable.CSR
              noHeader
              data={students}
              pagination={false}
              columns={studentsColumns}
              className="react-dataTable"
              totalCount={students.length}
              // footerComponent={ExpandableTableFooterDetails(totalPrice)}
            />
          </div>
        </div>
      );
    }
  };

  const conditionalRowStyles = [
    {
      when: (row: IActivityDbModel) =>
        row.students.find((student) => student.parentStudent),
      classNames: ["child-row"],
    },
  ];

  const columns = [
    {
      name: "ANTRENÖR",
      sortable: true,
      width: "14%",
      sortField: "client.name",
      sortFunction: caseInsensitiveSort,
      textWrap: "ellipsis",
      selector: (row: IActivityDbModel) => row.trainer.user.name,
      cell: (row: IActivityDbModel, rowIndex: number) => {
        return (
          <div className={`d-flex justify-content-left align-items-center`}>
            {renderClient(row)}
            <div className="d-flex flex-column">
              <h6
                className="user-name mb-0"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title={row.trainer.user.name + " " + row.trainer.user.surname}
              >
                {row.trainer.user.name} {row.trainer.user.surname}
              </h6>
            </div>
          </div>
        );
      },
    },
    {
      name: "DERS ÜCRETİ",
      width: "10%",
      sortField: "perHourPrice",
      selector: (row: IActivityDbModel) => {
        const perHourPrice = row.perHourPrice;
        return `${row.isDeleted ? 0 : perHourPrice}`;
      },
    },
    {
      name: "ÖĞRENCİ/GRUP",
      width: "14%",
      sortField: "group",
      selector: (row: IActivityDbModel) =>
        //@ts-ignore
        {
          return row.group ? (
            <div>
              <h6>{row.group.name}</h6>
              <span>Grup</span>
            </div>
          ) : (
            <div>
              <h6>
                {row.students.map((x) => x.user.name + " " + x.user.surname)}
              </h6>

              {row.students.map((x) => {
                const parentStudent = x.parentStudent;

                return (
                  parentStudent?.user && (
                    <span>
                      {parentStudent.user.name +
                        " " +
                        parentStudent.user.surname}
                    </span>
                  )
                );
              })}
            </div>
          );
        },
    },
    {
      name: "LOKASYON",
      width: "14%",
      selector: (row: IActivityDbModel) => row.name.split("/")[0],
    },
    {
      name: "DERS BAŞLANGICI",
      width: "auto",
      selector: (row: IActivityDbModel) => {
        const startDate = moment(row.startDate).format(dateFormat);
        return startDate;
      },
    },

    {
      name: "DERSİN DURUMU",
      width: "11%",
      sortField: "status",
      selector: (row: IActivityDbModel) => {
        const statusLesson = getStatusLesson(row);
        return (
          <Badge pill color={statusLesson.color} className="text-wrap">
            {statusLesson.text}
          </Badge>
        );
      },
    },
    {
      name: "TAHSİLAT ZAMANI",
      width: "13%",
      sortField: "startDate",
      selector: (row: IActivityDbModel) => {
        const enCashLog = (row.logs || []).find((x) => x.logType === "Encash");
        return (
          <p>
            {enCashLog
              ? moment
                  .utc(enCashLog.creationTime)
                  .local()
                  .format(dateFormatWithMin)
              : "Tahsil edilmedi"}
          </p>
        );
      },
    },
  ];

  const totalPrice = () => {
    if (detailData?.totalCount! > 0) {
      let x = 0;
      //@ts-ignore
      detailData?.items.map((item) => {
        //@ts-ignore
        if (!item.isDeleted)
          //@ts-ignore
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      detailData.items?.map((item) => {
        if (!item.isDeleted) x += item.perHourPrice;
        return x;
      });
      return x;
    }
  };
  const selectedTotalPrice = () => {
    if (selectableRows.length < 0) {
      let x = 0;
      //@ts-ignore
      detailData?.items.map((item) => {
        //@ts-ignore
        if (!item.isDeleted)
          //@ts-ignore
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      selectableRows.map((item) => {
        if (!item.isDeleted) x += item.perHourPrice;
        return x;
      });
      return x;
    }
  };

  const selectedPaidPrice = () => {
    if (selectableRows.length < 0) {
      let x = 0;
      //@ts-ignore
      detailData?.items.map((item) => {
        //@ts-ignore
        if (
          !item.isDeleted &&
          item.days[0].students.find((student) => student.isEncashed)
        )
          //@ts-ignore
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      selectableRows.map((item) => {
        if (
          !item.isDeleted &&
          item.days[0].students.find((student) => student.isEncashed)
        )
          x += item.perHourPrice;
        return x;
      });
      return x;
    }
  };

  const handleQueryFilter = (key: string, value: any) => {
    const newActivitySearchQueries = {
      ...activitySearchQueries,
      [key]: value,
    };
    setActivitySearchQueries(newActivitySearchQueries);
  };

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start "
        >
          <p>Tahsilat Takibi Detayı</p>
        </Col>
        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center   justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions">
            <ExportDropdown<IActivityDbModel>
              className="me-1"
              array={selectableRows}
              fileName={`${moment(activitySearchQueries.startDate).format(
                "YYYY-MM-DD"
              )}_${moment(activitySearchQueries.endDate).format(
                "YYYY-MM-DD"
              )}_tahsilat_takibi_detayı`}
              sheetName={`${moment(activitySearchQueries.startDate).format(
                "YYYY-MM-DD"
              )}_${moment(activitySearchQueries.endDate).format("YYYY-MM-DD")}`}
              title="BILGI FISI: *Bu belgenin mali degeri yoktur."
              A4Orientation="portrait"
              labelValueData={[
                {
                  label: "Toplam Borç",
                  value: selectedTotalPrice().toString(),
                },
                {
                  label: "Ödenen Borç",
                  value: selectedPaidPrice().toString(),
                },
                {
                  label: "Kalan Borç",
                  value: (
                    selectedTotalPrice() - selectedPaidPrice()
                  ).toString(),
                },
              ]}
              headers={[
                {
                  key: "trainer.user.name",
                  label: "ANTRENOR ISMI",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.name} ${element.trainer.user.surname}`;
                  },
                },
                {
                  key: "perHourPrice",
                  label: "DERS UCRETI",
                  onRender: (item: boolean, element) => {
                    const perHourPrice = element.perHourPrice;
                    return `${element.isDeleted ? "0" : perHourPrice}`;
                  },
                },
                {
                  key: "students",
                  label: "OGRENCI ISMI",
                  onRender: (item: string, element) => {
                    return `${
                      element.group
                        ? element.group.students.map((s) => {
                            const student = element.days[0].students.find(
                              (x) => x.studentId === s.id
                            );
                            if (!student) return " ";
                            return s.user.name + " " + s.user.surname;
                          })
                        : element.students.map(
                            (s) => s.user.name + " " + s.user.surname
                          )
                    }`;
                  },
                },
                {
                  key: "name",
                  label: "LOKASYON",
                  onRender: (item: string, element) => {
                    return `${element.name.split("/")[0]}`;
                  },
                },
                {
                  key: "groupName",
                  label: "GRUP",
                  onRender: (item: string, element) => {
                    return `${element.group ? element.group.name : "-"}`;
                  },
                },

                {
                  key: "startDate",
                  label: "DERS BASLANGICI",
                  onRender: (item: string, element) => {
                    return `${moment(element.startDate).format(dateFormat)}`;
                  },
                },
                // {
                //   key: "endDate",
                //   label: "DERS BITISI",
                //   onRender: (item: string, element) => {
                //     return `${moment(element.endDate).format(dateFormat)}`;
                //   },
                // },

                {
                  key: "cashed",
                  label: "DERSIN DURUMU",
                  onRender: (item: boolean, element) => {
                    const statusLesson = getStatusLesson(element);
                    return `${statusLesson.text}`;
                  },
                },
                {
                  key: "paymentTime",
                  label: "TAHSILAT ZAMANI",
                  onRender: (item: string, element) => {
                    const enCashLog = (element.logs || []).find(
                      (x) => x.logType === "Encash"
                    );
                    return enCashLog
                      ? moment
                          .utc(enCashLog.creationTime)
                          .local()
                          .format(dateFormatWithMin)
                      : "Tahsil edilmedi";
                  },
                },
              ]}
            />
            {checkRoles([UserRole.Manager]) && (
              <Button
                block
                color="warning text-nowrap"
                className="d-flex align-items-center gap-50"
                disabled={!selectableRows.length}
                onClick={async () => {
                  await SwalFire({
                    title: "Emin misiniz?",
                    text: `${selectableRows.length} ders için doğrulama yapmak üzeresiniz.    
                   İşleme devam etmek istiyor musunuz?`,
                    onOk: async () => {
                      const payload: IMultipleConfirmActivityModel[] =
                        selectableRows.map((activity) => {
                          return {
                            activityIds: activity.id,
                            day: moment(new Date(activity.startDate)).format(
                              "YYYY-MM-DD"
                            ),
                          };
                        });
                      await confrimMultipleActivity(payload)
                        .then((e) => {
                          toast.success("Etkinlik onaylandı");
                          setToggleClearRows(!toggledClearRows);
                          refetchActivities(e);
                        })
                        .catch((e) => {
                          toast.error(e);
                        });
                    },
                  });
                }}
              >
                <CheckSquare size={15} />
                Yönetici Onayı
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="location">
            Lokasyon
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Lokasyon Listesi"
            searchValue={locationSearchValue}
            headers={["name"]}
            data={locationData.items}
            isLoading={locationLoading}
            id="location"
            totalCount={locationData.totalCount}
            onInputChange={(inputValue) =>
              handleFilter(inputValue, locationSearch)
            }
            onMenuScrollBottom={() => locationPaginate(locationPage + 1)}
            className="w-75"
            onChange={(location: any) => {
              handleQueryFilter("locationId", location?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="student">
            Öğrenci
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Öğrenci Listesi"
            searchValue={studentSearchValue}
            totalCount={studentData.totalCount}
            headers={["user.name", "user.surname"]}
            data={studentData.items}
            isLoading={studentLoading}
            id="students"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, studentSearch)
            }
            onMenuScrollBottom={() => studentPaginate(studentPages + 1)}
            onChange={(student: any) => {
              handleQueryFilter("studentId", student?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label
            className="mb-0 pe-1 w-25 text-end"
            htmlFor="connected-student"
          >
            Bağlantılı Öğrenci
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Bağlantılı Öğrenci Listesi"
            searchValue={connectedStudentSearchValue}
            totalCount={connectedStudentData.totalCount}
            headers={["user.name", "user.surname"]}
            data={connectedStudentData.items}
            isLoading={connectedStudentLoading}
            id="connectedStudents"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, connectedStudentSearch)
            }
            onMenuScrollBottom={() =>
              connectedStudentPaginate(connectedStudentPages + 1)
            }
            onChange={(student: any) => {
              handleQueryFilter("subStudentId", student?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="group">
            Grup
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Grup Listesi"
            searchValue={groupSearchValue}
            totalCount={groupData.totalCount}
            headers={["name", "surname"]}
            data={groupData.items}
            isLoading={groupLoading}
            id="group"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, groupSearch)
            }
            onMenuScrollBottom={() => groupPaginate(groupPages + 1)}
            onChange={(group: any) => {
              handleQueryFilter("groupId", group?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="lesson-status">
            Dersin Durumu
          </label>
          <Select
            isDisabled={isLoading}
            id="lesson-status"
            placeholder="Durum Seçin"
            classNamePrefix="select"
            isClearable
            isMulti={false}
            options={lessonStatusOptions}
            onChange={(event: any) => {
              if (event) {
                switch (event.value) {
                  case "isDeleted":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: false,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: true,
                    });
                    break;
                  case "inEncashed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: false,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: false,
                    });
                    break;
                  case "isEncashed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: true,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: false,
                    });
                    break;

                  case "isEncashedByAdmin":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: true,
                      isConfirmed: false,
                      isEncashedByAdmin: true,
                      isDeleted: false,
                    });
                    break;

                  case "isConfirmed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: undefined,
                      isConfirmed: true,
                      isEncashedByAdmin: undefined,
                      isDeleted: false,
                    });
                    break;
                  default:
                    break;
                }
              } else {
                setActivitySearchQueries({
                  ...activitySearchQueries,
                  isEncashed: undefined,
                  isConfirmed: undefined,
                  isEncashedByAdmin: undefined,
                  isDeleted: undefined,
                });
              }
            }}
            className="react-select w-75"
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="payment-type">
            Ödeme Yöntemi
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Ödeme Yöntemi"
            searchValue={paymentTypeSearchValue}
            totalCount={paymentTypeData.totalCount}
            headers={["name"]}
            data={paymentTypeData.items}
            isLoading={paymentTypeLoading}
            id="payment-type"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, paymentTypeSearch)
            }
            onMenuScrollBottom={() => paymentTypePaginate(paymentTypePages + 1)}
            onChange={(type: any) => {
              handleQueryFilter("paymentTypeId", type?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="start-date">
            Ders Başlangıcı
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              value={activitySearchQueries.startDate}
              placeholder="Ders Başlangıç Tarihi"
              id="startDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "startDate",
                  moment(dates![0]).format("YYYY-MM-DD")
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="start-date">
            Ders Bitişi
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              value={activitySearchQueries.endDate}
              placeholder="Ders Bitiş Tarihi"
              id="endDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "endDate",
                  moment(dates![0]).format("YYYY-MM-DD")
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="coach">
            Antrenör
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            className="w-75"
            searchValue={coachSearchValue}
            totalCount={coachData.totalCount}
            headers={["user.name", "user.surname"]}
            data={coachData.items}
            isLoading={coachLoading}
            id="coach"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, coachSearch)
            }
            onMenuScrollBottom={() => coachPaginate(coachPages + 1)}
            onChange={(type: any) => {
              handleQueryFilter("trainerIds", type?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="entry-date">
            Giriş
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              isClearable
              value={activitySearchQueries.serviceAreaEntryDate}
              placeholder="Otele Giriş Tarihi"
              id="entryDate"
              className={classnames("form-control w-max")}
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "serviceAreaEntryDate",
                  dates ? moment(dates[0]).format("YYYY-MM-DD") : null
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="exitDate">
            Çıkış
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              isClearable
              value={activitySearchQueries.serviceAreaExitDate}
              placeholder="Otelden Çıkış Tarihi"
              id="exitDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "serviceAreaExitDate",
                  dates ? moment(dates[0]).format("YYYY-MM-DD") : null
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" md="12" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end">Göster:</label>
          <Input
            disabled={isLoading}
            defaultValue={defaultCount}
            type="select"
            onChange={(e) =>
              handleQueryFilter("maxResultCount", parseInt(e.target.value))
            }
            className="w-75"
          >
            {options.concat([500])?.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="start-date">
            Ders Tipi
          </label>
          <Select
            isDisabled={isLoading}
            id="start-date"
            placeholder="Kişi/Grup"
            classNamePrefix="select"
            isClearable
            isMulti={false}
            options={lessonTypeOptions}
            onChange={(event: any) => {
              handleQueryFilter("activityType", event?.value || undefined);
            }}
            className="react-select w-75"
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="search-invoice">
            Ara
          </label>
          <Input
            disabled={isLoading}
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={(e) => handleFiltered(e.target.value, handleSearch)}
            className="w-75"
          />
        </Col>
      </Row>
    </div>
  );

  const ToFooterDetails = () => (
    <div
      className="d-flex align-items-center px-1 "
      style={{ borderBlock: "1px solid #4B4B4B", minHeight: "48px" }}
    >
      <span style={{ marginLeft: "16.5%" }}>
        Toplam: <b style={{ color: "#ffff" }}>{totalPrice()}</b>
      </span>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Breadcrumbs
        title="Tahsilat Takip Detayı"
        data={[
          { title: "Tahsilat takibi", link: "/" },
          { title: "Tahsilat Takip Detay" },
        ]}
      />
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.SSR
            subHeader
            totalCount={detailData.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={detailData.items}
            pagination
            onChangePaginate={handlePaginate}
            pending={isLoading}
            pageNumber={page}
            perPage={activitySearchQueries.maxResultCount}
            footerComponent={ToFooterDetails()}
            expandableRows
            expandableRowsComponent={ExpandableTable}
            selectableRows
            onSelectedRowsChange={(e: any) => {
              setSelectableRows(e.selectedRows);
            }}
            clearSelectedRows={toggledClearRows}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </Card>
    </div>
  );
};

export default PaymentTrackerDetail;
