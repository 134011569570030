import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import dateFormat from "../../../helper/dateFormat";
import { ISelectDate } from "../../../model/commonModel/selectDate";
import Flatpickr from "react-flatpickr";
import { ISelectableDates } from "./types";

interface ITimeSelectionProps {
  onSelectDate: (param: ISelectDate) => void;
}
const TimeSelection: FC<ITimeSelectionProps> = ({ onSelectDate }) => {
  const [picker, setPicker] = useState<any>();
  const [selectedSpecificDate, setSelectedSpecificDate] = useState<boolean>();
  const [selected, setSelected] = useState<number>(1);
  const [selectableDates, setSelectableDates] = useState<ISelectableDates>();

  const calendarRef = useRef<any>();
  return (
    <Row className="g-1">
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 1}
          onClick={() => {
            setSelected(1);
            onSelectDate({
              startDate: moment().format(dateFormat),
              endDate: moment().format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Bugün
        </Button>
      </Col>
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 2}
          onClick={() => {
            setSelected(2);
            onSelectDate({
              startDate: moment().subtract(1, "day").format(dateFormat),
              endDate: moment().subtract(1, "day").format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Dün
        </Button>
      </Col>
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 3}
          onClick={() => {
            setSelected(3);
            onSelectDate({
              startDate: moment().startOf("week").format(dateFormat),
              endDate: moment().endOf("week").format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Bu Hafta
        </Button>
      </Col>
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 4}
          onClick={() => {
            setSelected(4);
            onSelectDate({
              startDate: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .format(dateFormat),
              endDate: moment()
                .subtract(1, "weeks")
                .endOf("week")
                .format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Geçen Hafta
        </Button>
      </Col>
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 5}
          onClick={() => {
            setSelected(5);
            onSelectDate({
              startDate: moment().startOf("month").format(dateFormat),
              endDate: moment().endOf("month").format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Bu Ay
        </Button>
      </Col>
      <Col className="mb-1 mb-md-1" xs="6" md="4" lg={true}>
        <Button
          block
          color="warning text-nowrap"
          outline
          active={selected === 6}
          onClick={() => {
            setSelected(6);
            onSelectDate({
              startDate: moment()
                .subtract(1, "month")
                .startOf("month")
                .format(dateFormat),
              endDate: moment()
                .subtract(1, "month")
                .endOf("month")
                .format(dateFormat),
            });
            setSelectedSpecificDate(false);
          }}
        >
          Geçen Ay
        </Button>
      </Col>

      {!selectedSpecificDate && (
        <Col className="mb-1 mb-md-1" xs="12" md="4" lg={true}>
          <Button
            block
            color="warning text-nowrap"
            outline
            active={selected === 9}
            onClick={() => {
              setSelected(9);
              setSelectedSpecificDate(true);
            }}
          >
            Özel Tarih
          </Button>
        </Col>
      )}
      {selectedSpecificDate && (
        <Col
          className="mb-1 mb-md-1"
          xs="12"
          md="4"
          lg={true}
          style={{ position: "relative", display: "flex" }}
        >
          <Flatpickr
            value={picker}
            ref={calendarRef}
            id="range-picker"
            placeholder="Tarih Seçiniz"
            className="form-control"
            onChange={(date) => {
              const startDate = moment(date[0]).subtract(90, "days");
              const finishDate = moment(date[0]).add(90, "days");
              setSelectableDates({
                minDate: startDate.toDate(),
                maxDate: finishDate.toDate(),
              });
              if (date.length === 2) {
                onSelectDate({
                  startDate: moment(date[0]).format(dateFormat),
                  endDate: moment(date[1]).format(dateFormat),
                });
              }
            }}
            options={{
              mode: "range",
              minDate: selectableDates?.minDate,
              maxDate: selectableDates?.maxDate,
            }}
          />

          <Button
            style={{ padding: 4, position: "absolute", right: 0, top: 5 }}
            onClick={() => {
              calendarRef.current.flatpickr.clear();
              setSelectableDates(undefined);
            }}
          >
            X
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default TimeSelection;
