import DataTableCSR from "./components/csr";
import DataTableSSR from "./components/ssr";

type DataTableType = {
  CSR: typeof DataTableCSR;
  SSR: typeof DataTableSSR;
} & typeof DataTableCSR &
  typeof DataTableSSR;

const DataTable = DataTableCSR as DataTableType;

DataTable.CSR = DataTableCSR;
DataTable.SSR = DataTableSSR;


export default DataTable;
