import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import { selectThemeColors } from "../../../utility/Utils";
import LoadingButton from "../../common/LoadingButton";
import SwitchLabel from "../../common/SwitchLabel";
import SwalFire from "../../common/Swal";
import { ICreateLocationMethodModel } from "../../../model/DbMdel/locationMethodDbModel";
import { createLocationMethodService } from "../../../services/locationMethod";
import toast from "react-hot-toast";

interface IProps {
  setShow: (value: boolean) => void;
  show: boolean;
  onReload: () => void;
}

const AddLocation: FC<IProps> = ({ setShow, show, onReload }) => {
  const [isLoading, setIsLoading] = useState(false);

  const AddPaymentMethodSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "İsim en az 2 karakter olmalıdır.")
      .max(64, "İsim en fazla 64 karakter olmalıdır.")
      .matches(/^[^\d]*$/, "Geçerli bir isim giriniz.")
      .required("Lokasyon ismi zorunlu bir alan."),
    description: yup
      .string()
      .max(256, "Açıklama en fazla 256 karakter olmalıdır."),
    price: yup
    .string()
    .required("Lokasyon fiyatı zorunlu alandır")
    .matches(/\d+/, "rakam giriniz")
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
    register,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddPaymentMethodSchema),
    defaultValues: {
      isActive: true,
    } as any,
  });

  useEffect(() => {
    reset();
  }, [show]);

  const handleSwal = async (values: ICreateLocationMethodModel) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Yeni bir lokasyon eklemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        onSubmit(values);
      },
    });
  };

  const onSubmit = async (values: ICreateLocationMethodModel) => {
    await createLocationMethodService(values)
      .then((e) => {
        onReload();
        toast.success(`Lokasyon Başarıyla Eklendi.`);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleCancel = () => {
    setShow(!show);
    reset();
    clearErrors();
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={handleCancel}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleCancel}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Lokasyon Ekle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        maxLength={64}
                        placeholder="İsim"
                        invalid={errors.name && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="price">
                  Ücret
                </Label>
                <Controller
                  control={control}
                  name="price"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="price"
                        type="number"
                        maxLength={9}
                        placeholder="Ücret"
                        invalid={errors.price && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.price && (
                  <FormFeedback>
                    {errors.price.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="description">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("description").ref}
                        id="description"
                        type="textarea"
                        maxLength={256}
                        invalid={errors.description && true}
                        placeholder="Lokasyon Açıklaması"
                        style={{ maxHeight: "200px", minHeight: "80px" }}
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <div className="form-switch">
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="switch"
                            id="isActive"
                            defaultChecked={true}
                            {...field}
                            onChange={(event) => {
                              setValue("isActive", event.target.checked);
                            }}
                          />
                        );
                      }}
                    />
                    <SwitchLabel htmlFor={"isActive"} />
                  </div>
                  <Label
                    className="form-check-label fw-bolder"
                    htmlFor="isActive"
                  >
                    Aktif Lokasyon
                  </Label>
                </div>
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(handleSwal)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={handleCancel}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddLocation;
