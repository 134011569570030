//@ts-ignore
import Breadcrumbs from "../../@core/components/breadcrumbs";
import PlanningDetailTable from "../../components/PlanningFollowUp/PlanningFollowUpDetail/PlanningDetailTable";

//Program takip detay sayfası
const PlanningFollowUpDetail = () => {
  return (
    <div>
      <Breadcrumbs
        title="Program Takibi Detayı"
        data={[
          { title: "Planlama Takibi", link: "/plans" },
          { title: "Program Takibi Detayı" },
        ]}
      />
      <div>
        <PlanningDetailTable />
      </div>
    </div>
  );
};

export default PlanningFollowUpDetail;
