import { Row, Col, Button, Spinner } from "reactstrap";
import CoachList from "../../components/payment/coachListTable";
import TotalEarnings from "../../components/TotalEarnings";
import TotalProfit from "../../components/TotalProfit";
import TotalCommission from "../../components/TotalCommission";
import CalendarTracking from "../../components/CalendarTracking";
import CoachProfitRate from "../../components/CoachProfitRate";
import TrainerBasedChart from "../../components/TrainerBasedChart";
import TimeSelection from "../../components/common/TimeSelection";
import moment from "moment";
import { useEffect, useState } from "react";
import dateFormat from "../../helper/dateFormat";
import { ISelectDate } from "../../model/commonModel/selectDate";
import CardCongratulations from "../../components/common/CardCongrulations";
import {
  ISalaryDBModel,
  ISavedSalaries,
} from "../../model/DbMdel/salaryDBModel";
import { getSalariesSummary, getSavedSalaries } from "../../services/salary";

import { ICoachGroupByModel } from "../../model/commonModel/groupCoach";
import { groupByCoach } from "../../helper/coachGroupby";
import { getSummaryCoachs } from "../../services/coach";
import { ICoachSummaryDBModel } from "../../model/DbMdel/coachDBModel";
import SalaryAccordionTime from "../../components/SalaryAccordionTime";
import { getAllPaymentMethodService } from "../../services/paymentMethod";
import { IPaymentMethodDBModel } from "../../model/DbMdel/paymentMethodDBModel";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState<ISelectDate>({
    startDate: moment().format(dateFormat),
    endDate: moment().format(dateFormat),
  });

  const [summaryData, setSummaryData] = useState<ISalaryDBModel[] | null>(null);
  const [groupTrainers, setGroupTrainers] = useState<ICoachGroupByModel[]>([]);
  const [trainersSummaryData, setTrainersSummaryData] =
    useState<ICoachSummaryDBModel>({
      dailySalaryInfo: [],
      trainerSummaries: [],
    });
  const [savedSalaries, setSavedSalaries] = useState<ISavedSalaries[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodDBModel[]>(
    []
  );

  const fetchSalariesSummary = async () => {
    setSummaryData(null);
    await getSalariesSummary(selectedDate)
      .then((res: ISalaryDBModel[]) => {
        setSummaryData(res);
        setGroupTrainers(groupByCoach(res));
      })
      .catch((err) => {});
  };

  const fetchTrainersSummaryData = async () => {
    await getSummaryCoachs(selectedDate)
      .then((response: ICoachSummaryDBModel) => {
        setTrainersSummaryData(response);
      })
      .catch((err) => {});
  };

  const fetchSavedSalaries = async () => {
    await getSavedSalaries(selectedDate)
      .then((e: ISavedSalaries[]) => {
        setSavedSalaries(e);
      })
      .catch((e) => {});
  };

  const fetchPaymentMethod = async () => {
    await getAllPaymentMethodService({
      maxResultCount: 1000,
      isActive: true,
    })
      .then((e: PagedResultDto<IPaymentMethodDBModel>) => {
        setPaymentMethods(e.items);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchSalariesSummary();
    fetchTrainersSummaryData();
    fetchSavedSalaries();
    fetchPaymentMethod();
  }, [selectedDate]);

  return (
    <>
      <div id="dashboard-analytics">
        <Row className="match-height">
          <Col lg="12" sm="12">
            <CardCongratulations />
          </Col>
        </Row>
        <Row className="match-height mb-1">
          <Col lg="12" sm="12">
            <TimeSelection onSelectDate={setSelectedDate} />
          </Col>
        </Row>
        {!summaryData ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Spinner color="warning" />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="match-height mb-1">
              <Col lg="12" sm="12">
                <SalaryAccordionTime salaryInfo={savedSalaries} />
              </Col>
            </Row>
            <Row className="match-height">
              <Col lg="4" xs="12">
                <TotalEarnings summaryData={summaryData} />
              </Col>
              <Col lg="4" xs="12">
                <TotalProfit summaryData={summaryData} />
              </Col>
              <Col lg="4" xs="12">
                <TotalCommission summaryData={summaryData} />
              </Col>
            </Row>

            <Row className="match-height">
              <Col lg="6" xs="12">
                <CalendarTracking
                  summaryData={summaryData}
                  selectedDate={selectedDate}
                  trainersSummaryData={trainersSummaryData}
                />
              </Col>
              <Col lg="6" xs="12">
                <CoachProfitRate groupTrainers={groupTrainers} />
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <TrainerBasedChart groupTrainers={groupTrainers} />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <CoachList
                  trainers={groupTrainers}
                  selectedDate={selectedDate}
                  paymentTypes={paymentMethods}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
