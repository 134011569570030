// ** Icons Imports
import { Award } from 'react-feather'

// ** Custom Components
//@ts-ignore
import Avatar from "@components/avatar";

// ** Reactstrap Imports
import { Card, CardBody, CardText } from 'reactstrap'

// ** Images
import decorationLeft from '../../../assets/img/decore-left.png'
import decorationRight from '../../../assets/img/decore-right.png'
import { useAuth } from '../../../utility/context/Auth'

const CardCongratulations = () => {
  const { userInfo } = useAuth()
  return (
    <Card className='card-congratulations'>
      <CardBody className='text-center'>
        <img className='congratulations-img-left' src={decorationLeft} alt='decor-left' />
        <img className='congratulations-img-right' src={decorationRight} alt='decor-right' />
        <Avatar icon={<Award size={28} style={{ backgroundColor: 'transparent' }} />} className='shadow' color='warning' size='xl' />
        <div className='text-center mb-3'>
          <h1 className='mb-1 text-white'>Merhaba  {userInfo!.name}</h1>
          <CardText className='m-auto w-75'>

          </CardText>
        </div>
      </CardBody>
    </Card>
  )
}

export default CardCongratulations
