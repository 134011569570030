// ** React Imports
import { Link } from "react-router-dom";

// ** Icons Imports
import { ArrowLeft } from "react-feather";
import * as yup from "yup";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// ** Styles
import "@styles/react/pages/page-authentication.scss";

import "@styles/base/pages/forgotPassword.scss";
import { useState } from "react";
import { ForgotPasswordService } from "../../services/passwords";
import toast from "react-hot-toast";
import LoadingButton from "../common/LoadingButton";

const ForgotPasswordCard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const SignupSchema = yup.object().shape({
    email: yup.string().email('E-mail tipinde olmalıdır.').required("E-mail zorunlu bir alandır."),
  });

  // ** Hooks
  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });


  const onSubmit = (e: any) => {
    setIsLoading(true);
    const data = {
      emailAddress: e.email
    }
    ForgotPasswordService(data)
      .then((res) => {
        toast.success("İşlem başarılı");
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("İşlem başarısız");
        setIsLoading(false);
      });
  };

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <p className="backToLogin">
            <Link to="/login">
              <ArrowLeft className="rotate-rtl me-25" size={14} />
              <span className="align-middle">Giriş Yap</span>
            </Link>
          </p>
          <CardBody>
            <CardTitle tag="h4" className=" mt-1 mb-1">
              Şifremi Unuttum
            </CardTitle>
            <CardText className="mb-2">
              Şifre sıfırlama linkini göndermemiz için sistemde kullandığın mail
              adresini aşağıdaki alana girebilirsiniz.
            </CardText>
            <Form
              className="auth-forgot-password-form mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-2">
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="email"
                        id="email"
                        placeholder="john@example.com"
                        autoFocus
                        invalid={errors.email && true}
                      />
                    );
                  }}
                />
                {errors.email && (
                  <FormFeedback>
                    {errors.email.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              <LoadingButton
                loading={isLoading}
                disabled={!isDirty || !isValid}
                color="warning"
                block
                type="submit"
                className="mb-4"
              >
                Gönder
              </LoadingButton>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPasswordCard;
