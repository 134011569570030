import http from "../helper/http";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";
import {
  IGroupCreateDBModel,
  IGroupDBModel,
  IGroupEditDBModel,
  IGroupPriceCreateDBModel,
  IGroupPriceDBModel,
  IGroupPriceEditDBModel,
} from "../model/DbMdel/groupDBModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";

interface IGetGroupsDto extends IPagedResultRequest {}

export const getGroups = (
  getGroupsDto: IGetGroupsDto
): Promise<PagedResultDto<IGroupDBModel>> => {
  return http.get<any, PagedResultDto<IGroupDBModel>>("/Groups", {
    params: getGroupsDto,
  });
};

export const getGroupStudent = (id: IGroupDBModel["id"]) => {
  return http.get<any, IGroupDBModel>(`/Groups/${id}`);
};

export const createGroupStudents = (groupId: number, studentIds: number[]) => {
  return http.post<any, IGroupDBModel>(`/Groups/${groupId}/students`, {
    studentIds,
  });
};

export const createGroup = (
  group: IGroupCreateDBModel
): Promise<PagedResultDto<IGroupDBModel>> => {
  return http.post<any, PagedResultDto<IGroupDBModel>>("/Groups", group);
};

export const editGroup = (
  group: IGroupEditDBModel,
  id: number
): Promise<IGroupDBModel> => {
  return http.put<any, IGroupDBModel>(`/Groups/${id}`, group);
};

export const deleteGroup = (groupId: number) => {
  return http.delete<any, IGroupDBModel>(`/Groups/${groupId}`);
};

export const getGroupPrice = (
  getGroupsPriceDto: IGetGroupsDto
): Promise<PagedResultDto<IGroupPriceDBModel>> => {
  return http.get<any, PagedResultDto<IGroupPriceDBModel>>("/PriceGroups", {
    params: getGroupsPriceDto,
  });
};

export const createGroupPrice = (
  group: IGroupPriceCreateDBModel
): Promise<PagedResultDto<IGroupPriceDBModel>> => {
  return http.post<any, PagedResultDto<IGroupPriceDBModel>>(
    "/PriceGroups",
    group
  );
};

export const editGroupPrice = (
  group: IGroupPriceEditDBModel,
  id: number
): Promise<IGroupPriceDBModel> => {
  return http.put<any, IGroupPriceDBModel>(`/PriceGroups/${id}`, group);
};

export const deleteGroupPrice = (priceGroupId: number) => {
  return http.delete<any, IGroupDBModel>(`/PriceGroups/${priceGroupId}`);
};

export const deleteGroupUser = (groupId: number, studentId: number) => {
  return http.delete<any, IGroupDBModel>(
    `/Groups/${groupId}/students/${studentId}`
  );
};

export const exportFile = async (data: IExportFileResponse) => {
  return await http.post("/groups/export-file", data, { responseType: "blob" });
};

export const exportFilePrice = async (data: IExportFileResponse) => {
  return await http.post("/price-groups/export-file", data, { responseType: "blob" });
};
