import { Input, Row, Col, Card, Button } from "reactstrap";
import { forwardRef, useEffect, useState } from "react";
//@ts-ignore
import Avatar from "@components/avatar";
import { Link, useSearchParams } from "react-router-dom";
import FlatPickr from "react-flatpickr";
import DataTable from "../../common/DataTable/components/ssr";
import {
  ICoachSummaryModel,
  ISalaryDBModel,
} from "../../../model/DbMdel/salaryDBModel";
import getSalaries, {
  createSalaries,
  updateSalaries,
} from "../../../services/salary";
import moment from "moment";
import { ICoachDBModel } from "../../../model/DbMdel/coachDBModel";
import toast from "react-hot-toast";
import dateFormat from "../../../helper/dateFormat";
import getCoachs from "../../../services/coach";
import SwalFire from "../../common/Swal";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { exportFile } from "../../../services/coach";
import ExportDropdownFile from "../../common/ExportDropdownFile";
import { handleFiltered } from "../../../utility/helper";

const PersonFilterTable = () => {
  let [searchParams] = useSearchParams();

  const date = searchParams.get("date");
  const [dateFilter, setDateFilter] = useState(
    date
      ? moment(date).format(dateFormat)
      : moment(new Date()).format(dateFormat)
  );

  let selectedItem: ICoachDBModel[] = [];

  const [defaultSelected, setDefaultSelected] = useState<ICoachSummaryModel[]>(
    []
  );
  const [salaries, setSalaries] = useState<ISalaryDBModel | null>(null);
  const [maxResultCount, setMaxResultCount] = useState(1000);

  const fetchSalaries = async () => {
    setSalaries(null);
    getSalaries(dateFilter)
      .then((res) => {
        setSalaries(res);
        setDefaultSelected(res.trainers);
      })
      .catch((err) => {
        toast.error(err);
        setSalaries(null);
        setDefaultSelected([]);
      });
  };
  const today = moment().format("YYYY-MM-DD");

  const isToday = today === dateFilter;

  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<ICoachDBModel>({
      callbackService: getCoachs,
      isActive: isToday ? isToday : undefined,
      maxResultCount: maxResultCount
    });

  useEffect(() => {
    refetch();
  }, [dateFilter, maxResultCount]);

  useEffect(() => {
    Promise.all([fetchSalaries()]);
  }, [dateFilter]);

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const onSubmit = async () => {
    if (!salaries?.isExist) {
      await createSalaries({
        day: dateFilter,
        trainerIds: selectedItem.map((x) => x?.id),
      })
        .then((e) => {
          Promise.all([fetchSalaries()]);
          toast.success("Çanak kaydedildi.");
        })
        .catch((e) => {
          toast.error(e);
        });
    } else {
      await updateSalaries({
        day: dateFilter,
        trainerIds: selectedItem.map((x) => x?.id),
      })
        .then((e) => {
          Promise.all([fetchSalaries()]);
          toast.success("Çanak güncellendi.");
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };
  const renderClient = (row: ICoachDBModel) => {
    if (row.photoURL) {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
          <Avatar className="me-1" img={row.photoURL} width="32" height="32" />
        </Link>
      );
    } else {
      return (
        <Avatar
          className="me-1"
          color={"light-warning"}
          content={getSummaryName(`${row.user.name} ${row.user.surname}`)}
          title={row.user.name}
        />
      );
    }
  };

  const caseInsensitiveSort = (rowA: ICoachDBModel, rowB: ICoachDBModel) => {
    const a = rowA.user.name.toLowerCase();
    const b = rowB.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns: any = [
    {
      name: "İSİM",
      sortable: true,
      sortField: "client.name",
      sortFunction: caseInsensitiveSort,
      selector: (row: ICoachDBModel) => row.user.name,
      cell: (row: ICoachDBModel, rowIndex: number) => {
        return (
          <div className="d-flex justify-content-left align-items-center">
            {renderClient(row)}

            <div className="d-flex flex-column">
              <h6 className="user-name text-truncate mb-0">
                {row.user.name} {row.user.surname}
              </h6>
              <small className="text-truncate text-muted mb-0">
                {row.user.emailAddress}
              </small>
            </div>
          </div>
        );
      },
    },
  ];

  // ** Bootstrap Checkbox Component
  const BootstrapCheckbox = forwardRef((props: any, ref: any) => (
    <div className="form-check form-check-warning">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  const rowSelectCritera = (row: ICoachDBModel) => {
    return defaultSelected.some(
      (x) => x.trainer.id === row.id && x.includeFloating
    );
  };
  const ToDetails = () => (  //TODO: Css Yapılacak
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75 ">
      <Row
        className="d-flex pb-2 justify-content-between gap-1"
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex align-items-center px-md-1 px-lg-2"
        >
          <p className="mb-0">Çanak Listesi</p>
        </Col>
        <Col className="d-flex gap-1 justify-content-end align-items-center">
          <Col
            lg="6"
            md="6"
            sm="6"
            xs="5"
            className="d-flex align-items-center  "
          >
            <label className="mb-0 pe-1" htmlFor="search">
              Ara:
            </label>
            <Input
              id="search"
              type="text"
              placeholder="Ara"
              aria-label="Search Input"
              onChange={(e) => handleFiltered(e.target.value, handleSearch)}
            />
          </Col>
          <Col xl="4" lg="3" md="4" sm="3" className="d-flex align-items-center ">
            <label className="mb-0 pe-1">
              Göster:
            </label>
            <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
              {[100, 250, 500, 1000].map((option: number) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
          </Col>
        </Col>
      </Row>

      <Row
       className="d-flex pb-0 pt-2 justify-content-between align-items-center"
       >
        <Col
        className="mb-2 mb-md-0" 
        lg="3" md="4" xs="12">
          <FlatPickr
            disabled={false}
            id="serviceAreaEntryDate"
            style={{ opacity: "1" }}
            className={"form-control"}
            defaultValue={dateFilter}
            onChange={([date]) => {
              setDateFilter(moment(date).format(dateFormat));
            }}
          />
        </Col>
        {!salaries?.isExist && (
          <Col className="mb-2 mb-md-0" lg="5" md="4" xs="12">
            <p className="text-danger mb-0">Günün çanağı kaydedilmemiştir.</p>
          </Col>
        )}

        <Col
          className="d-flex justify-content-center   justify-content-md-end  p-0 px-md-1  px-lg-2 "
        >
          <div className="d-flex align-items-center table-header-actions gap-1">
            <ExportDropdownFile
              callbackService={exportFile}
              fileName={`çanak_listesi_${moment(new Date()).format(
                dateFormat
              )}`}
              headers={["Name"]}
            />
            <Button
              color="warning"
              disabled={salaries?.isConfirmed}
              onClick={() => {
                SwalFire({
                  title: "Emin misiniz?",
                  text: `Günün çanağını güncellemek üzeresiniz.
                  Bu alanda yaptığınız değişiklikler hesaplamalara etki edecektir.
                  İşleme devam etmek istiyor musunuz?`,
                  onOk: async () => {
                    onSubmit();
                  },
                });
              }}
            >
              {!salaries?.isExist ? "Çanağı Kaydet" : "Çanağı Güncelle"}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable
            subHeaderComponent={ToDetails()}
            columns={columns}
            data={data.items}
            pending={isLoading}
            selectableRowsComponent={BootstrapCheckbox}
            selectableRows
            onSelectedRowsChange={(e: any) => {
              selectedItem = e.selectedRows;
            }}
            selectableRowSelected={rowSelectCritera}
            totalCount={data.totalCount}
            pagination
            onChangePaginate={handlePaginate}
            pageNumber={page}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default PersonFilterTable;
