export const enum ExportFileTypeName {
  PDF = "Pdf",
  EXCEL = "Excel",
  CSV = "Csv",
}

export const enum ExportFileTypeExtension {
  PDF = "pdf",
  EXCEL = "xlsx",
  CSV = "csv",
}

export interface ExportFileType {
  name: ExportFileTypeName;
  extension: ExportFileTypeExtension;
}

export type ExportFileTypes = ExportFileType[];
