import { ILanguage } from "../commonModel/language";
import { PagedResultDto } from "../commonModel/pagedResultDto";

export interface IRoleUsersModel {
  id: number;
  name: string;
  displayName: string;
  normalizedName: string;
  description: string;
  grantedPermissions: string[];
}

export enum RoleTypes{
  Admin="Admin",
  Student="Student",
  Trainer="Trainer",
  Manager="Manager",


}

export interface IUsersModel {
  id: number;
  name: string;
  photo: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  isActive: boolean;
  isPanelAccess: boolean;
  languages: ILanguage[];
  roles: IRoleUsersModel[];
}

export interface IGetUsersModel extends PagedResultDto<IUsersModel> {
  activeCount: number;
}

export interface IUsersCreateModel {
  name: string;
  photo: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  isActive: boolean;
  password: string;
  isPanelAccess: boolean;
  languageIds: number[];
  roleNames: string[];
}

export interface IUsersEditModel {
  roleNames: string[];
}

export interface IUserEditModel {
  photo: string;
  name: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  isActive: boolean;
  isPanelAccess: boolean;
  languageIds: number[];
  roleNames: string[];
}

export interface FaDbModel {
  isEnabled2FA: boolean;
  twoFactorBase64: string;
  manualEntrySetup2FACode: string;
}
