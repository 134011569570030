import http from "../helper/http";
import { IForgotPasswordModel, IResetPasswordModel } from "../model/DbMdel/passwordDBModel";

export const ResetPasswordService = (
  param: IResetPasswordModel
  ): Promise<IResetPasswordModel> => {
    return http.post<any, IResetPasswordModel>(`/Users/reset-password/`, param );
  };

export const ForgotPasswordService = (
    email: IForgotPasswordModel,
  ): Promise<IForgotPasswordModel> => {
    return http.post<any, IForgotPasswordModel>(`/Users/reset-password-email/send`, email);
  };