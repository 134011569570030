// ** Router imports
import { useRoutes } from "react-router-dom";

// ** GetRoutes
import { getPrivateRoutes, getPublicRoutes } from "./routes";

// ** Hooks Imports
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";
import { Fragment } from "react";

const Router = () => {
  const getChildRoutes = (routes) => {
    return routes.map((route) => {
      if (route.children) {
        return {
          path: route.path,
          children: getChildRoutes(route.children),
        };
      }
      return {
        path: route.path,
        element: route.isPrivate ? (
          <LayoutWrapper {...{}}>{route.element}</LayoutWrapper>
        ) : (
          <Fragment>
            {route.element}
          </Fragment>
        ),
      };
    });
  };

  const AllRoutes = [];

  const privateRoute = getPrivateRoutes();
  const publicRoute = getPublicRoutes();
  AllRoutes.push({
    path: "/",
    element: <VerticalLayout />,
    children: getChildRoutes(privateRoute),
  });

  AllRoutes.push({
    path: "/",
    element: <BlankLayout />,
    children: getChildRoutes(publicRoute),
  });

  const routes = useRoutes([...AllRoutes]);

  return routes;
};

export default Router;
