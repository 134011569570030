import { useState, Fragment, useEffect } from "react";
import DataTable from "../../common/DataTable/components/ssr";
import {
  Slack,
  User,
  Settings,
  Command,
  Eye,
  Plus,
  Edit2,
} from "react-feather";

import {
  Input,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import getLanguages from "../../../services/language";

// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss";
//@ts-ignore
import Avatar from "@components/avatar";
import EditUserModal from "../EditUserModal";
import { IUsersModel } from "../../../model/DbMdel/usersDBModel";
import AddNewUserModal from "../AddNewUserModal";
import classnames from "classnames";
import { ILanguage } from "../../../model/commonModel/language";
import debounce from "lodash.debounce";
import { exportFile } from "../../../services/user";
import ExportDropdownFile from "../../common/ExportDropdownFile";
import moment from "moment";
import dateFormat from "../../../helper/dateFormat";
import { usePageContext } from "../../../utility/context/UserContext";
import { options } from "../../common/DataTable/Constants";
const UsersTable = () => {
  const [user, setUser] = useState<IUsersModel[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [show, setShow] = useState(false);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const { data: users, handlePaginate: handleTablePaginate, handleSearch: handleTableFilter, isLoading, page, setMaxResultCount, maxResultCount, refetch } =
  usePageContext();

  useEffect(() => {
    Promise.all([getLanguages()])
      .then(([languages]) => {
        setLanguages(languages as any);
      })
      .catch(() => {});
  }, []);

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  useEffect(() => {
    setUser(users.items);
  }, [users.items]);

  const getRole = (role: string) => {
    switch (role) {
      case "Admin":
        return {
          class: "text-danger",
          icon: Command,
        };
      case "Trainer":
        return {
          class: "text-success",
          icon: Slack,
        };
      case "Student":
        return {
          class: "text-success",
          icon: User,
        };
      case "Manager":
        return {
          class: "text-success",
          icon: Settings,
        };

      default:
        return {
          class: "text-warning",
          icon: Edit2,
        };
    }
  };
  const debouncedSearch = debounce((value) => {
    handleTableFilter(value);
  }, 500);
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const renderClient = (row: IUsersModel) => {
    if (row.photo) {
      return (
        <Link
          style={{ paddingRight: "10px" }}
          to={`/usersandpermissions/users/profile-detail/${row.id}`}
        >
          <Avatar img={row.photo} width="32" height="32" />
        </Link>
      );
    } else {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
          <Avatar
            className="me-1"
            color={"light-warning"}
            content={getSummaryName(`${row.name} ${row.surname}`)}
            title={row.name}
          />
        </Link>
      );
    }
  };

  const caseInsensitiveSort = (rowA: IUsersModel, rowB: IUsersModel) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "İSİM",
      sortable: true,
      sortFunction: caseInsensitiveSort,
      cell: (row: IUsersModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center text-truncate">
            {renderClient(row)}
            <div className="d-flex flex-column">
              <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
                <span className="user-name fw-bolder text-dark text-truncate mb-0 ">
                  {row.name} {row.surname}
                </span>
              </Link>
              <small className="text-truncate text-muted mb-0">
                {row.emailAddress}
              </small>
            </div>
          </div>
        );
      },
    },
    {
      name: "ROL",
      cell: (row: IUsersModel) => {
        //@ts-ignore
        const Icon = getRole(row.roles.length > 0 ? row.roles[0].name : "");

        return (
          <span className="text-truncate text-capitalize align-middle">
            <Icon.icon
              size={18}
              className={classnames("me-50", {
                //@ts-ignore
                [Icon.class]: true,
              })}
            />
            {row.roles.length == 0 ? "Empty" : row.roles[0].displayName}
          </span>
        );
      },
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      right: true,
      cell: (row: any) => {
        return (
          <Row className="d-flex align-items-center ">
            {/* <Col xs="6">
              <Button
                color="warning"
                className="btn-sm m-1"
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedIndex(row.id)}
              >
                <Edit className="font-medium-2" />
              </Button>
            </Col> */}
            {/* {TODO:} */}
            {/* /* if (admin ||  coach){
  <Link to={`/usersandpermissions/users/profile-detail${row.id}`}>
  <Eye className="font-medium-3 text-body" />
</Link> } 
          } */}

            {/* <Col xs="6">
              <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
                <Eye className="font-medium-3 text-body" />
              </Link>
            </Col> */}
            <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
              <Eye className="font-medium-3 text-body" />
            </Link>
          </Row>
        );
      },
    },
  ];

  // export güncellendiğinde silinecek
  // const filterData = () => {
  //   if (!users) {
  //     return [];
  //   }
  //   if (filterText) {
  //     return search(users, Object.keys(users[0]), filterText);
  //   }

  //   return users;
  // };

  useEffect(() => {
    refetch()
  }, [maxResultCount])
  
  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row className="d-flex pb-2 ">
        <Col
          lg="12"
          md="12"
          className="d-flex justify-content-center  p-0 px-md-1  px-lg-2  justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions gap-1">
            <ExportDropdownFile
              callbackService={exportFile}
              fileName={`kullanıcılar_${moment(new Date()).format(
                dateFormat
              )}`}
              headers={["Name", "RoleNames"]}
            />

            <Button color="warning" onClick={() => setShow(true)}>
              <Plus style={{ marginRight: "8px" }} size={14} />
              Yeni Kullanıcı Ekle
            </Button>
            <AddNewUserModal
              setShow={() => setShow(!show)}
              show={show}
              onReload={() => {
                setSelectedIndex(-1);
              }}
              languages={languages}
            />
            {users && selectedIndex > -1 && (
              <EditUserModal
                setShowEdit={() => setSelectedIndex(-1)}
                showEdit={selectedIndex !== -1}
                user={user?.find((x) => x.id === selectedIndex)!}
                onReload={() => {
                  setSelectedIndex(-1);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value))} >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </Col>
        <Col lg="4" md="6" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1" htmlFor="search">
            Ara:
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <Fragment>
      <Card>
        <CardHeader style={{ borderBottom: "1px solid #4B4B4B" }}>
          <CardTitle tag="h2">Ara ve Filtrele</CardTitle>
        </CardHeader>
        <CardBody className="px-0 ">
          <div className="react-dataTable react-dataTable-selectable-rows">
            <DataTable
              subHeaderComponent={ToDetails()}
              columns={columns as any}
              totalCount={users.totalCount}
              data={users.items}
              pagination
              onChangePaginate={handleTablePaginate}
              pending={isLoading}
              pageNumber={page}
              perPage={maxResultCount}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UsersTable;
