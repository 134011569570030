import { useState } from "react";
import { Button, Input, Modal as ReactstrapModal } from "reactstrap";
import { ModalProps } from "./types";
import "./style.scss";

const ConfirmationModal: React.FunctionComponent<ModalProps> = ({
  open,
  onConfirm,
  onClose,
  header,
  subheader,
  disabled = false,
  children,
  select,
  options,
  onSelect,
  primaryHeader,
}: ModalProps) => {
  const [isValid, setIsValid] = useState<boolean>(true);

  return (
    <ReactstrapModal
      isOpen={open}
      toggle={() => onClose()}
      className="modal-dialog-centered modal-lg confirmation-modal"
    >
      <div className={"modal-body"}>
        {/* Stil sınıfını kullanıyoruz */}
        <div className="swal2-icon swal2-warning swal2-icon-show custom-swal-icon">
          <div className="swal2-icon-content justify-content-center">!</div>
        </div>
        <div className={"primary-header"}>{primaryHeader}</div>
        <div className={"header"}>{header}</div>
        <div className={"subheader"}>{subheader}</div>
        {select && (
          <Input
            defaultValue=""
            type="select"
            onChange={(e) => {
              onSelect && onSelect(e.target.value);
              setIsValid(e.target.value ? false : true);
            }}
          >
            <option hidden value="">
              Ödeme Tipini Seçiniz
            </option>
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        )}
        {children}
        <div className={"buttons"}>
          <div className={"centerButton"}>
            <Button
              className="btn btn-warning"
              onClick={onConfirm}
              disabled={isValid}
            >
              {"ONAYLA"}
            </Button>
            <Button
              className="btn-outline-danger ms-1"
              color="link"
              onClick={() => {
                onClose();
                setIsValid(true);
              }}
              variant="outlined"
            >
              {"İPTAL"}
            </Button>
          </div>
        </div>
      </div>
    </ReactstrapModal>
  );
};

export default ConfirmationModal;
