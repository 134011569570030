// ** Styles
import "@styles/react/apps/app-users.scss";
import LocationTable from "../../../components/systemSettings/locationTable";

const LocationList = () => {
  return (
    <div className="app-user-view">
      <div className="app-user-list">
        <LocationTable />
      </div>
    </div>
  );
};
export default LocationList;
