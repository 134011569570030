import  DataTable  from "../../common/DataTable/components/ssr";
import { Input, Row, Col, Card, Button } from "reactstrap";
import classnames from "classnames";

import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import { editCoachs } from "../../../services/coach";
import { useEffect, useState } from "react";
import { Edit, Plus, Eye } from "react-feather";
import { Link } from "react-router-dom";
import CoachCreateModal from "./coachCreateModal";
import CoachEditModal from "./coachEditModal";
import {
  ICoachDBModel,
  IEditCoachDBModel,
} from "../../../model/DbMdel/coachDBModel";
import getCoachs from "../../../services/coach";
import { ILanguage } from "../../../model/commonModel/language";
import getLanguages from "../../../services/language";
import SwitchLabel from "../../common/SwitchLabel";
import { User } from "../../../model/commonModel/user";
//@ts-ignore
import Avatar from "@components/avatar";
import SwalFire from "../../common/Swal";
import toast from "react-hot-toast";
import AuthView from "../../common/AuthView";
import { UserRole } from "../../../model/commonModel/enums";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { exportFile } from "../../../services/coach";
import ExportDropdownFile from "../../common/ExportDropdownFile";
import { handleFiltered } from "../../../utility/helper";
import moment from "moment";
import dateFormat from "../../../helper/dateFormat";
import { defaultCount, options } from "../../common/DataTable/Constants";

const CoachListTransactions = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [show, setShow] = useState(false);
  const [clickedRowIndex, setClickedRowIndex] = useState<number>(-1);
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<ICoachDBModel>({
      callbackService: getCoachs,
      maxResultCount: maxResultCount
    });

  const handleChange = (
    index: number,
    key: keyof User,
    value: any,
    swalText: string
  ) => {
    const coachIndex = data.items?.findIndex((e) => e.id === index);

    const { user, comission, id, photoURL } = data.items![coachIndex!];
    const tempUser = { ...user, [key]: value };
    const tempCoach = { ...data.items![coachIndex!], user: tempUser };

    const tempData = {
      userDto: {
        name: user.name,
        surname: user.surname,
        emailAddress: user.emailAddress,
        phoneNumber: user.phoneNumber,
        isActive: user.isActive,
        photo: user.photo,
        password: "",
        isPanelAccess: user.isPanelAccess,
        languageIds: user.languages.map((e) => e.id),
        [key]: value,
      },
      id: id,
      photoURL: photoURL,
      comission: comission,
    } as IEditCoachDBModel;

    SwalFire({
      title: "Emin misiniz?",
      text: swalText,
      onOk: () => {
        setClickedRowIndex(index);
        editCoachs(tempData!, tempCoach!.id)
          .then((res) => {
            const tempCoachs = [...data.items!];
            tempCoachs[coachIndex!] = tempCoach;
            setClickedRowIndex(-1);
            refetch();
          })
          .catch((err) => {
            toast.error(err);
            setClickedRowIndex(-1);
          });
      },
    });
  };

  const fetchLanguage = async () => {
    getLanguages()
      .then((languages) => {
        setLanguages(languages as any);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    fetchLanguage();
  }, []);
  
  useEffect(() => {
    refetch()
  }, [maxResultCount])

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const renderClient = (row: ICoachDBModel) => {
    if (row.user.photo) {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.user.id}`}>
          <Avatar
            className="me-1"
            img={row.user.photo}
            width="32"
            height="32"
          />
        </Link>
      );
    } else {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.user.id}`}>
          <Avatar
            className="me-1"
            color={"light-warning"}
            content={getSummaryName(`${row.user.name} ${row.user.surname}`)}
            title={row.user.name}
          />{" "}
        </Link>
      );
    }
  };

  const caseInsensitiveSort = (rowA: ICoachDBModel, rowB: ICoachDBModel) => {
    const a = rowA.user.name.toLowerCase();
    const b = rowB.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "",
      sortField: "active",
      maxWidth: "30px",
      selector: (row: ICoachDBModel) => (
        <div className="d-flex align-items-center ">
          <AuthView roles={[UserRole.Manager]}>
            <Button
              size="sm"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                position: "relative",
                marginRight: "8px",
              }}
              color="warning"
              onClick={() => setSelectedIndex(row.id)}
            >
              <Edit
                size={18}
                style={{
                  position: "absolute",
                  top: "3px",
                  left: "5px",
                }}
              />
            </Button>
          </AuthView>
          <Link to={`/usersandpermissions/users/profile-detail/${row.user.id}`}>
            <Eye className="font-medium-3 text-body" />
          </Link>
        </div>
      ),
    },
    {
      name: "Antrenör",
      sortable: true,
      sortField: "client.name",
      minWidth: "250px",
      sortFunction: caseInsensitiveSort,
      selector: (row: ICoachDBModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center">
            {renderClient(row)}
            <div className="d-flex flex-column">
              <Link
                to={`/usersandpermissions/users/profile-detail/${row.user.id}`}
              >
                <span
                  className="user-name fw-bolder text-dark text-truncate mb-0 "
                  style={{ maxWidth: "120px" }}
                >
                  {row.user.name} {row.user.surname}
                </span>
              </Link>
              <small className="text-truncate text-muted mb-0">
                {row.user.emailAddress}
              </small>
            </div>
          </div>
        );
      },
    },
    {
      name: "GSM",
      sortField: "phone",
      selector: (row: ICoachDBModel) => row.user.phoneNumber,
    },
    {
      name: "KOMİSYON",
      sortField: "percent",
      selector: (row: ICoachDBModel) => row.comission,
    },
    {
      name: "AKTİF/PASİF",
      sortField: "active",
      cell: (row: ICoachDBModel) => {
        return (
          <div
            className={classnames("form-switch", {
              [`form-check-warning`]: row.user.isActive,
            })}
          >
            <Input
              type="switch"
              checked={row.user.isActive}
              id={`${row.id}_icon-primary_isActive`}
              name="icon-warning"
              onChange={(e) => {
                //@ts-ignore
                handleChange(
                  row.id,
                  "isActive",
                  e.target.checked,
                  "Antrenör durumunu değiştirmeniz , tanımlanacak olan etkinliklere direkt etki etmektedir.İşleme devam etmek istiyor musunuz?"
                );
              }}
            />
            <SwitchLabel htmlFor={`${row.id}_icon-primary_isActive`} />
          </div>
        );
      },
    },
    {
      name: "PANEL ERİŞİMİ",
      sortField: "panel",
      cell: (row: ICoachDBModel) => {
        return (
          <div
            className={classnames("form-switch", {
              [`form-check-warning`]: row.user.isPanelAccess,
            })}
          >
            <Input
              type="switch"
              checked={row.user.isPanelAccess}
              id={`${row.id}_icon-primary_isPanelAccess`}
              name="icon-warning"
              onChange={(e) => {
                //@ts-ignore
                handleChange(
                  row.id,
                  "isPanelAccess",
                  e.target.checked,
                  "Bu işlem antrenörün panele erişimine direkt olarak etki edecektir.İşleme devam etmek istiyor musunuz?"
                );
              }}
            />
            <SwitchLabel htmlFor={`${row.id}_icon-primary_isPanelAccess`} />
          </div>
        );
      },
    },
    {
      name: "DİLLER",
      sortField: "languages",
      selector: (row: ICoachDBModel) =>
        row.user.languages
          .map((x) => {
            return x.name;
          })
          .join(","),
    },
    {
      name: "EMAİL",
      sortField: "mail",
      selector: (row: ICoachDBModel) => row.user.emailAddress,
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start p-0 px-md-1  px-lg-2  "
        >
          <p>Antrenör Çizelgesi</p>
        </Col>

        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center p-0 px-md-1  px-lg-2  justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions gap-1">
            <ExportDropdownFile
              callbackService={exportFile}
              fileName={`antrenör_listesi_${moment(new Date()).format(
                dateFormat
              )}`}
              headers={[
                "Name",
                "PhoneNumber",
                "IsActive",
                "IsPanelAccess",
                "EmailAddress",
              ]}
            />
            <Button color="warning" onClick={() => setShow(true)}>
              <Plus style={{ marginRight: "8px" }} size={14} />
              Antrenör Ekle
            </Button>
            <CoachCreateModal
              setShow={() => setShow(!show)}
              show={show}
              languages={languages}
              onReload={() => {
                setSelectedIndex(-1);
                refetch();
              }}
            />
            {data.items && selectedIndex > -1 && (
              <CoachEditModal
                setShowEdit={() => setSelectedIndex(-1)}
                showEdit={selectedIndex !== -1}
                coach={data.items.find((x) => x.id === selectedIndex)!}
                languages={languages}
                onReload={() => {
                  setSelectedIndex(-1);
                  refetch();
                }}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </Col>
        <Col lg="4" md="6" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search">
            Ara:
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={(e) => handleFiltered(e.target.value, handleSearch)}
          />
        </Col>
      </Row>
    </div>
  );
  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable
            totalCount={data.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={data.items}
            pagination
            onChangePaginate={handlePaginate}
            pending={isLoading}
            pageNumber={page}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default CoachListTransactions;
