import { ReactNode, TableHTMLAttributes, useEffect, useState, useRef } from "react";
import moment from "moment";
import {
  IActivityDbModel,
  IActivityModel,
  ICreateActivityDbModel,
  IEditActivityDBModel,
} from "../../model/DbMdel/activityDBModel";
import { groupBy } from "lodash";
import classnames from "classnames";
import toast from "react-hot-toast";
import dateFormat from "../../helper/dateFormat";
import { Col, Spinner } from "reactstrap";
import { UserRole } from "../../model/commonModel/enums";
import { checkRoles } from "../../helper/permissionHelper";
import AuthView from "../common/AuthView";
import "./index.scss";
import SwalFire from "../common/Swal";
import {
  approveActivity,
  createActivity,
  deleteActivity,
  editActivity,
} from "../../services/activity";
import { useMyContext } from "../../utility/context/CalendarContext";
import { coachConverter } from "../../utility/helper";
import InfiniteScroll from "react-infinite-scroll-component";
import { ViewTypes } from "../../views/calendar/Calendar";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ConfirmationModal from "./ConfirmationModal/Modal";
import { TouchBackend } from "react-dnd-touch-backend";
import { isTouchDevice } from "../../helper/device";

export interface ICordinationInfoModel {
  date: string;
  coach: IOption;
}

interface DraggableTableCellProps
  extends TableHTMLAttributes<HTMLTableCellElement> {
  selectedActivity: IActivityDbModel;
  disabled?: boolean;
  children: ReactNode;
}

interface DroppableTableCellProps
  extends TableHTMLAttributes<HTMLTableCellElement> {
  cordination: ICordinationInfoModel;
  disabled?: boolean;
  children?: ReactNode;
}

// ** CalendarColors
const calendarsColor: any = {
  Confirmed: "success",
  Encashed: "info",
  EncashedByAdmin: "primary",
  WaitingToBeEncashedForPerson: "person",
  WaitingToBeEncashedForGroup: "group",
  IsDeleted: "danger",
};

interface IOption {
  value: string;
  id: number;
  label: string;
}

function ProgramPlugin(props: any) {
  // TODO: plugin componenti global bir component olduğu halde IFullCalender proplarına bağımlı yapıldığı
  // için refactor edilmesi gerekiyor. Servis modelleri db modellerini kullanıyor. ui modellerin oluşturulması gerekiyor.
  // Furkan Bulut.

  const {
    allActivity,
    coachs,
    salaryTrainers,
    handlePaginate,
    page,
    confirmed,
    perPage,
  } = useMyContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [coachsProgramTable, setCoachsProgramTable] = useState<IOption[]>([]);
  const [draggedActivityInfo, setDraggedActivityInfo] = useState<
    | {
      cordination: ICordinationInfoModel;
      activity: IActivityDbModel;
    }
    | undefined
  >(undefined);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const data = allActivity.filter(
    (activity: IActivityDbModel) =>
      props.viewType === ViewTypes.ProgramView
        ?
        checkRoles([UserRole.Manager])
          ? activity
          : !activity.days[0]?.isConfirmed
        : checkRoles([UserRole.Manager])
          ?
          activity.days[0]?.students.every((s) => s.isEncashed === true) &&
          !activity.days[0]?.isConfirmed
          : checkRoles([UserRole.Admin]) &&
          activity.days[0]?.students.some((s) => s.isEncashed === false) &&
          !activity.days[0]?.isConfirmed

  );
  const groupedData = groupBy(data, "trainer.user.id");

  const pickerDate = props.pickerDate;
  const confirmedProgramTable = confirmed;
  const isLoading = props.isLoading;
  const today = new Date().setHours(0, 0, 0, 0);
  const pickerDateStartOfDay = new Date(pickerDate).setHours(0, 0, 0, 0);
  const isTodayOrLater = today <= pickerDateStartOfDay
  const trainers = ((checkRoles([UserRole.Manager]) ||
    checkRoles([UserRole.Admin])) && !isTodayOrLater && salaryTrainers.length) ?
    salaryTrainers.map((trainer: any) => trainer.trainer) : coachs;




  const refetchActivities = props.refetchActivities;
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const isPast =
    new Date(moment(pickerDate).format(dateFormat)).getTime() <
    new Date(moment(new Date()).format(dateFormat)).getTime();

  useEffect(() => {
    const noActivityTrainerList = trainers.filter((trainer: IOption) => {
      return Object.keys(groupedData).indexOf(trainer.value) === -1;
    });

    setCoachsProgramTable((prevState) => {
      const newTrainers = coachConverter(noActivityTrainerList);
      return [...newTrainers];
    });
  }, [salaryTrainers, coachs, page]);

  const loadMore = () => {
    setTimeout(() => {
      if (!isLoading) {
        handlePaginate(page + 1);
        if (page + 1 >= trainers.length / perPage) {
          setHasMoreItems(false);
        }
      }
    }, 500);
  };

  const handleTrainerClick = (trainerActivities: IActivityModel[]) => {
    let hasError = true;

    if (trainerActivities && checkRoles([UserRole.Manager])) {
      SwalFire({
        title: "Emin misiniz?",
        text: `Antrenörün tahsil edilen bütün etkinliklerini onaylamak üzeresiniz. 
        İşleme devam etmek istiyor musunuz?`,
        onOk: async () => {
          trainerActivities
            .filter(
              (f) => moment(f.startDate).format(dateFormat) === pickerDate
            )
            .map(async (activity: IActivityModel, index: number) => {
              const encasched = activity.days[0]?.students.some(
                (s) => s.isEncashed === true
              );
              const isConfirmed = activity.days[0]?.isConfirmed;

              if (!isConfirmed && encasched) {
                hasError = false;
                await approveActivity({
                  activityId: activity.id,
                  confirm: true,
                  day: moment(new Date(activity.startDate)).format(dateFormat),
                })
                  .then((e) => {
                    refetchActivities(e);
                  })
                  .catch((e) => {
                    toast.error(e);
                  });
              } else {
                if (hasError && trainerActivities.length - 1 === index) {
                  toast.error(
                    "Antrenörün onaylanacak etkinliği bulunmamaktadır."
                  );
                }
              }
            });
        },
      });
    }
  };

  const updateActivity = async () => {
    setLoading(true);
    const isDeletedActiviy = draggedActivityInfo?.activity.isDeleted;
    const activity = draggedActivityInfo?.activity;
    const cordination = draggedActivityInfo?.cordination;
    if (activity && cordination) {
      const students: number[] = activity.students.map((student) => student.id);
      const parsedHours = parseInt(cordination.date);
      const newStartDate = new Date(activity.startDate).setHours(parsedHours);
      const newEndDate = new Date(activity.endDate).setHours(parsedHours + 1);

      const payload: IEditActivityDBModel = {
        activityType: activity.activityType,
        description: activity.description,
        groupId: activity?.group?.id,
        locationId: activity?.location?.id,
        name: activity.name,
        perHourPrice: activity.perHourPrice,
        students: students,
        endDate: new Date(newEndDate).toUTCString(),
        startDate: new Date(newStartDate).toUTCString(),
        trainerId: cordination.coach.id,
      };

      if (isDeletedActiviy) {
        await deleteActivity(activity?.id, false).finally(() => {
          setLoading(false);
        });
      }

      await editActivity(payload, activity?.id!)
        .then((res) => {
          toast.success("Activity Güncelleme başarılı");
          refetchActivities(res);
        })
        .catch((err) => {
          toast.error(err);
        }).finally(() => {
          setLoading(false);
        });
      setDraggedActivityInfo(undefined);
    }
  };

  const duplicateActivity = async () => {
    setLoading(true);
    const activity = draggedActivityInfo?.activity;
    const cordination = draggedActivityInfo?.cordination;
    if (activity && cordination) {
      const students: number[] = activity.students.map((student) => student.id);
      const parsedHours = parseInt(cordination.date);
      const newStartDate = new Date(activity.startDate).setHours(parsedHours);
      const newEndDate = new Date(activity.endDate).setHours(parsedHours + 1);

      const payload: ICreateActivityDbModel = {
        activityType: activity.activityType,
        description: activity.description,
        groupId: activity?.group?.id,
        locationId: activity?.location?.id,
        name: `${activity.location.name}/${cordination.coach.label}`,
        perHourPrice: activity.perHourPrice,
        students: students,
        endDate: new Date(newEndDate).toUTCString(),
        startDate: new Date(newStartDate).toUTCString(),
        trainerId: cordination.coach.id,
      };

      await createActivity(payload)
        .then((res) => {
          toast.success("Activity Ekleme başarılı");
          refetchActivities(res);
        })
        .catch((err) => {
          toast.error(err);
        }).finally(() => {
          setLoading(false);
        });
      setDraggedActivityInfo(undefined);
    }
  };

  const tableHeader = [
    "Antrenör",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
  ];

  const TableHeaders = ({ }) => (
    <thead>
      <tr>
        {tableHeader.map((x, index) => {
          return <th key={index}>{x}</th>;
        })}
      </tr>
    </thead>
  );

  const DraggableTableCell: React.FC<DraggableTableCellProps> = ({
    selectedActivity,
    children,
    disabled = false,
    ...rest
  }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: "activityDragging",
        item: selectedActivity,
        canDrag: () => !disabled,
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
      }),
      []
    );
    const [startY, setStartY] = useState<number | null>(null);
    const [scrollNavigate, setScrollNavigate] = useState<
      "top" | "bottom" | null
    >(null);

    const handleTouchStart = (e: React.TouchEvent<HTMLTableCellElement>) => {
      setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLTableCellElement>) => {
      if (startY !== null && containerRef.current) {
        const deltaY = e.touches[0].clientY - startY;
        if (deltaY > 100) {
          setScrollNavigate("bottom");
        } else if (deltaY < -100) {
          setScrollNavigate("top");
        }
      }
    };

    const handleTouchEnd = (e: React.TouchEvent<HTMLTableCellElement>) => {
      setStartY(null);
      setScrollNavigate(null);
    };

    useEffect(() => {
      if (scrollNavigate) {
        const scrollInterval = setInterval(() => {
          if (containerRef.current) {
            if (scrollNavigate === "bottom") {
              containerRef.current.scrollTop += 10;
            } else {
              containerRef.current.scrollTop -= 10;
            }
          }
        }, 50);

        // Belirli bir süre sonra setInterval'i temizle
        setTimeout(() => {
          clearInterval(scrollInterval);
        }, 500000);

        return () => {
          // useEffect temizleme işlemi
          clearInterval(scrollInterval);
        };
      }
    }, [scrollNavigate]);

    return (
      <td
        {...rest}
        ref={drag}
        style={{
          ...rest.style,
          opacity: isDragging ? 0.5 : 1,
          cursor: isDragging ? "grab" : "cursor",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {children}
      </td>
    );
  };

  const DroppableTableCell: React.FC<DroppableTableCellProps> = ({
    disabled = false,
    cordination,
    children,
    ...rest
  }) => {
    const [, drop] = useDrop(
      () => ({
        accept: "activityDragging",
        canDrop: () => !disabled,
        drop: (e: IActivityDbModel) => {
          setDraggedActivityInfo({ cordination: cordination, activity: e });
          setModalVisible(true);
        },
        collect: (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
        }),
      }),
      []
    );
    return (
      <td {...rest} ref={drop}>
        {children}
      </td>
    );
  };

  const renderTable = () => (
    <InfiniteScroll
    
      dataLength={coachsProgramTable.length}
      hasMore={hasMoreItems}
      loader={
        isLoading ? (
          <h4 style={{ textAlign: "center" }}>
            <Spinner color="warning" />
          </h4>
        ) : (
          ""
        )
      }
      next={loadMore}
      scrollableTarget={"scrollTable"}
    >
      <div ref={containerRef} className="table-wrapper">
      <table id="scrollTable" className="table-responsive programViewTable">
        <TableHeaders />
        <tbody>
          {coachsProgramTable.map((coach: IOption, coachIndex: number) => (
            <tr key={coachIndex}>
              {tableHeader.map((cell, cellIndex) => {
                if (cellIndex === 0) {
                  return (
                    <td
                      key={cellIndex}
                      className={
                        !trainers.includeFloating ? "includeFloating" : ""
                      }
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title={coach.label}
                      onClick={() =>
                        handleTrainerClick(
                          pickerDate &&
                          groupedData[coach.value]?.filter(
                            (t: IActivityDbModel) =>
                              moment
                                .utc(t.startDate)
                                .local()
                                .format(dateFormat) === pickerDate
                          )
                        )
                      }
                    >
                      <div className="trainer-cell">{coach.label}</div>
                    </td>
                  );
                } else {
                  const findActivity: IActivityDbModel | undefined =
                    groupedData[coach.value]?.find(
                      (t: IActivityDbModel) =>
                        moment.utc(t.startDate).local().format("HH") === cell &&
                        moment.utc(t.startDate).local().format(dateFormat) ===
                        pickerDate
                    );
                  let encasherUser;
                  if (
                    findActivity &&
                    findActivity.days[0]?.students.some(
                      (s) => s.isEncashed === true
                    )
                  ) {
                    encasherUser = findActivity.days[0]?.students.find(
                      (s) => s.isEncashed === true
                    )?.encasherUser;
                  }

                  if (findActivity) {
                    const colorKey = findActivity.isDeleted
                      ? "IsDeleted"
                      : findActivity.days[0]?.isConfirmed
                        ? "Confirmed"
                        : findActivity.days[0]?.students.every(
                          (s) => s.isEncashed === true
                        )
                          ? encasherUser
                            ? findActivity.trainer.user.id === encasherUser.id
                              ? "Encashed"
                              : "EncashedByAdmin"
                            : "Encashed"
                          : findActivity.activityType === "Person"
                            ? "WaitingToBeEncashedForPerson"
                            : "WaitingToBeEncashedForGroup";

                    const isSelected = props.multipleActivitiesId.includes(
                      findActivity.id
                    );
                    return (
                      <DraggableTableCell
                        key={findActivity.id.toString()}
                        selectedActivity={findActivity}
                        disabled={
                          checkRoles([UserRole.Trainer]) ||
                          (checkRoles([UserRole.Admin]) ? isPast : false)
                        }
                        style={{
                          border: isSelected ? "3px solid" : "none",
                          borderColor: checkRoles([UserRole.Manager])
                            ? "#FD7E14"
                            : "#F0B967",
                          textAlign: "center",
                        }}
                        className={classnames(
                          `bg-light-${calendarsColor[colorKey]}`,
                          {
                            ["group"]: findActivity.activityType === "Group",
                            ["person"]: findActivity.activityType === "Person",
                          }
                        )}
                        onClick={() => {
                          if (ViewTypes.ApprovalView === props.viewType) {
                            findActivity &&
                              !isLoading && !findActivity.isDeleted &&
                              props.handleMultipleSelect(findActivity.id);
                          } else
                            !isLoading && findActivity && props.handleEdit(findActivity.id);
                        }}
                      >
                        <div className="box-container">
                          {findActivity.activityType === "Group" ? (
                            <div
                              className="activity-cell d-flex"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title={findActivity.group.name}
                            >
                              {findActivity.group.name}
                            </div>
                          ) : (
                            findActivity.students.map((student, index) => (
                              <span
                                key={index}
                                className="activity-cell d-flex"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title={`${student.user.name} ${student.user.surname}`}
                              >
                                {student.user.name} {student.user.surname}
                              </span>
                            ))
                          )}
                          {encasherUser&& findActivity.trainer.user.id !== encasherUser.id  && (
                            <span className="encasher-user">
                              Onaylayan:{" "}
                              {`${encasherUser.name} ${encasherUser.surname}`}
                            </span>
                          )}
                        </div>
                      </DraggableTableCell>
                    );
                  } else {
                    return (
                      <DroppableTableCell
                        cordination={{ date: cell, coach: coach }}
                        disabled={checkRoles([UserRole.Admin]) ? isPast : false}
                        onClick={() => {
                          if (checkRoles([UserRole.Trainer])) {
                            return;
                          }
                          if (!checkRoles([UserRole.Manager]) && isPast) {
                            toast.error(
                              "Geçmiş günlerde ekleme işlemi yapılamaz"
                            );
                            return;
                          }
                          !isLoading && props.handleAdd(cell, coach.id);
                        }}
                      />
                    );
                  }
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <p style={{ textAlign: "center" }}>
          Toplam Antrenör: {trainers.length} Adet
        </p>
      </div>
    </InfiniteScroll>
  );

  return (
    <>
        <AuthView roles={[UserRole.Admin, UserRole.Trainer]}>
          {confirmedProgramTable ? (
            <div className="d-flex align-items-center justify-content-center">
              <span className="badge text-danger">
                Gün onayı yöneticiniz tarafından verildiği için günün
                programını görüntüleyememektesiniz.
              </span>
            </div>
          ) : (
            !isLoading && (
              <DndProvider
                backend={isTouchDevice() ? TouchBackend : HTML5Backend}
              >
                {renderTable()}
              </DndProvider>
            )
          )}
        </AuthView>
        <AuthView roles={[UserRole.Manager]}>
          <DndProvider
            backend={isTouchDevice() ? TouchBackend : HTML5Backend}
            options={{ enableMouseEvents: true }}
          >
            {renderTable()}
          </DndProvider>
        </AuthView>

        {/* // isPast ? <AuthView roles={[UserRole.Admin, UserRole.Trainer]}>
      //   <div className="d-flex align-items-center justify-content-center">
      //     <span className="badge text-danger">Eski günlere ait günün programını görüntüleyememektesiniz.</span>
      //   </div>
      // </AuthView> :  */}
      {draggedActivityInfo && (
        <ConfirmationModal
          loading={loading}
          open={modalVisible}
          draggedActivityInfo={draggedActivityInfo}
          onClose={() => setModalVisible(false)}
          onMove={updateActivity}
          onCopy={duplicateActivity}
        />
      )}
    </>
  );
}

export default ProgramPlugin;
