// ** React Imports
import { useEffect, useState } from "react";
// ** Third Party Components
import ReactPaginate from "react-paginate";
import { ChevronDown, Edit } from "react-feather";
import DataTable from "react-data-table-component";
// ** Reactstrap Imports
import { Input, Row, Col, Button, Badge } from "reactstrap";

// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss";
import EditTransactionAuthorizationModal from "../EditTransactionAuthorizationModal";
import { getNameAndRolTempdata } from "../../../services/roles";

// ** Table Header
const CustomHeader = ({
  plan,
  handlePlanChange,
  handlePerPage,
  rowsPerPage,
  handleFilter,
  searchTerm,
}: any) => {
  return (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row>
        <Col xl="6" className="d-flex align-items-center p-0">
          <div className="d-flex align-items-center w-100">
            <label htmlFor="rows-per-page">Göster</label>
            <Input
              className="mx-50"
              type="select"
              id="rows-per-page"
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: "5rem" }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Input>
          </div>
        </Col>
        <Col
          xs="12"
          xl="6"
          className="d-flex align-items-sm-center justify-content-lg-end justify-content-start flex-lg-nowrap flex-wrap flex-sm-row flex-column pe-lg-1 p-0 mt-lg-0 mt-1"
        >
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              Ara:
            </label>
            <Input
              type="text"
              value={searchTerm}
              id="search-invoice"
              className="ms-50 w-100"
              onChange={(e: any) => handleFilter(e.target.value)}
              placeholder="Ara"
            />
          </div>
          <Input
            value={plan}
            type="select"
            style={{ width: "10rem" }}
            onChange={(e) => handlePlanChange(e.target.value)}
          >
            <option value="">Rol seç</option>
            <option value="admin">Admin</option>
            <option value="manager">Yönetici</option>
            <option value="coach">Antrenör</option>
            <option value="student">Öğrenci</option>
          </Input>
        </Col>
      </Row>
    </div>
  );
};

const RolesTable = () => {
  // ** Store Vars

  // ** States
  const [show, setShow] = useState(false);
  const [plan, setPlan] = useState("");
  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("id");
  const [nameAndRole, setnameAndRole] = useState<any[]>([]);


  useEffect(() => {
    const tempData = getNameAndRolTempdata();
    setnameAndRole(tempData);
  }, []);

  // ** Get data on mount
  // ** Function in get data on page change
  const handlePagination = (page: any) => {
    setCurrentPage(page.selected + 1);
  };
  const handleEditClick = (e: any) => {
    setShow(true);
  };

  // ** Function in get data on rows per page
  const handlePerPage = (e: any) => {
    const value = parseInt(e.currentTarget.value);

    setRowsPerPage(value);
  };

  // ** Function in get data on search query change
  const handleFilter = (val: any) => {
    setSearchTerm(val);
  };

  const handlePlanChange = (val: any) => {
    setPlan(val);
  };

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = 5;

    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end py-2 pe-1 pagination-warning"
        }
      />
    );
  };

  // ** Table data to render

  const handleSort = (column: any, sortDirection: any) => {
    setSort(sortDirection);
    setSortColumn(column.sortField);
  };
  const colors = {
    admin: "light-warning",
    student: "light-success",
    manager: "light-danger",
    coach: "light-info",
  };

  const columns = [
    {
      name: "İSİM",
      sortable: true,
      sortField: "fullName",
      cell: (row: any) => row.name,
    },
    {
      name: "ROL",
      sortField: "role",
      cell: (row: any) => {
        return row.role.map((x: any) => {
          return (
            //@ts-ignore
            <Badge pill color={colors[x]} className="text-capitalize">
              {x}
            </Badge>
          );
        });
      },
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      right: true,
      cell: (row: any) => (
        <Button
          size="sm"
          color="transparent"
          className="btn btn-icon"
          onClick={() => handleEditClick(row)}
        >
          <Edit className="font-medium-2" />
        </Button>
      ),
    },
  ];



  return (
    <div className="react-dataTable react-dataTable-selectable-rows">
      <DataTable
        noHeader
        subHeader
        pagination
        responsive
        columns={columns}
        onSort={handleSort}
        data={nameAndRole}
        sortIcon={<ChevronDown />}
        paginationComponent={CustomPagination}
        className="react-dataTable"
        subHeaderComponent={
          <CustomHeader
            plan={plan}
            searchTerm={searchTerm}
            rowsPerPage={rowsPerPage}
            handleFilter={handleFilter}
            handlePerPage={handlePerPage}
            handlePlanChange={handlePlanChange}
          />
        }
      />
      <EditTransactionAuthorizationModal
        show={show}
        onSubmit={() => { }}
        setShow={setShow}
      />
    </div>
  );
};

export default RolesTable;
