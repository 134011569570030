import usePaginateFetch from "../hooks/usePaginateFetch";
import { IUsersModel } from "../../model/DbMdel/usersDBModel";
import getUsers from "../../services/user";
import { createContext, useContext, useState } from "react";
import { defaultCount } from "../../components/common/DataTable/Constants";

const UserPageContext = createContext<any>(null);

const UserContext = ({ children }: any) => {
  const [maxResultCount, setMaxResultCount] = useState(defaultCount)
  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<IUsersModel>({
      callbackService: getUsers,
      maxResultCount: maxResultCount
    });
  return (
    <UserPageContext.Provider
      value={{
        data,
        handlePaginate,
        handleSearch,
        isLoading,
        refetch,
        page,
        setMaxResultCount,
        maxResultCount
      }}
    >
      {children}
    </UserPageContext.Provider>
  );
};

export const usePageContext = () => useContext(UserPageContext);
export default UserContext;
