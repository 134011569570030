import http from "../helper/http";
import { IUserUIModel } from "../model/UIModel/userUIModel";

const getUserInfo = async (): Promise<IUserUIModel> => {
  const data = await http.get<any,any>("/Users/CurrentUserInformations");
  return data.user
};


export default getUserInfo;
