import classnames from "classnames";
import { UseFormRegister } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./index.scss";
interface IPhoneInputComponent {
  register: UseFormRegister<any>;
  invalid?: boolean;
  onChange: (
    value: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => void;
}
const PhoneInputComponent = (props: IPhoneInputComponent) => {
  const ref = props.register("phoneNumber").ref;
  return (
    <PhoneInput
      inputProps={{
        required: true,
        name: "phoneNumber",
        ref: ref,
      }}
      autoFormat={true}
      enableAreaCodeStretch={false}
      dropdownClass={classnames("react-select", {
        "is-invalid": props.invalid,
      })}
      inputClass={classnames("form-control", {
        "is-invalid": props.invalid,
      })}
      placeholder=""
      country={"tr"}
      specialLabel={""}
      enableSearch={true}
      disableSearchIcon={true}
      searchPlaceholder="Ara"
      searchNotFound="Gösterilecek veri yok"
      {...props}
      onChange={(
        value: string,
        data: CountryData,
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string
      ) => props.onChange(value, data, event, formattedValue)}
    />
  );
};

export default PhoneInputComponent;
