import React from "react";
//@ts-ignore
import Breadcrumbs from "@components/breadcrumbs";
import RolesCard from "../../../components/UserRoles/RolesCard";
import RolesTable from "../../../components/UserRoles/RolesTable";

const Roles = () => {
  return (
    <div>
      <Breadcrumbs
        title="Roller"
        data={[{ title: "Kullanıcılar ve İzinler" }, { title: "Roller" }]}
      />
      <RolesCard />
      <div className="app-user-list">
        <RolesTable />
      </div>
    </div>
  );
};

export default Roles;
