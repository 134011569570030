import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, Fragment, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { createGroupPrice } from "../../../../services/groups";
import toast from "react-hot-toast";
import LoadingButton from "../../../common/LoadingButton";
import { IGroupPriceCreateDBModel } from "../../../../model/DbMdel/groupDBModel";
import InputNumber from "rc-input-number";
import { Plus, Minus } from "react-feather";
import "@styles/react/libs/input-number/input-number.scss";
import SwalFire from "../../../common/Swal";
import LocationDropdown from "../../../locationForPage";

interface IGroupPriceCreateDBModelProps {
  setShow: (value: SetStateAction<boolean>) => void;
  show: boolean;
  onReload: () => void;
}

const GroupPriceCreateModal: FC<IGroupPriceCreateDBModelProps> = ({
  setShow,
  show,
  onReload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const AddStudentSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Grup fiyat başlığı zorunlu alandır")
      .max(64, "Grup fiyat başlığı en fazla 64 karakter olmalıdır")
      .matches(
        /^([A-Za-z\ıİğĞşŞ\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s0-9]*)$/gi,
        "Geçerli bir grup fiyat başlığı giriniz"
      )
      .required("Grup fiyat başlığı zorunlu alandır"),

    maxusercount: yup
      .number()
      .min(2, "En az 2 kişi olmalıdır.")
      .max(999, "Maksimum sayıya ulaştınız!")
      .required("Kişi sayısı zorunlu alandır"),
    peruserprice: yup
      .number()
      .typeError("Geçerli bir sayı giriniz")
      .positive("Sıfırdan büyük bir sayı giriniz")
      .required("Kişi başına fiyat zorunlu alandır"),
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddStudentSchema),
    defaultValues: {
      maxusercount: 2,
    } as any,
  });

  const onSubmit = async (e: any) => {
    SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup fiyat listesi oluşturmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        const data: IGroupPriceCreateDBModel = {
          name: e.name,
          maxUserCount: e.maxusercount,
          perUserAndHourPrice: e.peruserprice,
          locationId: e.location.id,
        };
        setIsLoading(true);
        createGroupPrice(data as any)
          .then((res) => {
            toast.success("Grup Fiyatı Ekleme Başarılı.");
            setShow(false);
            onReload();
            setIsLoading(false);
            reset({
              name: "",
              maxusercount: 0,
              locationId: 0,
            });
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };

  const handleCancel = () => {
    setShow(!show);
    reset({ name: "", peruserprice: "" });
    clearErrors();
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={handleCancel}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleCancel}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Grup Fiyatı Oluştur</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={12} xs={12}>
                <Label>Lokasyon</Label>
                <LocationDropdown
                  invalid={!!errors.location}
                  control={control}
                  errorLocation={errors.location}
                  onChange={(event) => setValue("location", event)}
                />
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="name">
                  Grup Fiyat Başlığı
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("name").ref}
                        id="name"
                        placeholder="Grup fiyat başlığı"
                        invalid={errors.name && true}
                        type="text"
                        maxLength={64}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Label className="form-label" for="basic-number-input">
                  Kişi Sayısı
                </Label>
                <Controller
                  name="maxusercount"
                  control={control}
                  render={({ field }) => {
                    return (
                      <InputNumber
                        {...field}
                        min={2}
                        max={999}
                        color="warning"
                        id="basic-number-input"
                        upHandler={<Plus />}
                        downHandler={<Minus />}
                      />
                    );
                  }}
                />
                {errors.maxusercount && (
                  <FormFeedback>
                    {errors.maxusercount.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col lg={6} md={6} xs={12}>
                <Label className="form-label" for="peruserprice">
                  Kişi Başı Ücret
                </Label>
                <InputGroup>
                  <Controller
                    name="peruserprice"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          innerRef={register("peruserprice").ref}
                          id="peruserprice"
                          invalid={errors.peruserprice && true}
                          placeholder="Kişi başı ücret"
                        />
                      );
                    }}
                  />
                  <InputGroupText>TL</InputGroupText>

                  {errors.peruserprice && (
                    <FormFeedback>
                      {errors.peruserprice.message?.toString()}
                    </FormFeedback>
                  )}
                </InputGroup>
              </Col>
              <Col xs={12} className="text-center mt-5 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={handleCancel}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default GroupPriceCreateModal;
