// ** Third Party Components
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import "./index.scss";
// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { FC } from "react";
import {
  ICoachSummaryModel,
  ISalaryDBModel,
} from "../../model/DbMdel/salaryDBModel";
import { groupBy } from "lodash";
import { ICoachGroupByModel } from "../../model/commonModel/groupCoach";
interface IProps {
  groupTrainers: ICoachGroupByModel[];
}

const CoachProfitRate: FC<IProps> = ({ groupTrainers }) => {


  //   const tolalProfit =  parseInt(summaryData.reduce((acc: number, cur: ISalaryDBModel) => {
  //     return acc + (cur.totalEarnings - cur.totalPaidCommission);
  //   }, 0)).toFixed(2);


  // const totalCommission = parseInt(
  //   summaryData 
  //     .reduce((acc: number, cur: ISalaryDBModel) => {
  //       return acc + cur.totalPaidCommission;
  //     }, 0)
  //     .toFixed(2)
  // );

  //   const total = calcGroupedData.reduce(
  //     (acc: number, cur: ICoachSummaryModel) => {
  //       return acc + cur.differenceAmount;
  //     },
  //     0
  //   );


  const donutColors = {
    trainer1: "#FF9F43",
    trainer2: "#28C76F",
    trainer3: "#7367F0",
    trainer4: "#FF9B9E",
  };

  // ** Chart Options
  const options: ApexOptions = {
    legend: {
      show: true,
      position: "bottom",
      labels: {
        useSeriesColors: false,
      },
    },
    stroke: {
      show: false,
    },
    labels: groupTrainers.map((item) => {
      return item.trainer.user.name + ' ' + item.trainer.user.surname;
    }),
    colors: [
      donutColors.trainer1,
      donutColors.trainer4,
      donutColors.trainer3,
      donutColors.trainer2,
    ],
    dataLabels: {
      enabled: true,
      formatter(val: any) {
        return `${parseFloat(val).toFixed(1)}%`;
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              fontSize: "1rem",
              fontFamily: "Montserrat",
              formatter(val: any) {
                return `${parseInt(val)}%`;
              },
            },
            total: {
              show: true,
              fontSize: "1.5rem",
              label: "",
              formatter() {
                return "";
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "1.5rem",
                  },
                  value: {
                    fontSize: "1rem",
                  },
                  total: {
                    fontSize: "1.5rem",
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  // ** Chart Series
  const series = groupTrainers.map((item) => {
    return item.companyComissionAmount;
  });

  return (
    <Card>
      <CardHeader>
        <div>
          <CardTitle className="mb-75" tag="h4">
            Antrenör Bazında Kâr Oranı
          </CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        <Chart options={options} series={series} type="donut" height={350} />
      </CardBody>
    </Card>
  );
};

export default CoachProfitRate;
