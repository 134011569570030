//  React Imports
import { Outlet } from "react-router-dom";

//  Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

//  Menu Items Array
import navigation from "@src/navigation/vertical";

import { getRoutes } from "../router/routes";
import { checkRoles } from "../helper/permissionHelper";

const VerticalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  //  For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  // const getNavbar = (routes) => {
  //   return routes.map((route) => {
  //     if (route.children) {
  //       return {
  //         path: route.path,
  //         element: route.element,
  //         children: getChildRoutes(route.children),
  //       };
  //     }
  //     return {
  //       path: route.path,
  //       element:  route.element,

  //     };
  //   });
  // };

  return (
    <Layout
      menuData={getRoutes()
        .filter((x) => x.showMenu && checkRoles(x.roles))
        .map((x) => {
          return {
            id: x.id,
            title: x.title,
            icon: x.icon,
            navLink: x.path,
            tag: x.path,
            roles: x.roles,
            children:
              typeof x.children !== "undefined"
                ? x.children.filter((r) => checkRoles(r.roles)).map((y) => {
                    return {
                      id: y.id,
                      title: y.title,
                      icon: y.icon,
                      navLink: `${x.path}/${y.path}`,
                      tag: y.path,
                      roles: y.roles
                    };
                  })
                : null,
          };
        })}
      {...props}
    >
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
