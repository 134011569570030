// ** React Import
import { useRef, memo, useState, useEffect, useMemo } from "react";

import FullCalendar, { createPlugin } from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import Moment from "moment";
import toast from "react-hot-toast";
import { Menu } from "react-feather";
import { Button, Card, CardBody } from "reactstrap";
import trLocale from "@fullcalendar/core/locales/tr";
import ProgramPlugin from "../../components/ProgramViewTable";
import AuthView from "../../components/common/AuthView";
import { UserRole } from "../../model/commonModel/enums";
import dateFormat from "../../helper/dateFormat";
import SwalFire from "../../components/common/Swal";
import { confirmSalary } from "../../services/salary";
import { ISavedSalaries } from "../../model/DbMdel/salaryDBModel";
import { checkRoles } from "../../helper/permissionHelper";
import { useMyContext } from "../../utility/context/CalendarContext";
import {
  IActivityDbModel,
  IEncashAllModel,
  IMultipleConfirmActivityModel,
} from "../../model/DbMdel/activityDBModel";
import moment from "moment";
import {
  confrimMultipleActivity,
  encashAllActivity,
} from "../../services/activity";
import usePaginateFetch from "../../utility/hooks/usePaginateFetch";
import { IPaymentMethodDBModel } from "../../model/DbMdel/paymentMethodDBModel";
import { getAllPaymentMethodService } from "../../services/paymentMethod";
import ConfirmationModal from "./ConfirmationModal/Modal";

interface ICalendarProps {
  toggleSidebar: (e: boolean) => void;
  handleAddEventSidebar: (date: string, trainerId: number) => void;
  handleEditEventSidebar: (id: number) => void;
  changeProgramView: (e: boolean) => void;
  pickerDate: string;
  trainers: IOption[];
  getActiveRange: (range: string[]) => void;
  programView: boolean;
  refetchActivities: (e: IActivityDbModel) => void;
  activitiesIsLoading: boolean;
}

export enum ViewTypes {
  ProgramView = "programView",
  ApprovalView = "approvalView",
}

interface IOption {
  value: string;
  id: number;
  label: string;
}

interface ICalendar {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  backgroundColor: string;
  display: string;
  extendedProps: {
    calendar: string;
    start: Date;
  };
}

// ** CalendarColors
const calendarsColor: any = {
  Person: "warning",
  Group: "warning",
  Confirmed: "success",
  Encashed: "info",
  EncashedByAdmin: "primary",
  WaitingToBeEncashed: "warning",
  IsDeleted: "danger",
};

const Calendar = (props: ICalendarProps) => {
  const { savedSalaries, allActivity, setPickerDate, isLoading } =
    useMyContext();

  const calendarRef: any = useRef();

  const {
    handleAddEventSidebar,
    handleEditEventSidebar,
    toggleSidebar,
    changeProgramView,
    pickerDate,
    getActiveRange,
    programView,
    activitiesIsLoading,
  } = props;

  const [programSelected, setProgramSelected] = useState(programView);
  const [activities, setActivities] = useState<ICalendar[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [multipleActivitiesId, setMultipleActivitiesId] = useState<number[]>(
    []
  );
  const [viewType, setViewType] = useState(ViewTypes.ProgramView);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const {
    data: paymentData,
    handlePaginate,
    isLoading: paymentIsLoading,
    handleSearch,
    refetch,
    page,
  } = usePaginateFetch<IPaymentMethodDBModel>({
    callbackService: getAllPaymentMethodService,
    maxResultCount: 1000,
    isActive: true,
  });

  const data = allActivity.filter((coach: IActivityDbModel) =>
    viewType !== ViewTypes.ProgramView
      ? coach
      : checkRoles([UserRole.Manager])
        ? coach.trainer?.user.isActive &&
        coach.days[0].students.every((s) => s.isEncashed === true) &&
        !coach.days[0].isConfirmed
        : checkRoles([UserRole.Admin]) &&
        coach.trainer?.user.isActive &&
        coach.days[0]?.students.some((s) => s.isEncashed === false) &&
        !coach.days[0].isConfirmed &&
        !coach.days[0].isDeleted
  );

  useEffect(() => {
    getActiveRange(dateRange);
  }, [dateRange]);

  const eventList = useMemo(() => {
    const activityList: ICalendar[] = [];
    savedSalaries.map((salary: ISavedSalaries) => {
      const foundedActivities = allActivity.filter(
        (activity: any) =>
          new Date(Moment(activity.startDate).format(dateFormat)).getTime() ===
          new Date(Moment(salary.date).format(dateFormat)).getTime()
      );
      const isPast =
        new Date(Moment(salary.date).format(dateFormat)).getTime() <
        new Date(Moment(new Date()).format(dateFormat)).getTime();
      let calendar = "IsDeleted";

      if (foundedActivities.length) {
        foundedActivities.map((activity: IActivityDbModel) => {
          const isEncasched = !activity.days[0]?.students.some(
            (s: any) => s.isEncashed === false
          );
          const encasherUser = activity.days[0]?.students.filter(
            (s: any) => s.isEncashed === true
          )[0]?.encasherUser;
          const isConfirmed = activity.days[0]?.isConfirmed;

          if (activity.isDeleted) {
            calendar = "IsDeleted";
          } else if (!isConfirmed) {
            if (isEncasched) {
              if (encasherUser) {
                if (activity.trainer.user.id === encasherUser.id) {
                  calendar = "Encashed";
                } else if (activity.trainer.user.id !== encasherUser.id) {
                  calendar = "EncashedByAdmin";
                }
              } else {
                calendar = "Encashed";
              }
            } else if (!isEncasched) {
              calendar = "WaitingToBeEncashed";
            }
          } else if (isConfirmed) {
            calendar = "Confirmed";
          }

          if (salary.isConfirmed) {
            if (checkRoles([UserRole.Admin, UserRole.Trainer])) {
              activityList.push({
                id: -1,
                title: "",
                start: new Date(
                  Moment.utc(activity.startDate).local().format()
                ),
                end: new Date(Moment.utc(activity.endDate).local().format()),
                allDay: true,
                backgroundColor: "rgb(143, 223, 130,0.5)",
                display: "background",
                extendedProps: {
                  calendar: "",
                  start: activity.startDate,
                },
              } as ICalendar);
            } else {
              activityList.push({
                id: activity.id,
                title: activity.name,
                start: new Date(
                  Moment.utc(activity.startDate).local().format()
                ),
                end: new Date(Moment.utc(activity.endDate).local().format()),
                allDay: false,
                extendedProps: {
                  calendar: calendar,
                  start: activity.startDate,
                },
              } as ICalendar);
            }
          } else {
            activityList.push({
              id: activity.id,
              title: activity.name,
              start: new Date(Moment.utc(activity.startDate).local().format()),
              end: new Date(Moment.utc(activity.endDate).local().format()),
              allDay: false,
              extendedProps: {
                calendar: calendar,
                start: activity.startDate,
              },
            } as ICalendar);
          }
        });
      } else {
        if (salary.isConfirmed) {
          if (checkRoles([UserRole.Admin, UserRole.Trainer])) {
            activityList.push({
              id: -1,
              title: "",
              start: new Date(Moment.utc(salary.date).local().format()),
              end: new Date(
                Moment.utc(salary.date).add(1, "day").local().format()
              ),
              allDay: true,
              backgroundColor: "rgb(143, 223, 130,0.5)",
              display: "background",
              extendedProps: {
                calendar: "",
                start: new Date(Moment.utc(salary.date).local().format()),
              },
            } as ICalendar);
          }
        } else {
          if (isPast) {
            if (checkRoles([UserRole.Admin, UserRole.Trainer])) {
              activityList.push({
                id: -1,
                title: "",
                start: new Date(Moment.utc(salary.date).local().format()),
                end: new Date(
                  Moment.utc(salary.date).add(1, "day").local().format()
                ),
                allDay: true,
                display: "background",
                backgroundColor: "rgba(234, 84, 85, 0.5)",
                extendedProps: {
                  calendar: "",
                  start: new Date(Moment.utc(salary.date).local().format()),
                },
              });
            }
          }
        }
      }
    });

    return activityList;
  }, [allActivity]);

  useEffect(() => {
    setActivities(eventList);
  }, [allActivity, activitiesIsLoading]);

  useEffect(() => {
    setConfirmed(allActivity[0]?.isDayConfirmed); // gün içindeki bütün aktivitelerin isdayconfirmed aynı geliyor o yüzden herhangi birini kontrol etmek yeterli
  }, [allActivity]);

  useEffect(() => {
    if (programSelected) {
      calendarRef.current?.getApi().gotoDate(pickerDate);
    }
  }, [pickerDate, programSelected]);

  useEffect(() => {
    if (pickerDate) {
      setPickerDate(pickerDate);
    }
  }, [pickerDate]);

  const handleEdit = (activityId: number) => {
    handleEditEventSidebar(activityId);
  };

  const handleAdd = (selectedHour: string, trainerId: number) => {
    const date = Moment(new Date(pickerDate))
      .set("hour", Number(selectedHour))
      .format("YYYY-MM-DD HH:MM");
    handleAddEventSidebar(date, trainerId);
  };

  const handleMultipleSelect = (activityId: number | null) => {
    if (activityId === null) {
      setMultipleActivitiesId([]);
    } else {
      if (multipleActivitiesId.includes(activityId))
        setMultipleActivitiesId((prevstate) =>
          prevstate.filter((id) => id !== activityId)
        );
      else setMultipleActivitiesId((prevstate) => [...prevstate, activityId]);
    }
  };
  const managerMultipleConfrimHandler = () => {
    const selectedDatas = data.filter(
      (t: IActivityDbModel) =>
        moment.utc(t.startDate).local().format(dateFormat) === pickerDate &&
        multipleActivitiesId.includes(t.id)
    );

    const listConfirm: IMultipleConfirmActivityModel[] = [];
    selectedDatas.map((t: IActivityDbModel) => {
      listConfirm.push({
        activityIds: t.id,
        day: Moment(new Date(pickerDate)).format(dateFormat),
      });
    });

    if (checkRoles([UserRole.Manager]) && programSelected) {
      SwalFire({
        title: "Emin misiniz?",
        text: `Seçilenleri doğrulamak üzeresiniz. İşleme devam etmek istiyor musunuz?`,
        onOk: async () => {
          await confrimMultipleActivity(listConfirm)
            .then((results) => {
              results.map((result) => props.refetchActivities(result));
              toast.success(`Seçilenler doğrulandı`);
              setIsOpen(false);
              setMultipleActivitiesId([]);
            })
            .catch((e) => {
              toast.error(e);
            });
        },
      });
    }
  };

  const adminMultipleApproveHandler = async () => {
    const SelectedDatas = data.filter(
      (t: IActivityDbModel) =>
        moment.utc(t.startDate).local().format(dateFormat) === pickerDate &&
        multipleActivitiesId.includes(t.id)
    );
    const encashList: IEncashAllModel[] = [];

    SelectedDatas.map((t: IActivityDbModel) =>
      encashList.push({
        isEncashed: true,
        paymentTypeId: +selectedOption,
        activityId: t.id,
        day: Moment(new Date(pickerDate)).format(dateFormat),
      })
    );

    if (checkRoles([UserRole.Admin]) && programSelected) {
      await encashAllActivity(encashList)
        .then((results) => {
          results.map((result) => props.refetchActivities(result));
          setIsOpen(false);
          setMultipleActivitiesId([]);
          toast.success(`Seçilen etkinlikler öğrenciler için onaylandı`);
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };

  class MorePropsToView {
    transform(viewProps: any, calendarProps: any) {
      return {
        ...viewProps,
        options: calendarProps.viewSpec.optionOverrides,
        calendar: calendarProps.calendarApi,
        pickerDate: props.pickerDate,
        handleEdit: handleEdit,
        handleAdd: handleAdd,
        handleMultipleSelect: handleMultipleSelect,
        viewType: viewType,
        multipleActivitiesId: multipleActivitiesId,
        isLoading: isLoading,
        refetchActivities: props.refetchActivities,
      };
    }
  }

  // @ts-ignore
  const calendarOptions: any = {
    events: activities,
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      createPlugin({
        views: {
          programView: ProgramPlugin,
          approvalView: ProgramPlugin,
        },
        viewPropsTransformers: [MorePropsToView],
      }),
    ],
    initialView: "programView",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      center:
        programView &&
        (viewType === ViewTypes.ProgramView
          ? "dayConfirm"
          : viewType === ViewTypes.ApprovalView &&
            multipleActivitiesId.length > 0 &&
            (checkRoles([UserRole.Manager])
              ? "multipleConfirm"
              : "multipleApprove")),
      end: checkRoles([UserRole.Trainer])?"timeGridWeek,listMonth,programView":"approvalView,timeGridWeek,listMonth,programView",
    },
    locale: trLocale,
    editable: true,
    eventResizableFromStart: false,
    dragScroll: false,
    navLinks: true,
    dayMaxEvents: 2,
    eventClassNames({ event: calendarEvent }: any) {
      const colorName =
        calendarsColor[calendarEvent._def.extendedProps.calendar];
      return [`bg-light-${colorName}`];
    },
    eventClick({ event: clickedEvent }: any) {
      const id = clickedEvent._def.publicId;
      handleEditEventSidebar(id);
    },
    customButtons: {
      sidebarToggle: {
        // @ts-ignore
        text: <Menu className="d-block" />,
        click() {
          toggleSidebar(true);
        },
      },

      dayConfirm: {
        // @ts-ignore
        text: programSelected ? (
          <AuthView roles={[UserRole.Manager]}>
            <Button
              disabled={isLoading}
              title="Günü Onayla"
              size="sm"
              color={confirmed ? "relief-danger" : "relief-success"}
            >
              {confirmed ? "Gün Onayını Geri Al" : "Gün Onayı"}
            </Button>
          </AuthView>
        ) : (
          ""
        ),
        click: () => {
          if (checkRoles([UserRole.Manager]) && programSelected) {
            SwalFire({
              title: "Emin misiniz?",
              text: confirmed
                ? `Gün onayını geri almak üzeresiniz. İşleme devam etmek istiyor musunuz?`
                : `Günü onaylamak üzeresiniz. İşleme devam etmek istiyor musunuz?`,
              onOk: async () => {
                await confirmSalary({
                  isConfirmed: !confirmed,
                  day: Moment(new Date(pickerDate)).format(dateFormat),
                })
                  .then((e) => {
                    setConfirmed(!confirmed);
                    toast.success(
                      !confirmed ? `Gün onaylandı` : `Gün onayı geri alındı`
                    );
                  })
                  .catch((e) => {
                    toast.error(e);
                  });
              },
            });
          }
        },
      },
      multipleConfirm: {
        text: programSelected && !isLoading && (
          <AuthView roles={[UserRole.Manager]}>
            <Button
              title="Seçimleri Doğrula"
              size="sm"
              color={"relief-success"}
            >
              Seçilenleri Doğrula
            </Button>
          </AuthView>
        ),
        click: () => {
          managerMultipleConfrimHandler();
        },
      },
      multipleApprove: {
        text: programSelected && !isLoading && (
          <AuthView roles={[UserRole.Admin]}>
            <Button title="Seçimleri Onayla" size="sm" color={"relief-success"}>
              Seçilenleri Onayla
            </Button>
          </AuthView>
        ),
        click: () => {
          setIsOpen(true);

          if (!options.length)
            paymentData.items.map((item: IPaymentMethodDBModel) => {
              setOptions((prevOptions) => [
                ...prevOptions,
                { value: item.id, label: item.name },
              ]);
            });

          const SelectedDatas = data.filter(
            (t: IActivityDbModel) =>
              moment.utc(t.startDate).local().format(dateFormat) ===
                pickerDate && multipleActivitiesId.includes(t.id)
          );
          setTotalPrice(
            SelectedDatas.reduce(
              (total: number, item: IActivityDbModel) =>
                total +
                (item.perHourPrice ? item.perHourPrice : item.location.price),
              0
            )
          );
        },
      },

      timeGridWeek: {
        text: "Hafta",
        click: () => {
          calendarRef.current.getApi().changeView("timeGridWeek");
          setProgramSelected(false);
          handleMultipleSelect(null);
          changeProgramView(false);
        },
      },
      listMonth: {
        text: "Liste",
        click: () => {
          calendarRef.current.getApi().changeView("listMonth");
          setProgramSelected(false);
          handleMultipleSelect(null);
          changeProgramView(false);
        },
      },
      programView: {
        text: "Program",
        click() {
          calendarRef.current.getApi().changeView("programView");
          setProgramSelected(true);
          changeProgramView(true);
          handleMultipleSelect(null);
          setViewType(ViewTypes.ProgramView);
        },
      },
      approvalView: {
        text: <span style={{ padding: "5px 10px" }}>Onay</span>,
        click() {
          calendarRef.current.getApi().changeView("approvalView");
          setProgramSelected(true);
          changeProgramView(true);
          setViewType(ViewTypes.ApprovalView);
        },
      },
    },
    dateClick(info: DateClickArg) {
      if (checkRoles([UserRole.Trainer])) {
        return;
      }

      const result = savedSalaries.find(
        (s: any) =>
          new Date(Moment(s.date).format(dateFormat)).getTime() ===
          new Date(Moment(info.date).format(dateFormat)).getTime()
      );

      if (checkRoles([UserRole.Trainer, UserRole.Admin])) {
        if (result && result.isConfirmed) {
          toast.error(
            "Gün onayı yöneticiniz tarafından verildiği için işlem yapılamaz"
          );
          return;
        }
      }

      if (
        new Date(Moment(info.date).format(dateFormat)).getTime() <
        new Date(Moment(new Date()).format(dateFormat)).getTime()
      ) {
        toast.error("Geçmiş günlerde işlem yapılamaz");
        return;
      }

      if (!programSelected) {
        const date = Moment(new Date(new Date(info.date).setHours(8))).format(
          "YYYY-MM-DD HH:MM"
        );
        handleAddEventSidebar(date, -1);
      }
    },
    eventDrop({ event: droppedEvent }: any) {
      toast.success("Event Updated");
    },
    eventResize({ event: resizedEvent }: any) {
      toast.success("Event Updated");
    },
    eventContent({ event: contentEvent }: any) {
      //console.log(contentEvent._def.ui)
      // savedSalaries.find((s) => new Date(Moment(s.date).format(dateFormat)).getDate() === new Date(Moment(event.startDate).format(dateFormat)).getDate())
      // e.backgroundColor = 'red'
      // if (calendarRef.current.getApi() !== null && !programSelected) {
      //   const activeRange =
      //     calendarRef.current.getApi().currentDataManager.state.dateProfile
      //       .activeRange;
      //   getStartDate(Moment(new Date(activeRange.start)).format(dateFormat));
      //   getEndDate(Moment(new Date(activeRange.end)).format(dateFormat));
      // }
    },
    datesSet(e: any) {
      const start = Moment(new Date(e.startStr)).format(dateFormat);
      const end = Moment(new Date(e.endStr)).format(dateFormat);

      setDateRange((prevState) => {
        if (prevState[0] === start && prevState[1] === end) {
          return prevState;
        } else {
          return [start, end];
        }
      });
    },

    dayCellDidMount(arg: any) {
      // arg.el.style.borderColor = '#4B4B4B'
      // console.log(arg)
      //#28c76f1f
      //arg.el.style.backgroundColor = '#283F46'
      // if (arg._def.extendedProps.savedSalaries.length) {
      //   const result = arg._def.extendedProps.savedSalaries.find((s: any) => new Date(Moment(s.date).format(dateFormat)).getDate() === new Date(Moment(arg.date).format(dateFormat)).getDate())
      //   if (result && result.isConfirmed)
      //     arg.el.style.backgroundColor = '#162731'
      //   if (arg.isToday)
      //     arg.el.style.backgroundColor = ''
      // }
    },
  };
  return (
    <Card className="shadow-none border-0 mb-0 rounded-0">
      <ConfirmationModal
        primaryHeader={totalPrice.toString()}
        header="Emin misiniz?"
        subheader="Etkinliği öğrenci için onaylamak üzeresiniz. İşleme devam etmek istiyor musunuz?"
        onClose={() => setIsOpen(false)}
        onConfirm={adminMultipleApproveHandler}
        open={isOpen}
        options={options}
        select
        onSelect={(selectedValue) => {
          setSelectedOption(selectedValue);
        }}
      ></ConfirmationModal>
      <CardBody className="pb-0">
        <FullCalendar ref={calendarRef} {...calendarOptions} />
      </CardBody>
    </Card>
  );
};

export default memo(Calendar);
