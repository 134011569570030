// ** Reactstrap Imports
import { Card, CardTitle, CardBody, Input, Button } from "reactstrap";

//*Style
import "@styles/base/pages/user-info.scss";
import { forwardRef, useEffect, useState } from "react";
import {
  deleteAllNotification,
  deleteNotifications,
  getNotificationsForPaginate,
} from "../../../services/notification";
import { INotificationDBModel } from "../../../model/DbMdel/notificationDBModel";
import toast from "react-hot-toast";
import SwalFire from "../../common/Swal";
import DataTable from "../../common/DataTable/components/ssr";
import { useAuth } from "../../../utility/context/Auth";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { defaultCount, options } from "../../common/DataTable/Constants";
import { useParams } from "react-router";

const Notifications = () => {
  const { userInfo } = useAuth();
  const {id: userId} = useParams()
  const [selectedItem, setSelectedItem] = useState<INotificationDBModel[]>([])
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const { data: notifications, handlePaginate, isLoading, handleSearch, refetch, page } =
  usePaginateFetch<INotificationDBModel>({
    callbackService: (params: any) => getNotificationsForPaginate({userId , ...params}), 
    maxResultCount: maxResultCount
  });

  const removeNotifications = () => {
    SwalFire({
      title: "Emin misiniz?",
      text: `Seçilen bildirimleri silmek üzeresiniz. İşleme devam etmek istiyor musunuz?`,
      onOk: async () => {
        const notificationIds = selectedItem.map((x) => x?.id);

        try {
          // if (isAllSelected && userInfo?.id === parseInt(userId!)) {
          //   await deleteAllNotification(parseInt(userId!));
          // } else {
            await deleteNotifications(notificationIds);
          // }
          toast.success("Silme işlemi başarılı");
          await refetch();
        } catch (err) {
          toast.error(err as any);
        }
      },
    });
  };

  useEffect(() => {
    refetch()
  }, [maxResultCount,userId])
  
  const BootstrapCheckbox = forwardRef((props: any, ref: any) => (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  const rowSelectCritera = (row: INotificationDBModel) => {
    return false;
  };

  const columns = [
    {
      name: "İÇERİK",
      sortable: true,
      sortField: "content",
      cell: (row: INotificationDBModel) => (
        <div className="d-flex justify-content-center ">{row.content}</div>
      ),
    },
  ];
  return (
    <Card>
      <CardBody className="d-flex justify-content-between gap-1">
        <div>
          <CardTitle className="mb-50" tag="h4">
            Bildirimler
          </CardTitle>
          <p className="mb-0">
            Bildirimleri inceledikten sonra isterseniz silebilirsiniz.
          </p>
        </div>
        <div >
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </div>
      </CardBody>
      {
        <CardBody>
          <div className="react-dataTable react-dataTable-selectable-rows">
            <DataTable
              subHeader={false}
              columns={columns}
              data={notifications.items}
              pending={isLoading}
              pagination
              onChangePaginate={handlePaginate}
              selectableRowsComponent={BootstrapCheckbox}
              selectableRows
              onSelectedRowsChange={(e: {
                allSelected: boolean;
                selectedCount: number;
                selectedRows: INotificationDBModel[];
              }) => {
                setIsAllSelected(e.allSelected);
                setSelectedItem(e.selectedRows);
              }}
              totalCount={notifications.totalCount}
              perPage={maxResultCount}
              pageNumber={page}
            />
          </div>
        </CardBody>
      }
      <CardBody className="d-flex justify-content-end">
        <Button disabled={selectedItem.length === 0} color="warning" onClick={removeNotifications}>
          Bildirimleri Temizle
        </Button>
      </CardBody>
    </Card>
  );
};

export default Notifications;
