// @ts-ignore
// ** React Imports
import { FC, Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Label,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

// ** Custom Components
//@ts-ignore
import * as yup from "yup";
// ** Third Party Components
//@ts-ignore
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import img1 from "@src/assets/img/10.png";
import Moment from "moment";
//@ts-ignore
import Select, { components, CSSObjectWithLabel } from "react-select"; // eslint-disable-line
//@ts-ignore
import Avatar from "@components/avatar";
import toast from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import { selectThemeColors } from "../../../utility/Utils";
import LoadingButton from "../../../components/common/LoadingButton";
import classnames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IActivityDbModel,
  ICreateActivityDbModel,
} from "../../../model/DbMdel/activityDBModel";
import { createActivity } from "../../../services/activity";
import getCoachs from "../../../services/coach";
import { ICoachDBModel } from "../../../model/DbMdel/coachDBModel";
import getStudents from "../../../services/students";
import { getGroups, getGroupStudent } from "../../../services/groups";
import { IGroupDBModel } from "../../../model/DbMdel/groupDBModel";
import { IStudentDBModel } from "../../../model/DbMdel/studentDBModel";
import { Link } from "react-router-dom";
import { groupLocations } from "../../../helper/locations";
import { InfiniteScrollDropdown } from "../../../components/InfiniteScroll";
import { handleFilter } from "../../../utility/helper";
import { PagedResultDto } from "../../../model/commonModel/pagedResultDto";
import useInfinityPaginateFetch from "../../../utility/hooks/userInfinityPaginateFetch";
import LocationDropdown from "../../../components/locationForPage";
import { Plus } from "react-feather";
import "./index.scss"
interface AddNewEventModal {
  setShow: (e: boolean) => void;
  show: boolean;
  date?: string;
  refetchActivities: (e: PagedResultDto<IActivityDbModel>) => void;
  trainerId: number;
  trainer: ICoachDBModel;
  addStudentModalVisible?: boolean;
  addGroupModalVisible?: boolean;
  onClickAddStudent?: () => void;
  onClickAddGroup?: () => void;
}

interface IOption {
  value: string;
  id: number;
  label: string;
}

const AddNewEventModal: FC<AddNewEventModal> = ({
  show,
  setShow,
  date,
  refetchActivities,
  trainerId,
  trainer,
  addStudentModalVisible,
  addGroupModalVisible,
  onClickAddStudent,
  onClickAddGroup,
}) => {
  const AddNewEvent = yup.object().shape({
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
    coach: yup
      .object()
      .shape({
        value: yup.string().required("Antrenör seçimi zorunlu alandır."),
        label: yup.string().required("Antrenör seçimi zorunlu alandır."),
      })
      .required("Antrenör seçimi zorunlu alandır."),
    eventType: yup
      .object()
      .shape({
        value: yup.string().required("Etkinlik türü seçimi zorunlu alandır."),
        label: yup.string().required("Etkinlik türü seçimi zorunlu alandır."),
      })
      .required("Etkinlik türü seçimi zorunlu alandır."),
    groups: yup.object().when("eventType", {
      is: (eventType: any) => eventType?.id === 1,
      then: yup
        .object()
        .shape({
          value: yup.string().required("Grup seçimi zorunlu alandır."),
          label: yup.string().required("Grup seçimi zorunlu alandır."),
        })
        .required("Grup seçimi zorunlu alandır."),
    }),
    students: yup.array().when("eventType", {
      is: (eventType: any) => eventType?.id === 2,
      then: yup
        .array()
        .min(1, {
          value: yup.string().required("Ogrenci seçimi zorunlu alandır."),
          label: yup.string().required("Ogrenci seçimi zorunlu alandır."),
        })
        .required("Ogrenci seçimi zorunlu alandır."),
    }),
    customSwitch: yup.boolean().notRequired(),
    lessonPrice: yup.number().when("customSwitch", {
      is: (customSwitch: boolean) => customSwitch === true,
      then: yup
        .number()
        .min(0, "0 dan büyük olmalıdır.")
        .required("Ders fiyatı zorunlu alandır."),
    }),
    startDate: yup
      .date()
      .default(new Date())
      .required("Lütfen başlangıç tarihi giriniz."),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [coachs, setCoachs] = useState<IOption[]>([]);
  const [students, setStudents] = useState<IOption[]>([]);
  const [groups, setGroups] = useState<IOption[]>([]);
  const [groupStudents, setGroupStudents] = useState<IGroupDBModel | null>(
    null
  );

  // ** Hooks
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddNewEvent),
    defaultValues: {
      locationId: 0,
      coach: trainer && {
        id: trainer.id,
        value: trainer.user.name + " " + trainer.user.surname,
        label: trainer.user.name + " " + trainer.user.surname,
      },
      startDate: date
        ? new Date(new Date(date).setMinutes(0))
        : new Date(new Date().setMinutes(0)),
    } as any,
  });

  const eventType = watch("eventType");
  const selectedGroup = watch("groups");

  const options: IOption[] = [
    { id: 2, value: "person", label: "Kişi" },
    { id: 1, value: "group", label: "Grup" },
  ];

  // ** Requests
  const {
    data: coachData,
    isLoading: coachLoading,
    handleSearch: coachSearch,
    handlePaginate: coachPaginate,
    page: coachPages,
    searchValue: coachSearchValue,
  } = useInfinityPaginateFetch<ICoachDBModel>({
    callbackService: getCoachs,
    isActive: true,
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    handleSearch: studentSearch,
    handlePaginate: studentPaginate,
    page: studentPages,
    searchValue: studentSearchValue,
    refetch: refetchStudent,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getStudents,
    isActive: true,
    locationId: getValues("location.id") ?? 0,
  });

  const {
    data: groupData,
    isLoading: groupLoading,
    handleSearch: groupSearch,
    handlePaginate: groupPaginate,
    page: groupPages,
    searchValue: groupSearchValue,
    refetch: refetchGroup,
  } = useInfinityPaginateFetch<IGroupDBModel>({
    callbackService: getGroups,
    locationId: getValues("location.id") ?? 0,
  });

  //** Fetchs

  const fetchCoachs = async () => {
    if (coachs.length < 1) {
      const coachs = coachData.items.map((coach) => {
        return {
          id: coach.id,
          value: coach.user.name + " " + coach.user.surname,
          label: coach.user.name + " " + coach.user.surname,
        } as IOption;
      });

      setCoachs(coachs);
    }
  };

  const fetchStudents = async () => {
    if (students.length < 1) {
      const students = studentData.items.map((student) => {
        return {
          id: student.id,
          value: student.user.name + " " + student.user.surname,
          label: student.user.name + " " + student.user.surname,
        } as IOption;
      });

      setStudents(students);
    }
  };

  const fetchGroups = async () => {
    if (groups.length < 1) {
      const groups = groupData.items
        .filter((s) => s.students.length > 1)
        .map((group) => {
          return {
            id: group.id,
            value: group.name,
            label: group.name,
          } as IOption;
        });

      setGroups(groups);
    }
  };

  const fetchGroupStudents = async () => {
    await getGroupStudent(selectedGroup.id)
      .then((e: IGroupDBModel) => {
        setGroupStudents(e);
        setValue("customSwitch", false);
        locationsValue(e);
      })
      .catch((e) => {});
  };

  const locationsValue = (e: IGroupDBModel) => {
    const locationId = watch("locations.id");
    const locationPricebyId = groupLocations.find(
      (loc) => loc.id === locationId && loc.students === e?.students.length
    )?.price;
    if (locationPricebyId)
      setValue("lessonPrice", locationPricebyId * e?.students.length!);
  };

  useEffect(() => {
    if (selectedGroup?.id && selectedGroup?.id !== -1) fetchGroupStudents();
  }, [selectedGroup, watch("locations")]);

  useEffect(() => {
    fetchCoachs();
  }, [coachData]);

  useEffect(() => {
    fetchStudents();
  }, [studentData]);

  useEffect(() => {
    fetchGroups();
  }, [groupData]);

  useEffect(() => {
    refetchGroup();
  }, [addGroupModalVisible]);
  useEffect(() => {
    refetchStudent();
  }, [addStudentModalVisible]);

  const onSubmit = (e: any) => {
    setIsLoading(true);
    let sDate = e.startDate;
    const sDateIsArray = Array.isArray(e.startDate);
    if (sDateIsArray) {
      sDate = e.startDate[0];
    }
    const eDate = new Date(Moment(sDate).add(1, "hours").format());

    const data: ICreateActivityDbModel = {
      name: e.location.label + "/" + e.coach.label,
      trainerId: e.coach.id,
      activityType: e.eventType.value,
      startDate: sDate.toUTCString(),
      endDate: eDate.toUTCString(),
      description: e.description,
      groupId: eventType.id === 1 ? e.groups.id : undefined,
      locationId: e.location.id,
      students:
        eventType.id === 2
          ? e.students.map((x: IOption) => x.id as number)
          : undefined,
    };
    const perHourPriceData: ICreateActivityDbModel = {
      name: e.location.label + "/" + e.coach.label,
      trainerId: e.coach.id,
      activityType: e.eventType.value,
      startDate: sDate.toUTCString(),
      endDate: eDate.toUTCString(),
      perHourPrice: e.lessonPrice,
      description: e.description,
      groupId: eventType.id === 1 ? e.groups.id : undefined,
      locationId: e.location.id,
      students:
        eventType.id === 2
          ? e.students.map((x: IOption) => x.id as number)
          : undefined,
    };
    if (getValues("customSwitch")) {
      createActivity(perHourPriceData)
        .then((res) => {
          toast.success("Activity Ekleme başarılı");
          refetchActivities(res);
          setShow(!show);
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createActivity(data)
        .then((res) => {
          toast.success("Activity Ekleme başarılı");
          refetchActivities(res);
          setShow(!show);
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  //   ** Custom select components
  const OptionComponent = ({ data, ...props }: any) => {
    return (
      //@ts-ignore
      <components.Option {...props} className="d-flex align-items-center">
        <div>{data.label}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    setValue("eventType", null);
    resetField("groups");
    resetField("students");
  }, [watch("location")]);

  const StudentsComponent = ({ data, ...props }: any) => {
    return (
      //@ts-ignore
      <components.Option {...props}>
        <div className="d-flex flex-wrap align-items-center">
          <Avatar className="my-0 me-1" size="sm" img={img1} />
          <div>{data.label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <Fragment>
      (
      <Modal
        isOpen={!isLoading && show}
        onClosed={() => setShow(show)}
        toggle={() => setShow(!show)}
        className="sidebar-lg"
        contentClassName="p-0 overflow-hidden"
        modalClassName="modal-slide-in event-sidebar activity-create-modal"
      >
        <ModalHeader
          className="mb-1"
          toggle={() => setShow(!show)}
          close={() => setShow(show)}
          tag="div"
        >
          <div className="d-flex align-items-center flex-wrap">
            <h5 className="modal-title me-1">Etkinlik Ekle</h5>
            {onClickAddStudent && (
              <Button
                className="me-1 p-0 d-flex align-items-center justify-content-center"
                color="warning"
                size="sm"
                onClick={onClickAddStudent}
                style={{
                  height: "26px",
                  width: "100px",
                }}
              >
                <Plus style={{ marginRight: "8px" }} size={14} />
                Öğrenci
              </Button>
            )}
            {onClickAddGroup && (
              <Button
                className="p-0 d-flex align-items-center justify-content-center"
                color="warning"
                size="sm"
                onClick={onClickAddGroup}
                style={{
                  height: "26px",
                  width: "100px",
                }}
              >
                <Plus style={{ marginRight: "8px" }} size={14} />
                Grup
              </Button>
            )}
          </div>
        </ModalHeader>
        <PerfectScrollbar options={{ wheelPropagation: false }}>
          <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-1">
                <Label className="form-label" for="location">
                  Lokasyon
                </Label>
                <LocationDropdown
                  control={control}
                  onChange={(event) => setValue("location", event)}
                />
              </div>
              <div className="mb-1">
                <Label className="form-label" for="coach">
                  Antrenör
                </Label>
                <Controller
                  name="coach"
                  control={control}
                  render={({ field }) => (
                    <InfiniteScrollDropdown
                      searchValue={coachSearchValue}
                      defaultValue={getValues("coach")}
                      totalCount={coachData.totalCount}
                      field={field}
                      isDisabled={!getValues("location")}
                      headers={["user.name", "user.surname"]}
                      data={coachData.items}
                      isLoading={isLoading}
                      id="coach"
                      className={classnames("react-select", {
                        "is-invalid": errors.coach,
                      })}
                      components={{
                        Option: StudentsComponent,
                      }}
                      onInputChange={(inputValue) =>
                        handleFilter(inputValue, coachSearch)
                      }
                      onMenuScrollBottom={() => coachPaginate(coachPages + 1)}
                      onChange={(event: any) => {
                        setValue("coach", event);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                {errors.coach && (
                  <FormFeedback>
                    {errors.coach.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <Label className="form-label" for="eventType">
                  Etkinlik Türü
                </Label>
                <Controller
                  name="eventType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="eventType"
                      isDisabled={!getValues("location")}
                      placeholder="Seçiniz"
                      theme={selectThemeColors}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (provided: any) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(event) => {
                        field.onChange(event);
                        if (event?.id === 1) {
                          studentPaginate(0);
                        } else {
                          groupPaginate(0);
                        }
                      }}
                      classNamePrefix="select"
                      isClearable={false}
                      isMulti={false}
                      value={
                        field.value === null
                          ? null
                          : options.find((opt) => opt.id === field.value.id)
                      }
                      components={{
                        Option: OptionComponent,
                      }}
                      options={options}
                      className={classnames("react-select", {
                        "is-invalid": errors.eventType,
                      })}
                    />
                  )}
                />
                {errors.eventType && (
                  <FormFeedback>
                    {errors.eventType.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              {eventType && eventType.id === 1 ? (
                <div className="mb-1">
                  <Label className="form-label" for="groups">
                    Grup
                  </Label>
                  <Controller
                    name="groups"
                    control={control}
                    render={({ field }) => (
                      <InfiniteScrollDropdown
                        isDisabled={!getValues("location")}
                        searchValue={groupSearchValue}
                        field={field}
                        headers={["name"]}
                        totalCount={groupData.totalCount}
                        data={groupData.items}
                        isLoading={groupLoading}
                        id="groups"
                        className={classnames("react-select", {
                          "is-invalid": errors.groups,
                        })}
                        components={{
                          Option: OptionComponent,
                        }}
                        onInputChange={(inputValue) =>
                          handleFilter(inputValue, groupSearch)
                        }
                        onMenuScrollBottom={() => groupPaginate(groupPages + 1)}
                        onChange={(event: any) => {
                          setValue("groups", event);
                          resetField("students");
                          field.onChange(event);
                        }}
                        ref={register("groups").ref}
                      />
                    )}
                  />
                  {errors.groups && (
                    <FormFeedback>
                      {errors.groups.message?.toString()}
                    </FormFeedback>
                  )}
                </div>
              ) : null}

              {eventType && eventType.id === 2 ? (
                <div className="mb-1">
                  <Label className="form-label" for="students">
                    Kişiler
                  </Label>
                  <Controller
                    name="students"
                    control={control}
                    render={({ field }) => (
                      <InfiniteScrollDropdown
                        isDisabled={!getValues("location")}
                        searchValue={studentSearchValue}
                        totalCount={studentData.totalCount}
                        field={field}
                        headers={["user.name", "user.surname"]}
                        data={studentData.items}
                        isLoading={studentLoading}
                        id="students"
                        className={classnames("react-select", {
                          "is-invalid": errors.students,
                        })}
                        components={{
                          Option: OptionComponent,
                        }}
                        onInputChange={(inputValue) =>
                          handleFilter(inputValue, studentSearch)
                        }
                        onMenuScrollBottom={() =>
                          studentPaginate(studentPages + 1)
                        }
                        onChange={(event: any) => {
                          setValue("students", [event]);
                          setValue("groups", {
                            id: -1,
                            value: "",
                            label: "",
                          });
                          resetField("groups");

                          field.onChange([event]);
                        }}
                        ref={register("students").ref}
                      />
                    )}
                  />
                  {errors.students && (
                    <FormFeedback>
                      {errors.students?.message?.toString()}
                    </FormFeedback>
                  )}
                </div>
              ) : null}

              {eventType &&
              eventType.id === 1 &&
              selectedGroup &&
              groupStudents &&
              groupStudents.students.length > 0 ? (
                <Card>
                  <CardHeader className="border-bottom">
                    <p className="fw-bolder mb-0">{selectedGroup.label}</p>
                  </CardHeader>
                  <CardBody className="pt-1">
                    {groupStudents.students.map(
                      (item: IStudentDBModel, index) => {
                        return (
                          <div className="d-flex mt-1" key={index}>
                            <div className="d-flex align-item-center justify-content-between flex-grow-1">
                              <Link
                                to={`/usersandpermissions/users/profile-detail/${item.user.id}`}
                              >
                                <div className="me-1">
                                  <p className="fw-bolder mb-0">
                                    {item?.user?.name +
                                      " " +
                                      item?.user?.surname}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </CardBody>
                </Card>
              ) : null}

              <div className="mb-1">
                <Label className="form-label" for="startDate">
                  Tarih
                </Label>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={getValues("startDate")}
                  render={({ field: { ref, ...field } }) => (
                    <Flatpickr
                      disabled={!getValues("location")}
                      placeholder="Tarih"
                      id="startDate"
                      className={classnames("form-control", {
                        "is-invalid": errors.startDate,
                      })}
                      options={{
                        closeOnSelect: true,
                        enableTime: true,
                        dateFormat: "Y-m-d H:i",
                        minTime: "08:00",
                        maxTime: "17:00",
                        static: true,
                        minuteIncrement: 60,
                        time_24hr: true,
                        enableSeconds: false,
                        defaultHour: 8,
                      }}
                      {...field}
                      ref={register("startDate").ref}
                    />
                  )}
                />
                {errors.startDate && (
                  <FormFeedback>
                    {errors.startDate.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center form-switch mb-1 ">
                <div className="d-flex flex-row align-items-center">
                  <Controller
                    name="customSwitch"
                    control={control}
                    render={({ field }) => (
                      <Input
                        id="customSwitch"
                        type="switch"
                        className="me-1"
                        checked={getValues("customSwitch")}
                        {...field}
                      />
                    )}
                  />

                  <Label className="form-label mb-0" for="lessonPrice">
                    Özel kurs fiyatı
                  </Label>
                </div>
                {watch("customSwitch") ? (
                  <InputGroup className="w-50">
                    <Controller
                      name="lessonPrice"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="w-50"
                          type="number"
                          id="lessonPrice"
                          invalid={errors.lessonPrice && true}
                          {...field}
                        />
                      )}
                    />
                    <InputGroupText>TL</InputGroupText>
                  </InputGroup>
                ) : null}
                {errors.lessonPrice && (
                  <FormFeedback>
                    {errors.lessonPrice.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <Label className="form-label" for="description">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      isDisabled={!getValues("location")}
                      type="textarea"
                      maxLength={250}
                      id="description"
                      invalid={errors.description && true}
                      defaultValue={getValues("description")}
                      placeholder="Bir açıklama yaz..."
                      {...field}
                    />
                  )}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="d-flex mb-1 action-line">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  type="submit"
                  loading={isLoading}
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                >
                  Kaydet
                </LoadingButton>
                <Button
                  color="warning"
                  type="reset"
                  onClick={() => setShow(!show)}
                  outline
                >
                  İptal
                </Button>
              </div>
            </Form>
          </ModalBody>
        </PerfectScrollbar>
      </Modal>
      )
    </Fragment>
  );
};

export default AddNewEventModal;
