
import { UserRole } from "../../../model/commonModel/enums";
import { useAuth } from "../../../utility/context/Auth";

interface IAuthViewProps {
  children: React.ReactNode;
  roles: UserRole[];
}
const AuthView = (props: IAuthViewProps) => {
  const { children, roles } = props;
  const { userInfo } = useAuth();

  const isAllowed = userInfo ? roles.includes(userInfo?.roles[0]!) : false;

  return isAllowed ? <>{children}</> : null;
};


export default AuthView