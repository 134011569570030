import { Fragment, useEffect, useState } from "react";

import classnames from "classnames";
import { Row, Col, Spinner } from "reactstrap";

import Calendar from "./Calendar";
import SidebarLeft from "./SidebarLeft";

import "@styles/react/apps/app-calendar.scss";
import EditEventModal from "./EditEventModal";
import {
  IActivityDbModel,
  IActivityInputModel,
  IActivityModel,
} from "../../model/DbMdel/activityDBModel";
import { FilterType } from "../../model/commonModel/enums";
import { getActivity } from "../../services/activity";
import dateFormat from "../../helper/dateFormat";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import AddNewEventModal from "./AddNewEventModal";
import { useMyContext } from "../../utility/context/CalendarContext";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";
import StudentCreateModal from "../../components/transactions/students/studentCreateModal";
import { ILanguage } from "../../model/commonModel/language";
import GroupCreateModal from "../../components/transactions/group/groupList/groupCreateModal";
import getLanguages from "../../services/language";

interface IOption {
  value: string;
  id: number;
  label: string;
}

const CalendarComponent = () => {
  let [searchParams] = useSearchParams();

  const { setActivityData, setRangeDate, setActivityUpdate, coachs } =
    useMyContext();

  const dateParam = searchParams.get("date");

  const [addSidebarOpen, setAddSidebarOpen] = useState(false);
  const [editSidebarOpen, setEditSidebarOpen] = useState(false);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [addStudentModalOpen, setAddStudentModalOpen] =
    useState<boolean>(false);
  const [addGroupModalOpen, setAddGroupModalOpen] = useState<boolean>(false);
  const [programView, setProgramView] = useState(true);
  const [filterTypes, setFilterTypes] = useState<FilterType[]>([]);
  const [trainerIds, setTrainerIds] = useState<number[]>([]);
  const [date, setDate] = useState(
    dateParam
      ? moment(new Date(new Date(dateParam))).format(dateFormat)
      : moment(new Date(new Date().setHours(8))).format(dateFormat)
  );
  const [activeRange, setActiveRange] = useState<string[]>([]);
  const [activity, setActivity] = useState<IActivityModel | null>(null);
  const [picker, setPicker] = useState(
    dateParam
      ? moment(new Date(dateParam)).format(dateFormat)
      : moment(new Date()).format(dateFormat)
  );
  const [trainers, setTrainers] = useState<IOption[]>([]);
  const [activitiesIsLoading, setActivitiesIsLoading] = useState(false);
  const [trainerId, setTrainerId] = useState<number>(-1);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const fetchLanguage = async () => {
    getLanguages()
      .then((languages) => {
        setLanguages(languages);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getActivityType = () => {
    if (
      filterTypes.includes(FilterType.Person) &&
      filterTypes.includes(FilterType.Group)
    ) {
      return undefined;
    }

    if (filterTypes.includes(FilterType.Person)) {
      return FilterType.Person;
    } else if (filterTypes.includes(FilterType.Group)) {
      return FilterType.Group;
    } else return undefined;
  };

  const fetchActivities = async () => {
    const selectedCalendars = [
      FilterType.Person,
      FilterType.Group,
      FilterType.Confirmed,
      FilterType.Encashed,
      FilterType.EncashedByAdmin,
      FilterType.IsDeleted,
    ];
    const data: IActivityInputModel = {
      searched: undefined,
      maxResultCount: undefined,
      skipCount: undefined,
      startDate: activeRange[0],
      endDate: activeRange[1],
      trainerIds: trainerIds,
      activityType:
        filterTypes.length === selectedCalendars.length
          ? undefined
          : getActivityType(),
      isConfirmed:
        filterTypes.length === selectedCalendars.length
          ? undefined
          : filterTypes.includes(FilterType.Confirmed)
          ? true
          : undefined,
      isEncashed:
        filterTypes.length === selectedCalendars.length
          ? undefined
          : filterTypes.includes(FilterType.Encashed)
          ? true
          : undefined,
      isEncashedByAdmin:
        filterTypes.length === selectedCalendars.length
          ? undefined
          : filterTypes.includes(FilterType.EncashedByAdmin)
          ? true
          : undefined,
      isDeleted:
        filterTypes.length === selectedCalendars.length
          ? undefined
          : filterTypes.includes(FilterType.IsDeleted)
          ? undefined
          : false,
    };

    await setActivityData(data);
    setActivitiesIsLoading(false);
  };

  const fetchSavedSalaries = async () => {
    setRangeDate(activeRange[0], activeRange[1]);
  };

  useEffect(() => {
    if (activeRange.length) {
      fetchActivities();
      fetchSavedSalaries();
    }
  }, [trainerIds, filterTypes, activeRange]);

  useEffect(() => {
    fetchLanguage();
  }, []);

  const handleAddEventSidebar = (date: string, trainerId: number) => {
    setDate(date);
    setTrainerId(trainerId);
    setAddSidebarOpen(!addSidebarOpen);
  };

  const handleEditEventSidebar = (id: number) => {
    setEditSidebarOpen(!editSidebarOpen);
    fetchActivity(id);
  };

  const toggleSidebar = (val: boolean) => setLeftSidebarOpen(val);

  const changeProgramView = (val: boolean) => setProgramView(val);

  const updateFilters = (filterTypes: FilterType[]) =>
    setFilterTypes(filterTypes);

  const getActiveRange = (range: string[]) => {
    setActiveRange([...range]);
    if (programView) {
      setPicker(range[0]);
    }
  };

  const handleChangeTrainers = (trainers: IOption[]) => {
    const newArray: number[] = trainers.map((trainer: IOption) => trainer.id);
    setTrainerIds(newArray);
  };

  const fetchActivity = async (eventId: number) => {
    setActivity(null);
    await getActivity(eventId)
      .then((e: IActivityModel) => {
        setActivity(e);
      })
      .catch((e) => {});
  };

  const refetchActivities = (
    e: IActivityDbModel | PagedResultDto<IActivityDbModel>
  ) => {
    setActivitiesIsLoading(true);
    setActivityUpdate(e);
    setActivitiesIsLoading(false);
  };

  return (
    <Fragment>
      <div className="app-calendar overflow-hidden border">
        <Row className="g-0">
          <Col
            className={classnames(
              "app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column",
              {
                show: leftSidebarOpen,
              }
            )}
            id="app-calendar-sidebar"
          >
            <SidebarLeft
              toggleSidebar={toggleSidebar}
              handleAddEventSidebar={handleAddEventSidebar}
              programView={programView}
              updateFilters={updateFilters}
              handleChangeTrainers={handleChangeTrainers}
              picker={picker}
              setPicker={setPicker}
              getTrainers={(coachs: IOption[]) => setTrainers(coachs)}
            />
          </Col>
          {activitiesIsLoading ? (
            <Col className="d-flex align-items-center  justify-content-center">
              <Spinner size="md" color="warning" />
            </Col>
          ) : (
            <Col className="position-relative">
              <Calendar
                toggleSidebar={toggleSidebar}
                handleAddEventSidebar={handleAddEventSidebar}
                handleEditEventSidebar={handleEditEventSidebar}
                changeProgramView={changeProgramView}
                pickerDate={picker}
                trainers={trainers}
                getActiveRange={getActiveRange}
                programView={programView}
                refetchActivities={refetchActivities}
                activitiesIsLoading={activitiesIsLoading}
              />
            </Col>
          )}
          <div
            className={classnames("body-content-overlay", {
              show: leftSidebarOpen === true,
            })}
            onClick={() => toggleSidebar(false)}
          ></div>
        </Row>
      </div>
      {addSidebarOpen && (
        <AddNewEventModal
          show={addSidebarOpen}
          setShow={setAddSidebarOpen}
          date={date}
          trainerId={trainerId}
          refetchActivities={refetchActivities}
          trainer={coachs.find((x: any) => x.id === trainerId)}
          onClickAddStudent={() => setAddStudentModalOpen(true)}
          onClickAddGroup={() => setAddGroupModalOpen(true)}
          addGroupModalVisible={addGroupModalOpen}
          addStudentModalVisible={addStudentModalOpen}

        />
      )}
      {editSidebarOpen && activity && (
        <EditEventModal
          showEdit={editSidebarOpen}
          setShowEdit={setEditSidebarOpen}
          activity={activity!}
          refetchActivities={refetchActivities}
        />
      )}

      {addStudentModalOpen && (
        <StudentCreateModal
          setShow={() => setAddStudentModalOpen(false)}
          show={addStudentModalOpen}
          languages={languages}
          isFastCreateModal
        />
      )}
      {addGroupModalOpen && (
        <GroupCreateModal
          setShow={() => setAddGroupModalOpen(false)}
          show={addGroupModalOpen}
        />
      )}
    </Fragment>
  );
};

export default CalendarComponent;
