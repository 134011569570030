import axios from "axios";
import { getToken } from "./storageHelper";
const qs = require("qs");
import toast from "react-hot-toast";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const http = axios.create({
  baseURL: baseUrl,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    "Cache-Control": "no-store",
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  (config: any) => {
    const headerToken = getToken();
    if (headerToken) {
      config.headers.Authorization = `Bearer ${headerToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response: any) => {
    if (response.status === 200) {
      if (response.data instanceof Blob) {
        return response.data;
      }
      return response.data.result;
    } else {
      toast(response.data.message);
    }
    return Promise.reject(response.data.message);
  },
  async (error) => {
    if (error.response.status === 401) {
      //Reflesh token
      window.location.href = "/login";
    }

    if (error.response.data && error.response.data.error) {
      if (error.response.data.error.details) {
        return Promise.reject(
          error.response.data.error.message +
            " " +
            error.response.data.error.details
        );
      } else {
        return Promise.reject(error.response.data.error.message);
      }
    }
    return Promise.reject(error.response.data);
  }
);

export default http;
