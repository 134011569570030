import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import { createGroup } from "../../../../services/groups";
import toast from "react-hot-toast";
import LoadingButton from "../../../common/LoadingButton";
import { IGroupCreateDBModel } from "../../../../model/DbMdel/groupDBModel";
import SwalFire from "../../../common/Swal";
import useInfinityPaginateFetch from "../../../../utility/hooks/userInfinityPaginateFetch";
import LocationDropdown from "../../../locationForPage";
import { InfiniteScrollDropdown } from "../../../InfiniteScroll";
import { IStudentDBModel } from "../../../../model/DbMdel/studentDBModel";
import getStudents from "../../../../services/students";
import { handleFilter } from "../../../../utility/helper";

interface IGroupCreateDBModelProps {
  setShow: (value: SetStateAction<boolean>) => void;
  show: boolean;
  onReload?: () => void;
}

const GroupCreateModal: FC<IGroupCreateDBModelProps> = ({
  setShow,
  show,
  onReload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const AddStudentSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Grup ismi zorunlu alandır")
      .max(64, "Grup ismi en fazla 64 karakter olmalıdır")
      .required("Grup ismi zorunlu alandır"),
    description: yup.string(),
    location: yup.object().required("Lokasyon alanı zorunlu alandır."),
    students: yup
      .array()
      .min(1, "'Kişi Seç' alanı zorunlu alandır.")
      .required("'Kişi Seç' alanı zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddStudentSchema),
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    handleSearch: studentSearch,
    handlePaginate: studentPaginate,
    page: studentPage,
    searchValue: studentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getStudents,
    isActive: true,
    locationId: getValues("location.id") ?? 0,
  });

  const onSubmit = async (e: any) => {
    SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup listesi oluşturmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        const data: IGroupCreateDBModel = {
          name: e.name,
          description: e.description,
          locationId: e.location.id,
          studentsInput: {
            studentIds: e.students.map((student: any) => student.id),
          },
        };
        setIsLoading(true);
        createGroup(data as any)
          .then((res) => {
            toast.success("Grup Ekleme Başarılı.");
            onReload && onReload();
            setShow(false);
            setIsLoading(false);
            reset({
              name: "",
              description: "",
              locationId: 0,
              studentsInput: [],
            });
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };

  const handleCancel = () => {
    setShow(!show);
    reset({ name: "", description: "" });
    clearErrors();
  };
  useEffect(() => {
    setValue("students", []);
  }, [getValues("location")])
  
  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={handleCancel}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleCancel}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Grup Ekle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={12} xs={12}>
                <Label className="form-label" for="location">
                  Lokasyon
                </Label>
                <LocationDropdown
                  invalid={!!errors.location}
                  control={control}
                  errorLocation={errors.location}
                  onChange={(event) => setValue("location", event)}
                />
              </Col>
              <Col className="mb-1" lg={12} md={12} xs={12}>
                <Label className="form-label" for="name">
                  Grup İsmi
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                        innerRef={register("name").ref}
                        id="name"
                        type="text"
                        maxLength={64}
                        invalid={errors.name && true}
                        placeholder="Grup İsmi"
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="description">
                  Grup Açıklaması
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("description").ref}
                        id="description"
                        type="textarea"
                        maxLength={500}
                        invalid={errors.description && true}
                        placeholder="Grup açıklaması"
                        style={{ maxHeight: "200px", minHeight: "80px" }}
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={12} xs={12}>
                <Label className="form-label" for="students">
                  Öğrenci Seç
                </Label>
                <Controller
                  control={control}
                  name="students"
                  render={({ field }) => (
                    <InfiniteScrollDropdown
                      location={watch("location")}
                      onChange={(event: any) => {
                        setValue("students", event);
                        field.onChange(event);
                      }}
                      searchValue={studentSearchValue}
                      headers={["user.name", "user.surname"]}
                      data={studentData.items}
                      isLoading={studentLoading}
                      id="students"
                      field={field}
                      totalCount={studentData.totalCount}
                      isMulti
                      onInputChange={(inputValue) =>
                        handleFilter(inputValue, studentSearch)
                      }
                      onMenuScrollBottom={() =>
                        studentPaginate(studentPage + 1)
                      }
                      isDisabled={!getValues("location")}
                    />
                  )}
                />
                {errors.students && (
                  <FormFeedback>
                    {errors.students.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={handleCancel}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default GroupCreateModal;
