import { FC } from "react";
import Chart from "react-apexcharts";

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Row,
  Col,
} from "reactstrap";
import { ITrainerSummary } from "../../../model/DbMdel/coachDBModel";

interface IPlanningFollowCalendarTrackingProps {
  trainerSummary: ITrainerSummary[];
}

const PlanningFollowCalendarTracking: FC<
  IPlanningFollowCalendarTrackingProps
> = ({ trainerSummary }) => {
  const assignedLesson = trainerSummary.reduce((acc, item) => {
    return acc + item.assignedLessonSum;
  }, 0);

  const activeTrainersCount = trainerSummary.filter(
    (value) => value.trainer.user.isActive
  ).length;
  const waitingLesson = activeTrainersCount * 10 - assignedLesson;
  const lessonPercentage: any = (
    (assignedLesson / (assignedLesson + waitingLesson)) *
    100
  ).toFixed(0);

  const options: any = {
      chart: {
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      colors: ["#28C76F"],
      plotOptions: {
        radialBar: {
          offsetY: 10,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: "77%",
          },
          track: {
            background: "#ebe9f1",
            strokeWidth: "50%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              color: "#5e5873",
              fontFamily: "Montserrat",
              fontSize: "2.86rem",
              fontWeight: "600",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: [],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      grid: {
        padding: {
          bottom: 30,
        },
      },
    },
    series = [lessonPercentage];

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Takvim Takibi</CardTitle>
      </CardHeader>
      <CardBody className="p-0">
        <Chart
          options={options}
          series={series}
          type="radialBar"
          height={245}
          color="orange"
        />
      </CardBody>
      <Row className="border-top text-center mx-0">
        <Col xs="6" className="border-end py-1">
          <CardText className="text-muted mb-0">Atanan Ders Sayısı</CardText>
          <h3 className="fw-bolder mb-0">{assignedLesson}</h3>
        </Col>
        <Col xs="6" className="py-1">
          <CardText className="text-muted mb-0">
            Atama Bekleyen Ders Sayısı
          </CardText>
          <h3 className="fw-bolder mb-0">{waitingLesson}</h3>
        </Col>
      </Row>
    </Card>
  );
};
export default PlanningFollowCalendarTracking;
