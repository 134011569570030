import http from "../helper/http";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";
import { ISelectDate } from "../model/commonModel/selectDate";
import {
  ICoachDBModel,
  ICoachSummaryDBModel,
  ICreateCoachDbModel,
  IEditCoachDBModel,
} from "../model/DbMdel/coachDBModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";

interface IGetCoachesDto extends IPagedResultRequest {
  isActive?: boolean;
}

const getCoachs = (
  getCoachesDto: IGetCoachesDto
): Promise<PagedResultDto<ICoachDBModel>> => {
  return http.get<any, PagedResultDto<ICoachDBModel>>("/Trainers", {
    params: getCoachesDto,
  });
};

export const getCoachsById = (id: number): Promise<ICoachDBModel> => {
  return http.get<any, ICoachDBModel>(`/Trainers/${id}`);
};

export const createCoachs = (
  coachs: ICreateCoachDbModel
): Promise<PagedResultDto<ICoachDBModel>> => {
  return http.post<any, PagedResultDto<ICoachDBModel>>("/Trainers", coachs);
};

export const editCoachs = (
  coachs: IEditCoachDBModel,
  id: number
): Promise<ICoachDBModel> => {
  return http.put<any, ICoachDBModel>(`/Trainers/${id}`, coachs);
};

export const getSummaryCoachs = (
  param: ISelectDate
): Promise<ICoachSummaryDBModel> => {
  return http.get<any, ICoachSummaryDBModel>("/Trainers/summary", {
    params: param,
  });
};

export const exportFile = async (
  data: IExportFileResponse,
) => {
  return await http.post("/trainers/export-file", data, {
    responseType: "blob",
  });
};

export const getTrainerUserDetail = (
  userId: number
): Promise<ICoachDBModel> => {
  return http.get<any, ICoachDBModel>(`/Trainers/users/${userId}`);
};

export default getCoachs;
