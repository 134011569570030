import http from "../helper/http";
import { INotificationDBModel, INotificationInputModel } from "../model/DbMdel/notificationDBModel";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";

export const getNotificationsForPaginate = (
  input: INotificationInputModel

): Promise<PagedResultDto<INotificationDBModel>> => {
  return http.get<any, PagedResultDto<INotificationDBModel>>(`/Notifications`, {
    params: input
  });
};
export const getNotifications = (
  skipCount: number,
  maxResultCount: number,
  isRead?: boolean
): Promise<PagedResultDto<INotificationDBModel>> => {
  return http.get<any, PagedResultDto<INotificationDBModel>>(`/Notifications`, {
    params: {
      isRead: isRead,
      skipCount: skipCount || 0,
      maxResultCount: maxResultCount,
    },
  });
};

export const deleteNotifications = (ids: number[]) => {
  const params = {
    Ids: ids,
  };
  return http.delete<any, INotificationDBModel>(`/Notifications`, { params });
};

export const readNotifications = () => {
  return http.post<any, INotificationDBModel[]>("/Notifications/read");
};

export const readNotification = (id: number) => {
  return http.post<any, INotificationDBModel[]>(`/Notifications/${id}`);
};

export const deleteAllNotification = (id: number) => {
  const params = {
    userId: id,
  };
  return http.delete<any, INotificationDBModel[]>(`/Notifications/all`, {
    params,
  });
};
