import http from "../helper/http";
import { ICreateLocationMethodModel, IEditLocationMethodModel, ILocationMethodDBModel } from "../model/DbMdel/locationMethodDbModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";

import { PagedResultDto } from "../model/commonModel/pagedResultDto";

interface IGetLocationMethodDto extends IPagedResultRequest {
    isActive: boolean
}

export const getAllLocationMethodService = (
    params: IGetLocationMethodDto,
): Promise<PagedResultDto<ILocationMethodDBModel>> => {
    return http.get<any, PagedResultDto<ILocationMethodDBModel>>(`/Locations`, {params});
};

export const createLocationMethodService = (
    LocationMethods: ICreateLocationMethodModel,
): Promise<ILocationMethodDBModel> => {
    return http.post<any, ILocationMethodDBModel>(`/Locations`, LocationMethods);
};

export const updateLocationMethodService = (
    LocationMethods: IEditLocationMethodModel,
    locationMethodId: number
): Promise<ILocationMethodDBModel> => {
    return http.put<any, ILocationMethodDBModel>(`/Locations/${locationMethodId}`, LocationMethods);
};
export const deleteLocationMethodService = (
    locationMethodId: number
): Promise<ILocationMethodDBModel> => {
    return http.delete<any, ILocationMethodDBModel>(`/Locations/${locationMethodId}`);
};

export const exportFile = async (data: IExportFileResponse) => {
    return await http.post("/location/export-file", data, { responseType: "blob" });
};
