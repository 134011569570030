import http from "../helper/http";
import { AuthDto } from "../model/DbMdel/authDBModel";

const Authenticate = async (
  emailAddress: string,
  password: string,
  code: string
): Promise<AuthDto> => {
  return http.post("/TokenAuth/Authenticate", {
    emailAddress,
    password,
    code: code ? code : "",
  });
};

export const Check2FA = async (
  emailAddress: string,
  password: string
): Promise<any> => {
  return http.post(
    `/TokenAuth/Check2FA/Check2FA`,{
      emailAddress,
      password,
    }
  );
};

export default Authenticate;
