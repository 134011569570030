// ** React Imports
import { FC, Fragment, useContext, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  FormFeedback,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

//@ts-ignore
import { selectThemeColors } from "@utils";

// ** Styles
//@ts-ignore
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/base/pages/user-info.scss";
import SwitchLabel from "../../common/SwitchLabel";
import {
  IUserEditModel,
  IUsersModel,
  RoleTypes,
} from "../../../model/DbMdel/usersDBModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILanguage } from "../../../model/commonModel/language";
import classnames from "classnames";
import ImageUploadButton from "../../common/ImageUploadButton";
import LoadingButton from "../../common/LoadingButton";
import { editUser } from "../../../services/user";
import toast from "react-hot-toast";
import SwalFire from "../../common/Swal";
import { setUser, getUser } from "../../../helper/storageHelper";
import { imageTypes, maxSize } from "../../../helper/uploadImageHelper";
import { AuthContext } from "../../../utility/context/Auth";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import phone from "phone";

const statusOptions = [
  { id: 1, value: "aktif", label: "Aktif" },
  { id: 2, value: "pasif", label: "Pasif" },
];

interface IUserEditModalProps {
  role: RoleTypes;
  setShow: (e: boolean) => void;
  show: boolean;
  user: IUsersModel;
  languages: ILanguage[];
  refetchUserProfile: () => void;
}

const UserEditModal: FC<IUserEditModalProps> = ({
  setShow,
  show,
  user,
  languages,
  refetchUserProfile,
  role,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUserInfo } = useContext(AuthContext);

  const EditUserSchema = yup.object().shape({
    name: yup.string().required("İsim zorunlu alandır"),
    surname: yup.string().required("Soyisim zorunlu alandır."),
    emailAddress: yup
      .string()
      .email("Geçerli bir e-mail adresi giriniz")
      .required("E-mail zorunlu alandır."),
    phoneNumber: yup
      .string()
      .test("matches", "Geçerli bir numara giriniz", (str) => {
        const pn = phone(str!);

        if (!pn.isValid && userRolesName === "Student") {
          return true;
        }
        return pn.isValid;
      }),

    isActive: yup.object().notRequired(),
    languages: yup
      .array()
      .min(1, "En az bir dil seçiniz.")
      .required("Dil zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(EditUserSchema),
    defaultValues: {
      name: user.name,
      surname: user.surname,
      emailAddress: user.emailAddress,
      phoneNumber: phone(user.phoneNumber).isValid
        ? user.phoneNumber
        : undefined,
      isActive: user.isActive
        ? { value: "aktif", label: "Aktif" }
        : { value: "pasif", label: "Pasif" },
      languages: user.languages.map((x: ILanguage) => {
        return { value: x.id, label: x.name };
      }),
      isPanelAccess: user.isPanelAccess,
      photoUrl: user.photo,
    },
  });

  const handleSwal = async (e: any) => {
    let roleName: string;
    switch (role) {
      case RoleTypes.Student:
        roleName = "Öğrenci";
        break;
      case RoleTypes.Trainer:
        roleName = "Antrenör";
        break;
      case RoleTypes.Admin:
        roleName = "Admin";
        break;
      case RoleTypes.Manager:
        roleName = "Yönetici";
        break;
      default:
        roleName = "Kullanıcı";

        break;
    }
    await SwalFire({
      title: "Emin misiniz?",
      text: `${roleName} bilgilerini düzenlemek üzeresiniz. İşleme devam etmek istiyor musunuz?`,
      onOk: () => {
        onSubmit(e);
      },
    });
  };

  const userRolesName = user.roles.map((x) => x.name).toString();
  let successMessage: string;
  if (userRolesName === "Student") {
    successMessage = "Öğrenci Düzenleme Başarılı";
  } else if (userRolesName === "Trainer") {
    successMessage = "Antrenör Düzenleme Başarılı";
  } else {
    successMessage = "Yönetici Düzenleme Başarılı";
  }

  const onSubmit = (e: any) => {
    const id = user.id;
    const data: IUserEditModel = {
      photo: e.photoUrl,
      name: e.name,
      surname: e.surname,
      emailAddress: e.emailAddress,
      phoneNumber: phone(e.phoneNumber).phoneNumber ?? "",
      isActive: e.isActive.value === "aktif" ? true : false,
      isPanelAccess: e.isPanelAccess,
      languageIds: e.languages.map((l: any) => l.value),
      roleNames: user.roles.map((x) => x.displayName),
    };
    setIsLoading(true);
    editUser(data, id)
      .then((res) => {
        toast.success(successMessage);
        setIsLoading(false);
        setShow(false);
        refetchUserProfile();
        let userInfo = getUser();

        if (userInfo) {
          if (userInfo.id === id) {
            userInfo.name = res.name;
            userInfo.surname = res.surname;
            userInfo.emailAddress = res.emailAddress;
            userInfo.photo = res.photo;
            setUser(userInfo);
            setUserInfo(userInfo);
          }
        }
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };

  const onCancelClick = () => {
    reset();
    setShow(false);
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 pt-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Kullanıcı Bilgilerini Düzenle</h1>
            <p>
              Kullanıcı ayrıntılarının güncellenmesi bir gizlilik denetimi
              alacaktır.
            </p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-75">
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        maxLength={50}
                        placeholder="İsim"
                        invalid={errors.name && true}
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="surname">
                  Soyisim
                </Label>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="surname"
                      type="text"
                      maxLength={50}
                      placeholder="Soyisim"
                      invalid={errors.surname && true}
                    />
                  )}
                />
                {errors.surname && (
                  <FormFeedback>
                    {errors.surname.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="emailAddress">
                  E-mail
                </Label>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="emailAddress"
                      placeholder="mail@mail.com"
                      type="email"
                      maxLength={320}
                      invalid={errors.emailAddress && true}
                    />
                  )}
                />
                {errors.emailAddress && (
                  <FormFeedback>
                    {errors.emailAddress.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="phoneNumber">
                  Telefon
                </Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputComponent
                      invalid={!!errors.phoneNumber}
                      {...field}
                      {...register("phoneNumber")}
                      register={register}
                      onChange={() => {}}
                    />
                  )}
                />
                {errors.phoneNumber?.message && (
                  <FormFeedback>
                    {errors.phoneNumber.message.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="isActive">
                  Durum
                </Label>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="isActive"
                      isClearable={false}
                      className="react-select"
                      classNamePrefix="select"
                      options={statusOptions}
                      theme={selectThemeColors}
                      defaultValue={getValues("isActive")}
                      {...field}
                      onChange={(event) =>
                        event &&
                        setValue("isActive", {
                          value: event.value,
                          label: event.label,
                        })
                      }
                    />
                  )}
                />
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="language">
                  Dil(ler)
                </Label>
                <Controller
                  control={control}
                  name="languages"
                  render={({ field }) => (
                    <Select
                      id="languages"
                      isClearable={false}
                      isMulti
                      classNamePrefix="select"
                      options={languages.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      defaultValue={getValues("languages")}
                      className={classnames("react-select", {
                        "is-invalid": errors.languages,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.languages && (
                  <FormFeedback>
                    {(errors.languages as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              {/* Eğer Antrenör ise : <Col md={6} xs={12}>
                <Label className="form-label" for="country">
                  Komisyon
                </Label>
                <Input
                  id="coachFee"
                  defaultValue='%'
                  // defaultValue={selectedUser.fee}
                />
              </Col> */}

              {/* Eğer Öğrenci ise :  <Col md={6} xs={12}>
                <Label className="form-label" for="country">
                  Komisyon
                </Label>
                <Input
                  id="coachFee"
                  defaultValue='%'
                  // defaultValue={selectedUser.fee}
                />
              </Col>   */}
              <Col md={6} xs={12}>
                <Label className="form-label" for="file">
                  Fotoğraf Yükle
                </Label>
                <Controller
                  name="photoUrl"
                  control={control}
                  render={({ field }) => {
                    return (
                      <ImageUploadButton
                        {...field}
                        id="photoUrl"
                        onChange={(e: any) => {
                          if (e.length > 0) {
                            if (e[0].file.size > maxSize) {
                              toast.error(
                                "Resminiz yüklenirken bir hata oluştu. Boyutu 10 KB'ın altında olan geçerli bir resim dosyası olduğundan emin olun."
                              );
                              field.onChange("");
                              return;
                            }

                            if (!imageTypes.includes(e[0].file.type)) {
                              toast.error(
                                "Resminiz yüklenirken bir hata oluştu. Resim dosyasının formatı desteklenmemektedir."
                              );
                              field.onChange("");
                              return;
                            }
                            field.onChange(e[0].dataURL);
                          } else {
                            field.onChange("");
                          }
                        }}
                        images={watch("photoUrl")}
                      />
                    );
                  }}
                />
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <div className="form-switch">
                    <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }: any) => (
                        <Input
                          id="isPanelAccess"
                          type="switch"
                          {...field}
                          checked={getValues("isPanelAccess")}
                          onChange={(event) => {
                            if (event.target.checked) {
                              field.onChange(true);
                            } else {
                              field.onChange(false);
                            }
                          }}
                        />
                      )}
                    />

                    {/* <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="switch"
                            id="isPanelAccess"
                            checked={user.isPanelAccess}
                            {...field}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked)
                                field.onChange(false);
                              else
                                field.onChange(true)
                            }}
                          />
                        );
                      }}
                    /> */}
                    <SwitchLabel htmlFor={"isPanelAccess"} />
                  </div>
                  <Label
                    className="form-check-label fw-bolder"
                    htmlFor="isPanelAccess"
                  >
                    Panele Erişebilir
                  </Label>
                </div>
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(handleSwal)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={onCancelClick}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UserEditModal;
