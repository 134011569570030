import { UserRole } from "../model/commonModel/enums";
import { IUserUIModel } from "../model/UIModel/userUIModel";

export const getUser = (): IUserUIModel | null => {
  const userData = localStorage.getItem("userData");

  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

export const setUser = (param: IUserUIModel) => {
  localStorage.setItem("userData", JSON.stringify(param));
};

export const removeUser = () => {
  localStorage.removeItem("userData");
};

export const getToken = (): string | null => {
  const userData = localStorage.getItem("token");

  try {
    if (userData) {
      return JSON.parse(userData);
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const setToken = (param: string) => {
  localStorage.setItem("token", JSON.stringify(param));
};
export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getUserRole = (): UserRole | null => {
  const userData = localStorage.getItem("userData");

  if (userData) {
    return JSON.parse(userData).roles[0];
  } else return null;
};

export default getUser;
