import {
  ExportFileTypeExtension,
  ExportFileTypeName,
  ExportFileTypes,
} from "./types";

export const exportTypes: ExportFileTypes = [
  { name: ExportFileTypeName.PDF, extension: ExportFileTypeExtension.PDF },
  { name: ExportFileTypeName.EXCEL, extension: ExportFileTypeExtension.EXCEL },
  { name: ExportFileTypeName.CSV, extension: ExportFileTypeExtension.CSV },
];
