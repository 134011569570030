// ** Layouts
// @ts-nocheck
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";

import Login from "../../views/Login";
import Register from "../../views/Register";
import ForgotPassword from "../../views/ForgotPassword";
import ResetPassword from "../../views/ResetPassword";
import TwoStepsVerification from "../../views/TwoStepsVerification";
import Error from "../../views/Error";
import NotAuthorized from "../../views/NotAuthorized";
import Payment from "../../views/dashboard";
import PersonFilter from "../../views/transactions/pool";
import Coach from "../../views/transactions/coach";
import Students from "../../views/transactions/students";
import Groups from "../../views/transactions/groups";
import LessonsConfirm from "../../components/payment/lessonsConfirmTable";
import PaymentTracker from "../../components/payment/paymentTracker";
import CalendarComponent from "../../views/calendar";
import GroupAddStudent from "../../components/transactions/group/groupList/groupAddStudent";
import PlanningFollowUpDetail from "../../views/PlanningFollowUp/PlanningFollowUpDetail";
import MoneyIcon from "../../assets/img/moneyIcon";
import LocationIcon from "../../assets/img/locationIcon";

import {
  TrendingUp,
  Target,
  Calendar,
  Filter,
  Grid,
  User,
  Dribbble,
  Users,
  Shield,
  X,
  File,
} from "react-feather";
import Roles from "../../views/usersandpermissions/roles";
import ProfileSection from "../../views/usersandpermissions/userProfile";
import UsersPage from "../../views/usersandpermissions/users";
import PlanningFollowUp from "../../views/PlanningFollowUp";
import checkPermission, { checkRoles } from "../../helper/permissionHelper";
import { UserRole } from "../../model/commonModel/enums";
import CalendarContext from "../../utility/context/CalendarContext";
import UserContext from "../../utility/context/UserContext";
import PaymentMethodList from "../../views/systemSettings/paymentMethod";
import LocationList from "../../views/systemSettings/location";
import PrivacyPolicy from "./../../views/PrivacyPolicy";
// ** Utils

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - KAYAK";

// ** Default Route
const DefaultRoute = "/";

// ** Merge Routes

const Routes: any[] = [
  {
    id: "home",
    title: "Tahsilat Takibi",
    icon: <TrendingUp size={20} />,
    path: "/",
    element: <Payment />,
    isPrivate: true,
    showMenu: true,
    roles: [UserRole.Manager],
  },

  {
    id: "plans",
    title: "Planlama Takibi",
    icon: <Target size={20} />,
    path: "/plans",
    element: <PlanningFollowUp />,
    isPrivate: true,
    showMenu: true,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    id: "plansDetail",
    title: "Program Takibi Detayı",
    icon: <Target size={20} />,
    path: "plans/plans-detail",
    element: <PlanningFollowUpDetail />,
    isPrivate: true,
    showMenu: false,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    id: "calendar",
    title: "Takvim",
    icon: <Calendar size={20} />,
    path: "/calendar",
    element: (
      <CalendarContext>
        <CalendarComponent />
      </CalendarContext>
    ),
    isPrivate: true,
    showMenu: true,
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Trainer],
  },
  {
    id: "lessonsConfirm",
    title: "Ders Onayı Detayı",
    path: "/lessons-confirm",
    element: <LessonsConfirm />,
    isPrivate: true,
    showMenu: false,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    id: "paymentTracker",
    title: "Tahsilat Takibi Detayı",
    path: "/payment-tracker",
    element: <PaymentTracker />,
    isPrivate: true,
    showMenu: false,
    roles: [UserRole.Manager],
  },
  {
    id: "groupAddStudent",
    title: "Grup Kişi Detayı",
    path: "/group-add-student/:id",
    element: <GroupAddStudent />,
    isPrivate: true,
    showMenu: false,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    id: "reports",
    title: "Raporlar",
    path: "reports",
    showMenu: true,
    isPrivate: true,
    roles: [UserRole.Manager,UserRole.Admin],
    children: [
      {
        id: "paymentReport",
        title: "Tahsilat Raporu",
        icon: <File size={20} />,
        path: "payment-tracker",
        element: (
          <UserContext>
            <PaymentTracker />,
          </UserContext>
        ),
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Manager],
      },
      {
        id: "planningReport",
        title: "Planlama Raporu",
        icon: <File size={20} />,
        path: "planning-tracker",
        element: (
          <UserContext>
            <PlanningFollowUpDetail />,
          </UserContext>
        ),
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Manager, UserRole.Admin],
      },
    ],
  },
  {
    id: "transactions",
    title: "İşlemler",
    path: "/transactions",
    isPrivate: true,
    showMenu: true,
    roles: [UserRole.Admin, UserRole.Manager],
    children: [
      {
        id: "Çanak",
        title: "Çanak",
        icon: <Filter size={20} />,
        path: "pool",
        element: <PersonFilter />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Admin, UserRole.Manager],
      },
      {
        id: "coach",
        title: "Antrenör",
        icon: <Dribbble size={20} />,
        path: "coach",
        element: <Coach />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Admin, UserRole.Manager],
      },
      {
        id: "students",
        title: "Öğrenciler",
        icon: <User size={20} />,
        path: "students",
        element: <Students />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Admin, UserRole.Manager],
      },
      {
        id: "groups",
        title: "Grup",
        icon: <Grid size={20} />,
        path: "groups",
        element: <Groups />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Admin, UserRole.Manager],
      },
    ],
  },
  {
    id: "usersandpermissions",
    title: "Kullanıcılar ve İzinler",
    path: "usersandpermissions",
    showMenu: true,
    isPrivate: true,
    roles: [UserRole.Manager],
    children: [
      {
        id: "users",
        title: "Kullanıcılar",
        icon: <Users size={20} />,
        path: "users",
        element: (
          <UserContext>
            <UsersPage />
          </UserContext>
        ),
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Manager],
      },
      {
        //TODO: Geçici olarak kapatıldı
        id: "roles",
        title: "Roller",
        icon: <Shield size={20} />,
        path: "roles",
        element: <Roles />,
        isPrivate: true,
        showMenu: true,
        roles: [],
      },
    ],
  },
  {
    id: "systemsettings",
    title: "Sistem Ayarları",
    path: "systemsettings",
    showMenu: true,
    isPrivate: true,
    roles: [UserRole.Manager],
    children: [
      {
        id: "paymentMethods",
        title: "Ödeme Yöntemi",
        icon: <MoneyIcon />,
        path: "paymentmethods",
        element: <PaymentMethodList />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Manager],
      },
      {
        id: "location",
        title: "Lokasyon",
        icon: <LocationIcon />,
        path: "location",
        element: <LocationList />,
        isPrivate: true,
        showMenu: true,
        roles: [UserRole.Manager],
      },
    ],
  },
  {
    id: "users",
    title: "Profil İnceleme",
    icon: <Users size={20} />,
    path: "usersandpermissions/users/profile-detail/:id",
    element: <ProfileSection />,
    isPrivate: true,
    showMenu: false,
    roles: [UserRole.Admin, UserRole.Manager, UserRole.Trainer],
  },

  {
    id: "forgotPassword",
    title: "Şifremi Unuttum",
    icon: <div></div>,
    path: "/forgot-password",
    element: <ForgotPassword />,
    isPrivate: false,
    showMenu: false,
  },
  {
    id: "login",
    path: "/login",
    element: <Login />,
    title: "Giriş Yap",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    id: "resetPassword",
    path: "reset-password",
    element: <ResetPassword />,
    title: "Giriş Yap",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    id: "twoStepsVerification",
    path: "/two-steps-verification",
    element: <TwoStepsVerification />,
    title: "2 Adımlı Doğrulama",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    path: "/register",
    element: <Register />,
    title: "Kayıt Ol",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    path: "/error",
    element: <Error />,
    title: "Hata",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    path: "/*",
    element: <Error />,
    title: "Hata",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    path: "/unauthorized",
    element: <NotAuthorized />,
    title: "Yetkisiz",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
  {
    id: "privacy-policy",
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    title: "Gizlilik Politikası",
    icon: <div></div>,
    isPrivate: false,
    showMenu: false,
  },
];

const getRoutes = () => {
  return Routes;
};

const recursiveRoutes = (routes) => {
  return routes
    .filter(
      (x) =>
        x.isPrivate && (x.permissionName || checkPermission(x.permissionName))
    )
    .map((route) => {
      if (route.children) {
        return {
          ...route,
          children: recursiveRoutes(route.children),
        };
      } else {
        return route;
      }
    });
};

const getPrivateRoutes = (routes) => {
  if (!routes) {
    routes = Routes;
  }
  return routes
    .filter((x) => x.isPrivate && checkRoles(x.roles))
    .map((route) => {
      if (route.children) {
        return {
          ...route,
          children: getPrivateRoutes(route.children),
        };
      } else {
        return route;
      }
    });
};
const getPublicRoutes = () => {
  return Routes.filter((route) => !route.isPrivate);
};
export {
  DefaultRoute,
  TemplateTitle,
  Routes,
  getRoutes,
  getPublicRoutes,
  getPrivateRoutes,
};
