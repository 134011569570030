import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const SwalFire = (options: any) => {
  return MySwal.fire({
    title: "Emin misiniz?",
    text: "Bu kişiyi gruptan çıkartmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Onayla",
    customClass: {
      confirmButton: "btn btn-warning",
      cancelButton: "btn btn-outline-danger ms-1",
      input: "react-select m-2",
      inputLabel: "form-label text-success mb-0"
    },
    cancelButtonText: "İptal",
    buttonsStyling: false,
    ...options,
  }).then(function (result) {
    if (result.isConfirmed) {
      if (options.onOk) {
        options.onOk();
      }
    }
    else{
      if(options.onCancel){
        options.onCancel();
      }
    }
  });
};

export default SwalFire;
