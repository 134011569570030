import { FC, useState } from "react";
import { Button, ButtonProps, Input, Spinner } from "reactstrap";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { acceptTypes } from "../../../helper/uploadImageHelper";

const ImageUploadButton: FC<any> = (props: any) => {
  return (
    <ImageUploading
      multiple={false}
      value={props.images}
      onChange={props.onChange}
      maxNumber={1}
      acceptType={acceptTypes}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            {imageList.length > 0 ? (
              <Button
                className="btn btn-primary"
                block
                onClick={onImageRemoveAll}
                {...dragProps}
                {...props}
              >
                Resmi Sil
              </Button>
            ) : (
              <Button
                className="btn btn-warning"
                block
                // style={{ opacity: 0.7 }}
                onClick={onImageUpload}
                {...dragProps}
                {...props}
              >
                Resim Ekle
              </Button>
            )}
          </div>
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUploadButton;
