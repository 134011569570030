import React from "react";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import classnames from "classnames";
import { X } from "react-feather";

interface CustomFlatpickerProps extends DateTimePickerProps {
  isClearable?: boolean;
  className?: string;
  value?: DateTimePickerProps["value"];
  onChange?: (dates: Date[] | null) => void;
}
 const CustomFlatpicker: React.FC<CustomFlatpickerProps> = ({
  isClearable,
  className,
  onChange,
  value,
  ...rest
}) => {
  return (
    <div className="position-relative">
      <Flatpickr
        {...rest}
        className={classnames(`form-control ${className}`)}
        onChange={onChange}
        value={value}
      />
      {value && isClearable && (
        <X
          className="position-absolute cursor-pointer top-50 end-0 translate-middle-y pe-1"
          onClick={() => onChange && onChange(null)}
          size={30}
        />
      )}
    </div>
  );
};

export default CustomFlatpicker
