import {
  Input,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import { Link, useNavigate } from "react-router-dom";
import { FC, useState } from "react";
//@ts-ignore
import Avatar from "@components/avatar";
import {
  ICoachDBModel,
  ITrainerSummary,
} from "../../../model/DbMdel/coachDBModel";
import ExportDropdown from "../../common/ExportDropdown";
import _debounce from "lodash/debounce";
import dateFormat from "../../../helper/dateFormat";
import moment from "moment";
import { search } from "ss-search";
import DataTable from "../../common/DataTable";
import { defaultCount, options } from "../../common/DataTable/Constants";

interface ICoachScheduleProps {
  trainerSummary: ITrainerSummary[];
  startDate: string;
  endDate: string;
}

const CoachSchedule: FC<ICoachScheduleProps> = ({
  trainerSummary,
  startDate,
  endDate,
}) => {
  const [filterText, setFilterText] = useState("");
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);
  
  const navigate = useNavigate();

  const plansDetail = () => {
    navigate({
      pathname: "/plans/plans-detail",
      search: `startDate=${startDate}&endDate=${endDate}`,
    });
  };

  const handleFilter = (event: any): void => {
    _debounce(() => {
      setFilterText(event.target.value);
    }, 500)();
  };

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const renderClient = (row: ICoachDBModel) => {
    if (row.user.photo) {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
          <Avatar
            className="me-1"
            img={row.user.photo}
            width="32"
            height="32"
          />
        </Link>
      );
    } else {
      return (
        <Avatar
          className="me-1"
          color={"light-warning"}
          content={getSummaryName(`${row.user.name} ${row.user.surname}`)}
          title={`${row.user.name} ${row.user.surname}`}
        />
      );
    }
  };

  const caseInsensitiveSort = (
    rowA: ITrainerSummary,
    rowB: ITrainerSummary
  ) => {
    const a = rowA.trainer.user.name.toLowerCase();
    const b = rowB.trainer.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "İSİM",
      sortable: true,
      maxWidth: "300px",
      sortField: "name",
      sortFunction: caseInsensitiveSort,
      selector: (row: ITrainerSummary) => {
        return (
          <div className="d-inline-flex justify-content-left align-items-center">
            {renderClient(row.trainer)}

            <div className="d-flex flex-column">
              <p className="user-name text-truncate mb-0">
                {row.trainer.user.name} {row.trainer.user.surname}
              </p>
              <small className="text-truncate text-muted mb-0">Antrenör</small>
            </div>
          </div>
        );
      },
    },
    {
      name: "GSM",
      maxWidth: "160px",
      sortField: "contact",
      selector: (row: ITrainerSummary) => row.trainer.user.phoneNumber,
    },
    {
      name: "E-MAİL",
      maxWidth: "250px",
      sortField: "email",
      selector: (row: ITrainerSummary) => row.trainer.user.emailAddress,
    },
    {
      name: "%",
      maxWidth: "150px",
      sortField: "density",
      selector: (row: ITrainerSummary) =>
        ((row.assignedLessonSum / row.maxAssignableLessonSum) * 100).toFixed(0),
    },
    {
      name: "ATANAN #",
      maxWidth: "150px",
      sortField: "assignedLesson",
      selector: (row: ITrainerSummary) => row.assignedLessonSum,
    },
    {
      name: "ATANABİLİR #",
      maxWidth: "150px",
      sortField: "negotiableLesson",
      selector: (row: ITrainerSummary) =>
        row.maxAssignableLessonSum - row.assignedLessonSum,
    },
  ];

  const time = `${moment(startDate).format(dateFormat)} / ${moment(
    endDate
  ).format(dateFormat)}`;

  const filterData = () => {
    if (trainerSummary) {
      return trainerSummary;
    }
    if (!trainerSummary) {
      return [];
    }
    if (filterText) {
      return search(trainerSummary, Object.keys(trainerSummary[0]), filterText);
    }

    return trainerSummary;
  };

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start "
        >
          <Breadcrumb className="breadcrumb-pipes">
            <BreadcrumbItem>
              <span>Antrenör Çizelgesi</span>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span className="text-primary">
                {startDate + " | " + endDate}
              </span>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center   justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions">
            <ExportDropdown<ITrainerSummary>
              className="me-1"
              array={filterData()}
              paperSize="A3"
              fileName={`antrenör_çizelgesi_detay_${moment(new Date()).format(
                dateFormat
              )}`}
              sheetName={`${moment(startDate).format(dateFormat)}_${moment(
                endDate
              ).format(dateFormat)}`}
              headers={[
                {
                  key: "name",
                  label: "ANTRENÖR ISMI",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.name} ${element.trainer.user.surname}`;
                  },
                },
                {
                  key: "phoneNumber",
                  label: "GSM",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.phoneNumber}`;
                  },
                },
                {
                  key: "emailAddress",
                  label: "E-MAIL",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.emailAddress}`;
                  },
                },
                {
                  key: "density",
                  label: "YOGUNLUK %",
                  onRender: (item: string, element) => {
                    return `${(
                      (element.assignedLessonSum /
                        element.maxAssignableLessonSum) *
                      100
                    ).toFixed(0)}`;
                  },
                },
                {
                  key: "assignedLessonSum",
                  label: "ATANAN DERS SAYISI",
                  onRender: (item: string, element) => {
                    return `${element.assignedLessonSum}`;
                  },
                },
                {
                  key: "negotiableLesson",
                  label: "ATANABILIR DERS SAYISI",
                  onRender: (item: string, element) => {
                    return `${
                      element.maxAssignableLessonSum - element.assignedLessonSum
                    }`;
                  },
                },
              ]}
              title={time}
            />
            <Button
              style={{ textDecoration: "underline" }}
              color="flat-warning"
              onClick={plansDetail}
            >
              Detaylar
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search-invoice">
            Ara
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.CSR
            subHeader
            subHeaderComponent={ToDetails()}
            columns={columns}
            data={trainerSummary}
            filterText={filterText}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default CoachSchedule;
