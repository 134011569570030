import Breadcrumbs from "../../../@core/components/breadcrumbs";
import StudentsListTable from "../../../components/transactions/students/studentsListTable";

const Students = () => {
  return (
    <>
      <Breadcrumbs
        title="Öğrenci"
        data={[{ title: "İşlemler" }, { title: "Öğrenci" }]}
      />
      <div>
        <StudentsListTable />
      </div>
    </>
  );
};

export default Students;
