// ** React Imports
import { FC, Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
//@ts-ignore
import { selectThemeColors } from "@utils";
//@ts-ignore
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/base/pages/user-info.scss";
import { IUsersCreateModel } from "../../../model/DbMdel/usersDBModel";
import { createUsers } from "../../../services/user";
import toast from "react-hot-toast";
import classnames from "classnames";
import { ILanguage } from "../../../model/commonModel/language";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "../../common/LoadingButton";
import SwitchLabel from "../../common/SwitchLabel";
import SwalFire from "../../common/Swal";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import { usePageContext } from "../../../utility/context/UserContext";
import phone from "phone";

const rolesOptions = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
];

interface IAddNewUserModalProps {
  setShow: (param: any) => void;
  show: boolean;
  onReload: () => void;
  languages: ILanguage[];
}

const AddNewUserModal: FC<IAddNewUserModalProps> = ({
  setShow,
  show,
  onReload,
  languages,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = usePageContext();

  const statusOptions = [
    { value: true, label: "Aktif" },
    { value: false, label: "Pasif" },
  ];

  const AddUsersSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .email("Geçerli bir email adresi giriniz.")
      .required("E-mail zorunlu alandır."),
    name: yup
      .string()
      .min(2, "İsim zorunlu alandır")
      .max(64, "İsim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir isim giriniz")
      .required("İsim zorunlu alandır"),
    surname: yup
      .string()
      .min(2, "İsim zorunlu alandır")
      .max(64, "İsim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir isim giriniz")
      .required("İsim zorunlu alandır"),
    phoneNumber: yup
      .string()
      .test("matches", "Geçerli bir numara giriniz", (str) => {
        const pn = phone(str!);
        return pn.isValid;
      })
      .required("Telefon numarası zorunludur"),
    status: yup.string().notRequired(),
    languages: yup
      .array()
      .min(1, "En az bir dil seçiniz.")
      .required("Dil zorunlu alandır."),
    roles: yup.object().required("Rol zorunlu alandır."),
    isPanelAccess: yup.boolean().notRequired(),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
    register,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddUsersSchema),
    defaultValues: {
      isActive: { value: true, label: "Aktif" },
      languages: [{ value: 1, label: "Türkçe" }],
      isPanelAccess: true,
    } as any,
  });

  const onSubmit = async (e: any) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Yeni bir kullanıcı oluşturmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: async () => {
        const data: IUsersCreateModel = {
          name: e.name,
          photo: e.photo,
          surname: e.surname,
          emailAddress: e.emailAddress,
          phoneNumber: phone(e.phoneNumber).phoneNumber ?? "",
          isActive: true,
          isPanelAccess: e.isPanelAccess,
          languageIds: e.languages.map((l: any) => l.value),
          roleNames: [e.roles.label],
          password: "",
        };
        setIsLoading(true);
        createUsers(data)
          .then((res) => {
            toast.success("Kullanıcı Ekleme Başarılı.");
            setShow(false);
            onReload();
            setIsLoading(false);
            reset({
              name: "",
              surname: "",
              emailAddress: "",
              phoneNumber: "",
              status: true,
              languages: [],
              roles: [],
            });
            refetch();
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 pt-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Yönetici/Admin Ekle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-75">
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="name"
                        placeholder="İsim"
                        invalid={errors.name && true}
                        type="text"
                        maxLength={64}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="surname">
                  Soyisim
                </Label>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="surname"
                      type="text"
                      maxLength={64}
                      placeholder="Soyisim"
                      invalid={errors.surname && true}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    />
                  )}
                />
                {errors.surname && (
                  <FormFeedback>
                    {" "}
                    {errors.surname.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="emailAddress">
                  E-mail
                </Label>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="emailAddress"
                      placeholder="mail@mail.com"
                      type="email"
                      maxLength={320}
                      invalid={errors.emailAddress && true}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    />
                  )}
                />
                {errors.emailAddress && (
                  <FormFeedback>
                    {errors.emailAddress.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="phone">
                  Telefon
                </Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputComponent
                      invalid={!!errors.phoneNumber}
                      {...field}
                      {...register("phoneNumber")}
                      register={register}
                      onChange={() => {}}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <FormFeedback>
                    {errors.phoneNumber.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="status">
                  Durum
                </Label>
                <Select
                  id="status"
                  isClearable={false}
                  className="react-select"
                  classNamePrefix="select"
                  options={statusOptions}
                  theme={selectThemeColors}
                  defaultValue={statusOptions[0]}
                />
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="language">
                  Dil(ler)
                </Label>
                <Controller
                  control={control}
                  name="languages"
                  render={({ field }) => (
                    <Select
                      id="languages"
                      isClearable={false}
                      isMulti
                      classNamePrefix="select"
                      options={languages.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      defaultValue={getValues("languages")}
                      className={classnames("react-select", {
                        "is-invalid": errors.languages,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.languages && (
                  <FormFeedback>
                    {" "}
                    {errors.languages.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="roles">
                  Roller
                </Label>
                <Controller
                  control={control}
                  name="roles"
                  render={({ field }) => (
                    <Select
                      id="roles"
                      isClearable={false}
                      classNamePrefix="select"
                      options={rolesOptions.map((x) => {
                        return { value: x.value, label: x.label };
                      })}
                      className={classnames("react-select", {
                        "is-invalid": errors.roles,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.roles && (
                  <FormFeedback>
                    {" "}
                    {errors.roles.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <div className="form-switch">
                    <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="switch"
                            id="isPanelAccess"
                            defaultChecked
                            onChange={(event) => {
                              setValue("isPanelAccess", event.target.checked);
                            }}
                          />
                        );
                      }}
                    />
                    <SwitchLabel htmlFor={"isPanelAccess"} />
                  </div>
                  <Label
                    className="form-check-label fw-bolder"
                    htmlFor="isPanelAccess"
                  >
                    Panele Erişebilir
                  </Label>
                </div>
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShow(false)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewUserModal;
