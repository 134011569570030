// ** React Imports
import { useParams } from "react-router-dom";

// ** Reactstrap Imports
import { Row, Col } from "reactstrap";

// ** User View Components
import UserInfo from "../../../components/UserProfile/UserInfo";
import UserTabs from "../../../components/UserProfile/Tabs";
// ** Styles
import "@styles/react/apps/app-users.scss";
import Breadcrumbs from "../../../@core/components/breadcrumbs";

const ProfileSection = () => {

  const { id } = useParams();

  return (
    <div className="app-user-view">
      <Breadcrumbs
        title="Kullanıcılar"
        data={[
          { title: "Kullanıcılar ve İzinler" },
          { title: "Kullanıcılar", link: "/usersandpermissions/users" },
          { title: "Profil İnceleme" },
        ]}
      />

      <Row>
        <Col xl="4" lg="5" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
          <UserInfo id={parseInt(id!)} />
        </Col>
        <Col xl="8" lg="7" xs={{ order: 0 }} md={{ order: 1, size: 7 }}>
          <UserTabs
            id={parseInt(id!)}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ProfileSection;
