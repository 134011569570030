import { createContext, useContext, useEffect, useState } from "react";
import { ICoachDBModel } from "../../model/DbMdel/coachDBModel";
import getCoachs, { getCoachsById } from "../../services/coach";
import getSalaries, { getSavedSalaries } from "../../services/salary";
import toast from "react-hot-toast";
import {
  ICoachSummaryModel,
  ISavedSalaries,
} from "../../model/DbMdel/salaryDBModel";
import {
  IActivityDbModel,
  IActivityInputModel,
} from "../../model/DbMdel/activityDBModel";
import getActivities from "../../services/activity";
import useInfinityPaginateFetch from "../hooks/userInfinityPaginateFetch";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";

interface ICalendarProps {
  coachs?: ICoachDBModel[];
  setRangeDate?: (startDate: string, endDate: string) => void;
  setPickerDate?: (value: string) => void;
  setActivityData?: (data: IActivityInputModel) => void;
  etActivityUpdate?: (values: IActivityDbModel) => void;
  savedSalaries?: ISavedSalaries[];
  confirmed?: boolean;
  salaryTrainers?: ICoachSummaryModel[];
  allActivity?: IActivityDbModel[];
  page?: number;
  handlePaginate?: (value: number) => void;
  coachTotalCount?: number;
}

const CalendarPageContext = createContext<any>(null);

const CalendarContext = ({ children }: any) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [salaryTrainers, setSalaryTrainers] = useState<ICoachSummaryModel[]>(
    []
  );
  const [pickerDateCoach, setPickerDateCoach] = useState("");
  const [dateRangeContext, setDateRangeContext] = useState({
    startDate: "",
    endDate: "",
  });
  const [activityDataContext, setActivityDataContext] = useState<any>([]);
  const [allActivity, setAllActivity] = useState<IActivityDbModel[]>([]);
  const [savedSalaries, setSavedSalaries] = useState<ISavedSalaries[]>([]);
  const [selectedCoach, setSelectedCoach] = useState<any>(null);
  const [coach, setCoach] = useState([] as any);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const perPage = 500;

  const {
    data,
    handlePaginate,
    handleSearch,
    page,
    refetch,
    isLoading: coachLoading,
  } = useInfinityPaginateFetch<ICoachDBModel>({
    callbackService: getCoachs,
    maxResultCount: perPage,
    isActive: true,
  });

  useEffect(() => {
    setIsLoading(coachLoading);
  }, [coachLoading]);

  const fetchSalaries = async () => {
    if (pickerDateCoach) {
      getSalaries(pickerDateCoach!)
        .then((res) => {
          setConfirmed(res.isConfirmed);
          setSalaryTrainers(res.trainers);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const fetchActivities = async () => {
    if (activityDataContext.startDate && activityDataContext.endDate) {
      setIsLoading(true);
      try {
        const response = await getActivities({
          ...activityDataContext,
          endDate: activityDataContext.startDate,
        });
        setAllActivity(response.items as any);
      } catch (error) {
        setAllActivity([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchSavedSalaries = async () => {
    setSavedSalaries([]);
    await getSavedSalaries({
      startDate: dateRangeContext.startDate,
      endDate: dateRangeContext.endDate,
    })
      .then((e: ISavedSalaries[]) => {
        setSavedSalaries(e);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchSalaries();
  }, [pickerDateCoach]);

  useEffect(() => {
    if (dateRangeContext.startDate && dateRangeContext.endDate) {
      fetchSavedSalaries();
    }
  }, [dateRangeContext.startDate && dateRangeContext.endDate]);

  useEffect(() => {
    if (activityDataContext !== null) {
      fetchActivities();
    }
  }, [activityDataContext]);

  const setPickerDate = (value: string) => {
    if (value) {
      setPickerDateCoach(value);
    }
  };

  const setRangeDate = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      setDateRangeContext({
        startDate: startDate,
        endDate: endDate,
      });
    }
  };

  const setActivityData = (data: IActivityInputModel) => {
    if (data) {
      setActivityDataContext(data);
    }
  };

  const setActivityUpdate = (values: IActivityDbModel) => {
    const hasMatch = allActivity.some((activity) => activity.id === values.id);

    if (hasMatch) {
      setAllActivity((prevState) =>
        prevState.map((activity) =>
          activity.id === values.id ? values : activity
        )
      );
    } else {
      setAllActivity((prevState) => [...prevState, values]);
    }
  };

  const setSelectedTrainer = (value: number) => {
    if (value) {
      getCoachsById(value)
        .then((res) => {
          setSelectedCoach(res);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    setCoach((prevState: any) => [...prevState, ...data.items]);
  }, [data.items]);

  return (
    <CalendarPageContext.Provider
      value={{
        coachs: coach,
        setRangeDate,
        setPickerDate,
        setActivityData,
        setActivityUpdate,
        savedSalaries,
        confirmed,
        salaryTrainers,
        allActivity,
        page,
        handlePaginate,
        coachTotalCount: data.totalCount,
        selectedCoach,
        setSelectedTrainer,
        isLoading,
        perPage,
      }}
    >
      {children}
    </CalendarPageContext.Provider>
  );
};

export const useMyContext = () => useContext(CalendarPageContext);
export default CalendarContext;
