// ** Styles
import "@styles/react/apps/app-users.scss";
import UsersTable from "../../../components/Users/UsersTable";
import UsersHeadBar from "../../../components/Users/UsersHeadBar";
import { useEffect } from "react";
import getUsers from "../../../services/user";
import {
  IGetUsersModel,
  IUsersModel,
} from "../../../model/DbMdel/usersDBModel";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { usePageContext } from "../../../utility/context/UserContext";

const Users = () => {
  const { data } =
    usePageContext();
  return (
    <div className="app-user-view">
      <div className="app-user-list">
        <UsersHeadBar
          count={data.totalCount}
          activeCount={(data as IGetUsersModel).activeCount}
        />
        <UsersTable/>
      </div>
    </div>
  );
};
export default Users;
