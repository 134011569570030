import http from "../helper/http";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";
import {
  IActivityDbModel,
  IActivityInputModel,
  IActivityModel,
  IApproveActivityModel,
  ICreateActivityDbModel,
  IEditActivityDBModel,
  IEncashAllModel,
  IMultipleConfirmActivityModel,
  IStudentActivityDbModel,
  IStudentEncashModel,
} from "../model/DbMdel/activityDBModel";

const getActivities = (
  input: IActivityInputModel
): Promise<PagedResultDto<IActivityDbModel>> => {

  return http.get<any, PagedResultDto<IActivityDbModel>>("/Activities", {
    params: input,
  });
};

export const getActivity = (activityId: number): Promise<IActivityModel> => {
  return http.get<any, IActivityModel>(`/Activities/${activityId}`);
};

export const createActivity = (
  activity: ICreateActivityDbModel
): Promise<PagedResultDto<IActivityDbModel>> => {
  return http.post<any, PagedResultDto<IActivityDbModel>>(
    "/Activities",
    activity
  );
};

export const editActivity = (
  activity: IEditActivityDBModel,
  id: number
): Promise<IActivityDbModel> => {
  return http.put<any, IActivityDbModel>(`/Activities/${id}`, activity);
};

export const approveActivity = (
  input: IApproveActivityModel
): Promise<IActivityDbModel> => {
  return http.put<any, IActivityDbModel>(
    `/Activities/${input.activityId}/${input.day}/confirmation/${input.confirm}`
  );
};
export const confrimMultipleActivity = (
  input: Array<IMultipleConfirmActivityModel>
): Promise<Array<IActivityDbModel>> => {
  return http.put<any, Array<IActivityDbModel>>(
    `/Activities/multiple-confirmation`,
    input
  );
};

export const studentEncashActivity = (
  input: IStudentEncashModel,
  isEncashed: boolean,
  paymentTypeId?: number
): Promise<IActivityDbModel> => {
  return http.put<any, IActivityDbModel>(
    `/Activities/${input.activityId}/${input.day}/${input.studentId}/encash`,
    { isEncashed, paymentTypeId }
  );
};
export const encashAllActivity = (
  input: Array<IEncashAllModel>
): Promise<Array<IActivityDbModel>> => {
  return http.put<any, Array<IActivityDbModel>>(`/Activities/encashAll`, input);
};

export const deleteActivity = (activityId: number, status: boolean) => {
  return http.delete<any, IActivityDbModel>(
    `/Activities/${activityId}/${status}`
  );
};

export const getStudentActivity = (
  input: IActivityInputModel
): Promise<PagedResultDto<IStudentActivityDbModel>> => {

  return http.get<any, PagedResultDto<IStudentActivityDbModel>>("/Activities/GetAllStudentDto", {
    params: input,
  });
};


export default getActivities;
