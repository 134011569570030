// ** React Imports
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import "@styles/base/plugins/extensions/ext-component-sweet-alerts.scss";
import "@styles/react/libs/editor/editor.scss";
import "@styles/react/libs/flatpickr/flatpickr.scss";
import "moment/locale/tr"; // without this line it didn't work
moment.locale("tr");
// ** Service Worker
import { ToastPosition } from "react-hot-toast/dist/core/types";
import AuthProvider from "./utility/context/Auth";
import App from "./App";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import CalendarContext from "./utility/context/CalendarContext";

const apiKey =
  process.env.REACT_APP_NODE_ENV &&
  process.env.REACT_APP_NODE_ENV === "localhost"
    ? "localhost"
    : process.env.REACT_APP_BUGSNAG_KEY;
Bugsnag.start({
  apiKey: apiKey as string,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <AuthProvider>
      <BrowserRouter>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <ThemeContext>
              <App />
              <Toaster
                position={themeConfig.layout.toastPosition as ToastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ThemeContext>
          </Suspense>
        </Provider>
      </BrowserRouter>
    </AuthProvider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
