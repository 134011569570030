import React, { useEffect, useState } from "react";
import {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  MenuPlacement,
  SingleValue,
} from "react-select";
import Select from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { selectThemeColors } from "../../utility/Utils";
import "./style.scss";

interface InfiniteScrollProps {
  headers?: string[];
  data: any;
  isLoading: boolean;
  id: string;
  className?: string;
  components?: Partial<SelectComponents<IOption, false, GroupBase<IOption>>>;
  onChange?: (
    newValue: SingleValue<IOption>,
    actionMeta: ActionMeta<IOption>
  ) => void;
  ref?: any;
  field?: any;
  defaultValue?: any;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onMenuScrollBottom?: () => void;
  options?: boolean;
  isMulti?: boolean;
  searchValue?: string;
  totalCount?: number;
  isDisabled?: boolean;
  location?: any;
  invalid?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
}
interface IOption {
  value: string;
  id: number;
  label: string;
  price: string;
}

export const InfiniteScrollDropdown: React.FC<InfiniteScrollProps> = ({
  headers = [],
  data,
  isLoading,
  id,
  className,
  components,
  onChange,
  ref,
  field,
  defaultValue,
  onInputChange,
  onMenuScrollBottom,
  options,
  isMulti = false,
  searchValue,
  totalCount,
  isDisabled,
  location,
  invalid,
  placeholder: placeHolder = "Seçiniz",
  isClearable = false,
  menuPlacement = "auto",
}) => {
  const [filteredDropDownData, setFilteredDropDownData] = useState<IOption[]>(
    []
  );

  useEffect(() => {
    const filteredData = options
      ? data
      : data.map((children: any) => {
          const tempObj = {
            id: children.id,
          } as IOption;
          const headersData = headers?.map((header) => {
            if (header.includes(".")) {
              const [mainObject, field] = header.split(".");
              return children[mainObject][field];
            } else return children[header];
          });
          tempObj.price = children.price;
          tempObj.value! = children.id;
          tempObj.label! = headersData.join(" ");
          return tempObj;
        });

    searchValue?.length
      ? setFilteredDropDownData(filteredData)
      : setFilteredDropDownData((prevFilteredData) => {
          const newFilteredData = filteredData.filter(
            (newItem: any) =>
              !prevFilteredData.some(
                (existingItem) => existingItem.id === newItem.id
              )
          );
          return [...prevFilteredData, ...newFilteredData];
        });
  }, [data]);

  useEffect(() => {
    if (location) {
      setFilteredDropDownData([]);
    }
  }, [location?.id]);

  return (
    <Select
      captureMenuScroll
      onMenuScrollToBottom={onMenuScrollBottom}
      options={filteredDropDownData}
      onInputChange={onInputChange}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      id={id}
      isDisabled={isDisabled}
      placeholder={placeHolder}
      theme={selectThemeColors}
      className={`${className} ${invalid ? "invalid-select" : ""}`}
      classNamePrefix="select"
      isClearable={isClearable}
      isMulti={isMulti}
      components={{ ...components }}
      defaultValue={defaultValue}
      {...field}
      styles={{
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      onChange={onChange}
      ref={ref}
      menuPlacement={menuPlacement}
    />
  );
};
