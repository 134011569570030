// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Custom Components
import Avatar from "@components/avatar/";

// ** Third Party Components
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Bell, X, Check, AlertTriangle } from "react-feather";

// ** Reactstrap Imports
import {
  Button,
  Badge,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {
  getNotifications,
  readNotifications,
} from "../../../../services/notification";
import toast from "react-hot-toast";

const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    setNotifications({});
    await getNotifications(0, 1000, false)
      .then((e) =>setNotifications(e))
      .catch((e) => {});
  };
  useEffect(() => {
    fetchNotifications();
    let interval = setInterval(() => {
      fetchNotifications();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const readAllNotification = async (e) => {
    e.preventDefault();
    await readNotifications();
  };

  const onToggleDropdown = async (event, isOpen) => {
    if (!isOpen) await fetchNotifications();
  };

  // ** Function to render Notifications
  /*eslint-disable */
  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
      >
        {(notifications?.items ?? []).map((item, index) => {
          return (
            <div
              key={item.id}
              className={classnames("list-item d-flex align-items-center")}
            >
              <Fragment>
                <div className="nav-link dropdown-user-link">
                  {item.content}
                </div>
              </Fragment>
            </div>
          );
        })}
      </PerfectScrollbar>
    );
  };
  /*eslint-enable */

  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification nav-item me-25"
      onToggle={onToggleDropdown}
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        // href="/"
      >
        <Bell size={21} />
        <Badge pill color="danger" className="badge-up">
          {notifications?.totalCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0">
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 me-auto">Notifications</h4>
            <Badge tag="div" color="light-warning" pill>
              {notifications?.totalCount}
            </Badge>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
        <li className="dropdown-menu-footer">
          <Button disabled={!notifications?.totalCount} color="warning" block onClick={readAllNotification}>
            Read all notifications
          </Button>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationDropdown;
