import DataTable from "../../common/DataTable/components/ssr";
import { Input, Row, Col, Card, Button } from "reactstrap";
import { useEffect, useState } from "react";
import { Edit, Eye, Plus } from "react-feather";

import {
  editStudent,
  getAllStudentsIncludesChild,
} from "../../../services/students";
import {
  IStudentDBModel,
  IStudentEditDBModel,
} from "../../../model/DbMdel/studentDBModel";
import StudentCreateModal from "./studentCreateModal";
import StudentEditModal from "./studentEditModal";
import { ILanguage } from "../../../model/commonModel/language";
import SwitchLabel from "../../common/SwitchLabel";
import { User } from "../../../model/commonModel/user";
import classnames from "classnames";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import SwalFire from "../../common/Swal";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { exportFile } from "../../../services/students";
import ExportDropdownFile from "../../common/ExportDropdownFile";
import getLanguages from "../../../services/language";
import { handleFiltered } from "../../../utility/helper";
import { defaultCount, options } from "../../common/DataTable/Constants";
const dateFormat = "DD/MM/YYYY";
const StudentsListTable = () => {
  const [clickedRowIndex, setClickedRowIndex] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const [show, setShow] = useState({ parentId: undefined, mode: "none" } as {
    parentId: number | undefined;
    mode: "none" | "create" | "edit";
  });

  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<IStudentDBModel>({
      callbackService: getAllStudentsIncludesChild,
      maxResultCount: maxResultCount
    });

  const fetchLanguage = async () => {
    getLanguages()
      .then((languages) => {
        setLanguages(languages as any);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const studentsWithoutParent = data.items.filter(
    (student) => !student.parentStudent
  );
  const studentsWithParent = data.items.filter(
    (student) => student.parentStudent
  );

  const groupedStudents = {} as any;
  studentsWithParent.forEach((student) => {
    if (!groupedStudents[student.parentStudent?.id!]) {
      groupedStudents[student.parentStudent?.id!] = [];
    }
    groupedStudents[student.parentStudent?.id!].push(student);
  });

  const sortedData = studentsWithoutParent
    .map((student) => {
      if (groupedStudents[student.id]) {
        return [student, ...groupedStudents[student.id]];
      } else {
        return student;
      }
    })
    .flat();

  useEffect(() => {
    fetchLanguage();
  }, []);

  useEffect(() => {
    refetch()
  }, [maxResultCount])

  const handleChange = (
    index: number,
    key: keyof User,
    value: any,
    swalText: string
  ) => {
    const studentIndex = data.items?.findIndex((e) => e.id === index);

    const {
      user,
      perHourPrice,
      parentStudent,
      serviceAreaEntryDate,
      serviceAreaExitDate,
      location,
    } = data.items![studentIndex!];

    const tempUser = { ...user, [key]: value };
    const tempStudent = { ...data.items![studentIndex!], user: tempUser };

    const tempData = {
      userDto: {
        name: user.name,
        surname: user.surname,
        emailAddress: user.emailAddress,
        phoneNumber: user.phoneNumber,
        isActive: user.isActive,
        password: "",
        isPanelAccess: user.isPanelAccess,
        languageIds: user.languages.map((e) => e.id),

        [key]: value,
      },
      serviceAreaEntryDate: serviceAreaEntryDate,
      serviceAreaExitDate: serviceAreaExitDate,
      perHourPrice: perHourPrice,
      locationId: location?.id,
      studentId: parentStudent ? parentStudent.id : null,
    } as IStudentEditDBModel;
    SwalFire({
      title: "Emin misiniz?",
      text: swalText,
      onOk: () => {
        setClickedRowIndex(index);
        editStudent(tempData!, tempStudent!.id)
          .then((res) => {
            const tempStudents = [...data.items!];
            tempStudents[studentIndex!] = tempStudent;
            setClickedRowIndex(-1);
            refetch();
          })
          .catch((err) => {
            toast.error(err);
            setClickedRowIndex(-1);
          });
      },
    });
  };

  const caseInsensitiveSort = (
    rowA: IStudentDBModel,
    rowB: IStudentDBModel
  ) => {
    const a = rowA.user.name.toLowerCase();
    const b = rowB.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "",
      right: true,
      wrap: true,
      allowOverflow: true,
      cell: (row: IStudentDBModel) => {
        return (
          <div className="d-flex align-items-center">
            {!row.parentStudent && (
              <Button
                color="success"
                size="sm"
                style={{
                  marginRight: "4px",
                  width: "26px",
                  height: "26px",
                  position: "relative",
                }}
                onClick={(e) => {
                  setShow({ parentId: row.id, mode: "create" });
                  e.preventDefault();
                }}
              >
                <Plus
                  size={18}
                  style={{ position: "absolute", top: "4px", left: "6px" }}
                  className="font-medium-2"
                />
              </Button>
            )}

            <Button
              color="warning"
              size="sm"
              style={{
                marginRight: "4px",
                width: "26px",
                height: "26px",
                position: "relative",
              }}
              onClick={(e) => {
                setShow({
                  parentId: row.parentStudent
                    ? row.parentStudent?.id
                    : undefined,
                  mode: "edit",
                });
                setSelectedIndex(row.id);
                e.preventDefault();
              }}
            >
              <Edit
                size={18}
                style={{ position: "absolute", top: "3px", left: "7px" }}
                className="font-medium-2"
              />
            </Button>
            <Link
              style={{ paddingRight: "10px" }}
              to={`/usersandpermissions/users/profile-detail/${row.user.id}`}
            >
              <Eye size={18} className="font-medium-3 text-body" />
            </Link>
          </div>
        );
      },
    },
    {
      name: "İSİM",
      sortable: true,
      sortField: "user.name",
      sortFunction: caseInsensitiveSort,
      selector: (row: IStudentDBModel) => row.user.name,
      cell: (row: IStudentDBModel) => {
        return (
          <div className="d-flex justify-content-left align-items-center">
            <div className="d-flex flex-column">
              <h6
                className="user-name d-block mb-0 text-truncate"
                style={{ maxWidth: "150px" }}
              >
                {row.user.name} {row.user.surname}
              </h6>
              <small className="text-truncate text-muted mb-0">
                {"Öğrenci"}
              </small>
            </div>
          </div>
        );
      },
    },
    {
      name: "GSM",
      sortField: "phone",
      cell: (row: IStudentDBModel) => row.user.phoneNumber,
    },
    {
      name: "LOKASYON",
      sortField: "location",
      cell: (row: IStudentDBModel) => row.location?.name,
    },
    {
      name: "FİYAT",
      sortField: "price",
      maxWidth: "50px",
      cell: (row: IStudentDBModel) => row.perHourPrice,
    },
    {
      name: "AKTİF/PASİF",
      sortField: "active",
      maxWidth: "50px",
      cell: (row: IStudentDBModel) => {
        return (
          <div
            className={classnames("form-switch", {
              [`form-check-warning`]: row.user.isActive,
            })}
          >
            <Input
              type="switch"
              checked={row.user.isActive}
              id={`${row.id}_icon-primary_isActive`}
              name="icon-primary"
              onClick={(e: any) => {
                //@ts-ignore
                handleChange(
                  row.id,
                  "isActive",
                  e.target.checked as any,
                  "Öğrenci durumunu değiştirmeniz , tanımlanacak olan etkinliklere direkt etki etmektedir.İşleme devam etmek istiyor musunuz?"
                );
              }}
            />
            <SwitchLabel htmlFor={`${row.id}_icon-primary_isActive`} />
          </div>
        );
      },
    },
    {
      name: "PANEL ERİŞİMİ",
      sortField: "panel",
      maxWidth: "50px",
      cell: (row: IStudentDBModel) => {
        return (
          <div
            className={classnames("form-switch", {
              [`form-check-warning`]: row.user.isPanelAccess,
            })}
          >
            <Input
              type="switch"
              checked={row.user.isPanelAccess}
              id={`${row.id}_icon-primary_isPanelAccess`}
              name="icon-primary"
              onChange={(e) => {
                //@ts-ignore
                handleChange(
                  row.id,
                  "isPanelAccess",
                  e.target.checked,
                  "Bu işlem öğrencinin panele erişimine direkt olarak etki edecektir.İşleme devam etmek istiyor musunuz?"
                );
              }}
            />
            <SwitchLabel htmlFor={`${row.id}_icon-primary_isPanelAccess`} />
          </div>
        );
      },
    },
    {
      name: "OTELE GİRİŞ-ÇIKIŞ TARİHLERİ",
      minWidth: "160px",
      sortField: "serviceAreaEntryDate",
      cell: (row: IStudentDBModel) => {
        return (
          <div className="d-flex flex-row justify-content-between align-items-center ">
            <p className="m-0 text-nowrap">
              {row.serviceAreaEntryDate
                ? moment(row.serviceAreaEntryDate).format(dateFormat)
                : null}
            </p>
            {row.serviceAreaEntryDate || row.serviceAreaExitDate ? (
              <div>-</div>
            ) : null}
            <p className="m-0 text-nowrap">
              {row.serviceAreaExitDate
                ? moment(row.serviceAreaExitDate).format(dateFormat)
                : null}
            </p>
          </div>
        );
      },
    },
    {
      name: "DİLLER",
      sortField: "languages",
      maxWidth: "50px",
      cell: (row: IStudentDBModel) =>
        row.user.languages
          .map((x) => {
            return x.name;
          })
          .join(", "),
    },
    {
      name: "EMAİL",
      sortField: "user.emailAddress",
      cell: (row: IStudentDBModel) => row.user.emailAddress,
      minWidth: "200px",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: IStudentDBModel) => row.parentStudent,
      classNames: ["child-row"],
    },
  ];
  const ToDetails = () => {
    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row
          className="d-flex pb-2 "
          style={{ borderBottom: "1px solid #4B4B4B" }}
        >
          <Col
            lg="6"
            md="4"
            className="d-flex justify-content-center align-items-center justify-content-md-start  p-0 px-md-1  px-lg-2 "
          >
            <p>Öğrenci Listesi</p>
          </Col>

          <Col
            lg="6"
            md="8"
            className="d-flex justify-content-center  p-0 px-md-1  px-lg-2  justify-content-md-end "
          >
            <div className="d-flex align-items-center table-header-actions gap-1">
              <ExportDropdownFile
                callbackService={exportFile}
                fileName={`öğrenciler_listesi_${moment(new Date()).format(
                  dateFormat
                )}`}
                headers={[
                  "Name",
                  "PhoneNumber",
                  "IsActive",
                  "IsPanelAccess",
                  "EmailAddress",
                ]}
              />
              <Button
                color="warning"
                onClick={() => setShow({ parentId: undefined, mode: "create" })}
              >
                <Plus style={{ marginRight: "8px" }} size={14} />
                Öğrenci Ekle
              </Button>
              <StudentCreateModal
                setShow={() => setShow({ parentId: undefined, mode: "none" })}
                parentId={show.parentId}
                show={show.mode === "create"}
                languages={languages}
                onReload={async () => await refetch()}
              />
              {data.items && show.mode === "edit" && (
                <StudentEditModal
                  setShowEdit={() =>
                    setShow({ parentId: undefined, mode: "none" })
                  }
                  parentId={show.parentId}
                  showEdit={show.mode === "edit"}
                  student={data.items.find((x) => x.id === selectedIndex)!}
                  languages={languages}
                  onReload={async () => await refetch()}
                />
              )}
            </div>
          </Col>
        </Row>

        <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </Col>
          <Col lg="4" md="6" sm="12" className="d-flex align-items-center ">
            <label className="mb-0 pe-1" htmlFor="search-invoice">
              Ara:
            </label>
            <Input
              id="search"
              type="text"
              placeholder="Ara"
              aria-label="Search Input"
              onChange={(e) => handleFiltered(e.target.value.replace(/\s/g, ''), handleSearch)} // /\s/g düzenli ifadesi tüm boşluk karakterlerini (beyaz boşluklar, sekme, yeni satır vb.) bulur ve bunları boş bir dizeyle değiştirir,
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable
            conditionalRowStyles={conditionalRowStyles}
            totalCount={data.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={sortedData}
            pagination
            onChangePaginate={handlePaginate}
            pending={isLoading}
            perPage={maxResultCount}
            pageNumber={page}
          />
        </div>
      </Card>
    </div>
  );
};

export default StudentsListTable;
