// ** React Imports
import { FC, Fragment, useState } from "react";

// ** Reactstrap Imports
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// ** Icons Imports
import { Lock, Bell } from "react-feather";

// ** User Components
import SecurityTab from "../SecurityTab";
import Notifications from "../Notifications";
import { useAuth } from "../../../utility/context/Auth";

interface IUserTabsProps {
  id: number;
}

const UserTabs: FC<IUserTabsProps> = ({ id }) => {
  const { userInfo } = useAuth();
  {
    /*TODO: Geçici olarak kapatıldı*/
  }
  // const [activeTabIndex, setActiveTabIndex] = useState<number>(
  //   id === userInfo?.id ? 1 : 2
  // );

  const [activeTabIndex, setActiveTabIndex] = useState<number>(2);

  const toggleTab = (tab: number) => {
    if (activeTabIndex !== tab) {
      setActiveTabIndex(tab);
    }
  };

  return (
    <Fragment>
      <Nav pills className="mb-2">
        {/*TODO: Geçici olarak kapatıldı*/}
        {/* {id === userInfo?.id && (
          <NavItem>
            <NavLink active={activeTabIndex === 1} onClick={() => toggleTab(1)}>
              <Lock className="font-medium-3 me-50" />
              <span className="fw-bold">Güvenlik</span>
            </NavLink>
          </NavItem>
        )} */}

        <NavItem>
          <NavLink active={activeTabIndex === 2} onClick={() => toggleTab(2)}>
            <Bell className="font-medium-3 me-50" />
            <span className="fw-bold">Bildirimler</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTabIndex.toString()}>
        {/*TODO: Geçici olarak kapatıldı*/}
        {/* {id === userInfo?.id && (
          <TabPane tabId="1">
            <SecurityTab />
          </TabPane>
        )} */}

        <TabPane tabId="2">
          <Notifications/>
        </TabPane>
      </TabContent>
    </Fragment>
  );
};
export default UserTabs;
