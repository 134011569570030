import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import {
  IGroupDBModel,
  IGroupEditDBModel,
} from "../../../../model/DbMdel/groupDBModel";
import { editGroup } from "../../../../services/groups";
import LoadingButton from "../../../common/LoadingButton";
import SwalFire from "../../../common/Swal";
import LocationDropdown from "../../../locationForPage";

interface IGroupsEditModalProps {
  setShowEdit: (value: SetStateAction<boolean>) => void;
  showEdit: boolean;
  group: IGroupDBModel;
  onReload: () => void;
}

const GroupEditModal: FC<IGroupsEditModalProps> = ({
  setShowEdit,
  showEdit,
  group,
  onReload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const AddStudentSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Grup ismi zorunlu alandır")
      .max(64, "Grup ismi en fazla 64 karakter olmalıdır")
      .matches(
        /^([A-Za-z\ıİğĞşŞ0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Geçerli bir grup ismi giriniz"
      )
      .required("Grup ismi zorunlu alandır"),
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
    description: yup.string(),
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddStudentSchema),
    defaultValues: {
      name: group.name,
      location: {
        id: group.location.id,
        value: group.location.id,
        label: group.location.name,
        student: group.students,
      },
      description: group.description,
    },
  });

  const onSubmit = async (e: any) => {
    SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup listesini düzenlemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        const data: IGroupEditDBModel = {
          name: e.name,
          description: e.description,
          locationId: e.location.id,
          studentsInput: e.students,
        };
        setIsLoading(true);
        editGroup(data, group.id)
          .then((res) => {
            toast.success("Grup Düzenleme başarılı");
            onReload();
            setIsLoading(false);
            reset({
              name: "",
              location: {},
            });
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };

  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShowEdit(!showEdit)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Grubu Düzenle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={12} xs={12}>
                <Label>Lokasyon</Label>
                <LocationDropdown
                  control={control}
                  onChange={(event) => setValue("location", event)}
                  defaultValue={getValues("location")}
                />
              </Col>
              <Col md={12} xs={12}>
                <Label className="form-label" for="name">
                  Grup İsmi
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("name").ref}
                        id="name"
                        type="text"
                        maxLength={64}
                        invalid={errors.name && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="description">
                  Grup Açıklaması
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("description").ref}
                        id="description"
                        type="textarea"
                        maxLength={500}
                        invalid={errors.description && true}
                        placeholder="Grup açıklaması"
                        style={{ maxHeight: "200px", minHeight: "80px" }}
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Onayla
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShowEdit(false)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default GroupEditModal;
