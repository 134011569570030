// ** React Imports
import { Link } from 'react-router-dom'

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardText, Button, Form, Input } from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'

const TwoStepsVerification = () => {
  return (
    <div className='auth-wrapper auth-basic px-2'>
      <div className='auth-inner my-2'>
        <Card className='mb-0'>
          <CardBody className='mt-2 mb-2'>
            <CardTitle tag='h2' className='fw-bolder mb-1'>
            İki Aşamalı Doğrulama 💬
            </CardTitle>
            <CardText className='mb-75'>
            Lütfen 6 haneli Google Autheticator kodunuzu girin.
            </CardText>
            <Form className='mt-2' onSubmit={e => e.preventDefault()}>
              <div className='auth-input-wrapper d-flex align-items-center justify-content-between'>
                <Input autoFocus maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
                <Input maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
                <Input maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
                <Input maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
                <Input maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
                <Input maxLength={1} className='auth-input height-50 text-center numeral-mask mx-25 mb-1' />
              </div>
            </Form>
            <Button block tag={Link} to='/' color='warning'>
              Devam Et
            </Button>
            {/* <p className='text-center mt-2 mb-2'>
              <span>Kodu almadınız mı?</span>
              <a href='/' onClick={e => e.preventDefault()}>
              Yeniden gönder
              </a>
              <span>ya da</span>
              <a href='/' onClick={e => e.preventDefault()}>
                Bize ulaşın
              </a>
            </p> */}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default TwoStepsVerification
