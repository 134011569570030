import {
  Input,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import { Link } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import ExportDropdown from "../common/ExportDropdown";
import { ISelectDate } from "../../model/commonModel/selectDate";
import _debounce from "lodash.debounce";
import moment from "moment";
import { ICoachGroupByModel } from "../../model/commonModel/groupCoach";
import dateFormat from "../../helper/dateFormat";
import { search } from "ss-search";
import DataTable from "../common/DataTable";
import { defaultCount, options } from "../common/DataTable/Constants";
import { IPaymentMethodDBModel } from "../../model/DbMdel/paymentMethodDBModel";
interface IProps {
  selectedDate: ISelectDate;
  trainers: ICoachGroupByModel[];
  paymentTypes: IPaymentMethodDBModel[];
}
const CoachList: FC<IProps> = ({ selectedDate, trainers, paymentTypes }) => {
  const getColumnText = () => {
    var start = moment(selectedDate.endDate);
    var enDate = moment(selectedDate.startDate);
    const diff = start.diff(enDate, "days");

    switch (true) {
      case diff < 2:
        return "Günlük";
      case diff < 7 && diff > 2:
        return "Haftalık";
      case diff < 30 && diff > 7:
        return "Aylık";
      default:
        return "Yıllık";
    }
  };
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);
  const [filterText, setFilterText] = useState<string>("");
  const [selectableRows, setSelectableRows] = useState<ICoachGroupByModel[]>(
    []
  );
  const [selectedFilteredData, setSelectedFilteredData] = useState<
    ICoachGroupByModel[]
  >([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const handleFilter = (event: any): void => {
    _debounce(() => {
      setFilterText(event.target.value);
    }, 500)();
  };

  const caseInsensitiveSort = (
    rowA: ICoachGroupByModel,
    rowB: ICoachGroupByModel
  ) => {
    const a = rowA.trainer.user.name.toLowerCase();
    const b = rowB.trainer.user.name.toLowerCase();
    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const calculateUnpaidValue = (row: ICoachGroupByModel) => {
    return (
      row.dailyEarningAmount -
      row.trainerEncashSummary.reduce((total, item) => total + item.price, 0)
    );
  };

  const coachSafe = (row: ICoachGroupByModel) => {
    const safe = row.trainerEncashSummary.filter(
      (item) => item.isCoachMoneySafe
    );
    return safe.reduce((total, item) => total + item.price, 0) || 0;
  };

  const managerSafe = (row: ICoachGroupByModel) => {
    const safe = row.trainerEncashSummary.filter(
      (item) => !item.isCoachMoneySafe
    );
    return safe.reduce((total, item) => total + item.price, 0) || 0;
  };

  const columns = [
    {
      name: "Antrenör",
      sortable: true,
      width: "10vw",
      sortField: "client.name",
      sortFunction: caseInsensitiveSort,
      selector: (row: ICoachGroupByModel) => {
        const { trainer } = row;
        return (
          <div
            className={` d-flex justify-content-left align-items-center`}
            style={{ maxWidth: "120px" }}
          >
            <div className="d-flex flex-column">
              <p className="user-name text-truncate mb-0">
                {trainer.user.name} {trainer.user.surname}
              </p>
              <small className="text-truncate text-muted mb-0">{"Coach"}</small>
            </div>
          </div>
        );
      },
    },
    {
      name: "Ders Saati",
      minWidth: "80px",
      sortField: "lessons",
      selector: (row: ICoachGroupByModel) => row.sumOfLessons,
    },
    {
      name: `${getColumnText()} Kazanç`,
      minWidth: "80px",
      sortField: "daily",
      selector: (row: ICoachGroupByModel) => row.dailyEarningAmount.toFixed(0),
    },
    {
      name: "Kişi Başına Düşen Mik.",
      minWidth: "80px",
      sortField: "amount",
      selector: (row: ICoachGroupByModel) => row.perUserAmount.toFixed(0),
    },
    {
      name: "Çanak",
      width: "120px",
      sortField: "filter",
      selector: (row: ICoachGroupByModel) =>
        row.floatingComissionAmount.toFixed(0),
    },
    {
      name: "Ödenmeyen",
      minWidth: "80px",
      selector: (row: ICoachGroupByModel) =>
        calculateUnpaidValue(row).toFixed(0),
    },
    {
      name: "Antrenör Kasası",
      minWidth: "80px",
      selector: (row: ICoachGroupByModel) =>
        (coachSafe(row) + calculateUnpaidValue(row)).toFixed(0),
    },
    {
      name: "Yönetici Kasası",
      minWidth: "80px",
      selector: (row: ICoachGroupByModel) => managerSafe(row).toFixed(0),
    },
    {
      name: "Mahsuplaşma",
      minWidth: "80px",
      selector: (row: ICoachGroupByModel) =>
        (
          coachSafe(row) +
          calculateUnpaidValue(row) -
          row.floatingComissionAmount
        ).toFixed(0),
    },
    {
      name: "Komisyon",
      minWidth: "80px",
      sortField: "percent",
      selector: (row: ICoachGroupByModel) => "%" + row.trainer.comission,
    },
    {
      name: "Bireysel",
      width: "120px",
      sortField: "personal",
      selector: (row: ICoachGroupByModel) =>
        row.dailyComissionAmount.toFixed(0),
    },
    {
      name: "Fark",
      width: "100px",
      sortField: "gap",
      selector: (row: ICoachGroupByModel) => row.differenceAmount.toFixed(0),
    },
    {
      name: "Şirket Payı",
      width: "140px",
      sortField: "profit",
      selector: (row: ICoachGroupByModel) =>
        row.companyComissionAmount.toFixed(0),
    },
  ];

  useEffect(() => {
    let newColumns = [...columns];

    if (paymentTypes.length) {
      paymentTypes.forEach((paymentMethod, index) => {
        const newColumn = {
          name: paymentMethod.name,
          width: "140px",
          sortField: "paymentTypeName",
          selector: (row: ICoachGroupByModel) => {
            const findPaymentMethod = row.trainerEncashSummary.find(
              (safe) => safe.id === paymentMethod.id
            );
            return findPaymentMethod?.price?.toFixed(0) || (0).toFixed(0);
          },
        };

        newColumns.splice(5 + index, 0, newColumn);
        //FB - Yeni sütunu günlük kazanç sütunundan sonra sırala
      });
    }

    setTableColumns([...newColumns] as []);
  }, [trainers, paymentTypes]);

  const conditionalRowStyles = [
    {
      when: (row: ICoachGroupByModel) => !row.includeFloating,
      style: {
        backgroundColor: "rgba(234, 84, 85, 0.2)",
        userSelect: "none",
      },
    },
  ];

  const filterData = () => {
    if (!trainers) {
      return [];
    }
    if (trainers.length && filterText) {
      return search(trainers, Object.keys(trainers[0]), filterText);
    }

    return trainers;
  };

  const totalNumbers = (e: any) => {
    const activeData = selectableRows.length ? selectableRows : trainers;

    if (trainers.length > filterData().length) {
      if (e === "dailyEarningAmount") {
        let x = 0;
        filterData().map((item) => {
          //@ts-ignore
          if (!item.isDeleted)
            //@ts-ignore
            (x += item.dailyEarningAmount), 0;
        });
        return x.toFixed(0);
      } else if (e === "floatingComissionAmount") {
        let y = 0;
        //@ts-ignore
        filterData().map((item) => {
          //@ts-ignore
          if (!item.isDeleted)
            //@ts-ignore
            (y += item.floatingComissionAmount), 0;
        });
        return y.toFixed(0);
      } else if (e === "companyComissionAmount") {
        let z = 0;
        //@ts-ignore
        filterData().map((item) => {
          //@ts-ignore
          if (!item.isDeleted)
            //@ts-ignore
            (z += item.companyComissionAmount), 0;
        });
        return z.toFixed(0);
      }
    } else {
      if (e === "dailyEarningAmount") {
        let x = 0;
        activeData.map((item) => {
          x += item.dailyEarningAmount;
        });
        return x.toFixed(0);
      } else if (e === "floatingComissionAmount") {
        let y = 0;
        activeData.map((item) => {
          y += item.floatingComissionAmount;
        });
        return y.toFixed(0);
      } else if (e === "companyComissionAmount") {
        let z = 0;
        activeData.map((item) => {
          z += item.companyComissionAmount;
        });
        return z.toFixed(0);
      }
    }
  };

useEffect(() => {
    // @ts-ignore
    setSelectedFilteredData(selectableRows.flatMap(selectableRow =>
    //@ts-ignore
    filterData().filter(data => selectableRow.trainer.id === data.trainer.id)
  ))
}, [selectableRows, filterData()])

  useEffect(() => {
    filterData();
    trainers;
  }, [handleFilter]);

  const time = `${moment(selectedDate.startDate).format(dateFormat)} / ${moment(
    selectedDate.endDate
  ).format(dateFormat)}`;

  const ToDetails = () => {
    const columns = [
      {
        key: "trainer.user.name",
        label: "ISIM",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.trainer.user.name} ${element.trainer.user.surname}`;
        },
      },
      {
        key: "sumOfLessons",
        label: "DERS SAATI",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.sumOfLessons.toString()}`;
        },
      },
      {
        key: "benefit",
        label: "GUNLUK KAZANC",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.dailyEarningAmount.toFixed(0)}`;
        },
      },
      {
        key: "dailyEarningAmount",
        label: "KISI BASINA DUSEN MIKTAR",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.perUserAmount.toFixed(0)}`;
        },
      },
      {
        key: "dailyComissionAmount",
        label: "CANAK",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.floatingComissionAmount.toFixed(0)}`;
        },
      },
      {
        key: "unpaid",
        label: "ODENMEYEN",
        onRender: (item: string, element: ICoachGroupByModel) =>
          calculateUnpaidValue(element).toFixed(0),
      },
      {
        key: "coachSafe",
        label: "ANTRENOR KASASI",
        onRender: (item: string, element: ICoachGroupByModel) =>
          (coachSafe(element) + calculateUnpaidValue(element)).toFixed(0),
      },
      {
        key: "manageSafe",
        label: "YONETICI KASASI",
        onRender: (item: string, element: ICoachGroupByModel) =>
          managerSafe(element).toFixed(0),
      },
      {
        key: "coachPayment",
        label: "MAHSUPLAŞMA",
        onRender: (item: string, element: ICoachGroupByModel) =>
          (
            coachSafe(element) +
            calculateUnpaidValue(element) -
            element.floatingComissionAmount
          ).toFixed(0),
      },
      {
        key: "trainer.comission",
        label: "KOMISYON",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.trainer.comission.toString()}%`;
        },
      },
      {
        key: "floatingComissionAmount",
        label: "BIREYSEL",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.dailyComissionAmount.toFixed(0)}`;
        },
      },
      {
        key: "differenceAmount",
        label: "FARK",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.differenceAmount.toFixed(0)}`;
        },
      },
      {
        key: "companyComissionAmount",
        label: "SIRKET PAYI",
        onRender: (item: string, element: ICoachGroupByModel) => {
          return `${element.companyComissionAmount.toFixed(0)}`;
        },
      },
    ];

    useEffect(() => {
      let newColumns = [...columns];

      if (paymentTypes.length) {
        paymentTypes.forEach((paymentMethod, index) => {
          const newColumn = {
            key: paymentMethod.name,
            label: paymentMethod.name.toLocaleUpperCase(),
            onRender: (item: string, element: ICoachGroupByModel) => {
              const findPaymentMethod = element.trainerEncashSummary.find(
                (safe) => safe.id === paymentMethod.id
              );
              return findPaymentMethod?.price?.toFixed(0) || (0).toFixed(0);
            },
          };

          newColumns.splice(5 + index, 0, newColumn);
          //FB - Yeni sütunu günlük kazanç sütunundan sonra sırala
        });
      }

      setExportColumns([...newColumns] as []);
    }, [trainers, paymentTypes]);

    return (
      <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
        <Row
          className="d-flex pb-2 "
          style={{ borderBottom: "1px solid #4B4B4B" }}
        >
          <Col
            lg="6"
            md="4"
            className="d-flex justify-content-center align-items-center justify-content-md-start "
          >
            <Breadcrumb className="breadcrumb-pipes">
              <BreadcrumbItem>
                <span>Antrenör Çizelgesi</span>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <span className="text-primary">
                  {selectedDate.startDate + " | " + selectedDate.endDate}
                </span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col
            lg="6"
            md="8"
            className="d-flex justify-content-center   justify-content-md-end "
          >
            <div className="d-flex align-items-center table-header-actions">
              <ExportDropdown
                disabled={!selectableRows.length}
                className="me-1"
                array={selectedFilteredData}
                paperSize="A3"
                fileName={`${moment(selectedDate.startDate).format(
                  dateFormat
                )}_${moment(selectedDate.endDate).format(
                  dateFormat
                )}_antrenör_çizelgesi_detay`}
                title={time}
                labelValueData={[
                  {
                    label: `Toplam ${getColumnText()} Kazanç`,
                    value: totalNumbers("dailyEarningAmount")!.toString(),
                  },
                  {
                    label: "Toplam Çanak",
                    value: totalNumbers("floatingComissionAmount")!.toString(),
                  },
                  {
                    label: "Toplam Sirket Payi",
                    value: totalNumbers("companyComissionAmount")!.toString(),
                  },
                ]}
                sheetName={`${moment(selectedDate.startDate).format(
                  dateFormat
                )}_${moment(selectedDate.endDate).format(dateFormat)}`}
                headers={exportColumns}
              />

              <Link
                to={`/payment-tracker?startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`}
              >
                <Button
                  style={{ textDecoration: "underline" }}
                  color="flat-warning"
                >
                  Detaylar{" "}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>

        <Row className="d-flex pb-0 pt-2 justify-content-between ">
          <Col lg="4" sm="12" className="align-items-center ">
            <p>
              Toplam {getColumnText()} Kazanç:{" "}
              {totalNumbers("dailyEarningAmount")}
            </p>
            <p> Toplam Çanak: {totalNumbers("floatingComissionAmount")}</p>
            <p>Toplam Şirket Payı: {totalNumbers("companyComissionAmount")}</p>
          </Col>
          <Col className="d-flex align-items-center justify-content-end gap-1">
            <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
              <label className="mb-0 pe-1">Göster:</label>
              <Input
                defaultValue={maxResultCount}
                type="select"
                onChange={(e) => setMaxResultCount(parseInt(e.target.value))}
              >
                {options?.map((option: number) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg="4" sm="12" className="d-flex align-items-center ">
              <label className="mb-0 pe-1" htmlFor="search-invoice">
                Ara
              </label>
              <Input
                id="search"
                type="text"
                placeholder="Ara"
                aria-label="Search Input"
                onChange={handleFilter}
              />
            </Col>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.CSR
            subHeader
            subHeaderComponent={ToDetails()}
            columns={tableColumns}
            data={trainers}
            filterText={filterText}
            conditionalRowStyles={conditionalRowStyles}
            perPage={maxResultCount}
            selectableRows
            onSelectedRowsChange={(e: any) => setSelectableRows(e.selectedRows)}
          />
        </div>
      </Card>
    </div>
  );
};

export default CoachList;
