// ** Third Party Components

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import {
  ICoachSummaryDBModel,
  ITrainerSummary,
} from "../../../model/DbMdel/coachDBModel";
import { FC } from "react";
// ** Chart Data
const data: any = [
  {
    name: "Ayşe Doğu",
    AtananDers: 8,
    MaksimumDers: 20,
  },
  {
    name: "Baran Erdemli",
    AtananDers: 10,
    MaksimumDers: 20,
  },
  {
    name: "Kaan Pekbilir",
    AtananDers: 6,
    MaksimumDers: 20,
  },
  {
    name: "Yasemin Bal",
    AtananDers: 18,
    MaksimumDers: 20,
  },
];

const CustomTooltip = (data: any) => {
  return (
    <div className="recharts-custom-tooltip mb-2 p-1">
      {/* TODO:Antrenörün adı yazılacak */}
      {/* <p className="fw-bold mb-0">{data.name}</p> */}
      <hr />
      <div className="active">
        {data.payload.map((i: any) => {
          return (
            <div className="d-flex align-items-center" key={i.dataKey}>
              <span
                className="bullet bullet-sm bullet-bordered me-50"
                style={{
                  backgroundColor: i.fill,
                }}
              ></span>
              <span className="text-capitalize me-75">
                {i.dataKey} : {i.payload[i.dataKey]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface IPlanningFollowUpTrainerBasedChartProps {
  trainerSummary: ITrainerSummary[];
}

const PlanningFollowUpTrainerBasedChart: FC<
  IPlanningFollowUpTrainerBasedChartProps
> = ({ trainerSummary }) => {
  const data: any = trainerSummary.map((item) => {
    return {
      name: item.trainer.user.name + " " + item.trainer.user.surname,
      assignedLessonSum: item.assignedLessonSum,
      maxAssignableLessonSum:
        item.maxAssignableLessonSum - item.assignedLessonSum,
    };
  });

  const tickFormatter = (value: string, index: number) => {
    const limit = 10;
    if (!value) return "";
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}${value.length > limit ? "..." : ""}`;
  };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    if (payload && payload.value) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            height="auto"
            textAnchor="middle"
            fontSize={12}
            x={-20}
            y={10}
            width={1}
            fill="#666"
          >
            {payload.value}
          </text>
        </g>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardHeader className="flex-sm-row flex-column justify-content-sm-between justify-content-center align-items-sm-center align-items-start mt-1">
        <CardTitle tag="h4">Antrenör Bazlı Grafik</CardTitle>
      </CardHeader>

      <CardBody>
        <div className="d-flex align-items-center flex-wrap mb-4">
          <div className="me-1">
            <span
              className="bullet bullet-sm bullet-bordered me-50"
              style={{ backgroundColor: "#EA5455" }}
            ></span>
            <span className="align-middle me-75">Atanan Ders Sayısı</span>
          </div>
          <div className="me-1">
            <span
              className="bullet bullet-sm bullet-bordered me-50"
              style={{ backgroundColor: "#20C997" }}
            ></span>
            <span className="align-middle me-75">Maksimum Ders Sayısı</span>
          </div>
        </div>
        <div className="recharts-wrapper bar-chart" style={{ height: "350px" }}>
          <ResponsiveContainer>
            <BarChart height={300} data={data} barSize={10}>
              <CartesianGrid stroke="#4B4B4B" />
              <XAxis
                textAnchor="middle"
                dataKey="name"
                width={15}
                fontSize={12}
                tickFormatter={tickFormatter}
              />
              <YAxis tickCount={3} />
              <Tooltip />
              <Bar
                dataKey="assignedLessonSum"
                name="Toplam Atanmış Ders Sayısı"
                stackId="a"
                fill="#EA5455"
              />
              <Bar
                dataKey="maxAssignableLessonSum"
                name="Toplam Atanılabilecek Ders Sayısı"
                stackId="a"
                fill="#20C997"
                radius={[10, 10, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </Card>
  );
};
export default PlanningFollowUpTrainerBasedChart;
