import { FC, useEffect } from "react";
import ReactDataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { Col, Row, Spinner, TableProps } from "reactstrap";
import { IPropsSSR } from "../../types";

const DataTableSSR: FC<IPropsSSR | TableProps> = ({
  data,
  columns,
  subHeaderComponent,
  perPage,
  pending,
  conditionalRowStyles,
  selectableRowsComponent,
  selectableRows = false,
  onSelectedRowsChange,
  selectableRowSelected = null,
  expandableRows = false,
  expandOnRowClicked = true,
  expandableRowsComponent,
  pagination = true,
  subHeader = true,
  paginationComponentOptions,
  totalCount,
  onChangePaginate,
  isFooter = true,
  pageNumber = 0,
  footerComponent,
  selectableRowDisabled,
  selectableRowsSingle,
  clearSelectedRows,
  customStyles,
}) => {
  const filterData = () => {
    if (!data) {
      return [];
    }
    return data;
  };

  useEffect(() => {
    if (data) {
      if (data.length === 0 && pageNumber !== 0) {
        onChangePaginate(pageNumber - 1);
      }
    }
  }, [data]);

  const CustomPagination = (e: any) => {
    return (
      <>
        <>{footerComponent}</>
        {pagination && (
          <Row className="d-flex align-items-center">
            {isFooter && (
              <Col>
                <small className=" text-muted m-1 ">
                  {`${totalCount} kayıt arasından ${pageNumber * perPage}
              - ${
                (pageNumber + 1) * perPage > totalCount
                  ? totalCount
                  : (pageNumber + 1) * perPage
              } gösteriliyor.`}
                </small>
              </Col>
            )}
            <Col>
              <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                forcePage={pageNumber}
                onPageChange={(page) => {
                  onChangePaginate(page.selected);
                }}
                pageCount={Math.ceil(totalCount / perPage)}
                activeClassName="active"
                pageClassName={"page-item"}
                nextLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                containerClassName={
                  "pagination react-paginate justify-content-end py-2 pe-1 pagination-warning"
                }
              />
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <>
      <ReactDataTable
        paginationComponentOptions={paginationComponentOptions}
        pagination
        paginationComponent={CustomPagination}
        subHeader={subHeader}
        subHeaderComponent={subHeaderComponent}
        customStyles={{
          progress: {
            style: {
              background: "transparent",
              marginBottom: "10px",
            },
          },
          ...customStyles,
        }}
        columns={columns}
        data={filterData()}
        paginationPerPage={perPage}
        paginationServer
        paginationDefaultPage={pageNumber + 1}
        paginationTotalRows={totalCount}
        progressPending={pending}
        progressComponent={<Spinner color="warning" />}
        conditionalRowStyles={conditionalRowStyles}
        selectableRows={selectableRows}
        onSelectedRowsChange={(e) => {
          if (onSelectedRowsChange) {
            onSelectedRowsChange(e);
          }
        }}
        selectableRowsSingle={selectableRowsSingle}
        selectableRowSelected={selectableRowSelected}
        selectableRowDisabled={selectableRowDisabled}
        expandableRowsComponent={expandableRowsComponent}
        expandOnRowClicked={expandOnRowClicked}
        expandableRows={expandableRows}
        noDataComponent={
          <div className="text-center m-2">Kayıt Bulunamadı</div>
        }
        clearSelectedRows={clearSelectedRows}
      />
    </>
  );
};

export default DataTableSSR;
