import { Row, Col, Spinner } from "reactstrap";
import OccupancyRatebyTrainer from "../../components/PlanningFollowUp/OccupancyRatebyTrainer";
import PlanningFollowCalendarTracking from "../../components/PlanningFollowUp/PlanningFollowCalendarTracking";
import PlanningFollowUpTrainerBasedChart from "../../components/PlanningFollowUp/PlanningFollowUpTrainerBasedChart";
import CoachSchedule from "../../components/PlanningFollowUp/CoachSchedule";
import { ICoachSummaryDBModel } from "../../model/DbMdel/coachDBModel";
import { useEffect, useState } from "react";
import { getSummaryCoachs } from "../../services/coach";
import moment from "moment";
import dateFormat from "../../helper/dateFormat";
import TimeSelection from "../../components/common/TimeSelection";
import { ISelectDate } from "../../model/commonModel/selectDate";
import AccordionTime from "../../components/PlanningFollowUp/AccordionTime";

const PlanningFollowUp = () => {
  const [summaryData, setSummaryData] = useState<ICoachSummaryDBModel | null>(
    null
  );

  const [selectedDate, setSelectedDate] = useState<ISelectDate>({
    startDate: moment().format(dateFormat),
    endDate: moment().format(dateFormat),
  });

  const fetchSummaryData = async () => {
    setSummaryData(null);
    await getSummaryCoachs(selectedDate)
      .then((response) => {
        const filteredData = response.trainerSummaries.filter(
          (item) => item.assignedLessonSum > 0 || item.trainer.user.isActive
        );
        setSummaryData({ ...response, trainerSummaries: filteredData });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchSummaryData();
  }, [selectedDate]);

  return (
    <>
      <div id="dashboard-analytics">
        <Row className="match-height mb-1">
          <Col lg="12" sm="12">
            <TimeSelection onSelectDate={setSelectedDate} />
          </Col>
        </Row>

        {!summaryData ? (
          <Row>
            <Col className="d-flex justify-content-center">
              <Spinner color="warning" />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="match-height mb-1">
              <Col lg="12" sm="12">
                <AccordionTime dailySalaryInfo={summaryData.dailySalaryInfo} />
              </Col>
            </Row>
            <Row className="match-height">
              <Col lg="6" xs="12">
                <OccupancyRatebyTrainer
                  trainerSummary={summaryData.trainerSummaries}
                />
              </Col>
              <Col lg="6" xs="12">
                <PlanningFollowCalendarTracking
                  trainerSummary={summaryData.trainerSummaries}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <PlanningFollowUpTrainerBasedChart
                  trainerSummary={summaryData.trainerSummaries}
                />
              </Col>
            </Row>
            <div>
              <CoachSchedule
                trainerSummary={summaryData.trainerSummaries}
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PlanningFollowUp;
