import http from "../helper/http";
import { ISelectDate } from "../model/commonModel/selectDate";
import {
  IConfirmSalaries,
  ICreateSalaries,
  ISalaryDBModel,
  ISavedSalaries,
  IUpdateSalaries,
} from "../model/DbMdel/salaryDBModel";

const getSalaries = async (day: string): Promise<ISalaryDBModel> => {
  return await http.get<any, ISalaryDBModel>(`/Salaries/daily/${day}`);
};

export const getSalariesSummary = async (
  param: ISelectDate
): Promise<ISalaryDBModel[]> =>
  await http.get<any, ISalaryDBModel[]>(
    `/Salaries/daily/${param.startDate}/${param.endDate}/summary`
  );

export const confirmSalary = async (
  param: IConfirmSalaries
): Promise<ISalaryDBModel[]> => {
  return await http.put<any, ISalaryDBModel[]>(
    `/Salaries/daily/confirm`,
    param
  );
};

export const createSalaries = async (
  param: ICreateSalaries
): Promise<ISalaryDBModel[]> => {
  return await http.post<any, ISalaryDBModel[]>(`/Salaries/daily`, param);
};

export const updateSalaries = async (
  param: IUpdateSalaries
): Promise<ISalaryDBModel[]> => {
  return await http.put<any, ISalaryDBModel[]>(`/Salaries/daily`, param);
};

export const getSavedSalaries = async (
  param: ISelectDate
): Promise<ISavedSalaries[]> =>
  await http.get<any, ISavedSalaries[]>(
    `/Salaries/daily/${param.startDate}/${param.endDate}/saved`
  );

export default getSalaries;
