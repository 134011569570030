// ** Reactstrap Imports
import moment from "moment";
import { FC } from "react";
import { CheckSquare, XSquare } from "react-feather";
import { Link } from "react-router-dom";
import {
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";
import dateFormat from "../../helper/dateFormat";
import { ISavedSalaries } from "../../model/DbMdel/salaryDBModel";

interface ISalaryAccordionTimeProps {
    salaryInfo: ISavedSalaries[];
}

const SalaryAccordionTime: FC<ISalaryAccordionTimeProps> = ({ salaryInfo }) => {
    return (
        <>
            <UncontrolledAccordion className="mb-2" open={"true"} defaultOpen="0">
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        <div className="d-flex align-items-center mb-2">
                            {salaryInfo.filter(
                                (x) =>
                                    x.isConfirmed &&
                                    moment(x.date).format(dateFormat) ===
                                    moment().format(dateFormat)
                            ).length > 0 ? (
                                <>
                                    <CheckSquare
                                        size="16"
                                        style={{ color: "#28C76F", marginRight: "12px" }}
                                    />
                                    <span className="text-success ">
                                        Günün onayı kaydedilmiştir.
                                    </span>
                                </>
                            ) : (
                                <>
                                    <XSquare
                                        size="16"
                                        style={{ color: "#EA5455", marginRight: "12px" }}
                                    />
                                    <span className="text-danger ">
                                        Günün onayı kaydedilmemiştir.
                                    </span>
                                </>
                            )}
                        </div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <ul className="list-unstyled text-success">
                            {salaryInfo
                                .filter((x) => x.isConfirmed)
                                .map((x) => {
                                    return (
                                        <Link
                                            to={{
                                                pathname: "/calendar",
                                                search: `date=${moment(x.date).format(dateFormat)}`,
                                            }}
                                        >
                                            <li className="text-success">
                                                {" "}
                                                {moment(x.date).format(dateFormat + " dddd")}
                                            </li>
                                        </Link>
                                    );
                                })}
                        </ul>
                        <ul className="list-unstyled text-danger">
                            {salaryInfo
                                .filter((x) => !x.isConfirmed)
                                .map((x) => {
                                    return (
                                        <Link
                                            to={{
                                                pathname: "/calendar",
                                                search: `date=${moment(x.date).format(dateFormat)}`,
                                            }}
                                        >
                                            <li className="text-danger">
                                                {moment(x.date).format(dateFormat + " dddd")}
                                            </li>
                                        </Link>
                                    );
                                })}
                        </ul>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </>
    );
};

export default SalaryAccordionTime;