import { Input, Row, Col, Card, Badge, Button, Label } from "reactstrap";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
//@ts-ignore
import Avatar from "@components/avatar";
import ExportDropdown from "../../../common/ExportDropdown";
import _debounce from "lodash/debounce";
import {
  encashAllActivity,
  getStudentActivity,
  studentEncashActivity,
} from "../../../../services/activity";
import {
  IActivityInputModel,
  IEncashAllModel,
  IStudentActivityDbModel,
  IStudentEncashModel,
} from "../../../../model/DbMdel/activityDBModel";
import moment from "moment";
import { IStudentDBModel } from "../../../../model/DbMdel/studentDBModel";
import { convertPaymentType } from "../../../../utility/Utils";
import DataTable from "../../../common/DataTable";
import usePaginateFetch from "../../../../utility/hooks/usePaginateFetch";
import { defaultCount, options } from "../../../common/DataTable/Constants";
import { handleFilter, handleFiltered } from "../../../../utility/helper";
import useInfinityPaginateFetch from "../../../../utility/hooks/userInfinityPaginateFetch";
import { getAllPaymentMethodService } from "../../../../services/paymentMethod";
import { getGroups } from "../../../../services/groups";
import {
  getAllStudentsIncludesChild,
  getParentStudents,
} from "../../../../services/students";
import { ILocationMethodDBModel } from "../../../../model/DbMdel/locationMethodDbModel";
import { getAllLocationMethodService } from "../../../../services/locationMethod";
import { ICoachDBModel } from "../../../../model/DbMdel/coachDBModel";
import getCoachs from "../../../../services/coach";
import { InfiniteScrollDropdown } from "../../../InfiniteScroll";
import Select from "react-select";
import CustomFlatpicker from "../../../Flatpickr";
import { checkRoles } from "../../../../helper/permissionHelper";
import { UserRole } from "../../../../model/commonModel/enums";
import SwalFire from "../../../common/Swal";
import toast from "react-hot-toast";
import { CheckSquare } from "react-feather";
import { IPaymentMethodDBModel } from "../../../../model/DbMdel/paymentMethodDBModel";
import { IGroupDBModel } from "../../../../model/DbMdel/groupDBModel";
import ConfirmationModal from "../../../../views/calendar/ConfirmationModal/Modal";

interface IStatusLesson {
  color: string;
  text: string;
}

const PlanningDetailTable = () => {
  let [searchParams] = useSearchParams();
  const startDate =
    searchParams.get("startDate") || moment(new Date()).format("YYYY-MM-DD");
  const endDate =
    searchParams.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
  const [selectableRows, setSelectableRows] = useState<
    IStudentActivityDbModel[]
  >([]);
  const [activitySearchQueries, setActivitySearchQueries] =
    useState<IActivityInputModel>({
      endDate: endDate ?? undefined,
      startDate: startDate ?? undefined,
      maxResultCount: defaultCount,
    });

  const [encashedModalVisibe, setEncashedModalVisibe] =
    useState<boolean>(false);

  const [toggledClearRows, setToggleClearRows] = useState<boolean>(false);
  const [paymentTypeId, setPaymentTypeId] = useState<number>(0);

  const {
    data: coachData,
    isLoading: coachLoading,
    handleSearch: coachSearch,
    handlePaginate: coachPaginate,
    page: coachPages,
    searchValue: coachSearchValue,
  } = useInfinityPaginateFetch<ICoachDBModel>({
    callbackService: getCoachs,
  });

  const {
    data: locationData,
    handlePaginate: locationPaginate,
    isLoading: locationLoading,
    handleSearch: locationSearch,
    page: locationPage,
    searchValue: locationSearchValue,
  } = useInfinityPaginateFetch<ILocationMethodDBModel>({
    callbackService: getAllLocationMethodService,
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    handleSearch: studentSearch,
    handlePaginate: studentPaginate,
    page: studentPages,
    searchValue: studentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getParentStudents,
    isActive: true,
  });

  const {
    data: connectedStudentData,
    isLoading: connectedStudentLoading,
    handleSearch: connectedStudentSearch,
    handlePaginate: connectedStudentPaginate,
    page: connectedStudentPages,
    searchValue: connectedStudentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: (params: any) =>
      getAllStudentsIncludesChild({ IsParent: false, ...params }),
    isActive: true,
  });

  const {
    data: groupData,
    isLoading: groupLoading,
    handleSearch: groupSearch,
    handlePaginate: groupPaginate,
    page: groupPages,
    searchValue: groupSearchValue,
  } = useInfinityPaginateFetch<IGroupDBModel>({
    callbackService: getGroups,
  });

  const {
    data: paymentTypeData,
    isLoading: paymentTypeLoading,
    handleSearch: paymentTypeSearch,
    handlePaginate: paymentTypePaginate,
    page: paymentTypePages,
    searchValue: paymentTypeSearchValue,
  } = useInfinityPaginateFetch<IPaymentMethodDBModel>({
    callbackService: getAllPaymentMethodService,
    isActive: true,
  });

  const lessonStatusOptions = [
    { value: "isEncashed", label: "Tahsil Edildi" },
    { value: "isEncashedByAdmin", label: "Admin Tarafından Tahsil Edildi" },
    { value: "inEncashed", label: "Tahsil Edilmeyi Bekliyor" },
    { value: "isConfirmed", label: "Onaylandı" },
    { value: "isDeleted", label: "Silindi" },
  ];

  const {
    data: detailData,
    handlePaginate,
    isLoading,
    handleSearch,
    refetch: refetchActivities,
    page,
  } = usePaginateFetch<IStudentActivityDbModel>({
    callbackService: (params: any) =>
      getStudentActivity({ ...activitySearchQueries, ...params }),
    maxResultCount: activitySearchQueries.maxResultCount,
  });

  const dateFormat = "YYYY-MM-DD HH:00";
  const dateFormatWithMin = "YYYY-MM-DD HH:mm";

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  useEffect(() => {
    refetchActivities();
  }, [activitySearchQueries]);

  const renderClient = (row: IStudentActivityDbModel) => {
    if (row.trainer.user.photo) {
      return (
        <Link
          to={`/usersandpermissions/users/profile-detail/${row.trainer.user.id}`}
        >
          <Avatar
            className="me-1"
            img={row.trainer.user.photo}
            width="32"
            height="32"
          />
        </Link>
      );
    } else {
      return (
        <Link
          to={`/usersandpermissions/users/profile-detail/${row.trainer.user.id}`}
        >
          <Avatar
            className="me-1"
            color={"light-warning"}
            content={getSummaryName(
              `${row.trainer.user.name} ${row.trainer.user.surname}`
            )}
            title={`${row.trainer.user.name} ${row.trainer.user.surname}`}
          />
        </Link>
      );
    }
  };

  const getStatusLesson = (row: IStudentActivityDbModel): IStatusLesson => {
    const isEncasched = row.isEncash;
    const encasherUser = row.encasherUser;
    const isConfirmed = row.isConfirmed;
    const isDeleted = row.activityIsDeleted;
    let statusLesson: IStatusLesson = {
      color: "light-warning",
      text: "Tahsil Edilmeyi Bekliyor",
    };

    if (isDeleted) {
      statusLesson.color = "light-danger";
      statusLesson.text = "Silindi";
    } else if (!isConfirmed) {
      if (isEncasched) {
        if (encasherUser) {
          if (row.trainer.user.id === encasherUser.id) {
            statusLesson.color = "light-info";
            statusLesson.text = "Tahsil Edildi";
          } else if (row.trainer.user.id !== encasherUser.id) {
            statusLesson.color = "light-primary";
            statusLesson.text = `${encasherUser.name} ${encasherUser.surname} Admin Tarafından Tahsil Edildi`;
          }
        } else {
          statusLesson.color = "light-info";
          statusLesson.text = "Tahsil Edildi";
        }
      } else if (!isEncasched) {
        statusLesson.color = "light-warning";
        statusLesson.text = "Tahsil Edilmeyi Bekliyor";
      }
    } else if (isConfirmed) {
      statusLesson.color = "light-success";
      statusLesson.text = "Doğrulandı";
    }

    return statusLesson;
  };

  const caseInsensitiveSort = (
    rowA: IStudentActivityDbModel,
    rowB: IStudentActivityDbModel
  ) => {
    const a = rowA.trainer.user.name.toLowerCase();
    const b = rowB.trainer.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const conditionalRowStyles = [
    {
      when: (row: IStudentActivityDbModel) => row.parent,
      classNames: ["child-row"],
    },
  ];

  const columns = [
    {
      name: "ANTRENÖR",
      sortable: true,
      width: "13%",
      sortField: "name",
      sortFunction: caseInsensitiveSort,
      selector: (row: IStudentActivityDbModel) => row.trainer.user.name,
      cell: (row: IStudentActivityDbModel, rowIndex: number) => {
        return (
          <div className="d-flex flex-row justify-content-left align-items-center">
            {renderClient(row)}
            <div className="d-flex flex-column">
              <h6 className="user-name  mb-0">
                    {row.trainer.user.name} {row.trainer.user.surname}
              </h6>
            </div>
          </div>
        );
      },
    },
    {
      name: "ÖDEME TİPİ",
      width: "10%",
      sortField:"paymentType",
      selector: (row: IStudentActivityDbModel) => row.paymentType,
    },
    {
      name: "DERS ÜCRETİ",
      width: "10%",
      sortField: "perHourPrice",
      selector: (row: IStudentActivityDbModel) => {
        const perHourPrice = row.perHourPrice;
        return `${row.activityIsDeleted ? 0 : perHourPrice}`;
      },
    },
    {
      name: "ÖĞRENCİ",
      width: "15%",
      sortField: "group",
      selector: (row: IStudentActivityDbModel) => (
        <div>
          <h5>{row.studentName}</h5>
          {row.parent?.user && (
            <span>
              {row.parent.user.name} {row.parent.user.surname}
            </span>
          )}
        </div>
      ),
    },
    {
      name: "LOKASYON",
      width: "10%",
      sortField: "startDate",
      selector: (row: IStudentActivityDbModel) => row.location.split("/")[0],
    },
    {
      name: "GRUP",
      width: "10%",
      sortField: "group",
      selector: (row: IStudentActivityDbModel) => row.groupName ?? "-",
    },
    
    {
      name: "DERS BAŞLANGICI",
      width: "auto",
      sortField: "startdate",
      selector: (row: IStudentActivityDbModel) => {
        const startDate = moment(row.startOfActivityDate).format(dateFormat);
        return (
          <div>
            <p>{startDate}</p>
          </div>
        );
      },
    },
    {
      name: "DERSİN DURUMU",
      width: "10%",
      sortField: "statusLesson",
      selector: (row: IStudentActivityDbModel) => {
        const statusLesson = getStatusLesson(row);
        return (
          <Badge pill color={statusLesson.color} className="text-wrap">
            {statusLesson.text}
          </Badge>
        );
      },
    },
    // {
    //   name: "TAHSİLAT ZAMANI",
    //   width: "14%",
    //   sortField: "startDate",
    //   selector: (row: IStudentActivityDbModel) => {
    //     const enCashLog = row.logs.find((x) => x.logType === "Encash");
    //     return (
    //       <p>
    //         {enCashLog
    //           ? moment
    //               .utc(enCashLog.creationTime)
    //               .local()
    //               .format(dateFormatWithMin)
    //           : "Tahsil edilmedi"}
    //       </p>
    //     );
    //   },
    // },
  ];

  const totalPrice = () => {
    if (detailData?.totalCount! > 0) {
      let x = 0;
      detailData?.items.map((item) => {
        if (!item.activityIsDeleted)
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      return x;
    }
  };
  const selectedTotalPrice = () => {
    if (selectableRows.length > 0) {
      let x = 0;
      //@ts-ignore
      selectableRows.map((item) => {
        //@ts-ignore
        if (!item.activityIsDeleted)
          //@ts-ignore
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      detailData.items?.map((item) => {
        // if (!item.isDeleted) x += item.perHourPrice;
        return x;
      });
      return x;
    }
  };
  
  const selectedPaidPrice = () => {
    if (selectableRows.length < 0) {
      let x = 0;
      detailData?.items.map((item) => {
        if (
          !item.activityIsDeleted &&
          item.isEncash
        )
          //@ts-ignore
          (x += item.perHourPrice), 0;
      });
      return x;
    } else {
      let x = 0;
      selectableRows.map((item) => {
        if (
          !item.activityIsDeleted &&
          item.isEncash
        )
          x += item.perHourPrice;
        return x;
      });
      return x;
    }
  };
  const handleQueryFilter = (key: string, value: any) => {
    const newActivitySearchQueries = {
      ...activitySearchQueries,
      [key]: value,
    };
    setActivitySearchQueries(newActivitySearchQueries);
  };

  const ToFooterDetails = () => (
    <div
      className="d-flex align-items-center px-1 "
      style={{ borderBlock: "1px solid #4B4B4B", minHeight: "48px" }}
    >
      <span style={{ marginLeft: "30%" }}>
        Toplam: <b style={{ color: "#ffff" }}>{totalPrice()}</b>
      </span>
    </div>
  );

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start "
        >
          <p>Program Takibi Detayı</p>
        </Col>

        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center   justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions">
            <ExportDropdown<IStudentActivityDbModel>
              className="me-1"
              array={selectableRows}
              fileName={`${moment(activitySearchQueries.startDate).format(
                "YYYY-MM-DD"
              )}_${moment(activitySearchQueries.endDate).format("YYYY-MM-DD")}_program_takibi_detayı`}
              sheetName={`${moment(activitySearchQueries.startDate).format(
                "YYYY-MM-DD"
              )}_${moment(activitySearchQueries.endDate).format("YYYY-MM-DD")}`}
              title="BILGI FISI: *Bu belgenin mali degeri yoktur."
              A4Orientation="portrait"
              labelValueData={[
                {
                  label: "Toplam Borç",
                  value: selectedTotalPrice().toString(),
                },
                {
                  label: "Ödenen Borç",
                  value: selectedPaidPrice().toString(),
                },
                {
                  label: "Kalan Borç",
                  value: (
                    selectedTotalPrice() - selectedPaidPrice()
                  ).toString(),
                },
              ]}
              headers={[
                {
                  key: "trainer.user.name",
                  label: "ANTRENOR ISMI",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.name} ${element.trainer.user.surname}`;
                  },
                },
                {
                  key: "paymentType",
                  label: "ODEME TIPI",
                  onRender: (item: string, element) => {
                    return `${element.paymentType ?? "-"}`;
                  },
                },
                {
                  key: "perHourPrice",
                  label: "DERS UCRETI",
                  onRender: (item: boolean, element) => {
                    const perHourPrice = element.perHourPrice;
                    return `${element.activityIsDeleted ? 0 : perHourPrice}`;
                  },
                },
                {
                  key: "students",
                  label: "OGRENCI ISMI",
                  onRender: (item: string, element) => {
                    const paymentType = convertPaymentType(element.paymentType);

                    return element.studentName;
                  },
                },
                {
                  key: "location",
                  label: "LOKASYON",
                  onRender: (item: boolean, element) => element.location.split("/")[0],
                },
                {
                  key: "groupName",
                  label: "GRUP",
                  onRender: (item: string, element) => {
                    return `${element.groupName ?? "-"}`;
                  },
                },
                {
                  key: "startDate",
                  label: "DERS BASLANGICI",
                  onRender: (item: string, element) => {
                    return `${moment(element.startOfActivityDate).format(
                      dateFormat
                    )}`;
                  },
                },
                // {
                //   key: "endDate",
                //   label: "DERS BITISI",
                //   onRender: (item: string, element) => {
                //     return `${moment(element.endDate).format(dateFormat)}`;
                //   },
                // },
                
                {
                  key: "cashed",
                  label: "DERSIN DURUMU",
                  onRender: (item: boolean, element) => {
                    const statusLesson = getStatusLesson(element);
                    return `${statusLesson.text}`;
                  },
                },
              ]}
            />
            {checkRoles([UserRole.Admin]) && (
              <Button
                block
                color="warning text-nowrap"
                className="d-flex align-items-center gap-50"
                disabled={!selectableRows.length}
                onClick={() => {
                  setEncashedModalVisibe(true);
                }}
              >
                <CheckSquare size={15} />
                Admin Onayı
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="location">
            Lokasyon
          </label>
          <InfiniteScrollDropdown
            isClearable
            isDisabled={isLoading}
            placeholder="Lokasyon Listesi"
            searchValue={locationSearchValue}
            headers={["name"]}
            data={locationData.items}
            isLoading={locationLoading}
            id="location"
            totalCount={locationData.totalCount}
            onInputChange={(inputValue) =>
              handleFilter(inputValue, locationSearch)
            }
            onMenuScrollBottom={() => locationPaginate(locationPage + 1)}
            className="w-75"
            onChange={(location: any) => {
              handleQueryFilter("locationId", location?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="student">
            Öğrenci
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Öğrenci Listesi"
            searchValue={studentSearchValue}
            totalCount={studentData.totalCount}
            headers={["user.name", "user.surname"]}
            data={studentData.items}
            isLoading={studentLoading}
            id="students"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, studentSearch)
            }
            onMenuScrollBottom={() => studentPaginate(studentPages + 1)}
            onChange={(student: any) => {
              handleQueryFilter("studentId", student?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label
            className="mb-0 pe-1 w-25 text-end"
            htmlFor="connected-student"
          >
            Bağlantılı Öğrenci
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Bağlantılı Öğrenci Listesi"
            searchValue={connectedStudentSearchValue}
            totalCount={connectedStudentData.totalCount}
            headers={["user.name", "user.surname"]}
            data={connectedStudentData.items}
            isLoading={connectedStudentLoading}
            id="connectedStudents"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, connectedStudentSearch)
            }
            onMenuScrollBottom={() =>
              connectedStudentPaginate(connectedStudentPages + 1)
            }
            onChange={(student: any) => {
              handleQueryFilter("subStudentId", student?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="group">
            Grup
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Grup Listesi"
            searchValue={groupSearchValue}
            totalCount={groupData.totalCount}
            headers={["name", "surname"]}
            data={groupData.items}
            isLoading={groupLoading}
            id="group"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, groupSearch)
            }
            onMenuScrollBottom={() => groupPaginate(groupPages + 1)}
            onChange={(group: any) => {
              handleQueryFilter("groupId", group?.id || undefined);
            }}
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="lesson-status">
            Dersin Durumu
          </label>
          <Select
            isDisabled={isLoading}
            id="lesson-status"
            placeholder="Durum Seçin"
            classNamePrefix="select"
            isClearable
            isMulti={false}
            options={lessonStatusOptions}
            onChange={(event: any) => {
              if (event) {
                switch (event.value) {
                  case "isDeleted":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: false,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: true,
                    });
                    break;
                  case "inEncashed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: false,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: false,
                    });
                    break;
                  case "isEncashed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: true,
                      isConfirmed: false,
                      isEncashedByAdmin: false,
                      isDeleted: false,
                    });
                    break;

                  case "isEncashedByAdmin":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: true,
                      isConfirmed: false,
                      isEncashedByAdmin: true,
                      isDeleted: false,
                    });
                    break;

                  case "isConfirmed":
                    setActivitySearchQueries({
                      ...activitySearchQueries,
                      isEncashed: undefined,
                      isConfirmed: true,
                      isEncashedByAdmin: undefined,
                      isDeleted: false,
                    });
                    break;
                  default:
                    break;
                }
              } else {
                setActivitySearchQueries({
                  ...activitySearchQueries,
                  isEncashed: undefined,
                  isConfirmed: undefined,
                  isEncashedByAdmin: undefined,
                  isDeleted: undefined,
                });
              }
            }}
            className="react-select w-75"
          />
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="payment-type">
            Ödeme Yöntemi
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            placeholder="Ödeme Yöntemi"
            searchValue={paymentTypeSearchValue}
            totalCount={paymentTypeData.totalCount}
            headers={["name"]}
            data={paymentTypeData.items}
            isLoading={paymentTypeLoading}
            id="payment-type"
            className="w-75"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, paymentTypeSearch)
            }
            onMenuScrollBottom={() => paymentTypePaginate(paymentTypePages + 1)}
            onChange={(type: any) => {
              handleQueryFilter("paymentTypeId", type?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="start-date">
            Ders Başlangıcı
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              value={activitySearchQueries.startDate}
              placeholder="Ders Başlangıç Tarihi"
              id="startDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "startDate",
                  moment(dates![0]).format("YYYY-MM-DD")
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="start-date">
            Ders Bitişi
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              placeholder="Ders Bitiş Tarihi"
              id="endDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              value={activitySearchQueries.endDate}
              onChange={(dates) =>
                handleQueryFilter(
                  "endDate",
                  moment(dates![0]).format("YYYY-MM-DD")
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="coach">
            Antrenör
          </label>
          <InfiniteScrollDropdown
            isDisabled={isLoading}
            isClearable
            className="w-75"
            searchValue={coachSearchValue}
            totalCount={coachData.totalCount}
            headers={["user.name", "user.surname"]}
            data={coachData.items}
            isLoading={coachLoading}
            id="coach"
            onInputChange={(inputValue) =>
              handleFilter(inputValue, coachSearch)
            }
            onMenuScrollBottom={() => coachPaginate(coachPages + 1)}
            onChange={(type: any) => {
              handleQueryFilter("trainerIds", type?.id || undefined);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="entry-date">
            Giriş
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              isClearable
              value={activitySearchQueries.serviceAreaEntryDate}
              placeholder="Otele Giriş Tarihi"
              id="entryDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "serviceAreaEntryDate",
                  dates ? moment(dates[0]).format("YYYY-MM-DD") : null
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="exitDate">
            Çıkış
          </label>
          <div className="w-75">
            <CustomFlatpicker
              disabled={isLoading}
              isClearable
              value={activitySearchQueries.serviceAreaExitDate}
              placeholder="Otelden Çıkış Tarihi"
              id="exitDate"
              className="w-max"
              options={{
                enableTime: false,
                closeOnSelect: true,
                dateFormat: "Y-m-d",
              }}
              onChange={(dates) =>
                handleQueryFilter(
                  "serviceAreaExitDate",
                  dates ? moment(dates[0]).format("YYYY-MM-DD") : null
                )
              }
            />
          </div>
        </Col>
        <Col lg="4" md="12" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end">Göster:</label>
          <Input
            disabled={isLoading}
            defaultValue={defaultCount}
            type="select"
            onChange={(e) =>
              handleQueryFilter("maxResultCount", parseInt(e.target.value))
            }
            className="w-75"
          >
            {options.concat([500])?.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between ">
        <Col lg="4" sm="12" className="d-flex align-items-center">
          <label className="mb-0 pe-1 w-25 text-end" htmlFor="search-invoice">
            Ara
          </label>
          <Input
            disabled={isLoading}
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={(e) => handleFiltered(e.target.value, handleSearch)}
            className="w-75"
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.SSR
            totalCount={detailData.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={detailData.items.map((item, index) => ({
              ...item,
              id: `${index}${item.activityId}`,
            }))}
            pagination
            onChangePaginate={handlePaginate}
            pending={isLoading}
            pageNumber={page}
            perPage={activitySearchQueries.maxResultCount}
            footerComponent={ToFooterDetails()}
            selectableRows
            onSelectedRowsChange={(e: any) => setSelectableRows(e.selectedRows)}
            clearSelectedRows={toggledClearRows}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
        <ConfirmationModal
          primaryHeader={selectedTotalPrice().toString()}
          header="Emin misiniz?"
          subheader={`${selectableRows.length} ders için admin onayı vermek üzeresiniz.
          İşleme devam etmek istiyor musunuz?`}
          onClose={() => setEncashedModalVisibe(false)}
          onConfirm={async () => {
            const groupData = selectableRows.filter(
              (item) => item.groupName
            );
            const personData = selectableRows.filter(
              (item) => !item.groupName
            );

            const groupEncashes: IStudentEncashModel[] = groupData.map(
              (activity) => {
                return {
                  studentId: activity.studentId,
                  activityId: activity.activityId,
                  day: moment(new Date(activity.startOfActivityDate)).format(
                    "YYYY-MM-DD"
                  ),
                };
              }
            );

            const personEncashes: IEncashAllModel[] = personData.map(
              (activity) => {
                return {
                  isEncashed: true,
                  paymentTypeId: paymentTypeId,
                  activityId: activity.activityId,
                  day: moment(new Date(activity.startOfActivityDate)).format(
                    "YYYY-MM-DD"
                  ),
                };
              }
            );

            if (personEncashes.length) {
              await encashAllActivity(personEncashes)
                .then((e) => {
                  setPaymentTypeId(0);
                  setToggleClearRows(!toggledClearRows);
                  setEncashedModalVisibe(false);
                  refetchActivities(e);
                  toast.success("Etkinlik öğrenciler için onaylandı");
                })
                .catch((e) => {
                  toast.error(e);
                });
            }

            if (groupEncashes.length) {
              for (let i = 0; i < groupEncashes.length; i++) {
                const payload = groupEncashes[i];
                await studentEncashActivity(payload, true, paymentTypeId)
                  .then((e) => {
                    setPaymentTypeId(0);
                    setToggleClearRows(!toggledClearRows);
                    setEncashedModalVisibe(false);
                    refetchActivities(e);
                    toast.success("Etkinlik öğrenciler için onaylandı");
                  })
                  .catch((e) => {
                    toast.error(e);
                  });
              }
            }
          }}
          open={encashedModalVisibe}
          options={paymentTypeData.items.map((type) => {
            return {
              label: type.name,
              value: type.id,
            };
          })}
          select
          onSelect={(e: any) => setPaymentTypeId(e)}
        ></ConfirmationModal>
      </Card>
    </div>
  );
};

export default PlanningDetailTable;
