import http from "../helper/http";
import { ICreatePaymentMethodModel, IEditPaymentMethodModel, IPaymentMethodDBModel } from "../model/DbMdel/paymentMethodDBModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";

interface IGetPaymentMethodDto extends IPagedResultRequest {
    isActive?: boolean
}

export const getAllPaymentMethodService = (
    params: IGetPaymentMethodDto,
): Promise<PagedResultDto<IPaymentMethodDBModel>> => {
    return http.get<any, PagedResultDto<IPaymentMethodDBModel>>(`/PaymentTypes`, {params});
};

export const createPaymentMethodService = (
    paymentTypes: ICreatePaymentMethodModel,
): Promise<IPaymentMethodDBModel> => {
    return http.post<any, IPaymentMethodDBModel>(`/PaymentTypes`, paymentTypes);
};

export const updatePaymentMethodService = (
    paymentTypes: IEditPaymentMethodModel,
    paymentMethodId: number
): Promise<IPaymentMethodDBModel> => {
    return http.put<any, IPaymentMethodDBModel>(`/PaymentTypes/${paymentMethodId}`, paymentTypes);
};
export const deletePaymentMethodService = (
    paymentMethodId: number
): Promise<IPaymentMethodDBModel> => {
    return http.delete<any, IPaymentMethodDBModel>(`/PaymentTypes/${paymentMethodId}`);
};

export const exportFile = async (data: IExportFileResponse) => {
    return await http.post("/payment-type/export-file", data, { responseType: "blob" });
};
