import { useEffect, useState } from "react";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";
import toast from "react-hot-toast";

interface IPaginationSearchData {
  page: number;
  search: string;
  isActive?: boolean;
}
interface UsePaginateFetchProps {
  callbackService: CallableFunction;
  maxResultCount?: number;
  params?: Object;
  defaultSearchText?: string;
  isActive?: boolean;
}

interface UsePaginateFetch<T> {
  data: PagedResultDto<T>;
  handleSearch: (value: string) => void;
  handlePaginate: (value: number) => void;
  page: number;
  isLoading: boolean;
  refetch: CallableFunction;
  searchValue?: string;
}

const usePaginateFetch = <T>({
  callbackService,
  maxResultCount = 10,
  params = {},
  defaultSearchText = "",
  isActive,
}: UsePaginateFetchProps): UsePaginateFetch<T> => {
  const [paginationSearchData, setPaginationSearchData] =
    useState<IPaginationSearchData>({
      page: 0,
      search: defaultSearchText,
    } as IPaginationSearchData);
  const [data, setData] = useState<PagedResultDto<T>>({
    items: [],
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const fetchAsync = async () => {
    try {
      setIsLoading(true)
      const _params = {
        skipCount: paginationSearchData.page * maxResultCount,
        maxResultCount: maxResultCount,
        searched: paginationSearchData.search,

        isActive: isActive,
        ...params,
      };

      const _data = await callbackService(_params);

      setData(_data);
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsLoading(false);

    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAsync();
  }, [paginationSearchData]);

  const handleSearch = (value: string) => {
    setPaginationSearchData((prevState) => ({
      ...prevState,
      search: value,
      page: 0,
    }));
  };

  const handlePaginate = (value: number) => {
    if (value !== null) {
      setPaginationSearchData((prevState) => ({ ...prevState, page: value }));
    }
  };

  return {
    data,
    handleSearch,
    handlePaginate,
    page: paginationSearchData.page,
    isLoading: isLoading,
    refetch: fetchAsync,
    searchValue: paginationSearchData.search,
  };
};

export default usePaginateFetch;
