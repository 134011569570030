// ** React Imports
import { useState, Fragment, useEffect } from "react";

// ** Reactstrap Imports
import { Card, CardBody, Button, Badge, Spinner } from "reactstrap";

// ** Third Party Components
import { Mail } from "react-feather";
import { useForm } from "react-hook-form";
import moment from "moment";
import getLanguages from "../../../services/language";

// ** Custom Components
//@ts-ignore
import Avatar from "@components/avatar";
// ** Utils
//@ts-ignore
import { selectThemeColors } from "@utils";

// ** Styles
//@ts-ignore
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/base/pages/user-info.scss";
import UserEditModal from "../UserEditModal";
import {
  IUserEditModel,
  IUsersModel,
  RoleTypes,
} from "../../../model/DbMdel/usersDBModel";
import { editUser, getUserProfile } from "../../../services/user";
import { ILanguage } from "../../../model/commonModel/language";
import SwalFire from "../../common/Swal";
import toast from "react-hot-toast";
import { IStudentUserDetailModel } from "../../../model/DbMdel/studentDBModel";
import { getStudentUserDetail } from "../../../services/students";
import dateFormat from "../../../helper/dateFormat";
import { getTrainerUserDetail } from "../../../services/coach";
import { ICoachDBModel } from "../../../model/DbMdel/coachDBModel";

const buttonsColors = {
  waiting: "warning",
  approved: "success",
};
const isMailVerify = {
  waiting: "Onay bekliyor",
  approved: "Başarılı",
};
const roleColors = {
  admin: "light-warning",
  coach: "light-warning",
  student: "light-warning",
};

const statusColors = {
  true: "light-success",
  false: "light-secondary",
};

const statusOptions = [
  { value: true, label: "Aktif" },
  { value: false, label: "Askıya alındı" },
];

const languageOptions = [
  { value: "turkish", label: "Türkçe" },
  { value: "english", label: "İngilizce" },
  { value: "german", label: "Almanca" },
  { value: "french", label: "Fransızca" },
];

interface IUserInfoProps {
  id: number;
}
const UserInfo = ({ id }: IUserInfoProps) => {
  // ** State
  const [show, setShow] = useState(false);
  const [user, setUser] = useState<IUsersModel | null>(null);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentDetail, setStudentDetail] =
    useState<IStudentUserDetailModel | null>(null);
  const [trainerDetail, setTrainerDetail] = useState<ICoachDBModel | null>(
    null
  );

  const otherIdVariable = Number(id);

  const fetchUserDetail = async (role: RoleTypes) => {
    try {
      setIsLoading(true);

      switch (role) {
        case RoleTypes.Student:
          const studentResponse = await getStudentUserDetail(otherIdVariable);
          setStudentDetail(studentResponse);
          break;
        case RoleTypes.Trainer:
          const trainerResponse = await getTrainerUserDetail(otherIdVariable);
          setTrainerDetail(trainerResponse);
          break;

        default:
          break;
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    try {
      setIsLoading(true);
      const response = await getUserProfile(otherIdVariable);
      setUser(response);
      fetchLanguage();
    } catch (error: any) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [id]);

  useEffect(() => {
    const roleType = user?.roles[0]?.displayName;
    if (roleType) {
      fetchUserDetail(roleType as RoleTypes);
    }
  }, [user]);

  const {
    setError,
    formState: { errors },
  } = useForm({});

  const fetchLanguage = async () => {
    getLanguages()
      .then((languages) => {
        setLanguages(languages as any);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const renderUserImg = () => {
    if (user !== null && user.photo?.length) {
      return (
        <img
          height="110"
          width="110"
          alt="user-avatar"
          src={user.photo}
          className="img-fluid rounded mt-3 mb-2"
        />
      );
    } else {
      return (
        <Avatar
          initials
          className="me-1"
          color={"light-warning"}
          content={getSummaryName(`${user?.name} ${user?.surname}`)}
          title={user?.name + " " + user?.surname!}
          contentStyles={{
            borderRadius: 0,
            fontSize: "calc(48px)",
            width: "100%",
            height: "100%",
          }}
          style={{
            height: "110px",
            width: "110px",
          }}
        />
      );
    }
  };

  const handleSuspendedClick = (id: number) => {
    return SwalFire({
      title: "Emin misiniz?",
      icon: "warning",
      text: "Kullanıcının panele erişimini engellemek üzeresiniz. Devam etmek istiyor musunuz?",
      showCancelButton: true,
      confirmButtonText: "Evet, erişimini engelle!",
      onOk: () => {
        const data: IUserEditModel = {
          photo: user?.photo!,
          name: user?.name!,
          surname: user?.surname!,
          emailAddress: user?.emailAddress!,
          phoneNumber: user?.phoneNumber!,
          isActive: user?.isActive!,
          isPanelAccess: !user?.isPanelAccess!,
          languageIds: user?.languages.map((e) => e.id)!,
          roleNames: user?.roles.map((x) => x.displayName)!,
        };
        setIsLoading(true);
        editUser(data, id)
          .then((res) => {
            toast.success("Düzenleme başarılı");
            setIsLoading(false);
            setShow(false);
            fetchUserProfile();
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
      onCancel: () => {},
    });
  };

  return (
    <Fragment>
      {!user ? (
        <Spinner color="warning" />
      ) : (
        <>
          <Card>
            <CardBody>
              <div className="user-avatar-section">
                <div className="d-flex align-items-center flex-column">
                  {renderUserImg()}
                  <div className="d-flex flex-column align-items-center text-center">
                    <div className="user-info mt-2">
                      {/* <h4>{selectedUser !== null ? selectedUser.fullName : 'Eleanor Aguilar'}</h4> */}
                      <h4>
                        {user.name} {user.surname}
                      </h4>
                      {/*{selectedUser !== null ? ( */}
                      <Badge
                        color={roleColors.admin}
                        className="text-capitalize"
                      >
                        {user.roles[0] ? user.roles[0].displayName : "Öğrenci"}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="fw-bolder border-bottom pb-50 mt-2 mb-2">
                Details
              </h4>
              <div className="info-container">
                <ul className="list-unstyled w-100">
                  <li className="d-flex justify-content-between align-items-center flex-wrap mb-75">
                    <div className="text-break">
                      <span className="fw-bolder me-75">Email:</span>
                      <span>{user.emailAddress} </span>
                    </div>

                    <Button size="sm" color={buttonsColors.waiting}>
                      <Mail className="ms-25" size={14} />
                      <span className="align-middle ms-25">
                        {isMailVerify.waiting}
                      </span>
                    </Button>
                  </li>
                  <li className="mb-75">
                    <span className="fw-bolder me-75">Durum:</span>
                    <Badge
                      className="text-capitalize"
                      color={
                        user.isActive === true
                          ? statusColors.true
                          : statusColors.false
                      }
                    >
                      {user.isActive === true ? "Aktif" : "Pasif"}
                    </Badge>
                  </li>

                  <li className="mb-75">
                    <span className="fw-bolder me-75">Telefon:</span>
                    <span>{user.phoneNumber}</span>
                  </li>
                  <li className="mb-75">
                    <span className="fw-bolder me-75">Diller:</span>
                    <span>
                       
                      {user.languages
                        .map((x) => {
                          return x.name;
                        })
                        .join(", ")}
                    </span>
                  </li>
                  {user.roles[0].id === 3 && studentDetail && (
                    <>
                      <li className="mb-75">
                        <span className="fw-bolder me-75">
                          Otele giriş tarihi:
                        </span>
                        <span>
                          {studentDetail.serviceAreaEntryDate
                            ? moment(studentDetail.serviceAreaEntryDate).format(
                                dateFormat
                              )
                            : ""}
                        </span>
                      </li>
                      <li className="mb-75">
                        <span className="fw-bolder me-75">
                          Otelden çıkış tarihi:
                        </span>
                        <span>
                          {studentDetail.serviceAreaExitDate
                            ? moment(studentDetail.serviceAreaExitDate).format(
                                dateFormat
                              )
                            : ""}
                        </span>
                      </li>
                      {studentDetail.description && (
                        <li className="mb-75">
                          <span className="fw-bolder me-75">Açıklama:</span>
                          <span>{studentDetail.description} </span>
                        </li>
                      )}
                      {studentDetail.perHourPrice && (
                        <li className="mb-75">
                          <span className="fw-bolder me-75">
                            Saatlik Ücret:
                          </span>
                          <span>{studentDetail.perHourPrice} </span>
                        </li>
                      )}
                    </>
                  )}
                  {user.roles[0].id === 4 && trainerDetail && (
                    <>
                      {trainerDetail.comission && (
                        <li className="mb-75">
                          <span className="fw-bolder me-75">
                            Komisyon Oran:
                          </span>
                          <span>{trainerDetail.comission} </span>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <div className="footerButtonGroup">
                <Button color="warning" onClick={() => setShow(true)}>
                  Düzenle
                </Button>
                {user.isPanelAccess === true ? (
                  <Button
                    className="ms-1"
                    color="danger"
                    outline
                    onClick={() => {
                      handleSuspendedClick(user.id);
                    }}
                  >
                    Askıya Al
                  </Button>
                ) : null}
              </div>
            </CardBody>
          </Card>
          <UserEditModal
            role={user.roles[0].displayName as RoleTypes}
            setShow={setShow}
            show={show}
            user={user!}
            languages={languages}
            refetchUserProfile={fetchUserProfile}
          />
        </>
      )}
    </Fragment>
  );
};

export default UserInfo;
