import { ICoachGroupByModel } from "../model/commonModel/groupCoach";
import {
  ICoachSummaryModel,
  ISalaryDBModel,
  ITrainerEncashSummaryModel,
} from "../model/DbMdel/salaryDBModel";
import { groupBy } from "lodash";

export const groupByCoach = (
  summaryData: ISalaryDBModel[]
): ICoachGroupByModel[] => {
  const allTrainersData: ICoachSummaryModel[] = [];

  summaryData.forEach((item: ISalaryDBModel) => {
    if (item.trainers) {
      item.trainers.forEach((item: ICoachSummaryModel) => {
        allTrainersData.push(item);
      });
    }
  });

  const groupedData = groupBy(allTrainersData, "trainer.user.id");

  const calcGroupedData = Object.keys(groupedData).map((key) => {
    const sumOfLessons = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.sumOfLessons;
      },
      0
    );
    const perUserAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.perUserAmount;
      },
      0
    );
    const dailyComissionAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.dailyComissionAmount;
      },
      0
    );
    const dailyEarningAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.dailyEarningAmount;
      },
      0
    );

    const companyComissionAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.companyComissionAmount;
      },
      0
    );

    const differenceAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.differenceAmount;
      },
      0
    );

    const floatingComissionAmount = groupedData[key].reduce(
      (acc: number, cur: ICoachSummaryModel) => {
        return acc + cur.floatingComissionAmount;
      },
      0
    );

    const includeFloating = groupedData[key].reduce(
      (acc: boolean, cur: ICoachSummaryModel) => {
        return cur.includeFloating;
      },
      false
    );

    const trainerEncashSummary = groupedData[key].reduce(
      (acc: ITrainerEncashSummaryModel[], cur: ICoachSummaryModel) => {
        const groupedData: { [key: string]: ITrainerEncashSummaryModel } = {};

        cur.trainerEncashSummary.forEach((item) => {
          const key = item.paymentTypeName;
          if (!groupedData[key]) {
            groupedData[key] = { ...item, price: 0 };
          }
          if (item.id !== 0) {
            groupedData[key].price += item.price;
          }
        });

        const result: ITrainerEncashSummaryModel[] = Object.values(groupedData);
        return acc.concat(result);
      },
      []
    );

    function groupAndSumPrices(
      data: ITrainerEncashSummaryModel[]
    ): ITrainerEncashSummaryModel[] {
      const groupedData: { [key: string]: ITrainerEncashSummaryModel } = {};

      data.forEach((item) => {
        const key = `${item.id}_${item.paymentTypeName}_${item.isCoachMoneySafe}`;

        if (!groupedData[key]) {
          groupedData[key] = { ...item, price: 0 };
        }

        groupedData[key].price += item.price;
      });

      return Object.values(groupedData);
    }

    return {
      trainer: groupedData[key][0].trainer,
      fullName:
        groupedData[key][0].trainer.user.name +
        " " +
        groupedData[key][0].trainer.user.surname,
      sumOfLessons: sumOfLessons,
      perUserAmount: perUserAmount,
      floatingComissionAmount: floatingComissionAmount,
      includeFloating: includeFloating,
      dailyComissionAmount: dailyComissionAmount,
      dailyEarningAmount: dailyEarningAmount,
      companyComissionAmount: companyComissionAmount,
      differenceAmount: differenceAmount,
      benefit: dailyEarningAmount - dailyComissionAmount,
      trainerEncashSummary: groupAndSumPrices(trainerEncashSummary),
    } as ICoachGroupByModel;
  });

  return calcGroupedData;
};
