// ** React Imports
import { FC } from "react";

// ** Third Party Components
import Chart from "react-apexcharts";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardHeader,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ISelectDate } from "../../model/commonModel/selectDate";
import {
  ISalaryDBModel,
} from "../../model/DbMdel/salaryDBModel";
import { ICoachSummaryDBModel, ITrainerSummary } from "../../model/DbMdel/coachDBModel";

interface IProps {
  selectedDate: ISelectDate;
  summaryData: ISalaryDBModel[];
  trainersSummaryData: ICoachSummaryDBModel;
}

const CalendarTracking: FC<IProps> = ({ selectedDate, summaryData, trainersSummaryData }) => {

  const assignedLessonSum = trainersSummaryData.trainerSummaries.reduce((acc: number, cur: ITrainerSummary) => {
    
    return (
      acc + cur.assignedLessonSum
    );
  }, 0);


  const totalConfirmedLesson = trainersSummaryData.trainerSummaries.reduce((acc: number, cur: ITrainerSummary) => {
    return (
      acc + cur.confirmedLessonSum
    );
  }, 0);

  const totalEncashedAndWaitingForConfirm = trainersSummaryData.trainerSummaries.reduce((acc: number, cur: ITrainerSummary) => {
    return (
      acc + cur.encashedAndWaitingForConfirm
    );
  }, 0);

  const totalWaitingForEncashLesson = trainersSummaryData.trainerSummaries.reduce((acc: number, cur: ITrainerSummary) => {
    return (
      acc + cur.waitingForEncashLessonSum
    );
  }, 0);


  const options = {
    plotOptions: {
      radialBar: {
        size: 150,
        offsetY: 20,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#fff",
          strokeWidth: "0%",
        },
        dataLabels: {
          name: {
            offsetY: -5,
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "300",
            color: "#ff9f43",
          },
          value: {
            offsetY: 15,
            fontFamily: "Montserrat",
            fontSize: "1.714rem",
            color: "#ff9f43",
            formatter: (val: any) => {
              return val.toFixed(0) + "%";
            }
          },
          styles: {
            colors: ["#ff9f43"],
          },

        },
      },
    },
    colors: ["rgba(231, 103, 85, 1)"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["rgba(31, 103, 85, 1)"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      dashArray: 8,
    },
    labels: ["Tamamlanan Dersler"],
  },
    series = [(100 * totalConfirmedLesson) / assignedLessonSum];

  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle tag="h4">Takvim Takibi</CardTitle>
        <UncontrolledDropdown className="chart-dropdown">
          <Button color="flat-warning">
            <Link
              to={`/lessons-confirm?startDate=${selectedDate.startDate}&endDate=${selectedDate.endDate}`}
            >
              Detaylar
            </Link>
          </Button>
          {/* <DropdownMenu end>
            {data.last_days.map(item => (
              <DropdownItem className='w-100' key={item}>
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu> */}
        </UncontrolledDropdown>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="2" className="d-flex flex-column flex-wrap text-center">
            {/* <h1 className='font-large-2 fw-bolder mt-2 mb-0'>{totalLesson}</h1> */}
            <h1 className="font-large-2 fw-bolder mt-2 mb-0">{assignedLessonSum}</h1>
            <CardText>Ders</CardText>
          </Col>
          <Col sm="10" className="d-flex justify-content-center">
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height={270}
              id="support-tracker-card"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-between mt-1">
          <div className="text-center">
            <CardText className="mb-50">Tahsil Edilmiş Ve Doğrulanmış Dersler</CardText>
            {/* <span className='font-large-1 fw-bold'>{data.ConfirmedCourses}</span> */}
            <span className="font-large-1 fw-bold">{totalConfirmedLesson}</span>
          </div>
          <div className="text-center">
            <CardText className="mb-50">Tahsil Edilmiş Ve Doğrulanmayı Bekleyen Dersler</CardText>
            {/* <span className='font-large-1 fw-bold'>{data.charged}</span> */}
            <span className="font-large-1 fw-bold">{totalEncashedAndWaitingForConfirm}</span>
          </div>
          <div className="text-center">
            <CardText className="mb-50">
              Tahsil Edilmeyi Bekleyen Dersler
            </CardText>
            {/* <span className='font-large-1 fw-bold'>{data.waitingCharged}</span> */}
            <span className="font-large-1 fw-bold">{totalWaitingForEncashLesson}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
export default CalendarTracking;
