import { FC, Fragment, useEffect, useState } from "react";

import {
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Label,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Card,
  CardHeader,
  CardBody,
  Table,
  Badge,
} from "reactstrap";

import Select, { components } from "react-select";
// ** Custom Components
//@ts-ignore
import * as yup from "yup";
// ** Third Party Components
//@ts-ignore
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import img1 from "@src/assets/img/10.png";
//@ts-ignore
import Moment from "moment";
//@ts-ignore
import Avatar from "@components/avatar";
import toast from "react-hot-toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import { convertPaymentType, selectThemeColors } from "../../../utility/Utils";
import LoadingButton from "../../../components/common/LoadingButton";
import classnames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IActivityDbModel,
  IActivityModel,
  IEditActivityDBModel,
} from "../../../model/DbMdel/activityDBModel";
import getCoachs from "../../../services/coach";
import { ICoachDBModel } from "../../../model/DbMdel/coachDBModel";
import getStudents from "../../../services/students";
import { getGroups, getGroupStudent } from "../../../services/groups";
import {
  approveActivity,
  deleteActivity,
  editActivity,
  studentEncashActivity,
} from "../../../services/activity";
import { IStudentDBModel } from "../../../model/DbMdel/studentDBModel";
import SwalFire from "../../../components/common/Swal";
import dateFormat from "../../../helper/dateFormat";
import AuthView from "../../../components/common/AuthView";
import { UserRole } from "../../../model/commonModel/enums";
import { checkRoles } from "../../../helper/permissionHelper";
import { Link } from "react-router-dom";
import { IGroupDBModel } from "../../../model/DbMdel/groupDBModel";
import { groupLocations } from "../../../helper/locations";
import usePaginateFetch from "../../../utility/hooks/usePaginateFetch";
import { InfiniteScrollDropdown } from "../../../components/InfiniteScroll";
import { handleFilter } from "../../../utility/helper";
import useInfinityPaginateFetch from "../../../utility/hooks/userInfinityPaginateFetch";
import { IPaymentMethodDBModel } from "../../../model/DbMdel/paymentMethodDBModel";
import { getAllPaymentMethodService } from "../../../services/paymentMethod";
import LocationDropdown from "../../../components/locationForPage";
import "./index.scss";
interface EditEventModal {
  setShowEdit: (e: boolean) => void;
  showEdit: boolean;
  activity: IActivityModel;
  refetchActivities: (e: IActivityDbModel) => void;
}

interface IOption {
  value: string;
  id: number;
  label: string;
}

interface ICheckedList {
  id: number;
  paymentType: string;
}

const EditEventModal: FC<EditEventModal> = ({
  showEdit,
  setShowEdit,
  activity,
  refetchActivities,
}) => {
  const EditEvent = yup.object().shape({
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
    coach: yup
      .object()
      .shape({
        value: yup.string().required("Antrenör seçimi zorunlu alandır."),
        label: yup.string().required("Antrenör seçimi zorunlu alandır."),
      })
      .required("Antrenör seçimi zorunlu alandır."),
    eventType: yup
      .object()
      .shape({
        value: yup.string().required("Etkinlik türü seçimi zorunlu alandır."),
        label: yup.string().required("Etkinlik türü seçimi zorunlu alandır."),
      })
      .required("Etkinlik türü seçimi zorunlu alandır."),

    groups: yup.object().when("eventType", {
      is: (eventType: IOption) => eventType.id === 1,
      then: yup
        .object()
        .shape({
          value: yup.string().required("Grup seçimi zorunlu alandır."),
          label: yup.string().required("Grup seçimi zorunlu alandır."),
        })
        .required("Grup seçimi zorunlu alandır."),
    }),
    students: yup.array().when("eventType", {
      is: (eventType: IOption) => eventType.id === 2,
      then: yup
        .array()
        .min(1, {
          value: yup.string().required("Ogrenci seçimi zorunlu alandır."),
          label: yup.string().required("Ogrenci seçimi zorunlu alandır."),
        })
        .required("Ogrenci seçimi zorunlu alandır."),
    }),
    customSwitch: yup.boolean().notRequired(),
    lessonPrice: yup.number().when("customSwitch", {
      is: (customSwitch: boolean) => customSwitch === true,
      then: yup
        .number()
        .min(0, "0 dan büyük olmalıdır.")
        .required("Ders fiyatı zorunlu alandır."),
    }),
    startDate: yup
      .date()
      .default(new Date())
      .required("Lütfen başlangıç tarihi giriniz."),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [coachs, setCoachs] = useState<IOption[]>([]);
  const [students, setStudents] = useState<IOption[]>([]);
  const [groups, setGroups] = useState<IOption[]>([]);
  const [checkedList, setCheckedList] = useState<ICheckedList[]>([]);
  const [checkedEncash, setCheckedEncash] = useState<boolean>(false);
  const [groupStudents, setGroupStudents] = useState<IGroupDBModel | null>(
    null
  );

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("");

  const [options] = useState<IOption[]>([
    { id: 2, value: "person", label: "Kişi" },
    { id: 1, value: "group", label: "Grup" },
  ]);

  const locations: IOption[] = [
    { id: 1, value: "Dorukkaya", label: "Dorukkaya" },
    { id: 2, value: "Kaya Palazzo", label: "Kaya Palazzo" },
    { id: 3, value: "Golden Key", label: "Golden Key" },
  ];

  const isConfirmed = activity.days[0].isConfirmed;

  const current = new Date(Moment.utc(activity.startDate).local().format());

  const isPast =
    new Date(Moment(activity.startDate).format(dateFormat)).getTime() <
    new Date(Moment(new Date()).format(dateFormat)).getTime();

  // ** Hooks
  const {
    control,
    handleSubmit,
    getValues,
    register,
    watch,
    setValue,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(EditEvent),
    defaultValues: {
      location: {
        id: activity.location?.id,
        value: activity.location?.id,
        label: activity.location?.name,
      },
      approveEvent: isConfirmed,
      cancelEvent: activity.isDeleted,
      // title: activity.name,
      coach: {
        id: activity.trainer?.id,
        value:
          activity.trainer?.user?.name + " " + activity.trainer?.user?.surname,
        label:
          activity.trainer?.user?.name + " " + activity.trainer?.user?.surname,
      },
      eventType:
        activity.activityType === "Person"
          ? { id: 2, value: "person", label: "Kişi" }
          : { id: 1, value: "group", label: "Grup" },
      groups: {
        id: activity.group?.id,
        value: activity.group?.name,
        label: activity.group?.name,
      },
      students: activity.students.map((x: IStudentDBModel) => {
        return {
          id: x.id,
          value: x.user?.name + " " + x.user?.surname,
          label: x.user?.name + " " + x.user?.surname,
        };
      }),
      startDate: [current],
      customSwitch: activity.group?.perUserAndHourPrice
        ? activity.perHourPrice ===
          activity.group?.perUserAndHourPrice * activity.group?.students.length
          ? false
          : true
        : activity.perHourPrice !== activity.location?.price
        ? true
        : false,
      lessonPrice: activity.perHourPrice
        ? activity.perHourPrice
        : activity.group?.perUserAndHourPrice
        ? activity.group?.perUserAndHourPrice * activity.group?.students.length
        : 1200,
      description: activity.description,
    },
  });

  // ** Requests
  const {
    data: coachData,
    isLoading: coachLoading,
    handleSearch: coachSearch,
    handlePaginate: coachPaginate,
    page: coachPages,
    searchValue: coachSearchValue,
  } = useInfinityPaginateFetch<ICoachDBModel>({
    callbackService: getCoachs,
    isActive: true,
  });

  const {
    data: studentData,
    isLoading: studentLoading,
    handleSearch: studentSearch,
    handlePaginate: studentPaginate,
    page: studentPages,
    searchValue: studentSearchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getStudents,
    isActive: true,
    locationId: getValues("location.id") ?? 0,
  });

  const {
    data: groupData,
    isLoading: groupLoading,
    handleSearch: groupSearch,
    handlePaginate: groupPaginate,
    page: groupPages,
    searchValue: groupSearchValue,
  } = useInfinityPaginateFetch<IGroupDBModel>({
    callbackService: getGroups,
    locationId: getValues("location.id") ?? 0,
  });

  const {
    data: paymentData,
    handlePaginate,
    isLoading: paymentIsLoading,
    handleSearch,
    refetch,
    page,
  } = usePaginateFetch<IPaymentMethodDBModel>({
    callbackService: getAllPaymentMethodService,
    maxResultCount: 1000,
    isActive: true,
  });

  //** Fetchs

  const fetchCoachs = async () => {
    if (coachs.length < 1) {
      const coachs = coachData.items.map((coach) => {
        return {
          id: coach.id,
          value: coach.user.name + " " + coach.user.surname,
          label: coach.user.name + " " + coach.user.surname,
        } as IOption;
      });

      setCoachs(coachs);
    }
  };

  useEffect(() => {
    if (activity?.location?.id !== getValues("location.id")) {
      setValue("eventType", { id: -1, value: "", label: "" });
      setValue("students", [{ id: -1, label: "", value: "" }]);
      setValue("groups", { id: -1, label: "", value: "" });
    }
  }, [watch("location")]);

  const fetchStudents = async () => {
    if (students.length < 1) {
      const students = studentData.items.map((student) => {
        return {
          id: student.id,
          value: student.user.name + " " + student.user.surname,
          label: student.user.name + " " + student.user.surname,
        } as IOption;
      });

      setStudents(students);
    }
  };

  const fetchGroups = async () => {
    if (groups.length < 1) {
      const groups = groupData.items
        .filter((s) => s.students.length > 1)
        .map((group) => {
          return {
            id: group.id,
            value: group.name,
            label: group.name,
          } as IOption;
        });

      setGroups(groups);
    }
  };

  const fetchGroupStudents = async () => {
    await getGroupStudent(watch("groups").id)
      .then((e: IGroupDBModel) => {
        setGroupStudents(e);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchCoachs();
  }, [coachData]);

  useEffect(() => {
    fetchStudents();
  }, [studentData]);

  useEffect(() => {
    fetchGroups();
    fetchGroupStudents();
  }, [groupData]);

  useEffect(() => {
    activity.group?.students?.length > 0 &&
      activity.group?.students.map((item: IStudentDBModel) => {
        const st = activity.days[0].students.find(
          (student) => student.studentId === item.id
        );

        if (st) {
          let updatedList = checkedList;
          if (st.isEncashed) {
            updatedList.push({
              id: Number(st.studentId),
              paymentType: convertPaymentType(st.paymentType),
            });
            setCheckedList(updatedList);
          } else {
            const newList = updatedList.filter(
              (c) => c.id !== Number(st.studentId)
            );
            setCheckedList(newList);
          }
        }
      });

    activity.students?.length > 0 &&
      activity.students.map((item: IStudentDBModel) => {
        const st = activity.days[0].students.find(
          (student) => student.studentId === item.id
        );
        if (st) {
          setCheckedEncash(st.isEncashed ? true : false);
        }
      });

    if (activity.activityType === "Person") {
      const type = convertPaymentType(activity.days[0].students[0].paymentType);
      setPaymentType(type);
    }
  }, [activity]);

  const onSubmit = (e: any) => {
    setIsLoading(true)
    if (
      !groupStudents?.perUserAndHourPrice &&
      watch("customSwitch") === false &&
      getValues("eventType").id === 1
    ) {
      toast.error("Kişi başına düşen miktar belirlenmemiştir.");
      return;
    }
    let sDate = e.startDate;
    const sDateIsArray = Array.isArray(e.startDate);
    if (sDateIsArray) {
      sDate = e.startDate[0];
    }

    const eDate = new Date(Moment(sDate).add(1, "hours").format());

    const perHourPrice = watch("customSwitch")
      ? e.lessonPrice
      : e.eventType.value === "group" && groupStudents
      ? groupStudents.perUserAndHourPrice * groupStudents.students.length
      : e.location.price;

    const data: IEditActivityDBModel = {
      name: e.location.label + "/" + e.coach.label,
      trainerId: e.coach.id,
      activityType: e.eventType.value,
      startDate: sDate.toUTCString(),
      endDate: eDate.toUTCString(),
      perHourPrice: perHourPrice,
      description: e.description,
      locationId: e.location.id,
      groupId: e.eventType.id === 1 ? e.groups?.id : undefined,
      students:
        e.eventType.id === 2
          ? e.students.map((x: IOption) => x.id as number)
          : undefined,
    };


    editActivity(data, activity.id)
      .then((res) => {
        toast.success("Activity Güncelleme başarılı");
        refetchActivities(res);
        setShowEdit(!showEdit);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(()=>{
        setIsLoading(false);
      });
  };

  const handleCheck = (event: any, studentId: number) => {
    if (checkRoles([UserRole.Manager])) {
      toast.error(
        "Etkinlik ücreti için değişiklik yapmaya yetkiniz bulunmamaktadır."
      );
      return;
    }

    var updatedList = [...checkedList];

    if (event.target.checked) {
      SwalFire({
        title: "Emin misiniz?",
        text: `Etkinliği öğrenci için onaylamak üzeresiniz. İşleme devam etmek istiyor musunuz?`,
        input: "select",
        inputLabel: "Ödeme tipi",
        inputOptions: Object.fromEntries(
          paymentData.items.map((item) => [item.id, item.name])
        ),
        inputPlaceholder: "Ödeme tipini seçin",
        inputValidator: (value: number) => {
          return new Promise(async (resolve: any) => {
            if (!value) {
              resolve("Ödeme tipini seçiniz");
            } else {
              await studentEncashActivity(
                {
                  activityId: activity.id,
                  studentId: studentId,
                  day: Moment(new Date(activity.startDate)).format(dateFormat),
                },
                true,
                value
              )
                .then((e) => {
                  updatedList = [
                    ...checkedList,
                    {
                      id: Number(event.target.value),
                      paymentType: convertPaymentType(value),
                    },
                  ];
                  setCheckedList(updatedList);
                  setCheckedEncash(true);
                  refetchActivities(e);
                  setPaymentType(convertPaymentType(value));
                  resolve();
                  toast.success("Etkinlik öğrenci için onaylandı");
                })
                .catch((e) => {
                  toast.error(e);
                });
            }
          });
        },
      });
    } else {
      SwalFire({
        title: "Emin misiniz?",
        text: `Etkinliği öğrenci için iptal etmek üzeresiniz. İşleme devam etmek istiyor musunuz?`,
        onOk: async () => {
          await studentEncashActivity(
            {
              activityId: activity.id,
              studentId: studentId,
              day: Moment(new Date(activity.startDate)).format(dateFormat),
            },
            false
          )
            .then((e) => {
              const newList = updatedList.filter(
                (c) => c.id !== Number(event.target.value)
              );
              setCheckedList(newList);
              setCheckedEncash(false);
              setPaymentType("");
              refetchActivities(e);
              toast.success("Etkinlik öğrenci için iptal edildi");
            })
            .catch((e) => {
              toast.error(e);
            });
        },
      });
    }
  };

  const renderActivityHistory = () => {
    return (
      <div>
        <Table responsive borderless>
          <tbody>
            {activity.logs.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    width="70%"
                    dangerouslySetInnerHTML={{ __html: item.log }}
                  />
                  <td width="30%">
                    <span className="text-muted">
                      <small className="d-flex justify-content-end">
                        {Moment.utc(item.creationTime)
                          .local()
                          .format(dateFormat)}
                      </small>
                      <small className="d-flex justify-content-end">
                        {Moment.utc(item.creationTime).local().format("HH:MM")}
                      </small>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderPaymentType = (item: IStudentDBModel) => {
    const student = checkedList.find((x) => x.id === item.id);
    if (!student) return;
    if (student.paymentType === "") return;
    return (
      <div>
        <Badge pill color="light-warning">
          {student.paymentType}
        </Badge>
      </div>
    );
  };

  //   ** Custom select components
  const OptionComponent = ({ data, ...props }: any) => {
    return (
      //@ts-ignore
      <components.Option {...props} className="d-flex align-items-center">
        <div>{data.label}</div>
      </components.Option>
    );
  };

  const StudentsComponent = ({ data, ...props }: any) => {
    return (
      //@ts-ignore
      <components.Option {...props}>
        <div className="d-flex flex-wrap align-items-center">
          <Avatar className="my-0 me-1" size="sm" img={img1} />
          <div>{data.label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        onClosed={() => setShowEdit(showEdit)}
        toggle={() => setShowEdit(!showEdit)}
        className="sidebar-lg"
        contentClassName="p-0 overflow-hidden"
        modalClassName="modal-slide-in event-sidebar activity-edit-modal"
      >
        <ModalHeader
          className="mb-1"
          toggle={() => setShowEdit(!showEdit)}
          close={() => setShowEdit(showEdit)}
          tag="div"
        >
          <h5 className="modal-title">Etkinlik Düzenle</h5>
        </ModalHeader>
        <PerfectScrollbar options={{ wheelPropagation: false }}>
          <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <AuthView roles={[UserRole.Manager]}>
                <div
                  style={{ paddingLeft: "0" }}
                  className="d-flex justify-content-between align-items-center form-switch mb-1 "
                >
                  <Label
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    className="form-label text-success mb-0"
                    for="approveEvent"
                  >
                    Etkinlik onayı
                  </Label>
                  <Controller
                    name="approveEvent"
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        id="approveEvent"
                        type="switch"
                        className="me-1"
                        {...field}
                        defaultChecked={isConfirmed}
                        checked={watch("approveEvent")}
                        disabled={activity.isDeleted}
                        onChange={(event) => {
                          if (event.target.checked) {
                            SwalFire({
                              title: "Emin misiniz?",
                              text: `Etkinliği onaylamak üzeresiniz.
                          İşleme devam etmek istiyor musunuz?`,
                              onOk: async () => {
                                await approveActivity({
                                  activityId: activity.id,
                                  confirm: true,
                                  day: Moment(
                                    new Date(activity.startDate)
                                  ).format(dateFormat),
                                })
                                  .then((e) => {
                                    field.onChange(true);
                                    toast.success("Etkinlik onaylandı");
                                    refetchActivities(e);
                                  })
                                  .catch((e) => {
                                    toast.error(e);
                                    field.onChange(false);
                                  });
                              },
                            });
                          } else {
                            SwalFire({
                              title: "Emin misiniz?",
                              text: `Etkinlik onayını geri almak üzeresiniz.
                          İşleme devam etmek istiyor musunuz?`,
                              onOk: async () => {
                                await approveActivity({
                                  activityId: activity.id,
                                  confirm: false,
                                  day: Moment(
                                    new Date(activity.startDate)
                                  ).format(dateFormat),
                                })
                                  .then((e) => {
                                    field.onChange(false);
                                    toast.success("Etkinlik onayı geri alındı");
                                    refetchActivities(e);
                                  })
                                  .catch((e) => {
                                    toast.error(e);
                                    field.onChange(true);
                                  });
                              },
                            });
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </AuthView>

              {watch("eventType.id") === 2 ? (
                <AuthView roles={[UserRole.Admin, UserRole.Trainer]}>
                  <div
                    style={{ paddingLeft: "0" }}
                    className="d-flex justify-content-between align-items-center form-switch mb-1 "
                  >
                    <Label
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      className="form-label text-success mb-0"
                      for="encashEvent"
                    >
                      Etkinlik Ücreti Onayı{" "}
                      {paymentType !== "" && (
                        <Badge pill color="light-success">
                          {" "}
                          {paymentType}{" "}
                        </Badge>
                      )}
                    </Label>
                    <Input
                      id="encashEvent"
                      name="encashEvent"
                      type="switch"
                      className="me-1"
                      checked={checkedEncash}
                      disabled={activity.isDeleted}
                      onChange={(event) =>
                        checkRoles([UserRole.Manager])
                          ? null
                          : handleCheck(event, getValues("students")[0]?.id)
                      }
                    />
                  </div>
                </AuthView>
              ) : null}

              <AuthView roles={[UserRole.Manager, UserRole.Admin]}>
                <div
                  style={{ paddingLeft: "0" }}
                  className="d-flex justify-content-between align-items-center form-switch mb-1 "
                >
                  <Label
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    className="form-label text-danger  mb-0"
                    for="cancelEvent"
                  >
                    Etkinliği iptal et
                  </Label>
                  <Controller
                    name="cancelEvent"
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        id="cancelEvent"
                        type="switch"
                        className="me-1"
                        {...field}
                        defaultChecked={activity.isDeleted}
                        checked={watch("cancelEvent")}
                        disabled={
                          watch("eventType.id") === 1
                            ? groupStudents?.students.length ===
                              checkedList.length
                              ? true
                              : false
                            : checkedEncash
                        }
                        onChange={(event) => {
                          if (event.target.checked) {
                            SwalFire({
                              title: "Emin misiniz?",
                              text: `Etkinliği iptal etmek üzeresiniz.
                        İşleme devam etmek istiyor musunuz?`,
                              onOk: async () => {
                                await deleteActivity(activity.id, true)
                                  .then((e) => {
                                    field.onChange(true);
                                    toast.success("Etkinlik iptal edildi");
                                    refetchActivities(e);
                                    setShowEdit(!showEdit);
                                  })
                                  .catch((e) => {
                                    toast.error(e);
                                    field.onChange(false);
                                  });
                              },
                            });
                          } else {
                            SwalFire({
                              title: "Emin misiniz?",
                              text: `Etkinliği aktif etmek üzeresiniz.
                        İşleme devam etmek istiyor musunuz?`,
                              onOk: async () => {
                                await deleteActivity(activity.id, false)
                                  .then((e) => {
                                    field.onChange(false);
                                    toast.success("Etkinlik aktif edildi");
                                    refetchActivities(e);
                                    setShowEdit(!showEdit);
                                  })
                                  .catch((e) => {
                                    toast.error(e);
                                    field.onChange(true);
                                  });
                              },
                            });
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </AuthView>
              <div className="mb-1">
                <Label className="form-label" for="location">
                  Lokasyon
                </Label>
                <LocationDropdown
                  control={control}
                  defaultValue={getValues("location")}
                  onChange={(event) => setValue("location", event)}
                />
              </div>

              <div className="mb-1">
                <Label className="form-label" for="coach">
                  Antrenör
                </Label>
                <Controller
                  name="coach"
                  control={control}
                  render={({ field }) => (
                    <InfiniteScrollDropdown
                      searchValue={coachSearchValue}
                      totalCount={coachData.totalCount}
                      field={field}
                      headers={["user.name", "user.surname"]}
                      data={coachData.items}
                      isLoading={coachLoading}
                      id="coach"
                      className={classnames("react-select", {
                        "is-invalid": errors.coach,
                      })}
                      components={{
                        Option: StudentsComponent,
                      }}
                      onInputChange={(inputValue) =>
                        handleFilter(inputValue, coachSearch)
                      }
                      onMenuScrollBottom={() => coachPaginate(coachPages + 1)}
                      defaultValue={getValues("coach")}
                      onChange={(event: any) => {
                        setValue("coach", event);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                {errors.coach && (
                  <FormFeedback>
                    {" "}
                    {(errors.coach as any).message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <Label className="form-label" for="eventType">
                  Etkinlik Türü
                </Label>
                <Controller
                  name="eventType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      id="eventType"
                      placeholder="Seçiniz"
                      theme={selectThemeColors}
                      defaultValue={getValues("eventType")}
                      classNamePrefix="select"
                      isClearable={false}
                      isMulti={false}
                      components={{
                        Option: OptionComponent,
                      }}
                      options={options}
                      className={classnames("react-select custom-select", {
                        "is-invalid": errors.eventType,
                      })}
                      {...field}
                      onChange={(event: any) => {
                        if (watch("eventType.id") === 1)
                          setValue("groups", { id: -1, value: "", label: "" });
                        studentPaginate(0);

                        if (watch("eventType.id") === 2)
                          setValue("lessonPrice", 1200);
                        groupPaginate(0);
                        setValue("students", [
                          { id: -1, value: "", label: "" },
                        ]);

                        setValue("eventType", event);
                        setValue("customSwitch", false);

                        field.onChange(event);
                      }}
                      ref={register("eventType").ref}
                    />
                  )}
                />
                {errors.eventType && (
                  <FormFeedback>
                    {errors.eventType.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              {watch("eventType") && watch("eventType.id") === 1 ? (
                <div className="mb-1">
                  <Label className="form-label" for="groups">
                    Grup
                  </Label>
                  <Controller
                    name="groups"
                    control={control}
                    render={({ field }) => (
                      <InfiniteScrollDropdown
                        searchValue={groupSearchValue}
                        field={field}
                        headers={["name"]}
                        totalCount={groupData.totalCount}
                        data={groupData.items}
                        isLoading={groupLoading}
                        id="groups"
                        className={classnames("react-select", {
                          "is-invalid": errors.groups,
                        })}
                        components={{
                          Option: OptionComponent,
                        }}
                        onInputChange={(inputValue) =>
                          handleFilter(inputValue, groupSearch)
                        }
                        onMenuScrollBottom={() => groupPaginate(groupPages + 1)}
                        onChange={(event: any) => {
                          setValue("groups", event);
                          resetField("students");
                          field.onChange(event);
                        }}
                        ref={register("groups").ref}
                        defaultValue={getValues("groups")}
                      />
                    )}
                  />
                  {errors.groups && (
                    <FormFeedback>
                      {" "}
                      {(errors.groups as any).message?.toString()}
                    </FormFeedback>
                  )}
                </div>
              ) : null}

              {watch("eventType") && watch("eventType.id") === 2 ? (
                <div className="mb-1">
                  <Label className="form-label" for="students">
                    Kişiler
                  </Label>
                  <Controller
                    name="students"
                    control={control}
                    render={({ field }) => (
                      <InfiniteScrollDropdown
                        searchValue={studentSearchValue}
                        field={field}
                        totalCount={studentData.totalCount}
                        headers={["user.name", "user.surname"]}
                        data={studentData.items}
                        isLoading={studentLoading}
                        id="students"
                        className={classnames("react-select", {
                          "is-invalid": errors.students,
                        })}
                        components={{
                          Option: OptionComponent,
                        }}
                        onInputChange={(inputValue) =>
                          handleFilter(inputValue, studentSearch)
                        }
                        onMenuScrollBottom={() =>
                          studentPaginate(studentPages + 1)
                        }
                        onChange={(event: any) => {
                          setValue("students", [event]);
                          setValue("groups", {
                            id: -1,
                            value: "",
                            label: "",
                          });
                          resetField("groups");
                          field.onChange([event]);
                        }}
                        ref={register("students").ref}
                        defaultValue={getValues("students")}
                      />
                    )}
                  />
                  {errors.students && (
                    <FormFeedback>
                      {" "}
                      {(errors.students as any)?.message?.toString()}
                    </FormFeedback>
                  )}
                </div>
              ) : null}

              {watch("eventType") &&
              watch("eventType.id") === 1 &&
              watch("groups") &&
              groupStudents &&
              groupStudents.students.length > 0 ? (
                <Card>
                  <CardHeader className="border-bottom">
                    <p className="fw-bolder mb-0">{watch("groups").label}</p>
                  </CardHeader>
                  <CardBody className="pt-1">
                    {groupStudents.students.map((item: IStudentDBModel) => {
                      const switchChecked = checkedList.some(
                        (x) => x.id === item.id
                      );
                      return (
                        <div className="d-flex mt-1" key={item.id}>
                          <div className="d-flex align-item-center justify-content-between flex-grow-1">
                            <Link
                              to={`/usersandpermissions/users/profile-detail/${item.user.id}`}
                            >
                              <div className="me-1">
                                <p className="fw-bolder mb-0">
                                  {item?.user?.name + " " + item?.user?.surname}
                                </p>
                                {renderPaymentType(item)}
                              </div>
                            </Link>
                            <div className="mt-50 mt-sm-0">
                              <div className="form-switch">
                                <Input
                                  type="switch"
                                  checked={switchChecked}
                                  value={item.id}
                                  id={`account-${item?.user?.name}`}
                                  disabled={checkRoles([UserRole.Manager])}
                                  onChange={(event) =>
                                    handleCheck(event, item.id)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              ) : null}

              <div className="mb-1">
                <Label className="form-label" for="startDate">
                  Tarih
                </Label>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={getValues("startDate")}
                  render={({ field: { ref, ...field } }) => (
                    <Flatpickr
                      placeholder="Tarih"
                      id="startDate"
                      className={classnames("form-control", {
                        "is-invalid": errors.startDate,
                      })}
                      options={{
                        closeOnSelect: true,
                        enableTime: true,
                        dateFormat: "Y-m-d H:i",
                        minTime: "08:00",
                        maxTime: "17:00",
                        static: true,
                        minuteIncrement: 60,
                        time_24hr: true,
                        defaultHour: 8,
                        enableSeconds: false,
                      }}
                      {...field}
                      ref={register("startDate").ref}
                    />
                  )}
                />
                {errors.startDate && (
                  <FormFeedback>
                    {errors.startDate.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-center form-switch mb-1 ">
                <div className="d-flex flex-row align-items-center">
                  <Controller
                    name="customSwitch"
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        id="customSwitch"
                        type="switch"
                        className="me-1"
                        defaultValue={getValues("customSwitch")}
                        checked={getValues("customSwitch")}
                        {...field}
                      />
                    )}
                  />
                  <Label className="form-label mb-0" for="lessonPrice">
                    Özel kurs fiyatı
                  </Label>
                </div>
                {watch("customSwitch") ? (
                  <InputGroup className="w-50">
                    <Controller
                      name="lessonPrice"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="w-50"
                          type="number"
                          id="lessonPrice"
                          invalid={errors.lessonPrice && true}
                          {...field}
                          value={getValues("lessonPrice")}
                          ref={register("lessonPrice").ref}
                        />
                      )}
                    />
                    <InputGroupText>TL</InputGroupText>
                  </InputGroup>
                ) : null}
                {errors.lessonPrice && (
                  <FormFeedback>
                    {errors.lessonPrice.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <Label className="form-label" for="description">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="textarea"
                      maxLength={250}
                      id="description"
                      invalid={errors.description && true}
                      defaultValue={getValues("description")}
                      placeholder="Bir açıklama yaz..."
                      {...field}
                    />
                  )}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <AuthView roles={[UserRole.Manager, UserRole.Admin]}>
                <div className="d-flex mb-1 modal-footer">
                  <Row className="d-flex flex-row align-items-center footer-row">
                    <Col className="d-flex flex-row modal-action" >
                      {!(watch("eventType.id") === 1
                        ? groupStudents?.students.length === checkedList.length
                          ? true
                          : false
                        : checkedEncash) && (
                        <>
                          {checkRoles([UserRole.Manager]) ? (
                            <LoadingButton
                              className="me-1"
                              type="submit"
                              color="warning"
                              loading={isLoading}
                              onClick={handleSubmit(onSubmit)}
                            >
                              Kaydet
                            </LoadingButton>
                          ) : (
                            !isPast &&
                            !activity.isDeleted && (
                              <LoadingButton
                                className="me-1"
                                type="submit"
                                color="warning"
                                loading={isLoading}
                                onClick={handleSubmit(onSubmit)}
                              >
                                Kaydet
                              </LoadingButton>
                            )
                          )}
                          <Button
                            color="warning"
                            type="reset"
                            onClick={() => setShowEdit(!showEdit)}
                            outline
                          >
                            İptal
                          </Button>
                        </>
                      )}
                    </Col>
                    <Col className="detail-button">
                      <Button
                        size="sm"
                        color="flat-dark"
                        className="text-muted"
                        onClick={() => setShowDetail(!showDetail)}
                      >
                        {showDetail ? "Detayları Gizle" : " Detayları Göster"}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </AuthView>
              {showDetail && activity.logs.length > 0 && (
                <Row className="d-flex align-items-center activityHistoryTable">
                  <PerfectScrollbar
                    options={{
                      wheelPropagation: false,
                    }}
                    style={{ height: "150px" }}
                  >
                    {renderActivityHistory()}
                  </PerfectScrollbar>
                </Row>
              )}
            </Form>
          </ModalBody>
        </PerfectScrollbar>
      </Modal>
    </Fragment>
  );
};

export default EditEventModal;
