import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import * as yup from "yup";
import Select, { components } from "react-select";
import { selectThemeColors } from "../../../utility/Utils";
import {
  IStudentDBModel,
  IStudentEditDBModel,
} from "../../../model/DbMdel/studentDBModel";
import { ILanguage } from "../../../model/commonModel/language";
import classnames from "classnames";
import { editStudent, getParentStudents } from "../../../services/students";
import toast from "react-hot-toast";
import LoadingButton from "../../common/LoadingButton";
import FlatPickr from "react-flatpickr";
import Moment from "moment";
import SwitchLabel from "../../common/SwitchLabel";
import SwalFire from "../../common/Swal";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import { CountryData } from "react-phone-input-2";
import dateFormat from "../../../helper/dateFormat";
import { InfiniteScrollDropdown } from "../../InfiniteScroll";
import { handleFilter } from "../../../utility";
import useInfinityPaginateFetch from "../../../utility/hooks/userInfinityPaginateFetch";
import LocationDropdown from "../../locationForPage";
import phone from "phone";

interface IStudentEditModalProps {
  setShowEdit: (param: any) => void;
  showEdit: boolean;
  student: IStudentDBModel;
  languages: ILanguage[];
  onReload: () => void;
  parentId: number | undefined;
}

const OptionComponent = ({ data, ...props }: any) => {
  return (
    //@ts-ignore
    <components.Option {...props} className="d-flex align-items-center">
      <div>{data.label}</div>
    </components.Option>
  );
};

const StudentEditModal: FC<IStudentEditModalProps> = ({
  setShowEdit,
  showEdit,
  student,
  languages,
  onReload,
  parentId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [localStudent, setLocalStudent] = useState<IStudentDBModel>(
    student as IStudentDBModel
  );
  const [localParentId, setLocalParentId] = useState<number | undefined>(
    parentId
  );

  const {
    data: parentStudentsData,
    handlePaginate: handleParentStudentPaginate,
    isLoading: isParentStudentLoading,
    handleSearch: handleParentStudentSearch,
    page: parentStudentCurrentPage,
    searchValue,
  } = useInfinityPaginateFetch<IStudentDBModel>({
    callbackService: getParentStudents,
  });

  const statusOptions = [
    { value: true, label: "Aktif" },
    { value: false, label: "Pasif" },
  ];

  const AddStudentSchema = yup.object().shape({
    emailAddress: yup.string().when("parentId", {
      is: (parentId: string) => {
        return parentId === undefined;
      },
      then: yup
        .string()
        .email("Geçerli bir email adresi giriniz.")
        .notRequired(),
    }),
    name: yup
      .string()
      .min(2, "İsim zorunlu alandır")
      .max(64, "İsim en fazla 64 karakter olmalıdır")
      .required("İsim zorunlu alandır"),
    surname: yup
      .string()
      .min(2, "Soyisim zorunlu alandır")
      .max(64, "Soyisim en fazla 64 karakter olmalıdır")
      .required("Soyisim zorunlu alandır."),
    phoneNumber: yup
      .string()
      .test("matches", "Geçerli bir numara giriniz", (str) => {
        if (str!.length > 5) {
          const pn = phone(str!);
          return pn.isValid;
        }
        return true;
      })
      .when("parentId", {
        is: (parentId: string) => {
          return parentId === undefined;
        },
        then: yup.string().notRequired(),
      }),
    status: yup.string().notRequired(),
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
    languages: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
          label: yup.string(),
        })
      )
      .min(1, "En az bir dil seçiniz")
      .required("Dil zorunlu alandır"),
    serviceAreaEntryDate: yup.date().notRequired().nullable(),
    serviceAreaExitDate: yup
      .date()
      .nullable()
      .when("serviceAreaEntryDate", (serviceAreaEntryDate, schema) => {
        return Moment(serviceAreaEntryDate).isValid()
          ? serviceAreaEntryDate &&
              schema.min(
                serviceAreaEntryDate,
                "Otelden çıkış tarihi giriş tarihinden önce olamaz"
              )
          : schema.notRequired();
      })
      .notRequired(),
    perHourPrice: yup
      .number()
      .min(1, "0 dan büyük olmalıdır")
      .required("Fiyat zorunlu alandır"),
    description: yup.string().nullable(),
    isPanelAccess: yup.boolean(),
    parentId: yup.number(),
  });

  const {
    control,
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddStudentSchema),
    defaultValues: {
      emailAddress: student.user.emailAddress ? student.user.emailAddress : "",
      name: student.user.name,
      surname: student.user.surname,
      phoneNumber: student.user.phoneNumber ? student.user.phoneNumber : "",
      isActive: student.user.isActive,
      languages: student.user.languages.map((x: ILanguage) => {
        return { value: x.id, label: x.name };
      }),
      perHourPrice: student.location?.price,
      description: student.description,
      serviceAreaEntryDate: student.serviceAreaEntryDate,
      serviceAreaExitDate: student.serviceAreaExitDate,
      parentStudent: {} as { id: number; value: string; label: string },
      isPanelAccess: student.user.isPanelAccess,
      parentId: parentId,
      location: {
        id: student.location?.id,
        value: student.location?.id,
        label: student.location?.name,
        price: student.location?.price,
      },
    },
  });

  useEffect(() => {
    setValue("parentId", parentId);
  }, [parentId, isParentStudentLoading]);

  useEffect(() => {
    if (student) {
      const data = {
        id: Number(student.parentStudent?.id),
        label: `${student.parentStudent?.user.name} ${student.parentStudent?.user.surname}`,
        value: `${student.parentStudent?.id}`,
      };
      setValue("parentStudent", data);
    }
  }, [student]);

  const handleSwal = async (e: any) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Öğrenci bilgilerini düzenlemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        onSubmit(e);
      },
    });
  };

  const onSubmit = (e: any) => {
    const data: IStudentEditDBModel = {
      userDto: {
        emailAddress: e.emailAddress,
        name: e.name,
        surname: e.surname,
        phoneNumber: phone(e.phoneNumber).phoneNumber ?? "",
        languageIds: e.languages.map((l: any) => l.value),
        isActive: e.isActive,
        isPanelAccess: e.isPanelAccess,
        photo: "",
      },
      locationId: e.location.id,
      perHourPrice: parseInt(e.perHourPrice),
      studentId: localParentId ?? null,
      description: e.description,
      serviceAreaEntryDate: e.serviceAreaEntryDate
        ? Moment(e.serviceAreaEntryDate).format(dateFormat)
        : null,
      serviceAreaExitDate: e.serviceAreaExitDate
        ? Moment(e.serviceAreaExitDate).format(dateFormat)
        : null,
    };
    setIsLoading(true);

    editStudent(data, student.id)
      .then((res) => {
        toast.success("Öğrenci Düzenleme Başarılı.");
        onReload();
        setShowEdit({ parentId: undefined, mode: "none" });
        setIsLoading(false);
        reset({
          name: "",
          surname: "",
          emailAddress: "",
          phoneNumber: "",
          languages: [],
          isActive: false,
          isPanelAccess: false,
        });
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const price = getValues("location.price");
    setValue("perHourPrice", price);
  }, [watch("location")]);

  useEffect(() => {
    const email = watch("emailAddress");
    const phoneNumber = watch("phoneNumber");

    if (parentId !== undefined && (!email || !phoneNumber)) {
      setValue("isPanelAccess", false);
    }
  }, [watch("emailAddress"), watch("phoneNumber"), parentId]);

  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShowEdit(!showEdit)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">
              {parentId ? "Bağlantılı" : ""} Öğrenci Düzenle
            </h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              {parentId && (
                <Col md={{ size: 6, offset: 3 }} xs={12}>
                  <Label className="form-label" for="status">
                    Bağlantısı
                  </Label>
                  <Controller
                    name="parentStudent"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InfiniteScrollDropdown
                          searchValue={searchValue}
                          field={field}
                          headers={["user.name", "user.surname"]}
                          data={parentStudentsData.items}
                          totalCount={parentStudentsData.totalCount}
                          isLoading={isParentStudentLoading}
                          id="students"
                          components={{
                            Option: OptionComponent,
                          }}
                          onInputChange={(inputValue) =>
                            handleFilter(inputValue, handleParentStudentSearch)
                          }
                          onMenuScrollBottom={() => {
                            handleParentStudentPaginate(
                              parentStudentCurrentPage + 1
                            );
                          }}
                          onChange={(event: any) => {
                            setValue("parentStudent", event);
                            setLocalParentId(event.id);
                          }}
                          ref={register("parentStudent").ref}
                          defaultValue={getValues("parentStudent")}
                        />
                      );
                    }}
                  />
                  {errors.isActive && (
                    <FormFeedback>
                      {errors.isActive.message?.toString()}
                    </FormFeedback>
                  )}
                </Col>
              )}
              <Col md={12} xs={12}>
                <Label>Lokasyon</Label>
                <LocationDropdown
                  control={control}
                  defaultValue={getValues("location")}
                  onChange={(event) => setValue("location", event)}
                />
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("name").ref}
                        id="name"
                        type="text"
                        maxLength={64}
                        invalid={errors.name && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="lastName">
                  Soyisim
                </Label>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("surname").ref}
                        id="lastName"
                        type="text"
                        maxLength={64}
                        invalid={errors.surname && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.surname && (
                  <FormFeedback>
                    {errors.surname.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="email"
                        maxLength={320}
                        id="emailAddress"
                        name="emailAddress"
                        innerRef={register("emailAddress").ref}
                        invalid={errors.emailAddress && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.emailAddress && (
                  <FormFeedback>
                    {errors.emailAddress.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="phoneNumber">
                  Telefon
                </Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputComponent
                      invalid={errors.phoneNumber && true}
                      {...field}
                      {...register("phoneNumber")}
                      register={register}
                      onChange={() => {}}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <FormFeedback>
                    {errors.phoneNumber.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="enter">
                  Otele Giriş Tarihi
                </Label>
                <Controller
                  name="serviceAreaEntryDate"
                  control={control}
                  render={({ field }) => (
                    <FlatPickr
                      disabled={false}
                      id="serviceAreaEntryDate"
                      style={{ opacity: "1" }}
                      options={{
                        closeOnSelect: true,
                        static: true,
                      }}
                      className={classnames("form-control", {
                        "is-invalid": errors.serviceAreaEntryDate,
                      })}
                      {...field}
                      onChange={(e) => {
                        if (e.length > 0) {
                          field.onChange(e[0]);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      ref={register("serviceAreaEntryDate").ref}
                    />
                  )}
                />
                {errors.serviceAreaEntryDate && (
                  <FormFeedback>
                    {(errors.serviceAreaEntryDate! as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="enter">
                  Otelden Çıkış Tarihi
                </Label>
                <Controller
                  name="serviceAreaExitDate"
                  control={control}
                  render={({ field }) => (
                    <FlatPickr
                      disabled={false}
                      style={{ opacity: "1" }}
                      options={{
                        closeOnSelect: true,
                        static: true,
                      }}
                      id="serviceAreaExitDate"
                      readOnly={false}
                      className={classnames("form-control", {
                        "is-invalid": errors.serviceAreaExitDate,
                      })}
                      {...field}
                      onChange={(e) => {
                        if (e.length > 0) {
                          field.onChange(e[0]);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      ref={register("serviceAreaExitDate").ref}
                    />
                  )}
                />
                {errors.serviceAreaExitDate && (
                  <FormFeedback>
                    {(errors.serviceAreaExitDate! as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>

              <Col md={6} xs={12}>
                <Label className="form-label" for="status">
                  Durum
                </Label>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        id="isActive"
                        defaultValue={statusOptions.find(
                          (x) => x.value === getValues("isActive")
                        )}
                        className="react-select"
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        options={statusOptions}
                        onChange={(value: any) => {
                          setValue("isActive", value.value);
                        }}
                      />
                    );
                  }}
                />
                {errors.isActive && (
                  <FormFeedback>
                    {errors.isActive.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="language">
                  Dil(ler)
                </Label>
                <Controller
                  control={control}
                  name="languages"
                  render={({ field }) => (
                    <Select
                      id="languages"
                      isClearable={false}
                      isMulti
                      classNamePrefix="select"
                      options={languages.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      defaultValue={getValues("languages")}
                      className={classnames("react-select", {
                        "is-invalid": errors.languages,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.languages && (
                  <FormFeedback>
                    {(errors.languages as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="perHourPrice">
                  Fiyat
                </Label>
                <InputGroup>
                  <Controller
                    name="perHourPrice"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          name="perHourPrice"
                          innerRef={register("perHourPrice").ref}
                          id="perHourPrice"
                          value={getValues("perHourPrice")}
                          invalid={errors.perHourPrice && true}
                          defaultValue={getValues("perHourPrice")}
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.preventDefault()
                          }
                        />
                      );
                    }}
                  />
                  <InputGroupText>TL</InputGroupText>
                </InputGroup>
                {errors.perHourPrice && (
                  <FormFeedback>
                    {errors.perHourPrice.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="price">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        maxLength={1000}
                        type="textarea"
                        {...field}
                        name="description"
                        style={{ maxHeight: "100px", minHeight: "63px" }}
                        innerRef={register("description").ref}
                        id="description"
                        invalid={errors.description && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <div className="form-switch">
                    <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="switch"
                            id="isPanelAccess"
                            disabled={
                              parentId !== undefined &&
                              !(watch("phoneNumber") && watch("emailAddress"))
                            }
                            checked={watch("isPanelAccess")}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                            }}
                          />
                        );
                      }}
                    />
                    <SwitchLabel htmlFor={"isPanelAccess"} />
                  </div>
                  <Label
                    className="form-check-label fw-bolder"
                    htmlFor="billing-switch"
                  >
                    Panele Erişebilir
                  </Label>
                </div>
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(handleSwal)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShowEdit(false)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default StudentEditModal;
function moment(serviceAreaEntryDate: any) {
  throw new Error("Function not implemented.");
}
