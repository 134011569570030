import { useState } from "react";
import Breadcrumbs from "../../../@core/components/breadcrumbs";
import GroupListTable from "../../../components/transactions/group/groupList/groupListTable";
import GroupPriceListTable from "../../../components/transactions/group/groupPrice/groupPriceListTable";
const Groups = () => {
  const [refetchToggle, setRefetchToggle] = useState<boolean>(false);
  return (
    <>
      <Breadcrumbs
        title="Grup"
        data={[{ title: "İşlemler" }, { title: "Grup" }]}
      />
      <div>
        <GroupListTable refetchToggle={refetchToggle} />
      </div>
      <div>
        <GroupPriceListTable
          handleRefetch={() => setRefetchToggle(!refetchToggle)}
        />
      </div>
    </>
  );
};

export default Groups;
