import { Fragment, useEffect, useRef, useState } from "react";

import classnames from "classnames";

import { CardBody, Button, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { ICoachDBModel } from "../../model/DbMdel/coachDBModel";
import getCoachs from "../../services/coach";
import { FilterType, UserRole } from "../../model/commonModel/enums";
import "./index.scss";
import moment from "moment";
import dateFormat from "../../helper/dateFormat";
import AuthView from "../../components/common/AuthView";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";
import usePaginateFetch from "../../utility/hooks/usePaginateFetch";
import { useMyContext } from "../../utility/context/CalendarContext";
import { coachConverter } from "../../utility/helper";
const filters = [
  {
    label: "Kişi",
    color: "warning",
    className: "form-check-warning mb-1",
    filterType: FilterType.Person,
  },
  {
    label: "Grup",
    color: "warning",
    className: "form-check-warning mb-1",
    filterType: FilterType.Group,
  },
  {
    label: "Doğrulananlar",
    color: "success",
    className: "form-check-success mb-1",
    filterType: FilterType.Confirmed,
  },
  {
    label: "Tahsil Edilenler",
    color: "info",
    className: "form-check-info mb-1",
    filterType: FilterType.Encashed,
  },
  {
    label: "Admin Tarafından Tahsil Edilenler",
    color: "primary",
    className: "form-check-primary mb-1",
    filterType: FilterType.EncashedByAdmin,
  },
  {
    label: "Silinenler",
    color: "danger",
    className: "form-check-danger",
    filterType: FilterType.IsDeleted,
  },
];

interface IOption {
  value: string;
  id: number;
  label: string;
}
interface ICoachOption extends IOption {
  avatar: string;
}

const SidebarLeft = (props: any) => {
  const {
    handleAddEventSidebar,
    toggleSidebar,
    programView,
    updateFilters,
    handleChangeTrainers,
    picker,
    setPicker,
  } = props;
  const [coachsSideBarLeft, setCoachsSideBarLeft] = useState<IOption[]>([]);
  const [selectedCalendars, setSelectedCalenders] = useState<string[]>([
    FilterType.Person,
    FilterType.Group,
    FilterType.Confirmed,
    FilterType.Encashed,
    FilterType.EncashedByAdmin,
    FilterType.IsDeleted,
  ]);

  // const calendarPickerRef: any = useRef(null)

  // useEffect(() => {
  //   changeProgramView && calendarPickerRef.current.flatpickr.open()
  // }, [changeProgramView])

  // const fetchCoachs = async () => {
  //   setCoachs([])
  //   getCoachs()
  //     .then((e: ICoachDBModel[]) => {
  //       e.map((coach => {
  //         const newArray: any = [{ ...coachs }, { id: coach.id, value: coach.user.name, label: coach.user.name, avatar: coach.photoURL }]
  //         setCoachs(newArray)
  //       }))
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const { coachs } = useMyContext();

  const fetchCoachs = async () => {
    const data = coachConverter(coachs);
    setCoachsSideBarLeft(data);
  };

  useEffect(() => {
    fetchCoachs();
  }, [coachs]);

  const handleAddEventClick = () => {
    toggleSidebar(false);
    handleAddEventSidebar();
  };

  const updateFilter = (filterType: FilterType) => {
    if (selectedCalendars.includes(filterType)) {
      const newFilterTypes = selectedCalendars.filter(
        (selected) => selected !== filterType
      );
      setSelectedCalenders(newFilterTypes);
    } else {
      setSelectedCalenders([...selectedCalendars, filterType]);
    }
  };

  const updateAllFilter = (option: any) => {
    let selectedFilter: string[] = [];
    if (option.target.checked === true) {
      selectedFilter = [
        FilterType.Person,
        FilterType.Group,
        FilterType.Confirmed,
        FilterType.Encashed,
        FilterType.EncashedByAdmin,
        FilterType.IsDeleted,
      ];
    } else {
      selectedFilter = [];
    }
    setSelectedCalenders(selectedFilter);
  };

  useEffect(() => {
    updateFilters(selectedCalendars);
  }, [selectedCalendars]);

  return (
    <Fragment>
      <div className="sidebar-wrapper">
        <CardBody className="card-body  my-sm-0 mb-3">
          <AuthView roles={[UserRole.Manager, UserRole.Admin]}>
            <Row>
              <Button color="warning" block onClick={handleAddEventClick}>
                <span className="align-middle">Yeni Ekle</span>
              </Button>
            </Row>
          </AuthView>

          {!programView ? (
            <Row className="mt-4">
              <h5 className="section-label mb-1">
                <span className="align-middle">FİLTRE</span>
              </h5>
              <AuthView roles={[UserRole.Manager, UserRole.Admin]}>
                <div className="mb-1">
                  <Select
                    id="trainers"
                    isClearable={false}
                    className="react-select"
                    classNamePrefix="select"
                    isMulti
                    onChange={(option) => handleChangeTrainers(option)}
                    name="trainers"
                    options={coachsSideBarLeft}
                  />
                </div>
              </AuthView>

              <div className="calendar-events-filter">
                <div className="form-check mb-1">
                  <Input
                    id="view-all"
                    type="checkbox"
                    label="Hepsini Göster"
                    className="select-all"
                    checked={selectedCalendars.length === filters.length}
                    onChange={(e) => updateAllFilter(e)}
                  />
                  <Label className="form-check-label" for="view-all">
                    Hepsini Göster
                  </Label>
                </div>
                {filters.length &&
                  filters.map((filter) => {
                    return (
                      <div
                        key={`${filter.label}-key`}
                        className={classnames("form-check", {
                          [filter.className]: filter.className,
                        })}
                      >
                        <Input
                          type="checkbox"
                          key={filter.filterType}
                          label={filter.label}
                          className="input-filter"
                          id={`${filter.filterType}-event`}
                          checked={selectedCalendars.includes(
                            filter.filterType
                          )}
                          onChange={() => {
                            updateFilter(filter.filterType);
                          }}
                        />
                        <Label
                          className="form-check-label"
                          for={`${filter.filterType}-event`}
                        >
                          {filter.label}
                        </Label>
                      </div>
                    );
                  })}
              </div>
            </Row>
          ) : (
            <Row className="mt-3">
              <Flatpickr
                className="form-control calendar-flat"
                value={picker}
                options={{ inline: true }}
                onChange={(date: any) => {
                  setPicker(moment(date[0]).format(dateFormat));
                }}
              />
            </Row>
          )}
        </CardBody>
      </div>
    </Fragment>
  );
};

export default SidebarLeft;
