// ** React Imports
import { Link } from "react-router-dom";

// ** Icons Imports
import { ArrowLeft, Check } from "react-feather";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";

import { useState } from "react";
import ForgotPasswordCard from "../components/ForgotPassword/forgotPasswordCard";
import ForgotPasswordSendMail from "../components/ForgotPassword/forgotPasswordSendMail";

const ForgotPassword = () => {
  const [isSendMail, setIsSendMail] = useState(false);
  return !isSendMail ? <ForgotPasswordCard /> : <ForgotPasswordSendMail />;
};

export default ForgotPassword;
