import { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";
import getUser, { getToken, setUser } from "../../helper/storageHelper";
import { IUserUIModel } from "../../model/UIModel/userUIModel";
import { FaDbModel } from "../../model/DbMdel/usersDBModel";
export const AuthContext = createContext<any>(null);

interface IAuthContext {
  token: string;
  setToken: (token: string) => void;
  userInfo: IUserUIModel | null;
  setUserInfo: (userInfo: IUserUIModel) => void;
  set2fa: (param: FaDbModel) => void;
}

const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState(getToken());
  const [userInfo, setUserInfo] = useState<IUserUIModel | null>(getUser());

  const set2fa = (param: FaDbModel) => {
    const tempData = {
      ...userInfo,
      ...param,
    } as IUserUIModel;

    setUser(tempData);
    setUserInfo(tempData);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        setUserInfo,
        userInfo,
        set2fa,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
AuthProvider.defaultProps = {
  defaultToken: null,
};

export const useAuth = (): IAuthContext => {
  return useContext(AuthContext);
};

export default AuthProvider;
