import { FC, useEffect } from "react";
//@ts-ignore
import AvatarGroup from "@components/avatar-group";
//@ts-ignore
// ** React Imports
import { Fragment, useState } from "react";

// ** Reactstrap Imports
import { Row, Col, Card, CardBody } from "reactstrap";

// ** Third Party Components
import { useForm, Controller } from "react-hook-form";
import { getRolesData } from "../../../services/roles";

interface IRolesCardProps {
  avatarData?: any;
}

const RolesCard: FC<IRolesCardProps> = ({ avatarData }) => {
  // ** States
  const [roles, setRoles] = useState<any[]>([]);

  // ** Hooks
  const {
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { roleName: "" } });

  useEffect(() => {
    const tempRoles = getRolesData();
    setRoles(tempRoles);
  }, []);

  return (
    <Fragment>
      <Row>
        {roles.map((item, index) => {
          return (
            <Col key={index} xl={4} md={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <span>{`Toplam ${item.totalUsers} kullanıcı`}</span>
                    <AvatarGroup data={item.users} />
                  </div>
                  <div className="d-flex justify-content-between align-items-end mt-1 pt-25">
                    <div className="role-heading">
                      <h4 className="fw-bolder">{item.title}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
};

export default RolesCard;
