import http from "../helper/http";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";
import {
  IStudentCreateDBModel,
  IStudentDBModel,
  IStudentEditDBModel,
  IStudentUserDetailModel,
} from "../model/DbMdel/studentDBModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";

interface IGetStudentsDto extends IPagedResultRequest {
  isActive?: boolean;
}

const getStudents = (
  getStudentsDto: IGetStudentsDto
): Promise<PagedResultDto<IStudentDBModel>> => {
  return http.get<any, PagedResultDto<IStudentDBModel>>("/Students", {
    params: getStudentsDto,
  });
};

export const getAllStudentsIncludesChild = (
  getStudentsDto: IGetStudentsDto
): Promise<PagedResultDto<IStudentDBModel>> => {
  return http.get<any, PagedResultDto<IStudentDBModel>>("/Students/GetAllStudentIncludeChild", {
    params: getStudentsDto,
  });
};

export const getParentStudents = (
  getStudentsDto: IGetStudentsDto
): Promise<PagedResultDto<IStudentDBModel>> => {
  return http.get<any, PagedResultDto<IStudentDBModel>>(
    "/Students?IsParent=true",
    {
      params: getStudentsDto,
    }
  );
};

export const createStudent = (
  student: IStudentCreateDBModel
): Promise<IStudentDBModel> => {
  return http.post<any, IStudentDBModel>("/Students", student);
};

export const editStudent = (
  student: IStudentEditDBModel,
  id: number
): Promise<IStudentDBModel> => {
  return http.put<any, IStudentDBModel>(`/Students/${id}`, student);
};

export const getStudentUserDetail = (
  userId: number
): Promise<IStudentUserDetailModel> => {
  return http.get<any, IStudentUserDetailModel>(`/Students/users/${userId}`);
};

export const exportFile = async (data: IExportFileResponse) => {
  return await http.post("/students/export-file", data, {
    responseType: "blob",
  });
};

export default getStudents;
