import { FileText, Grid, Printer, Share, File } from "react-feather";
import { FC, useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
//@ts-ignore
import { downloadExcel } from "react-export-table-to-excel";
//@ts-ignore
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import { get } from "lodash";
import "jspdf-autotable";
import * as XLSX from "xlsx";

interface IExportDropdownProps<T> {
  array: any;
  other?: any;
  className?: string;
  fileName: string;
  title: string;
  sheetName: string;
  headers: {
    label: string;
    key: string;
    onRender?: (item: any, element: T) => any;
  }[];
  labelValueData?: { label: string; value: string }[];
  disabled?: boolean;
  A4Orientation?: "portrait" | "landscape";
  paperSize?: "A4" | "A3" | "A2";
}

const ExportDropdown = <T extends unknown>({
  className,
  headers,
  fileName,
  array,
  title,
  sheetName,
  disabled,
  labelValueData,
  paperSize = "A4",
  A4Orientation = "landscape",
  ...other
}: IExportDropdownProps<T>) => {
  const [newDownloadArray, setNewDownloadArray] = useState<any>([]);

  const arrayConvert = (array: any) => {
    const tempData: any = [];
    array.forEach((element: any) => {
      const tempObject: any = {};
      headers.forEach((e: any) => {
        if (e.onRender) {
          const value = get(element, e.key);
          tempObject[e.key.replace(".", "")] = e.onRender(value, element);
        } else {
          const value = get(element, e.key);
          tempObject[e.key.replace(".", "")] = value;
        }
      });
      tempData.push(tempObject);
    });
    setNewDownloadArray(tempData);
  };

  useEffect(() => {
    if (Array.isArray(array)) {
      arrayConvert(array);
    }
  }, [array]);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: fileName,
      sheet: sheetName,
      tablePayload: {
        header: headers.map((e: any) => e.label),
        body: newDownloadArray,
      },
    });
  }

  function handleDownloadExcelX() {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(newDownloadArray);
    const headerRow = headers.map((header) => header.label);
    XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = paperSize; // Use A1, A2, A3 or A4
    const orientation = A4Orientation; // portrait or landscape

    const marginLeft = 0;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(10);

    const titleY = 10;
    const tableStartY = 50;

    let content = {
      styles: { fontFamily: "Montserrat", fontStyle: "normal" },
      startY: tableStartY,
      head: [headers.map((e: any) => e.label)],
      body: newDownloadArray.map((e: any) => {
        const A = headers
          .map((e2: any) => e[e2.key.replace(".", "")])
          .map((x: any) => {
            if (typeof x === "string") {
              return x
                .replaceAll("ğ", "g")
                .replaceAll("İ", "I")
                .replaceAll("Ö", "O")
                .replaceAll("ö", "o")
                .replaceAll("ü", "u")
                .replaceAll("Ü", "U")
                .replaceAll("Ğ", "G")
                .replaceAll("ı", "i")
                .replaceAll("Ç", "C")
                .replaceAll("ç", "c")
                .replaceAll("Ş", "S")
                .replaceAll("ş", "s");
            } else {
              return x;
            }
          });
        return A;
      }),
    };

    const tableData = [
      (labelValueData || []).reduce((acc: any, item) => {
        acc[item.label] = item.value;
        return acc;
      }, {}),
    ];

    const columnHeaders = (labelValueData || []).map((item) => ({
      key: item.label,
      label: item.label,
    }));

    let content2 = {
      styles: { fontFamily: "Montserrat", fontStyle: "normal" },
      startY: tableStartY,
      head: [columnHeaders.map((e: any) => e.label)],
      body: tableData.map((e: any) => {
        const A = columnHeaders
          .map((e2: any) => e[e2.key.replace(".", "")])
          .map((x: any) => {
            if (typeof x === "string") {
              return x
                .replaceAll("ğ", "g")
                .replaceAll("İ", "I")
                .replaceAll("Ö", "O")
                .replaceAll("ö", "o")
                .replaceAll("ü", "u")
                .replaceAll("Ü", "U")
                .replaceAll("Ğ", "G")
                .replaceAll("ı", "i")
                .replaceAll("Ç", "C")
                .replaceAll("ç", "c")
                .replaceAll("Ş", "S")
                .replaceAll("ş", "s");
            } else {
              return x;
            }
          });
        return A;
      }),
    };

    doc.text(title, 10, titleY);
    //@ts-ignore
    doc.autoTable(content);
    if (labelValueData) {
      //@ts-ignore
      const secondTableStartY = doc.autoTable.previous.finalY + 10;
      //@ts-ignore
      doc.autoTable({ ...content2, startY: secondTableStartY });
    }
    doc.save(`${fileName}.pdf`);
  };

  return (
    <UncontrolledDropdown {...other} className={className}>
      <DropdownToggle disabled={disabled} color="warning" caret outline>
        <Share className="font-small-4 me-50" />
        <span className="align-middle">Export</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem className="w-100">
          <Printer className="font-small-4 me-50" />
          <span className="align-middle">Print</span>
        </DropdownItem>
        <DropdownItem className="w-100">
          <FileText className="font-small-4 me-50" />
          {array && (
            <CSVLink
              data={newDownloadArray}
              separator={";"}
              headers={headers.map((e: any) => e.key.replace(".", ""))}
              filename={`${fileName}.csv`}
              tag="span"
              style={{ color: "unset" }}
            >
              Csv
            </CSVLink>
          )}
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => handleDownloadExcel()}>
          <Grid className="font-small-4 me-50" />
          <span className="align-middle">Excel.xls</span>
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => handleDownloadExcelX()}>
          <Grid className="font-small-4 me-50" />
          <span className="align-middle">Excel.xlsx</span>
        </DropdownItem>
        <DropdownItem
          className="w-100"
          onClick={() => {
            exportPDF();
          }}
        >
          <File className="font-small-4 me-50" />
          <span className="align-middle">PDF</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ExportDropdown;
