import { FC, ReactNode, useEffect, useState } from "react";
import ReactDataTable, { TableColumn } from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { Col, Row, Spinner, TableProps } from "reactstrap";
import { search } from "ss-search";
import { ISalaryDBModel } from "../../../../../model/DbMdel/salaryDBModel";
import { IProps } from "../../types";

const DataTableCSR: FC<IProps | TableProps> = ({
  data,
  columns,
  subHeaderComponent,
  perPage = 10,
  filterText = "",
  pending,
  conditionalRowStyles,
  selectableRowsComponent,
  selectableRows = false,
  onSelectedRowsChange,
  selectableRowSelected = null,
  expandableRows = false,
  expandOnRowClicked = true,
  expandableRowsComponent,
  pagination = true,
  subHeader = true,
  noHeader,
  footerComponent,
  selectableRowDisabled,
  selectableRowsSingle,
  clearSelectedRows,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const filterData = () => {
    if (!data) {
      return [];
    }

    if (data.length && filterText) {
      return search(data, Object.keys(data[0]), filterText);
    }

    return data.slice(currentPage * perPage, (currentPage + 1) * perPage);
  };

  useEffect(() => {
    if (filterText) {
      setCurrentPage(0);
    }
  }, [filterText]);

  const CustomPagination = (e: any) => {
    return (
      <>
        <>{footerComponent}</>
        {pagination && (
          <Row className="d-flex align-items-center">
            <Col>
              <small className=" text-muted m-1 ">
                {data.length} kayıt arasından {(e.currentPage - 1) * perPage} -{" "}
                {e.currentPage * perPage} gösteriliyor.
              </small>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                forcePage={currentPage}
                onPageChange={(page) => setCurrentPage(page.selected)}
                pageCount={e.rowCount / perPage}
                activeClassName="active"
                pageClassName={"page-item"}
                nextLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                containerClassName={
                  "pagination react-paginate justify-content-end py-2 pe-1 pagination-warning"
                }
              />
            </Col>
          </Row>
        )}
      </>
    );
  };
  return (
    <>
      <ReactDataTable
        pagination
        paginationComponent={CustomPagination}
        subHeader={!noHeader && subHeader}
        noHeader={noHeader}
        subHeaderComponent={!noHeader && subHeaderComponent}
        customStyles={{
          progress: {
            style: {
              background: "transparent",
              marginBottom: "10px",
            },
          },
        }}
        columns={columns}
        data={filterData()}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={data?.length}
        progressPending={pending}
        progressComponent={<Spinner color="warning" />}
        paginationDefaultPage={currentPage + 1}
        conditionalRowStyles={conditionalRowStyles}
        selectableRowsComponent={selectableRowsComponent}
        selectableRows={selectableRows}
        selectableRowSelected={selectableRowSelected}
        selectableRowDisabled={selectableRowDisabled}
        selectableRowsSingle={selectableRowsSingle}
        onSelectedRowsChange={(e) => {
          if(onSelectedRowsChange){
            onSelectedRowsChange(e)
          }
        }}
        expandableRowsComponent={expandableRowsComponent}
        expandOnRowClicked={expandOnRowClicked}
        expandableRows={expandableRows}
        noDataComponent={
          <div className="text-center m-2">Kayıt Bulunamadı</div>
        }
        clearSelectedRows={clearSelectedRows}
      />
    </>
  );
};

export default DataTableCSR;
