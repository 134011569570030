import { useEffect, useState } from "react";
import { PagedResultDto } from "../../model/commonModel/pagedResultDto";

interface IPaginationSearchData {
  page: number;
  search: string;
  isActive?: boolean;
}
interface UsePaginateFetchProps {
  callbackService: CallableFunction;
  maxResultCount?: number;
  params?: Object;
  defaultSearchText?: string;
  isActive?: boolean;
  locationId?: number;
  isLocation?: boolean;
}

interface UsePaginateFetch<T> {
  data: PagedResultDto<T>;
  handleSearch: (value: string) => void;
  handlePaginate: (value: number) => void;
  page: number;
  isLoading: boolean;
  refetch: CallableFunction;
  searchValue?: string;
}

const useInfinityPaginateFetch = <T>({
  callbackService,
  maxResultCount = 10,
  params = {},
  defaultSearchText = "",
  isActive,
  locationId,
  isLocation,
}: UsePaginateFetchProps): UsePaginateFetch<T> => {
  const [paginationSearchData, setPaginationSearchData] =
    useState<IPaginationSearchData>({
      page: 0,
      search: defaultSearchText,
    } as IPaginationSearchData);
  const [data, setData] = useState<PagedResultDto<T>>({
    items: [],
    totalCount: 0,
  });
  const [locationIndex, setLocationIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const fetchAsync = async () => {
    setLocationIndex(locationId!);
    const _params = {
      skipCount: paginationSearchData.page * maxResultCount,
      maxResultCount: maxResultCount,
      searched: paginationSearchData.search,
      locationId: locationId,
      isActive: isActive,
      ...params,
    };

    const _data = await callbackService(_params);

    const filteredItems = _data.items.filter((newItem: any) => {
      return !data.items.some(
        (existingItem: any) => existingItem.id === newItem.id
      );
    });

    setData((prevState) => ({
      items: [...prevState.items, ...filteredItems],
      totalCount: _data.totalCount,
    }));
    setIsLoading(false);
  };


  useEffect(() => {
    setData({ items: [], totalCount: 0 });
  }, [locationId]);

  useEffect(() => {
    setIsLoading(true);
    fetchAsync();
  }, [paginationSearchData, locationId]);

  const handleSearch = (value: string) => {
    setPaginationSearchData((prevState) => ({
      ...prevState,
      search: value,
      page: 0,
    }));
  };

  const handlePaginate = (value: number) => {
    if (value !== null && data.items.length < data.totalCount) {
      setPaginationSearchData((prevState) => ({ ...prevState, page: value }));
    }
  };

  return {
    data,
    handleSearch,
    handlePaginate,
    page: paginationSearchData.page,
    isLoading: isLoading,
    refetch: fetchAsync,
    searchValue: paginationSearchData.search,
  };
};

export default useInfinityPaginateFetch;
