// ** React Imports
import { FC, useEffect, useState } from "react";

// ** Third Party Components
import { Users } from "react-feather";

// ** Custom Components
//@ts-ignore
import StatsWithAreaChart from "@components/widgets/stats/StatsWithAreaChart";
import { ISalaryDBModel } from "../../model/DbMdel/salaryDBModel";

interface IProps {
  summaryData: ISalaryDBModel[];
}

const TotalCommission: FC<IProps> = ({ summaryData }: any) => {
  const totalCommission = parseInt(
    summaryData
      .reduce((acc: number, cur: ISalaryDBModel) => {
        return acc + cur.totalPaidCommission;
      }, 0)
      .toFixed(2)
  );

  const options = {
    chart: {
      id: "revenue",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#EA5455"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100],
      },
    },

    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: { show: false },
    },
    animations: {
      enabled: false,
    },
  };
  return (
    <StatsWithAreaChart
      icon={<Users size={21} />}
      color="danger"
      stats={`${totalCommission} TL`}
      statTitle="Toplam Komisyon"
      options={options}
      series={[
        {
          name: "Toplam Komisyon",
          data: summaryData.map(
            (item: ISalaryDBModel) => item.totalPaidCommission
          ),
        },
      ]}
      type="area"
    />
  );
};

export default TotalCommission;
