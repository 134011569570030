// ** Third Party Components

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import "./index.scss";
import { FC } from "react";
import { ICoachGroupByModel } from "../../model/commonModel/groupCoach";

interface IProps {
  groupTrainers: ICoachGroupByModel[];
}

const TrainerBasedChart: FC<IProps> = ({ groupTrainers }) => {

  const tickFormatter = (value: string, index: number) => {
    const limit = 10;
    if (!value) return ''
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}${value.length > limit ? '...' : ''}`;
  };

  return (
    <Card>
      <CardHeader className="flex-sm-row flex-column justify-content-sm-between justify-content-center align-items-sm-center align-items-start mt-1">
        <CardTitle tag="h4">Antrenör Bazlı Grafik</CardTitle>
      </CardHeader>

      <CardBody>
        <div className="d-flex align-items-center flex-wrap mb-4">
          <div className="me-1">
            <span
              className="bullet bullet-sm bullet-bordered me-50"
              style={{ backgroundColor: "#EA5455" }}
            ></span>
            <span className="align-middle me-75">Komisyon</span>
          </div>
          <div className="me-1">
            <span
              className="bullet bullet-sm bullet-bordered me-50"
              style={{ backgroundColor: "#28C76F" }}
            ></span>
            <span className="align-middle me-75">Kâr</span>
          </div>
          {/* <div className="me-1">
            <span
              className="bullet bullet-sm bullet-bordered me-50"
              style={{ backgroundColor: "#28C76F" }}
            ></span>
            <span className="align-middle me-75">Kazanç</span>
          </div> */}
        </div>
        <div className="recharts-wrapper bar-chart" style={{ height: "350px" }}>
          <ResponsiveContainer>
            <BarChart height={300} data={groupTrainers} barSize={15}>
              <CartesianGrid stroke="#4B4B4B" />
              <XAxis textAnchor="middle" dataKey="fullName" fontSize={12} tickFormatter={tickFormatter} />
              <YAxis />
              <Tooltip />
              <Bar dataKey="floatingComissionAmount" stackId="a" fill="#EA5455" name="Günlük Komisyon Tutarı" />
              <Bar 
                dataKey="companyComissionAmount" 
                stackId="a" 
                fill="#28C76F" //ESKİ RENK SARI #FF9F43
                name="Kar"
                radius={[10, 10, 0, 0]} />
              {/* <Bar
                dataKey="dailyEarningAmount"
                stackId="a"
                fill="#28C76F"
                radius={[10, 10, 0, 0]}
                name="Günlük Kazanç Tutarı"
              /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </Card>
  );
};
export default TrainerBasedChart;
