import React, {
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IGroupDBModel } from "../../../../model/DbMdel/groupDBModel";
import getStudents from "../../../../services/students";
import toast from "react-hot-toast";
import LoadingButton from "../../../common/LoadingButton";
import { createGroupStudents } from "../../../../services/groups";
import classnames from "classnames";
import { IGroupStudent } from "../../../../model/DbMdel/IGroupStudent";
import SwalFire from "../../../common/Swal";
import usePaginateFetch from "../../../../utility/hooks/usePaginateFetch";
import { InfiniteScrollDropdown } from "../../../InfiniteScroll";
import { handleFilter } from "../../../../utility/helper";
import useInfinityPaginateFetch from "../../../../utility/hooks/userInfinityPaginateFetch";

interface IGroupCreateDBModelProps {
  setShow: (value: SetStateAction<boolean>) => void;
  show: boolean;
  group: IGroupDBModel;
  onReload: () => void;
}

const GroupAddStudentModal: FC<IGroupCreateDBModelProps> = ({
  setShow,
  show,
  group,
  onReload,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState<IGroupStudent[]>([]);

  const AddStudentSchema = yup.object().shape({
    students: yup
      .array()
      .min(1, {
        value: yup.string().required("'Kişi Seç' seçimi zorunlu alandır."),
        label: yup.string().required("'Kişi Seç' seçimi zorunlu alandır."),
      })
      .required("'Kişi Seç' alanı zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddStudentSchema),
  });

  const {
    data,
    handlePaginate,
    isLoading: groupStudentLoading,
    handleSearch,
    page,
    searchValue,
  } = useInfinityPaginateFetch<IGroupStudent>({
    callbackService: getStudents,
    locationId: group.location.id ?? 0,
  });

  const filterStudent = useMemo<any>(() => {
    if (data.items) {
      return data.items
        .filter(
          (x) =>
            !group.students.some((t: any) => t.id === x.id) && x.user?.isActive
        )
        .map((student: any) => {
          return student;
        });
    }
  }, [data.items, group]);

  const onSubmit = (e: any) => {
    SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup öğrencisini eklemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        setIsLoading(true);

        const groupId = group.id;
        const studentIds = e.students.map((x: any) => x.id as number);
        setIsLoading(true);
        createGroupStudents(groupId, studentIds)
          .then((res) => {
            toast.success("Kişi ekleme başarılı.");
            onReload();
            setShow(false);
            setIsLoading(false);
            reset({
              groupId: "",
              studentIds: [],
            });
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Grup Ekle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row
              tag="form"
              className="gy-1 pt-75 d-flex justify-content-center"
            >
              <Col md={12} xs={12}>
                <Label className="form-label" for="students">
                  Kişi Seç
                </Label>
                <Controller
                  control={control}
                  name="students"
                  render={({ field }) => (
                    <InfiniteScrollDropdown
                      searchValue={searchValue}
                      headers={[
                        "user.name",
                        "user.surname",
                        "user.phoneNumber",
                      ]}
                      data={filterStudent}
                      isLoading={groupStudentLoading}
                      id="students"
                      field={field}
                      totalCount={data.totalCount}
                      isMulti
                      onInputChange={(inputValue) =>
                        handleFilter(inputValue, handleSearch)
                      }
                      onMenuScrollBottom={() => handlePaginate(page + 1)}
                      onChange={(event: any) => {
                        setValue("students", event);
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                {errors.students && (
                  <FormFeedback>
                    {errors.students.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  type="submit"
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Onayla
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShow(false)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default GroupAddStudentModal;
