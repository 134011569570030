// ** React Imports
import { FC, useEffect, useState } from "react";

// ** Third Party Components
import { Users } from "react-feather";

// ** Custom Components
//@ts-ignore
import StatsWithAreaChart from "@components/widgets/stats/StatsWithAreaChart";
import { ISalaryDBModel } from "../../model/DbMdel/salaryDBModel";

interface IProps {
  summaryData: ISalaryDBModel[];
}

const TotalProfit: FC<IProps> = ({ summaryData }: any) => {
  const tolalProfit = parseInt(summaryData.reduce((acc: number, cur: ISalaryDBModel) => {
    return acc + (cur.totalEarnings - cur.totalPaidCommission);
  }, 0)).toFixed(2);

  const options = {
    chart: {
      id: "revenue",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#FF9920"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 80, 100],
      },
    },

    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      x: { show: false },
    },
    animations: {
      enabled: false,
    },
  };

  //TODO:Icon eksik
  return (
    <StatsWithAreaChart
      icon={<Users size={21} />}
      color="warning"
      stats={`${tolalProfit} TL`}
      statTitle="Toplam Kar"
      options={options}
      series={[
        {
          name: `Toplam kar`,
          data: summaryData.map((cur: ISalaryDBModel) => {
            return (cur.totalEarnings - cur.totalPaidCommission).toFixed(2);
          }),
        },
      ]}
      type="area"
    />
  );
};

export default TotalProfit;
