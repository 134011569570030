// ** React Imports
import { Fragment } from "react";

// ** Custom Components
import NavbarUser from "./NavbarUser";
import { Calendar } from "react-feather";
import { NavLink, NavItem } from "reactstrap";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-feather";

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props;

  const navigate = useNavigate();

  const toCalendar = () => {
    navigate("/calendar");
  };

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <NavLink
          className="nav-menu-main menu-toggle hidden-xs is-active"
          onClick={() => toCalendar()}
        >
          <Calendar />
        </NavLink>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
