export enum UserRole {
  Admin = "Admin",
  Manager = "Manager",
  Student = "Student",
  Trainer = "Trainer",
}

export enum FilterType {
  Person = "Person",
  Group = "Group",
  Confirmed = "Confirmed",
  Encashed = "Encashed",
  EncashedByAdmin = "EncashedByAdmin",
  IsDeleted = "Deleted",
}

export enum ActivityType {
  Group = 1,
  Person = 2,
}

export enum CalendarsColor {
  Person = "warning",
  Group = "warning",
  Confirmed = "success",
  Encashed = "info",
  EncashedByAdmin = "primary",
  WaitingToBeEncashed = "warning",
  Deleted = "danger",
}

export enum PaymentType {
  Cash = "Nakit",
  CreditCard = "Kredi Kartı",
  Eft = "Eft",
}

