import "@styles/base/pages/privacy-policy.scss";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <p className="privacy-policy-container">
      <div className="history-button" onClick={() => navigate("/")}>
        {`< Geri dön`}
      </div>
      <br />
      <br />
      Gizlilik Politikası
      <br />
      <br />
      Son güncellenme: 06/12/2023 Güvenliğiniz bizim için önemli. Bu sebeple
      bizimle paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.
      <br />
      <br /> Biz, Cankayak, veri sorumlusu olarak, bu gizlilik ve kişisel
      verilerin korunması politikası ile, hangi kişisel verilerinizin hangi
      amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği,
      veri işleme yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize
      ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı
      amaçlıyoruz.
      <br />
      <br /> Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi{" "}
      <br />
      <br />
      Kimlik, (isim, soy isim, doğum tarihi gibi) iletişim (adres, e-posta
      adresi, telefon, IP, konum gibi),özlük, finans bilgileriniz ile görsel ve
      işitsel kayıtlarınız tarafımızca, çerezler (cookies) vb. teknolojiler
      vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve bazen de
      analitik sağlayıcılar, reklam ağları, arama bilgi sağlayıcıları, teknoloji
      sağlayıcıları gibi üçüncü taraflardan elde edilerek, kaydedilerek,
      depolanarak ve güncellenerek, aramızdaki hizmet ve sözleşme ilişkisi
      çerçevesinde ve süresince, meşru menfaat işleme şartına dayanılarak
      işlenecektir.
      <br />
      <br /> Kişisel Verilerinizin İşlenme Amacı
      <br />
      <br /> Bizimle paylaştığınız kişisel verileriniz; hizmetlerimizden
      faydalanabilmeniz amacıyla sizlerle sözleşmeler kurabilmek, sunduğumuz
      hizmetlerin gerekliliklerini en iyi şekilde ve aramızdaki sözleşmelere
      uygun olarak yerine getirebilmek, bu sözleşmelerden doğan haklarınızın
      tarafınızca kullanılmasını sağlayabilmek, ürün ve hizmetlerimizi,
      ihtiyaçlarınız doğrultusunda geliştirebilmek ve bu gelişmelerden sizleri
      haberdar edebilmek, ayrıca sizleri daha geniş kapsamlı hizmet
      sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan
      doğan zorunlulukların (kişisel verilerin talep halinde adli ve idari
      makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme ve
      hizmet süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve
      güncellenecektir.
      <br />
      <br /> Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla
      Aktarılabileceği
      <br />
      <br /> Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi
      yürütmek üzere hizmet aldığımız ve/veya verdiğimiz, sözleşmesel ilişki
      içerisinde bulunduğumuz, iş birliği yaptığımız, yurt içi ve yurt dışındaki
      3. şahıslar ile kurum ve kuruluşlara ve talep halinde adli ve idari
      makamlara, gerekli teknik ve idari önlemler alınması koşulu ile
      aktarılabilecektir. <br />
      <br /> Kişisel Verileri İşlenen Kişi Olarak Haklarınız <br />
      <br />
      KVKK madde 11 uyarınca herkes, veri sorumlusuna başvurarak aşağıdaki
      haklarını kullanabilir: <br />
      <br />
      Kişisel veri işlenip işlenmediğini öğrenme, Kişisel verileri işlenmişse
      buna ilişkin bilgi talep etme, Kişisel verilerin işlenme amacını ve
      bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
      <br />
      <br /> Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
      kişileri bilme,
      <br />
      <br /> Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
      bunların düzeltilmesini isteme,
      <br />
      <br /> Kişisel verilerin silinmesini veya yok edilmesini isteme, <br />
      <br />
      (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerin
      aktarıldığı üçüncü kişilere bildirilmesini isteme,
      <br />
      <br /> İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
      edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına
      itiraz etme,
      <br />
      <br /> Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
      uğraması hâlinde zararın giderilmesini talep etme,
      <br />
      <br /> haklarına sahiptir. Yukarıda sayılan haklarınızı kullanmak üzere
      emree.smsk@gmail.com veya yunus.meral@cankayak.com üzerinden bizimle
      iletişime geçebilirsiniz.
      <br />
      <br /> İletişim <br />
      <br />
      Sizlere talepleriniz doğrultusunda hizmet sunabilmek amacıyla, sadece
      gerekli olan kişisel verilerinizin, işbu gizlilik ve kişisel verilerin
      işlenmesi politikası uyarınca işlenmesini, kabul edip etmemek hususunda
      tamamen özgürsünüz. Uygulamayı kullanmaya devam ettiğiniz takdirde, kabul
      etmiş olduğunuz tarafımızca varsayılacaktır. Şayet kabul etmiyorsanız,
      lütfen uygulamayı tüm cihazlarınızdan kaldırınız. Ayrıntılı bilgi için
      bizimle emree.smsk@gmail.com veya yunus.meral@cankayak.com e-mail
      adresleri üzerinden iletişime geçmekten lütfen çekinmeyiniz.
    </p>
  );
};

export default PrivacyPolicy;
