import {
  IGroupLocationOptions,
  ILocationOptions,
} from "../model/commonModel/locationOptions";

export const locations: ILocationOptions[] = [
  { id: 1, value: "Dorukkaya", label: "Dorukkaya", price: 1300 },
  { id: 2, value: "Kaya Palazzo", label: "Kaya Palazzo", price: 1500 },
  { id: 3, value: "Golden Key", label: "Golden Key", price: 1300 },
];

export const groupLocations: IGroupLocationOptions[] = [
  { id: 1, value: "Dorukkaya", students: 2, price: 1100 },
  { id: 1, value: "Dorukkaya", students: 3, price: 900 },
  { id: 1, value: "Dorukkaya", students: 4, price: 800 },
  { id: 1, value: "Dorukkaya", students: 5, price: 800 },
  { id: 1, value: "Dorukkaya", students: 6, price: 800 },
  { id: 1, value: "Dorukkaya", students: 7, price: 800 },
  { id: 2, value: "Kaya Palazzo", students: 2, price: 1200 },
  { id: 2, value: "Kaya Palazzo", students: 3, price: 1000 },
  { id: 2, value: "Kaya Palazzo", students: 4, price: 900 },
  { id: 2, value: "Kaya Palazzo", students: 5, price: 900 },
  { id: 2, value: "Kaya Palazzo", students: 6, price: 900 },
  { id: 2, value: "Kaya Palazzo", students: 7, price: 900 },
  { id: 3, value: "Golden Key", students: 2, price: 1100 },
  { id: 3, value: "Golden Key", students: 3, price: 900 },
  { id: 3, value: "Golden Key", students: 4, price: 800 },
  { id: 3, value: "Golden Key", students: 5, price: 800 },
  { id: 3, value: "Golden Key", students: 6, price: 800 },
  { id: 3, value: "Golden Key", students: 7, price: 800 },
];
