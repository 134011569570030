import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
//@ts-ignore
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import { selectThemeColors } from "../../../utility/Utils";
import {
  ICoachDBModel,
  IEditCoachDBModel,
} from "../../../model/DbMdel/coachDBModel";
import { ILanguage } from "../../../model/commonModel/language";
import { editCoachs } from "../../../services/coach";
import toast from "react-hot-toast";
import LoadingButton from "../../common/LoadingButton";
import classnames from "classnames";
//@ts-ignore
import Avatar from "@components/avatar";
import SwitchLabel from "../../common/SwitchLabel";
import SwalFire from "../../common/Swal";
import ImageUploadButton from "../../common/ImageUploadButton";
import { imageTypes, maxSize } from "../../../helper/uploadImageHelper";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import phone from "phone";

interface ICoachCreateModalProps {
  setShowEdit: (value: SetStateAction<boolean>) => void;
  showEdit: boolean;
  coach: ICoachDBModel;
  languages: ILanguage[];
  onReload: () => void;
}

const CoachEditModal: FC<ICoachCreateModalProps> = ({
  setShowEdit,
  showEdit,
  languages,
  coach,
  onReload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const enum optionsEnum {
    Active = "aktif",
    Passive = "pasif",
  }

  const statusOptions = [
    { value: optionsEnum.Active, label: "Aktif" },
    { value: optionsEnum.Passive, label: "Pasif" },
  ];

  const AddCoachSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "İsim zorunlu alandır.")
      .max(64, "İsim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir isim giriniz")
      .required("İsim zorunlu alandır"),
    surname: yup
      .string()
      .min(2, "Soyisim zorunlu alandır.")
      .max(64, "Soyisim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir soyisim giriniz")
      .required("Soyisim zorunlu alandır."),
    emailAddress: yup
      .string()
      .email("Geçerli bir e-mail adresi giriniz")
      .required("E-mail zorunlu alandır."),
    phoneNumber: yup
      .string()
      .test("matches", "Geçerli bir numara giriniz", (str) => {
        const pn = phone(str!);
        return pn.isValid;
      })
      .required("Telefon numarası zorunludur"),
    isActive: yup.string().notRequired(),
    isPanelAccess: yup.boolean().notRequired(),
    languages: yup
      .array()
      .min(1, "En az bir dil seçiniz.")
      .required("Dil zorunlu alandır."),
    comission: yup
      .number()
      .min(1, "0 dan büyük olmalıdır.")
      .max(100, "Maksimum %100 olmalıdır.")
      .required("Komisyon zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddCoachSchema),
    defaultValues: {
      name: coach.user.name,
      surname: coach.user.surname,
      emailAddress: coach.user.emailAddress,
      phoneNumber: coach.user.phoneNumber,
      isActive: coach.user.isActive,
      photo: coach.user.photo,
      languages: coach.user.languages.map((x: ILanguage) => {
        return { value: x.id, label: x.name };
      }),
      comission: coach.comission,
      isPanelAccess: coach.user.isPanelAccess,
    },
  });

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const handleSwal = async (e: any) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Antrenör bilgilerini düzenlemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        onSubmit(e);
      },
    });
  };

  const onSubmit = (e: any) => {
    const data: IEditCoachDBModel = {
      userDto: {
        name: e.name,
        surname: e.surname,
        emailAddress: e.emailAddress,
        isActive: e.isActive,
        languageIds: e.languages.map((l: any) => l.value),
        phoneNumber: phone(e.phoneNumber).phoneNumber ?? "",
        isPanelAccess: e.isPanelAccess,
        photo: e.photo,
      },
      id: coach.id,
      photoURL: coach.photoURL,
      comission: parseInt(e.comission),
    };

    setIsLoading(true);

    editCoachs(data, coach.id)
      .then((res) => {
        toast.success("Antrenör Düzenleme başarılı");
        onReload();
        setIsLoading(false);
        setShowEdit(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShowEdit(!showEdit)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Antrenör Düzenle</h1>
            {coach.user.photo ? (
              <img
                height="76px"
                width="76px"
                alt="coach-avatar"
                src={coach.user.photo}
                className="img-fluid rounded-circle mt-2 mb-2"
              />
            ) : (
              <Avatar
                className="me-1"
                color={"light-warning"}
                content={getSummaryName(
                  `${coach.user.name} ${coach.user.surname}`
                )}
                contentStyles={{
                  fontSize: "20px",
                  width: "100%",
                  height: "100%",
                }}
                title={coach.user.name}
                style={{
                  height: "76px",
                  width: "76px",
                }}
              />
            )}
          </div>
          <Form>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        maxLength={64}
                        placeholder="İsim"
                        invalid={errors.name && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="surname">
                  Soyisim
                </Label>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="surname"
                      type="text"
                      maxLength={64}
                      placeholder="Soyisim"
                      invalid={errors.surname && true}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    />
                  )}
                />
                {errors.surname && (
                  <FormFeedback>
                    {errors.surname.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="emailAddress">
                  E-mail
                </Label>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="emailAddress"
                      placeholder="mail@mail.com"
                      type="email"
                      maxLength={320}
                      invalid={errors.emailAddress && true}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    />
                  )}
                />
                {errors.emailAddress && (
                  <FormFeedback>
                    {errors.emailAddress.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="phoneNumber">
                  Telefon
                </Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputComponent
                      invalid={errors.phoneNumber && true}
                      {...field}
                      {...register("phoneNumber")}
                      register={register}
                      onChange={() => {}}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <FormFeedback>
                    {errors.phoneNumber.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="status">
                  Durum
                </Label>
                <Select
                  id="status"
                  isClearable={false}
                  className="react-select"
                  classNamePrefix="select"
                  options={statusOptions}
                  theme={selectThemeColors}
                  defaultValue={
                    getValues("isActive") ? statusOptions[0] : statusOptions[1]
                  }
                  onChange={(event) => {
                    setValue(
                      "isActive",
                      event?.value === optionsEnum.Active ? true : false
                    );
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="language">
                  Dil(ler)
                </Label>
                <Controller
                  control={control}
                  name="languages"
                  render={({ field }) => (
                    <Select
                      id="languages"
                      isClearable={false}
                      isMulti
                      classNamePrefix="select"
                      options={languages.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      defaultValue={getValues("languages")}
                      className={classnames("react-select", {
                        "is-invalid": errors.languages,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.languages && (
                  <FormFeedback>
                    {(errors.languages as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="comission">
                  Komisyon
                </Label>

                <InputGroup
                  className={classnames("input-group-merge", {
                    "is-invalid": errors.comission,
                  })}
                >
                  <InputGroupText
                    className={classnames({
                      "is-invalid": errors.comission,
                    })}
                  >
                    %
                  </InputGroupText>

                  <Controller
                    name="comission"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="comission"
                        placeholder=""
                        type="number"
                        invalid={errors.comission && true}
                      />
                    )}
                  />
                </InputGroup>

                {errors.comission && (
                  <FormFeedback>
                    {errors.comission.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="file">
                  Fotoğraf Yükle
                </Label>
                <div>
                  <Controller
                    name="photo"
                    control={control}
                    render={({ field }) => {
                      return (
                        <ImageUploadButton
                          {...field}
                          id="photo"
                          onChange={(e: any) => {
                            if (e.length > 0) {
                              if (e[0].file.size > maxSize) {
                                toast.error(
                                  "Resminiz yüklenirken bir hata oluştu. Boyutu 10 KB'ın altında olan geçerli bir resim dosyası olduğundan emin olun."
                                );
                                field.onChange("");
                                return;
                              }

                              if (!imageTypes.includes(e[0].file.type)) {
                                toast.error(
                                  "Resminiz yüklenirken bir hata oluştu. Resim dosyasının formatı desteklenmemektedir."
                                );
                                field.onChange("");
                                return;
                              }
                              field.onChange(e[0].dataURL);
                            } else {
                              field.onChange("");
                            }
                          }}
                          images={watch("photo")}
                        />
                      );
                    }}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center">
                  <div className="form-switch">
                    <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="switch"
                            id="isPanelAccess"
                            defaultChecked={watch("isPanelAccess")}
                            onChange={(event) => {
                              setValue("isPanelAccess", event.target.checked);
                            }}
                          />
                        );
                      }}
                    />
                    <SwitchLabel htmlFor={"isPanelAccess"} />
                  </div>
                  <Label
                    className="form-check-label fw-bolder"
                    htmlFor="isPanelAccess"
                  >
                    Panele Erişebilir
                  </Label>
                </div>
              </Col>

              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(handleSwal)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShowEdit(!showEdit)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CoachEditModal;
