import DataTable from "../../../common/DataTable";
import { Input, Row, Col, Card, Button } from "reactstrap";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import {
  deleteGroup,
  getGroups,
  exportFile,
} from "../../../../services/groups";
import { FC, useEffect, useState } from "react";
import { Edit, Plus, Trash2, Users } from "react-feather";
import { IGroupDBModel } from "../../../../model/DbMdel/groupDBModel";
import GroupCreateModal from "./groupCreateModal";
import GroupEditModal from "./groupEditModal";
import { useNavigate } from "react-router";
import AvatarGroup from "../../../../@core/components/avatar-group";
import { UserRole } from "../../../../model/commonModel/enums";
import AuthView from "../../../common/AuthView";
import SwalFire from "../../../common/Swal";
import usePaginateFetch from "../../../../utility/hooks/usePaginateFetch";
import debounce from "lodash.debounce";
import ExportDropdownFile from "../../../common/ExportDropdownFile";
import moment from "moment";
import dateFormat from "../../../../helper/dateFormat";
import { defaultCount, options } from "../../../common/DataTable/Constants";
import { removeSpecialCharacters } from "../../../../utility/helper";

interface IProps {
  refetchToggle: boolean;
}

const GroupListTable: FC<IProps> = ({ refetchToggle }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const navigate = useNavigate();

  const addStudentClick = (id: IGroupDBModel["id"]) => {
    navigate("/group-add-student/" + id);
  };

  const getSummaryName = (str: string) => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<IGroupDBModel>({
      callbackService: getGroups,
      maxResultCount: maxResultCount,
    });

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
    setFilter(value);
  }, 500);
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [maxResultCount, refetchToggle]);

  const handleDelete = async (id: IGroupDBModel) => {
    const result = SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup listesini silmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: async () => {
        await deleteGroup(+id);
        refetch();
      },
    });
  };

  const caseInsensitiveSort = (rowA: IGroupDBModel, rowB: IGroupDBModel) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "GRUP İSMİ",
      sortable: true,
      sortField: "name",
      sortFunction: caseInsensitiveSort,
      selector: (row: IGroupDBModel) => {
        const name = row.name;
        return (
          <div
            className="d-flex justify-content-left align-items-center"
            style={{ maxWidth: "120px" }}
          >
            <div className="d-flex flex-column">
              <h6 className="user-name text-truncate mb-0">{name}</h6>
              <small className="text-truncate text-muted mb-0">{"Grup"}</small>
            </div>
          </div>
        );
      },
    },
    {
      name: "LOKASYON",
      sortField: "location",
      maxWidth: "200px",
      selector: (row: IGroupDBModel) => row.location?.name,
    },
    {
      name: "KİŞİ BAŞINA FİYAT",
      sortField: "price",
      selector: (row: IGroupDBModel) => row.perUserAndHourPrice,
    },
    {
      name: "KİŞİLER",
      sortField: "students",
      selector: (row: IGroupDBModel) => {
        return (
          <AvatarGroup
            data={row.students.map((student) => {
              return {
                content: getSummaryName(student.user.name),
                title: removeSpecialCharacters(`${student.user.name} ${student.user.surname}`),
              };
            })}
          />
        );
      },
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      selector: (row: IGroupDBModel) => (
        <div>
          <Button
            size="sm"
            style={{
              cursor: "pointer",
              width: "26px",
              height: "26px",
              position: "relative",
              marginRight: "4px",
            }}
            color="success"
            onClick={() => addStudentClick(row.id)}
          >
            <Users
              size={18}
              style={{ position: "absolute", top: "3px", left: "5px" }}
            />
          </Button>
          <Button
            size="sm"
            style={{
              cursor: "pointer",
              width: "26px",
              height: "26px",
              position: "relative",
              marginRight: "4px",
            }}
            color="warning"
            onClick={() => setSelectedIndex(row.id)}
          >
            <Edit
              size={18}
              style={{ position: "absolute", top: "3px", left: "5px" }}
            />
          </Button>
          <AuthView roles={[UserRole.Admin, UserRole.Manager]}>
            <Button
              size="sm"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                position: "relative",
              }}
              color="danger"
              onClick={() => handleDelete(row.id as unknown as IGroupDBModel)}
            >
              <Trash2
                size={18}
                style={{ position: "absolute", top: "3px", left: "5px" }}
              />
            </Button>
          </AuthView>
        </div>
      ),
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start  p-0 px-md-1  px-lg-2 "
        >
          <p>Gruplar Listesi</p>
        </Col>

        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center p-0 px-md-1  px-lg-2  justify-content-md-end gap-2"
        >
          {/* <ExportDropdown<IGroupDBModel>
            className="me-1"
            array={[]}
            fileName="Grup Listesi"
            sheetName="Grup_Listesi"
            headers={[
              {
                key: "name",
                label: "GRUP ISMI",
                onRender: (item: string, element) => {
                  return `${element.name}`;
                },
              },
              {
                key: "description",
                label: "GRUP ACIKLAMASI",
                onRender: (item: string, element) => {
                  return `${element.description}`;
                },
              },
              {
                key: "perUserAndHourPrice",
                label: "KISI BASINA FIYAT",
                onRender: (item: string, element) => {
                  return `${element.perUserAndHourPrice}`;
                },
              },

              {
                key: "students",
                label: "KISILER",
                onRender: (item: string, element) => {
                  return `${element.students.map(
                    (s) => s.user.name + " " + s.user.surname
                  )}`;
                },
              },
            ]}
            title={""}
          /> */}
          <ExportDropdownFile
            callbackService={exportFile}
            fileName={`gruplar_listesi_${moment(new Date()).format(
              dateFormat
            )}`}
            headers={[
              "Name",
              "Description",
              "PerUserAndHourPrice",
              "PersonList",
            ]}
          />
          <Button color="warning" onClick={() => setShow(true)}>
            <Plus style={{ marginRight: "8px" }} size={14} />
            Grup Ekle
          </Button>
          <GroupCreateModal
            setShow={() => setShow(!show)}
            show={show}
            onReload={async () => {
              setSelectedIndex(-1);
              await refetch();
            }}
          />
          {data.items && selectedIndex > -1 && (
            <GroupEditModal
              setShowEdit={() => setSelectedIndex(-1)}
              showEdit={selectedIndex !== -1}
              group={data.items.find((x) => x.id === selectedIndex)!}
              onReload={async () => {
                setSelectedIndex(-1);
                await refetch();
              }}
            />
          )}
        </Col>
      </Row>

      <Row className="d-flex pb-0 pt-2 justify-content-end justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">Göster:</label>
          <Input
            defaultValue={maxResultCount}
            type="select"
            onChange={(e) => setMaxResultCount(parseInt(e.target.value))}
          >
            {options?.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </Col>
        <Col lg="4" md="6" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search">
            Ara:
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.SSR
            totalCount={data.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={data.items}
            pending={isLoading}
            pagination
            onChangePaginate={handlePaginate}
            havePaginate
            isFilter={filter}
            pageNumber={page}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default GroupListTable;
