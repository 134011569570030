import { FC, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ExportFileType } from "./types";
import { exportTypes } from "./consts";
import moment from "moment";
import { Share } from "react-feather";

interface ExportDropdownFileProps {
  callbackService: CallableFunction;
  fileName: string;
  headers: string[];
}

const ExportDropdownFile: FC<ExportDropdownFileProps> = ({
  callbackService,
  fileName,
  headers,
}) => {
  const [isDropdownOpen, setDropdownStatus] = useState<boolean>(false);
  const toggle = () => setDropdownStatus((prevState) => !prevState);

  const dropdownClickAction = async (exportType: ExportFileType) => {
    const response = await callbackService({
      exportType: exportType.name,
      columns: headers,
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(new Blob([response]));
    link.download = `${fileName}.${
      exportType.extension
    }`;
    link.click();
  };

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
        <DropdownToggle caret outline color="warning">
          <Share className="font-small-4 me-50" />
          Export
        </DropdownToggle>
        <DropdownMenu>
          {exportTypes.map((exportType: ExportFileType, index) => (
            <DropdownItem
              className="w-100"
              key={index}
              onClick={() => dropdownClickAction(exportType)}
            >
              {exportType.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ExportDropdownFile;
