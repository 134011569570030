// ** Utils
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
// ** Custom Components
import { yupResolver } from "@hookform/resolvers/yup";

// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Label,
  FormFeedback,
  Input,
} from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { ResetPasswordService } from "../services/passwords";
import toast from "react-hot-toast";
import { IResetPasswordModel } from "../model/DbMdel/passwordDBModel";
import React from "react";
import { ArrowLeft } from "react-feather";
import LoadingButton from "../components/common/LoadingButton";
const defaultValues = {
  password: "",
  confirmPassword: "",
};
const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const SignupSchema = yup.object().shape({
    password: yup.string().required("Şifre girmek zorunludur."),
    // .matches(
    //   /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
    //   "Şifre en az 8 Karakter, Bir Büyük Harf, Bir Küçük Harf, Bir Sayı ve Bir Özel Durum Karakteri İçermelidir"
    // ),
    confirmPassword: yup
      .string()
      .required("Şifre girmek zorunludur.")
      .oneOf([yup.ref(`password`), null], "Şifreler aynı olmalıdır."),
  });

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema),
  });

  const email = query.get("email");
  const token = query.get("token")?.split(' ').join('+');

  const onSubmit = (e: any) => {
    setIsLoading(true);
    const data: IResetPasswordModel = {
      token: token!,
      newPassword: e.password,
      emailAddress: email!,
    };

    ResetPasswordService(data)
      .then((res) => {
        toast.success("Şifre sıfırlama işlemi başarılı");
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <p className="backToLogin">
            <Link to="/login">
              <ArrowLeft className="rotate-rtl me-25" size={14} />
              <span className="align-middle">Giriş Yap</span>
            </Link>
          </p>
          <CardBody className="mb-5 mt-1">
            <CardTitle tag="h4" className="mb-1">
              Şifreyi Sıfırla 🔐
            </CardTitle>
            <CardText className="mb-2">
              Yeni şifrenizi aşağıdaki alanlardan belirleyebilirsiniz.
            </CardText>
            <Form
              className="auth-reset-password-form mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-1">
                <Label className="form-label" for="password">
                  Yeni Şifre
                </Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="input-group-merge"
                      id="new-password"
                      type="password"
                      invalid={errors.password && true}
                      {...field}
                      placeholder="Şifre"
                      autoFocus
                    />
                  )}
                />
                {errors.password && (
                  <FormFeedback>
                    {errors.password.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              <div className="mb-1">
                <Label className="form-label" for="confirmPassword">
                  Yeni Şifreyi Tekrarlayın
                </Label>

                <Controller
                  control={control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <Input
                      className="input-group-merge"
                      id="confirmPassword"
                      placeholder="Şifre"
                      type="password"
                      invalid={errors.confirmPassword && true}
                      {...field}
                    />
                  )}
                />
                {errors.confirmPassword && (
                  <FormFeedback>
                    {errors.confirmPassword.message?.toString()}
                  </FormFeedback>
                )}
              </div>

              <LoadingButton loading={isLoading}
                disabled={!isDirty || !isValid}
                color="warning"
                block
                type="submit">
                Yeni Şifremi Ayarla
              </LoadingButton>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
