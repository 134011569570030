import Breadcrumbs from "../../../@core/components/breadcrumbs";
import CoachListTransactions from "../../../components/transactions/coach/coachListTable";
const Coach = () => {
  return (
    <>
    <Breadcrumbs
        title="Antrenör"
        data={[{ title: "İşlemler" }, { title: "Antrenör" }]}
      />
      <div>
        <CoachListTransactions/>
      </div>
    </>
  );
};

export default Coach;
