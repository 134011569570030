// ** React Imports
import { FC, Fragment, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap";

// ** Custom Components

// ** Third Party Components
//@ts-ignore
import { useForm, Controller } from "react-hook-form";

interface EditTransactionAuthorizationModal {
  setShow: (e: boolean) => void;
  show: boolean;
  onSubmit: any;
}

const EditTransactionAuthorizationModal: FC<
  EditTransactionAuthorizationModal
> = ({ show,  setShow }) => {
  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={() => setShow(show)}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody>
          <>
            <div className="text-center mb-2">
              <h1 className="mb-1"> İşlem Yetkisi İsmini Düzenle</h1>
              <p>
                İşlem yetkisi ismini kendi gereksinimlerinize göre
                düzenleyebilirsiniz.
              </p>
            </div>
            <Row tag={Form} onSubmit={handleSubmit(() => {})}>
              <Col xs={12} sm={9}>
                <Label className="form-label" for="permission-name">
                  Yetki İsmi
                </Label>
                <Controller
                  control={control}
                  name="permissionName"
                  render={({ field }) => (
                    <Input
                      placeholder="Yetki ismini girin"
                      invalid={errors.permissionName && true}
                      {...field}
                    />
                  )}
                />
                {errors && errors.permissionName && (
                  <FormFeedback>
                    Lütfen geçerli bir yetki ismi girin
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} sm={3} className="p-sm-0">
                <Button className="mt-2 mb-4" color="warning">
                  Düzenle
                </Button>
              </Col>
            </Row>
          </>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EditTransactionAuthorizationModal;
