import { InfiniteScrollDropdown } from "../InfiniteScroll";
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
} from "react-hook-form";
import { FormFeedback } from "reactstrap";
import { ILocationMethodDBModel } from "../../model/DbMdel/locationMethodDbModel";
import { getAllLocationMethodService } from "../../services/locationMethod";
import useInfinityPaginateFetch from "../../utility/hooks/userInfinityPaginateFetch";
import { handleFilter } from "../../utility";
import { ActionMeta, SingleValue } from "react-select";
import "./index.scss"

interface ILocationDropdownModel {
  onChange?:
    | ((newValue: SingleValue<any>, actionMeta: ActionMeta<any>) => void)
    | undefined;
  defaultValue?: any;
  control: any;
  errorLocation?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  invalid?: boolean;
}

const LocationDropdown: React.FC<ILocationDropdownModel> = ({
  onChange,
  defaultValue,
  control,
  errorLocation,
  invalid,
}) => {
  const {
    data: locationData,
    handlePaginate: locationPaginate,
    isLoading: locationLoading,
    handleSearch: locationSearch,
    page: locationPage,
    searchValue: locationSearchValue,
  } = useInfinityPaginateFetch<ILocationMethodDBModel>({
    callbackService: getAllLocationMethodService,
    isActive: true,
    isLocation: true,
  });

  return (
    <div className="location-dropdown">
      <Controller
        name="location"
        control={control}
        render={({ field }) => (
          <InfiniteScrollDropdown
            onChange={onChange}
            searchValue={locationSearchValue}
            headers={["name"]}
            data={locationData.items}
            isLoading={locationLoading}
            id="location"
            field={field}
            totalCount={locationData.totalCount}
            defaultValue={defaultValue}
            onInputChange={(inputValue) =>
              handleFilter(inputValue, locationSearch)
            }
            onMenuScrollBottom={() => locationPaginate(locationPage + 1)}
            invalid={invalid}
          />
        )}
      />
      {errorLocation && (
        <FormFeedback className="form-feedback">
          {errorLocation.message?.toString()}
        </FormFeedback>
      )}
    </div>
  );
};

export default LocationDropdown;
