import { Button, Modal as ReactstrapModal } from "reactstrap";
import "./style.scss";
import { ModalProps } from "./types";

const ConfirmationModal: React.FunctionComponent<ModalProps> = ({
  loading,
  open,
  draggedActivityInfo,
  onMove,
  onClose,
  onCopy,
}) => {

  const name: string =
    draggedActivityInfo.activity.group?.name ??
    `${draggedActivityInfo.activity.students[0].user.name} ${draggedActivityInfo.activity.students[0].user.surname}`;

  const isEncashed: boolean =
    draggedActivityInfo.activity.days[0].students.every(
      (s) => s.isEncashed === true
    );
  return (
    <ReactstrapModal
      isOpen={open}
      toggle={onClose}
      className="modal-dialog-centered modal-lg dragged-activity-modal"
    >
      <div className="modal-body">
        <div className="swal2-icon swal2-warning swal2-icon-show custom-swal-icon">
          <div className="swal2-icon-content justify-content-center">!</div>
        </div>
        <div className="header">Emin misiniz?</div>
        <div className="subheader">
          <span className="importance">{name}</span> dersini kopyalamak
          {!isEncashed && " ya da taşımak"} üzeresiniz.
          <br /> İşleme devam etmek istiyor musunuz?
        </div>
        <div className="buttons">
          <Button
            disabled={loading}
            className="btn btn-warning"
            onClick={onCopy}
          >
            Kopyala
          </Button>
          {!isEncashed && (
            <Button
              disabled={loading}
              className="btn btn-warning"
              onClick={onMove}
            >
              Taşı
            </Button>
          )}
          <Button
            className="btn-outline-danger"
            color="link"
            onClick={onClose}
            variant="outlined"
          >
            İptal
          </Button>
        </div>
      </div>
    </ReactstrapModal>
  );
};

export default ConfirmationModal;
