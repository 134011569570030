const getHex = (color: string) =>
  window.getComputedStyle(document.body).getPropertyValue(color).trim();

//** Colors obj
const obj = {
  primary: {
    light: getHex("--bs-primary").concat("1a"),
    main: getHex("--bs-primary"),
  },
  secondary: {
    light: getHex("--bs-secondary").concat("1a"),
    main: getHex("--bs-secondary"),
  },
  success: {
    light: getHex("--bs-success").concat("1a"),
    main: getHex("--bs-success"),
  },
  danger: {
    light: getHex("--bs-danger").concat("1a"),
    main: getHex("--bs-danger"),
  },
  warning: {
    light: getHex("--bs-warning").concat("1a"),
    main: getHex("--bs-warning"),
  },
  info: {
    light: getHex("--bs-info").concat("1a"),
    main: getHex("--bs-info"),
  },

};

const getColor = (colorName: string) => {
  //@ts-ignore
  return obj[colorName].main;
};

const getRandomColor = () => {
  const colors = Object.keys(obj);
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return getColor(randomColor);
};

export default getRandomColor;
