import { Input, Row, Col, Card, Button } from "reactstrap";
import "@styles/react/apps/app-invoice.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";
import {
  deleteGroupPrice,
  exportFilePrice,
  getGroupPrice,
} from "../../../../services/groups";
import { FC, useEffect, useState } from "react";
import { CreditCard, Edit, Trash2 } from "react-feather";
import { IGroupPriceDBModel } from "../../../../model/DbMdel/groupDBModel";
import GroupPriceCreateModal from "./groupPriceCreateModal";
import GroupPriceEditModal from "./groupPriceEditModal";
import AuthView from "../../../common/AuthView";
import { UserRole } from "../../../../model/commonModel/enums";
import SwalFire from "../../../common/Swal";
import usePaginateFetch from "../../../../utility/hooks/usePaginateFetch";
import debounce from "lodash.debounce";
import ExportDropdownFile from "../../../common/ExportDropdownFile";
import DataTable from "../../../common/DataTable/components/ssr";
import moment from "moment";
import dateFormat from "../../../../helper/dateFormat";
import { defaultCount, options } from "../../../common/DataTable/Constants";
import toast from "react-hot-toast";

interface IProps {
  handleRefetch: () => void;
}

const GroupPriceListTable: FC<IProps> = ({ handleRefetch }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);

  const { data, handlePaginate, isLoading, handleSearch, refetch, page } =
    usePaginateFetch<IGroupPriceDBModel>({
      callbackService: getGroupPrice,
      maxResultCount: maxResultCount,
    });

  const debouncedSearch = debounce((value) => {
    handleSearch(value);
    setFilter(value);
  }, 500);
  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [maxResultCount]);

  const handleDelete = (id: IGroupPriceDBModel) => {
    return SwalFire({
      title: "Emin misiniz?",
      text: "Bir grup fiyat listesini silmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: async () => {
        await deleteGroupPrice(+id).catch((err)=>toast.error(err));
        refetch();
        handleRefetch();
      },
    });
  };

  const caseInsensitiveSort = (
    rowA: IGroupPriceDBModel,
    rowB: IGroupPriceDBModel
  ) => {
    const a = rowA.name.toLowerCase();
    const b = rowB.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "GRUP FİYAT BAŞLIĞI",
      sortable: true,
      sortField: "name",
      sortFunction: caseInsensitiveSort,
      selector: (row: IGroupPriceDBModel) => {
        const name = row.name;
        return (
          <div className="d-flex justify-content-left align-items-center">
            <div className="d-flex flex-column">
              <h6 className="user-name text-truncate mb-0">{name}</h6>
            </div>
          </div>
        );
      },
    },
    {
      name: "KİŞİ SAYISI",
      sortField: "maxusercount",
      selector: (row: IGroupPriceDBModel) => row.maxUserCount,
    },
    {
      name: "KİŞİ BAŞINA DÜŞEN FİYAT",
      sortField: "peruserprice",
      selector: (row: IGroupPriceDBModel) => row.perUserAndHourPrice,
    },
    {
      name: "LOKASYON",
      sortField: "location",
      selector: (row: IGroupPriceDBModel) => row.location?.name,
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      selector: (row: IGroupPriceDBModel) => (
        <div>
          <Button
            size="sm"
            style={{
              cursor: "pointer",
              width: "26px",
              height: "26px",
              position: "relative",
              marginRight: "4px",
            }}
            color="warning"
            onClick={() => setSelectedIndex(row.id)}
          >
            <Edit
              size={18}
              style={{ position: "absolute", top: "3px", left: "5px" }}
            />
          </Button>
          <AuthView roles={[UserRole.Manager]}>
            <Button
              size="sm"
              style={{
                cursor: "pointer",
                width: "26px",
                height: "26px",
                position: "relative",
                marginRight: "4px",
              }}
              color="danger"
              onClick={() =>
                handleDelete(row.id as unknown as IGroupPriceDBModel)
              }
            >
              <Trash2
                size={18}
                style={{ position: "absolute", top: "3px", left: "5px" }}
              />
            </Button>
          </AuthView>
        </div>
      ),
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start  p-0 px-md-1  px-lg-2 "
        >
          <p>Grup Fiyatları Listesi</p>
        </Col>
        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center  p-0 px-md-1  px-lg-2  justify-content-md-end gap-1"
        >
          <ExportDropdownFile
            callbackService={exportFilePrice}
            fileName={`grup_fiyat_listesi_${moment(new Date()).format(
              dateFormat
            )}`}
            headers={["Name", "MaxUserCount", "PerUserAndHourPrice"]}
          />
          <Button color="warning" onClick={() => setShow(true)}>
            <CreditCard style={{ marginRight: "9px" }} size={16} />
            Grup Fiyatı Oluştur
          </Button>
          <GroupPriceCreateModal
            setShow={() => setShow(!show)}
            show={show}
            onReload={async () => {
              setSelectedIndex(-1);
              await refetch();
              handleRefetch();
            }}
          />
          {data.items && selectedIndex > -1 && (
            <GroupPriceEditModal
              setShowEdit={() => setSelectedIndex(-1)}
              showEdit={selectedIndex !== -1}
              group={data.items.find((x) => x.id === selectedIndex)!}
              onReload={async () => {
                setSelectedIndex(-1);
                await refetch();
                handleRefetch();
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">Göster:</label>
          <Input
            defaultValue={maxResultCount}
            type="select"
            onChange={(e) => setMaxResultCount(parseInt(e.target.value))}
          >
            {options?.map((option: number) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </Col>
        <Col lg="4" md="6" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search">
            Ara:
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable
            totalCount={data.totalCount}
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={data.items}
            pending={isLoading}
            pagination
            onChangePaginate={handlePaginate}
            pageNumber={page}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default GroupPriceListTable;
