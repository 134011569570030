import { User, UserCheck } from "react-feather";
import { Col, Row } from "reactstrap";
import Breadcrumbs from "../../../@core/components/breadcrumbs";
import StatsHorizontal from "../../../@core/components/widgets/stats/StatsHorizontal";
interface IUsersHeadTableProps {
  count: number;
  activeCount: number;
}

const UsersHeadBar: React.FC<IUsersHeadTableProps> = ({
  count,
  activeCount,
}) => {
  return (
    <div>
      <Breadcrumbs
        title="Kullanıcılar"
        data={[{ title: "Kullanıcılar ve İzinler" }, { title: "Kullanıcılar" }]}
      />
      <Row>
        <Col lg="6" sm="6">
          <StatsHorizontal
            color="warning"
            statTitle="Toplam Kullanıcı"
            icon={<User size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">{count}</h3>}
          />
        </Col>

        <Col lg="6" sm="6">
          <StatsHorizontal
            color="success"
            statTitle="Aktif Kullanıcı"
            icon={<UserCheck size={20} />}
            renderStats={<h3 className="fw-bolder mb-75">{activeCount}</h3>}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UsersHeadBar;
