import http from "../helper/http";
import { IPagedResultRequest } from "../model/commonModel/pagedFilterAndSortedRequest";
import { PagedResultDto } from "../model/commonModel/pagedResultDto";

import {
  FaDbModel,
  IUsersCreateModel,
  IUsersEditModel,
  IUsersModel,
} from "../model/DbMdel/usersDBModel";
import { IExportFileResponse } from "../model/UIModel/groupsUIModel";

interface IGetUsersDto extends IPagedResultRequest {}

const getUsers = (
  getUsersDto: IGetUsersDto
): Promise<PagedResultDto<IUsersModel>> => {
  return http.get<any, PagedResultDto<IUsersModel>>("/Users", {
    params: getUsersDto,
  });
};

export const getUserProfile = (id: number): Promise<IUsersModel> => {
  return http.get<any, IUsersModel>(`/Users/${id}`);
};

export const createUsers = (
  users: IUsersCreateModel
): Promise<PagedResultDto<IUsersModel>> => {
  return http.post<any, PagedResultDto<IUsersModel>>("/Users", users);
};

export const editUsers = (
  users: IUsersEditModel,
  id: number
): Promise<IUsersModel> => {
  return http.put<any, IUsersModel>(`/Users/${id}/Role`, users);
};

export const update2fa = (param: boolean): Promise<FaDbModel> => {
  return http.post<any, FaDbModel>(`/Users/2fa/${param}`);
};

export const editUser = (
  user: IUsersEditModel,
  id: number
): Promise<IUsersModel> => {
  return http.put<any, IUsersModel>(`/Users/${id}`, user);
};

export const exportFile = async (data: IExportFileResponse) => {
  return await http.post("/users/export-file", data, { responseType: "blob" });
};

export default getUsers;
