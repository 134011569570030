export const getRolesData = () => {
  return [
    {
      totalUsers: 4,
      title: "Antrenör",
      users: [
        {
          size: "sm",
          title: "Vinnie Mostowy",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Allen Rieske",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Julee Rossignol",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Kaith Dsouza",
          img: require("@src/assets/img/1.png").default,
        },
      ],
    },
    {
      totalUsers: 7,
      title: "Yönetici",
      users: [
        {
          size: "sm",
          title: "Jimmy Ressula",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "John Doe",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Kristi Lawker",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Kaith D",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Danny Paul",
          img: require("@src/assets/img/1.png").default,
        },
      ],
    },
    {
      totalUsers: 5,
      title: "Öğrenci",
      users: [
        {
          size: "sm",
          title: "Andrew Tye",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Rishi Swaat",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Rossie Kim",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Kim Merchent",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Sam Dsouza",
          img: require("@src/assets/img/1.png").default,
        },
      ],
    },
    {
      totalUsers: 3,
      title: "Admin",
      users: [
        {
          size: "sm",
          title: "Kim Karlos",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Katy Turner",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Peter Adward",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "Kaith Dsouza",
          img: require("@src/assets/img/1.png").default,
        },
        {
          size: "sm",
          title: "John Parker",
          img: require("@src/assets/img/1.png").default,
        },
      ],
    },
  ];
};

export const getNameAndRolTempdata = () => {
  return [
    { name: "Şirket bilgilerini görüntüleme", role: ["admin", "student"] },
    { name: "Şirket bilgilerini düzenleme", role: ["manager"] },
    { name: "Rapor görüntüleme", role: ["student"] },
    { name: "Takvim gün onayı verme", role: ["coach"] },
    { name: "Ders bilgilerini ekleme", role: ["manager"] },
    { name: "Ders bilgilerini silme", role: ["admin"] },
    { name: "Öğrenci bilgilerini görüntüleme", role: ["student"] },
    { name: "Öğrenci bilgilerini düzenleme", role: ["coach"] },
    { name: "Öğrenci bilgilerini ekleme", role: ["admin"] },
    { name: "Grup bilgilerini ekleme", role: ["student"] },
  ];
};
