import React, { useEffect, useState } from "react";
import { Eye, FileText, Plus, Share, Trash2 } from "react-feather";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import Breadcrumbs from "../../../../@core/components/breadcrumbs";
import { IGroupDBModel } from "../../../../model/DbMdel/groupDBModel";
import { IGroupStudent } from "../../../../model/DbMdel/IGroupStudent";
import { deleteGroupUser, getGroupStudent } from "../../../../services/groups";
import ExportDropdown from "../../../common/ExportDropdown";
import SwalFire from "../../../common/Swal";
import GroupAddStudentModal from "./groupAddStudentModal";
import DataTableCSR from "./../../../common/DataTable/components/csr/index";

const GroupAddStudent = () => {
  const [group, setGroup] = useState<IGroupDBModel | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const otherIdVariable = Number(id);

  const fetchStudents = async () => {
    setGroup(null);
    await getGroupStudent(otherIdVariable)
      .then((e: any) => {
        setGroup(e);
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
      });
  };

  const fetchGroupStudents = async () => {
    getGroupStudent(otherIdVariable)
      .then((res: any) => {
        setGroup(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchGroupStudents();
  }, []);

  const navigate = useNavigate();
  const toGroups = () => {
    navigate("/transactions/groups");
  };

  const handleDelete = (id: IGroupDBModel) => {
    SwalFire({
      title: "Emin misiniz?",
      text: "Bu kişiyi gruptan çıkartmak üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: (e: any) => {
        deleteGroupUser(group?.id!, +id)
          .then(() => fetchGroupStudents())
          .catch((err) => toast.error(err));
      },
      onCancel: (e: any) => {
        console.log("cancel");
      },
    });
  };

  const caseInsensitiveSort = (rowA: IGroupStudent, rowB: IGroupStudent) => {
    const a = rowA.user.name.toLowerCase();
    const b = rowB.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "KİŞİ İSİM SOYİSİM",
      sortable: true,
      sortField: "name",
      sortFunction: caseInsensitiveSort,
      selector: (row: IGroupStudent) => row.user.name + " " + row.user.surname,
    },
    {
      name: "KİŞİ E-MAİL",
      sortField: "lessons",
      selector: (row: IGroupStudent) => row.user.emailAddress,
    },
    {
      name: "KİŞİ GSM",
      sortField: "percent",
      selector: (row: IGroupStudent) => row.user.phoneNumber,
    },
    {
      name: "AKSİYONLAR",
      sortField: "actions",
      selector: (row: IGroupStudent) => (
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            style={{
              cursor: "pointer",
              width: "26px",
              height: "26px",
              position: "relative",
            }}
            color="danger"
            onClick={() => handleDelete(row.id as unknown as IGroupDBModel)}
          >
            <Trash2
              size={18}
              style={{ position: "absolute", top: "3px", left: "5px" }}
            />
          </Button>
          <Link
            style={{ marginLeft: "10px" }}
            to={`/usersandpermissions/users/profile-detail/${row.user.id}`}
          >
            <Eye size={18} className="font-medium-3 text-body" />
          </Link>
        </div>
      ),
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row className="d-flex pb-2 ">
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start  p-0 px-md-1  px-lg-2 "
        >
          <p>
            {group?.name}{" "}
            <span onClick={toGroups} style={{ cursor: "pointer" }}>
              Listesi
            </span>
          </p>
        </Col>
        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center  p-0 px-md-1  px-lg-2  justify-content-md-end "
        >
          <ExportDropdown<IGroupDBModel>
            className="me-1"
            array={group?.students}
            fileName="Grup Kişi Listesi"
            sheetName="Grup_Kisi_Listesi"
            headers={[
              {
                key: "user.name",
                label: "OGRENCI ISMI",
                onRender: (item: string, element) => {
                  //@ts-ignore
                  return `${element.user.name + " " + element.user.surname}`;
                },
              },
              {
                key: "email",
                label: "OGRENCI MAIL ADRESI",
                onRender: (item: string, element) => {
                  //@ts-ignore
                  return `${element.user.emailAddress}`;
                },
              },
              {
                key: "phone",
                label: "OGRENCI GSM",
                onRender: (item: string, element) => {
                  //@ts-ignore
                  return `${element.user.phoneNumber}`;
                },
              },
            ]}
            title={""}
          />
          <Button color="warning" onClick={() => setShow(true)}>
            <Plus style={{ marginRight: "8px" }} size={14} />
            Yeni Kişi Ekle
          </Button>
          {show && (
            <GroupAddStudentModal
              setShow={() => setShow(!show)}
              show={show}
              group={group!}
              onReload={() => {
                setSelectedIndex(-1);
                fetchStudents();
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Breadcrumbs
        title={group?.name!}
        data={[
          { title: "İşlemler" },
          { title: "Grup", link: "/transactions/groups" },
          { title: "Grup Kişi Detay" },
        ]}
      />
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTableCSR
            subHeaderComponent={ToDetails()}
            columns={columns as any}
            data={group?.students!}
            pending={isLoading}
            totalCount={group?.students.length}
          />
        </div>
      </Card>
    </div>
  );
};

export default GroupAddStudent;
