import { FC } from "react";
import { Button, ButtonProps, Spinner } from "reactstrap";

interface IProps extends ButtonProps {
  loading: boolean;
}
const LoadingButton: FC<IProps> = (props: any) => {
  return (
    <Button {...props} disabled={props.loading}>
      {props.loading ? (
        <>
          <Spinner color="white" size="sm" />
          <span className="ms-50">Loading...</span>
        </>
      ) : (
        props.children
      )}
    </Button>
  );
};

export default LoadingButton;
