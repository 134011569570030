import {
  TrendingUp,
  Target,
  Calendar,
  FileText,
  Circle,
  Filter,
  Grid,
  User,
  Dribbble,
  Users,
  Shield,
} from "react-feather";
import MoneyIcon from "../../assets/img/moneyIcon";


export default [
  {
    id: "payment",
    title: "Tahsilat Takibi",
    icon: <TrendingUp size={20} />,
    navLink: "/payment",
    tag: "payment",
  },
  {
    id: "plans",
    title: "Planlama Takibi",
    icon: <Target size={20} />,
    navLink: "/plans",
  },
  {
    id: "calendar",
    title: "Takvim",
    icon: <Calendar size={20} />,
    navLink: "/calendar",
  },
  {
    id: "transactions",
    title: "İşlemler",
    children: [
      {
        id: "personFilter",
        title: "Çanak",
        icon: <Filter size={20} />,
        navLink: "/transactions/person-filter",
      },
      {
        id: "coach",
        title: "Antrenör",
        icon: <Dribbble size={20} />,
        navLink: "/transactions/coach",
      },
      {
        id: "students",
        title: "Öğrenciler",
        icon: <User size={20} />,
        navLink: "/transactions/students",
      },
      {
        id: "groups",
        title: "Grup",
        icon: <Grid size={20} />,
        navLink: "/transactions/groups",
      },
    ],
  },
  {
    id: "usersandpermissions",
    title: "Kullanıcılar ve İzinler",
    children: [
      {
        id: "users",
        title: "Kullanıcılar",
        icon: <Users size={20} />,
        navLink: "/usersandpermissions/users",
      },
      {
        id: "roles",
        title: "Roller",
        icon: <Shield size={20} />,
        navLink: "/usersandpermissions/roles",
      },
    ],
  },
  {
    id: "systemsettings",
    title: "Sistem Ayarları",
    children: [
      {
        id: "paymentMethods",
        title: "Ödeme Yöntemi",
        icon: <MoneyIcon />,
        navLink: "/systemsettings/paymentmethods",
      },
    ],
  },
];
