import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import { selectThemeColors } from "../../../utility/Utils";
import { ILanguage } from "../../../model/commonModel/language";
import LoadingButton from "../../common/LoadingButton";
import classnames from "classnames";
import { IStudentCreateDBModel } from "../../../model/DbMdel/studentDBModel";
import { createStudent } from "../../../services/students";
import toast from "react-hot-toast";
import FlatPickr from "react-flatpickr";
import SwitchLabel from "../../common/SwitchLabel";
import moment from "moment";
import dateFormat from "../../../helper/dateFormat";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import LocationDropdown from "../../locationForPage";
import phone from "phone";
import { Check, Trash2 } from "react-feather";

interface IStudentCreateModalProps {
  setShow: (param: any) => void;
  show: boolean;
  languages: ILanguage[];

  onReload?: () => void;
  parentId?: number;
  isFastCreateModal?: boolean;
}

interface IConnectedStudent {
  name: string;
  surname: string;
}

const StudentCreateModal: FC<IStudentCreateModalProps> = ({
  setShow,
  show,
  languages,
  onReload,
  parentId,
  isFastCreateModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connectedStudents, setConnectedStudents] = useState<
    IConnectedStudent[]
  >([]);

  const statusOptions = [
    { value: true, label: "Aktif" },
    { value: false, label: "Pasif" },
  ];

  const AddStudentSchema = yup.object().shape({
    emailAddress: yup.string().when("parentId", {
      is: (parentId: string) => {
        return parentId === undefined;
      },
      then: yup
        .string()
        .email("Geçerli bir email adresi giriniz.")
        .notRequired(),
    }),
    name: yup
      .string()
      .min(2, "İsim zorunlu alandır")
      .max(64, "İsim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir isim giriniz")
      .required("İsim zorunlu alandır"),
    surname: yup
      .string()
      .min(2, "Soyisim zorunlu alandır")
      .max(64, "Soyisim en fazla 64 karakter olmalıdır")
      .matches(/^[A-Za-zÇçĞğIıİiÖöŞşÜü\s]+$/, "Geçerli bir soyisim giriniz")
      .required("Soyisim zorunlu alandır."),
    phoneNumber: yup
      .string()
      .test("matches", "Geçerli bir numara giriniz", (str) => {
        if (str!.length > 5) {
          const pn = phone(str!);
          return pn.isValid;
        }
        return true;
      })
      .when("parentId", {
        is: (parentId: string) => {
          return parentId === undefined;
        },
        then: yup.string().notRequired(),
      }),
    status: yup.string().notRequired(),
    location: yup
      .object()
      .shape({
        id: yup.number().required(),
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .required("Lokasyon alanı zorunlu alandır."),
    languages: yup
      .array()
      .min(1, "En az bir dil seçiniz.")
      .required("Dil zorunlu alandır."),
    serviceAreaEntryDate: yup.date().nullable().notRequired(),
    serviceAreaExitDate: yup
      .date()
      .nullable()
      .when(
        "serviceAreaEntryDate",
        (serviceAreaEntryDate, schema) =>
          serviceAreaEntryDate &&
          schema.min(
            serviceAreaEntryDate,
            "Otelden çıkış tarihi giriş tarihinden önce olamaz"
          )
      )
      .notRequired(),
    perHourPrice: yup
      .number()
      .min(1, "0 dan büyük olmalıdır.")
      .required("Fiyat zorunlu alandır."),
    description: yup.string(),
    isPanelAccess: yup.boolean(),
    parentId: yup.number(),
    isConnectedStudent: yup.boolean(),
    connectedStudentName: yup.string(),
    connectedStudentSurname: yup.string(),
  });

  const {
    control,
    handleSubmit,
    register,
    getValues,
    reset,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddStudentSchema),
    defaultValues: {
      isActive: { value: true, label: "Aktif" },
      isPanelAccess: true,
      isConnectedStudent: false,
      languages: [{ value: 1, label: "Türkçe" }],
      parentId: parentId,
      perHourPrice: 0,
    } as any,
  });

  useEffect(() => {
    setValue("parentId", parentId);
  }, [parentId]);

  const onSubmit = async (e: any) => {
    try {
      if (
        e.isConnectedStudent &&
        (e.connectedStudentName || e.connectedStudentSurname)
      ) {
        throw new Error(
          "Lütfen eklemiş olduğunuz bağlantılı öğrencilerinizi kaydettiğinizden emin olunuz. Bağlantılı öğrenci formunu düzenlendikten sonra aynı işlemi tekrardan deneyiniz"
        );
      }
      const data: IStudentCreateDBModel = {
        userDto: {
          emailAddress: e.emailAddress,
          name: e.name,
          surname: e.surname,
          phoneNumber: phone(e.phoneNumber).phoneNumber ?? "",
          languageIds: e.languages.map((l: any) => l.value),
          isActive: e.isActive.value,
          isPanelAccess: e.isPanelAccess,
          photo: "",
        },
        studentId: parentId ? parentId : null,
        description: e.description,
        serviceAreaEntryDate: e.serviceAreaEntryDate
          ? moment(e.serviceAreaEntryDate).format(dateFormat)
          : null,
        serviceAreaExitDate: e.serviceAreaExitDate
          ? moment(e.serviceAreaExitDate).format(dateFormat)
          : null,
        perHourPrice: parseInt(e.perHourPrice),
        locationId: e.location.id,
      };

      if (parentId && !e.emailAddress) {
        //@ts-ignore
        delete data.userDto.emailAddress;
      }
      setIsLoading(true);

      const response = await createStudent(data);
      toast.success("Öğrenci Ekleme başarılı");

      if (e.isConnectedStudent && connectedStudents.length) {
        for (const student of connectedStudents) {
          const payload: IStudentCreateDBModel = {
            ...data,
            userDto: {
              ...data.userDto,
              ...student,
              emailAddress: "",
              phoneNumber: "",
            },
            studentId: response.id,
          };
          await createStudent(payload);
          toast.success("Bağlantılı Öğrenci Ekleme başarılı");
        }
      }
      setShow({ parentId: undefined, mode: "none" });
      onReload && onReload();
      setIsLoading(false);
      reset({
        name: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        isPanelAccess: false,
        isConnectedStudent: false,
        price: "",
        description: "",
        locationId: {},
      });
    } catch (err: any) {
      toast.error(err.message || err);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShow(!show);
    reset();
    clearErrors();
  };

  useEffect(() => {
    const price = getValues("location.price");
    setValue("perHourPrice", price);
  }, [watch("location")]);

  useEffect(() => {
    const email = watch("emailAddress");
    const phoneNumber = watch("phoneNumber");

    if (!email || !phoneNumber) {
      setValue("isPanelAccess", false);
    }
  }, [watch("emailAddress"), watch("phoneNumber")]);

  const handleAddConnectedStudent = () => {
    const newStudent: IConnectedStudent = {
      name: getValues("connectedStudentName"),
      surname: getValues("connectedStudentSurname"),
    };
    if (newStudent.name && newStudent.surname) {
      setConnectedStudents((prevstate) => [...prevstate, newStudent]);
      setValue("connectedStudentName", "");
      setValue("connectedStudentSurname", "");
    }
  };

  const handleDeleteConnectedStudent = (index: number) => {
    const updatedConnectedStudents = [...connectedStudents];
    updatedConnectedStudents.splice(index, 1);
    setConnectedStudents(updatedConnectedStudents);
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={handleCancel}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleCancel}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">
              {parentId ? "Bağlantılı" : ""} Öğrenci Ekle
            </h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={12} xs={12}>
                <Label>Lokasyon</Label>
                <LocationDropdown
                  control={control}
                  onChange={(event) => setValue("location", event)}
                />
              </Col>

              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("name").ref}
                        id="name"
                        type="text"
                        maxLength={64}
                        invalid={errors.name && true}
                        placeholder="İsim"
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="lastName">
                  Soyisim
                </Label>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("surname").ref}
                        id="lastName"
                        type="text"
                        maxLength={64}
                        invalid={errors.surname && true}
                        placeholder="Soyisim"
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.surname && (
                  <FormFeedback>
                    {errors.surname.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="email">
                  Email
                </Label>
                <Controller
                  name="emailAddress"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="email"
                        id="emailAddress"
                        maxLength={320}
                        name="email"
                        innerRef={register("emailAddress").ref}
                        invalid={errors.emailAddress && true}
                        placeholder="mail@mail.com"
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.emailAddress && (
                  <FormFeedback>
                    {errors.emailAddress.message?.toString()}
                  </FormFeedback>
                )}
              </Col>

              <Col md={6} xs={12}>
                <Label className="form-label" for="phoneNumber">
                  Telefon
                </Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInputComponent
                      invalid={errors.phoneNumber && true}
                      {...field}
                      {...register("phoneNumber")}
                      register={register}
                      onChange={() => {}}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <FormFeedback>
                    {errors.phoneNumber.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="enter">
                  Otele Giriş Tarihi
                </Label>
                <Controller
                  name="serviceAreaEntryDate"
                  control={control}
                  render={({ field }) => (
                    <FlatPickr
                      placeholder="GG/AA/YYYY"
                      disabled={false}
                      id="serviceAreaEntryDate"
                      style={{ opacity: "1" }}
                      options={{
                        closeOnSelect: true,
                        static: true,
                      }}
                      className={classnames("form-control", {
                        "is-invalid": errors.serviceAreaEntryDate,
                      })}
                      {...field}
                      onChange={(e) => {
                        if (e.length > 0) {
                          field.onChange(e[0]);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      ref={register("serviceAreaEntryDate").ref}
                    />
                  )}
                />
                {errors.serviceAreaEntryDate && (
                  <FormFeedback>
                    {errors.serviceAreaEntryDate.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="enter">
                  Otelden Çıkış Tarihi
                </Label>
                <Controller
                  name="serviceAreaExitDate"
                  control={control}
                  render={({ field }) => (
                    <FlatPickr
                      placeholder="GG/AA/YYYY"
                      disabled={false}
                      style={{ opacity: "1" }}
                      id="serviceAreaExitDate"
                      options={{
                        static: true,
                        closeOnSelect: true,
                      }}
                      readOnly={false}
                      className={classnames("form-control", {
                        "is-invalid": errors.serviceAreaExitDate,
                      })}
                      {...field}
                      onChange={(e) => {
                        if (e.length > 0) {
                          field.onChange(e[0]);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      ref={register("serviceAreaExitDate").ref}
                    />
                  )}
                />
                {errors.serviceAreaExitDate && (
                  <FormFeedback>
                    {errors.serviceAreaExitDate.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="status">
                  Durum
                </Label>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        id="isActive"
                        className="react-select"
                        classNamePrefix="select"
                        theme={selectThemeColors}
                        defaultValue={true}
                        options={statusOptions}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.isActive && (
                  <FormFeedback>
                    {errors.isActive.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="language">
                  Dil(ler)
                </Label>
                <Controller
                  control={control}
                  name="languages"
                  render={({ field }) => (
                    <Select
                      id="languages"
                      isClearable={false}
                      isMulti
                      classNamePrefix="select"
                      options={languages.map((x) => {
                        return { value: x.id, label: x.name };
                      })}
                      defaultValue={languages[1]}
                      className={classnames("react-select", {
                        "is-invalid": errors.languages,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.languages && (
                  <FormFeedback>
                    {" "}
                    {errors.languages.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="perHourPrice">
                  Fiyat
                </Label>

                <InputGroup>
                  <Controller
                    name="perHourPrice"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          disabled
                          name="perHourPrice"
                          innerRef={register("locations.price").ref}
                          id="perHourPrice"
                          value={getValues("perHourPrice")}
                          invalid={errors.perHourPrice && true}
                          defaultValue={getValues("perHourPrice")}
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.preventDefault()
                          }
                        />
                      );
                    }}
                  />
                  <InputGroupText>TL</InputGroupText>
                </InputGroup>

                {errors.perHourPrice && (
                  <FormFeedback style={{ display: "inline" }}>
                    {errors.perHourPrice.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Label className="form-label" for="price">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        type="textarea"
                        {...field}
                        maxLength={1000}
                        name="description"
                        innerRef={register("description").ref}
                        id="description"
                        style={{ maxHeight: "100px", minHeight: "63px" }}
                        invalid={errors.description && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-between">
                  {isFastCreateModal && (
                    <div className="d-flex align-items-center justify-content-center">
                      <Controller
                        name="isConnectedStudent"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="form-switch">
                              <Input
                                defaultChecked={false}
                                type="switch"
                                id="isConnectedStudent"
                                checked={watch("isConnectedStudent")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                }}
                              />
                              <SwitchLabel htmlFor={"isConnectedStudent"} />
                            </div>
                          );
                        }}
                      />

                      <Label
                        className="form-check-label fw-bolder"
                        htmlFor="billing-switch"
                      >
                        Bağlantılı öğrencisi var
                      </Label>
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-center">
                    <Controller
                      name="isPanelAccess"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className="form-switch">
                            <Input
                              defaultChecked={false}
                              type="switch"
                              id="isPanelAccess"
                              disabled={
                                parentId !== undefined &&
                                !(watch("phoneNumber") && watch("emailAddress"))
                              }
                              checked={watch("isPanelAccess")}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                              }}
                            />
                            <SwitchLabel htmlFor={"isPanelAccess"} />
                          </div>
                        );
                      }}
                    />
                    <Label
                      className="form-check-label fw-bolder"
                      htmlFor="billing-switch"
                    >
                      Panele erişebilir
                    </Label>
                  </div>
                </div>
              </Col>
              {getValues("isConnectedStudent") && (
                <div className="connected-students">
                  {connectedStudents.map((student, index) => (
                    <Row key={index} className="d-flex gy-1 pt-75">
                      <Col md={6} xs={12}>
                        <Label
                          className="form-label"
                          for={`connectedStudentName-${index}`}
                        >
                          Bağlantılı Öğrenci İsim
                        </Label>
                        <Input
                          disabled
                          id={`connectedStudentName-${index}`}
                          type="text"
                          maxLength={64}
                          placeholder="İsim"
                          value={student.name}
                        />
                      </Col>
                      <Col md={6} xs={11}>
                        <Label
                          className="form-label"
                          for={`connectedStudentSurname-${index}`}
                        >
                          Bağlantılı Öğrenci Soyisim
                        </Label>
                        <div className="d-flex gap-1 position-relative">
                          <Input
                            disabled
                            id={`connectedStudentSurname-${index}`}
                            type="text"
                            maxLength={64}
                            placeholder="Soyisim"
                            value={student.surname}
                          />
                          <Button
                            className="position-absolute end-0 start-100 translate-end-x"
                            style={{
                              width: "min-content",
                              height: "100%",
                              marginLeft: "8px",
                            }}
                            size="sm"
                            color="danger"
                            onClick={() => {
                              handleDeleteConnectedStudent(index);
                            }}
                          >
                            <Trash2 size={16} />
                          </Button>
                        </div>
                      </Col>
                      <div className="d-flex flex-column-reverse px-0"></div>
                    </Row>
                  ))}
                  <Row className="gy-1 pt-75">
                    <Col md={6} xs={12}>
                      <Label
                        className="form-label"
                        for="connectedStudentSurname"
                      >
                        Bağlantılı Öğrenci İsim
                      </Label>
                      <Controller
                        name="connectedStudentName"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="d-flex gap-1 position-relative">
                              <Input
                                {...field}
                                innerRef={register("connectedStudentName").ref}
                                id="connectedStudentName"
                                type="text"
                                maxLength={64}
                                placeholder="İsim"
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.preventDefault()
                                }
                                invalid={
                                  watch("connectedStudentSurname") &&
                                  !field.value
                                }
                              />
                              <FormFeedback className="position-absolute top-100 translate-end-x">
                                İsim zorunlu alandır
                              </FormFeedback>
                              <Button
                                className="position-absolute end-100 translate-end-x"
                                style={{
                                  width: "min-content",
                                  height: "100%",
                                  marginRight: "8px",
                                }}
                                size="sm"
                                color="success"
                                onClick={handleAddConnectedStudent}
                              >
                                <Check size={16} />
                              </Button>
                            </div>
                          );
                        }}
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <Label
                        className="form-label"
                        for="connectedStudentSurname"
                      >
                        Bağlantılı Öğrenci Soyisim
                      </Label>
                      <Controller
                        name="connectedStudentSurname"
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="d-flex gap-1 position-relative">
                              <Input
                                {...field}
                                innerRef={
                                  register("connectedStudentSurname").ref
                                }
                                id="connectedStudentSurname"
                                type="text"
                                maxLength={64}
                                placeholder="Soyisim"
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.preventDefault()
                                }
                                invalid={
                                  watch("connectedStudentName") && !field.value
                                }
                              />
                              <Button
                                className="position-absolute end-0 start-100 translate-end-x"
                                style={{
                                  width: "min-content",
                                  height: "100%",
                                  marginLeft: "8px",
                                }}
                                size="sm"
                                color="warning"
                                onClick={() => {
                                  setValue("connectedStudentName", "");
                                  setValue("connectedStudentSurname", "");
                                }}
                              >
                                <Trash2 size={16} />
                              </Button>
                              <FormFeedback className="position-absolute top-100 translate-end-x">
                                Soyisim zorunlu alandır
                              </FormFeedback>
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Onayla
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default StudentCreateModal;
