import { UserRole } from "../model/commonModel/enums";
import { getUserRole } from "./storageHelper";

export enum PermissionsList {
  //İŞLEMLER
  POOL_VIEW = "POOL_VIEW",
  STUDENT_VIEW = "STUDENT_VIEW",
  TRAINER_VIEW = "TRAINER_VIEW",
  GROUP_VIEW = "GROUP_VIEW",

  //Dashboard
  PLANS_DASHBOARD_VIEW = "PLANS_DASHBOARD_VIEW",
  PAYMENT_DASHBOARD_VIEW = "PAYMENT_DASHBOARD_VIEW",
  CALENDAR_VIEW = "CALENDAR_VIEW",

  //kullanıcı ve roller
  USERS_VIEW = "USERS_VIEW",
  ROLES_VIEW = "ROLES_VIEW",
}

const AdminPermission: PermissionsList[] = [
  PermissionsList.POOL_VIEW,
  PermissionsList.STUDENT_VIEW,
  PermissionsList.TRAINER_VIEW,
  PermissionsList.GROUP_VIEW,
];
const ManagerPermission: PermissionsList[] = [
  PermissionsList.POOL_VIEW,
  PermissionsList.STUDENT_VIEW,
  PermissionsList.TRAINER_VIEW,
  PermissionsList.GROUP_VIEW,
  PermissionsList.PAYMENT_DASHBOARD_VIEW,
  PermissionsList.PLANS_DASHBOARD_VIEW,
  PermissionsList.CALENDAR_VIEW,
  PermissionsList.USERS_VIEW,
  PermissionsList.ROLES_VIEW,
];

const TrainerPermission: PermissionsList[] = [PermissionsList.CALENDAR_VIEW];
const StudentPermission: PermissionsList[] = [];

const checkPermission = (permissionName: PermissionsList) => {

  return AdminPermission.includes(permissionName);
};

export const checkRoles = (roles: UserRole[]) => {
  const user = getUserRole();

  if (user) {
    return roles.includes(user);
  }
  return false;
};

export default checkPermission;
