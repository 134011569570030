// ** React Imports
import { FC, Fragment, SetStateAction, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  Input,
  Label,
  ModalBody,
  ModalHeader,
  FormFeedback,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
// ** Utils
//@ts-ignore
import { selectThemeColors } from "@utils";

// ** Styles
//@ts-ignore
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/base/pages/user-info.scss";
import classnames from "classnames";
import LoadingButton from "../../common/LoadingButton";

import {
  IUsersEditModel,
  IUsersModel,
} from "../../../model/DbMdel/usersDBModel";
import { editUsers } from "../../../services/user";
import toast from "react-hot-toast";
import SwalFire from "../../common/Swal";
import { usePageContext } from "../../../utility/context/UserContext";

interface IEditUserModalProps {
  setShowEdit: (value: SetStateAction<boolean>) => void;
  showEdit: boolean;
  onReload: () => void;
  user: IUsersModel;
}

const EditUserModal: FC<IEditUserModalProps> = ({
  setShowEdit,
  showEdit,
  onReload,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = usePageContext();

  const rolesOptions: any = [
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
  ];

  const AddUsersSchema = yup.object().shape({
    roles: yup.object().required("Rol zorunlu alandır."),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AddUsersSchema),
    defaultValues: {
      roles: user.roles.map((x) => {
        return { value: x.id, label: x.name };
      }),
    },
  });

  const onSubmit = async (e: any) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Kullanıcının rolünü değiştirmek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        const data: IUsersEditModel = {
          roleNames: [e.roles.label],
        };
        const id = user.id;
        setIsLoading(true);
        editUsers(data, id)
          .then((res) => {
            toast.success("Kullanıcı ekleme başarılı");
            onReload();
            setIsLoading(false);
            reset({
              roles: [],
            });
            refetch();
          })
          .catch((err) => {
            toast.error(err);
            setIsLoading(false);
          });
      },
    });
  };
  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShowEdit(!showEdit)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 pt-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Kullanıcı Bilgilerini Düzenle</h1>
            <p>
              Kullanıcı ayrıntılarının güncellenmesi bir gizlilik denetimi
              alacaktır.
            </p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-75">
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="roles">
                  Roller
                </Label>
                <Controller
                  control={control}
                  name="roles"
                  render={({ field }) => (
                    <Select
                      id="roles"
                      isClearable={false}
                      classNamePrefix="select"
                      options={rolesOptions}
                      className={classnames("react-select", {
                        "is-invalid": errors.roles,
                      })}
                      theme={selectThemeColors}
                      {...field}
                    />
                  )}
                />
                {errors.roles && (
                  <FormFeedback>
                    {" "}
                    {(errors.roles as any).message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(onSubmit)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={() => setShowEdit(false)}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EditUserModal;
