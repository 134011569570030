// ** Styles
import "@styles/react/apps/app-users.scss";
import PaymentMethod from "../../../components/systemSettings/paymentMethodsTable";

const PaymentMethodList = () => {
  return (
    <div className="app-user-view">
      <div className="app-user-list">
        <PaymentMethod />
      </div>
    </div>
  );
};
export default PaymentMethodList;
