// ** React Imports
import { Link, useNavigate } from "react-router-dom";

// ** Custom Components
import Avatar from "@components/avatar";

// ** Third Party Components
import { Settings, Power } from "react-feather";

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";

// ** Default Avatar Image
import { removeToken } from "../../../../helper/storageHelper";
import { useAuth } from "../../../../utility/context/Auth";

const UserDropdown = () => {
  const { userInfo } = useAuth();
  const navigate = useNavigate()

  const getSummaryName = (str) => {
    const results = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{userInfo.name}</span>
          <span className="user-status">{userInfo.roles[0]}</span>
        </div>
        {userInfo.photo ? <Avatar
          img={userInfo.photo}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
        : <Avatar
        className="me-1"
        color={"light-warning"}
        content={getSummaryName(`${userInfo.name} ${userInfo.surname}`)}
        title={`${userInfo.name} ${userInfo.surname}`}
      />}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} >
          <Link to={`/usersandpermissions/users/profile-detail/${userInfo.id}`}>
            <Settings size={14} className="me-75" />
            <span className="align-middle">Settings</span>
          </Link>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/login"
          onClick={() => {
            removeToken();
            navigate('/login', { replace: true })
          }}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
