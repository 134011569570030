import debounce from "lodash.debounce";
interface IOption {
  value: string;
  id: number;
  label: string;
}

export const debouncedSearch = debounce(
  (value, search: (value: any) => void) => {
    search(value);
  },
  500
);

export const handleFilter = (value: string, search: (value: any) => void) => {
  if (value) {
    debouncedSearch(value, search);
  } else debouncedSearch("", search);
};

export const coachConverter = (data: any) => {
  const coach = data.map((coach: any) => {
    return {
      id: coach.id,
      value: coach.user.id.toString(),
      label: coach.user.name + " " + coach.user.surname,
    } as IOption;
  });
  return coach;
};

const debouncedSearched = debounce((value, handleSearch, setFilter?) => {
  handleSearch(
    encodeURIComponent(
      value
        ? value.replace(/I/g, "ı").replace(/İ/g, "i").toLocaleLowerCase()
        : ""
    )
  );
}, 500);
export const handleFiltered = (
  e: string,
  handleSearch: any,
) => {
  debouncedSearched(e, handleSearch);
};

export const removeSpecialCharacters = (inputString: string) => {
  // Yalnızca harf ve rakamları korumak için regex kullanılıyor
  return inputString.replace(/[^\w\sğüşıöçĞÜŞİÖÇ]/gi, '');
};
