// ** React Imports
import { Link } from "react-router-dom";
// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardText, Button } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";

import "@styles/base/pages/forgotPassword.scss";


const ForgotPasswordSendMail = () => {
  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <CardBody>
            <CardTitle tag="h4" className=" mt-1 mb-1">
              Şifremi Unuttum
            </CardTitle>
            <CardText className="mb-4">
              Şifre sıfırlama linki mail adresinize gönderildi. Spam kutunuzu
              kontrol etmeyi unutmayın.
            </CardText>

            <Link className="loginPage" to="/login">
              <Button className="mb-2" color="warning" block>
                Geri Dön
              </Button>
            </Link>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPasswordSendMail;
