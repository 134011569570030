// ** React Imports
import { Link, useNavigate } from "react-router-dom";

// ** Custom Components
//@ts-ignore
import InputPasswordToggle from "@components/input-password-toggle";
// ** Custom Components
//@ts-ignore
import { AlertCircle } from "react-feather";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// ** Reactstrap Imports
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import "@styles/base/pages/login.scss";
import Authenticate, { Check2FA } from "../services/auth";
import { useContext, useState } from "react";
import { AuthDto } from "../model/DbMdel/authDBModel";
import LoadingButton from "../components/common/LoadingButton";
import { removeUser, setToken, setUser } from "../helper/storageHelper";
import getUserInfo from "../services/session";
import { IUserUIModel } from "../model/UIModel/userUIModel";
import { AuthContext } from "../utility/context/Auth";

const Login = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<any>(null);
  const [isloading, setIsLoading] = useState(false);
  const { setUserInfo } = useContext(AuthContext);

  const SignupSchema = yup.object().shape({
    email: yup
      .string()
      .email("E-mail tipinde olmalıdır.")
      .required("E-mail zorunlu bir alandır."),
    password: yup
      .string()
      .min(5, "Şifre en az 5 karakter olmalıdır.")
      .required(),
    is2FA: yup.boolean(),
    code: yup.string().when("is2FA", {
      is: (is2FA: boolean) => is2FA,
      then: yup.string().required("2FA Kodu zorunlu bir alandır."),
    }),
  });

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    setValue,
    watch,
  } = useForm({ mode: "onChange", resolver: yupResolver(SignupSchema) });

  const onAuth = (data: any) => {
    setIsLoading(true);
    removeUser();
    setUserInfo(null)

    Authenticate(data.email, data.password, data.code)
      .then((e: AuthDto) => {
        setToken(e.accessToken);
        getUserInfo().then((e: IUserUIModel) => {
          setUser(e);
          setUserInfo(e)
          window.location.href = "/";
        });
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    if (watch("is2FA")) {
      onAuth(data);
    } else {
      await Check2FA(data.email, data.password)
        .then((e) => {
          if (e.isEnabled) {
            setValue("is2FA", true);
            setIsLoading(false);
          } else {
            onAuth(data);
          }
        })
        .catch((e) => {
          setError(e);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <CardBody>
            <CardTitle tag="h4" className="mb-1 mt-5">
              Hoş geldiniz! 👋🏻
            </CardTitle>
            <CardText className="mb-2">
              Lütfen sistem tarafından oluşturulan hesabınızla giriş yapınız.
              Hesap bilgilerinizi bilmiyorsanız yöneticiyle iletişime geçin.
            </CardText>
            <Form
              className="auth-login-form mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-1">
                <Label className="form-label" for="login-email">
                  Email
                </Label>
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        disabled={watch("is2FA")}
                        {...field}
                        type="email"
                        defaultValue="admin1@qandq.dev"
                        id="login-email"
                        placeholder="john@example.com"
                        autoFocus
                        invalid={errors.email && true}
                      />
                    );
                  }}
                />
                {errors.email && (
                  <FormFeedback>
                    {errors.email.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              <div className="mb-1">
                <div className="d-flex justify-content-between">
                  <Label className="form-label" for="login-password">
                    Şifre
                  </Label>

                  <Link className="forgotPassword" to="/forgot-password">
                    <small>Şifremi Unuttum</small>
                  </Link>
                </div>

                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <InputPasswordToggle
                      disabled={watch("is2FA")}
                      className="input-group-merge"
                      {...field}
                      id="login-password"
                      invalid={errors.password && true}
                    />
                  )}
                />
                {errors.password && (
                  <FormFeedback>
                    {errors.password.message?.toString()}
                  </FormFeedback>
                )}
              </div>
              {watch("is2FA") && (
                <div className="mb-1">
                  <Label className="form-label" for="login-email">
                    Code
                  </Label>
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          type="text"
                          id="code"
                          autoFocus
                          invalid={errors.code && true}
                        />
                      );
                    }}
                  />
                  {errors.code && (
                    <FormFeedback>
                      {errors.code.message?.toString()}
                    </FormFeedback>
                  )}
                </div>
              )}

              <div className="form-check mb-1">
                <Input type="checkbox" id="remember-me" />
                <Label className="rememberMe" for="remember-me">
                  Beni hatırla
                </Label>
              </div>
              <LoadingButton
                loading={isloading}
                disabled={!isDirty || !isValid}
                className="mb-5"
                color="warning"
                block
              >
                Giriş yap
              </LoadingButton>

              <Alert color="danger" isOpen={error}>
                <div className="alert-body">
                  <AlertCircle size={15} />{" "}
                  <span className="ms-1">{error}</span>
                </div>
              </Alert>
              <div className="login-footer">
                <div className="privacy-button" onClick={() => {navigate("/privacy-policy")}}>{`Gizlilik Politikası >`}</div>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;
