// ** Third Party Components
import { FC } from "react";
import Chart from "react-apexcharts";

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
import getRandomColor from "../../../helper/colors";
import {
  ICoachSummaryDBModel,
  ITrainerSummary,
} from "../../../model/DbMdel/coachDBModel";

interface IPlanningFollowUpTrainerBasedChartProps {
  trainerSummary: ITrainerSummary[];
}

const OccupancyRatebyTrainer: FC<IPlanningFollowUpTrainerBasedChartProps> = ({
  trainerSummary,
}) => {
  const tempData = trainerSummary.map((item) => {
    const calcPercent = (param: ITrainerSummary) =>
      Math.round(
        (param.assignedLessonSum / param.maxAssignableLessonSum) * 100
      );

    return {
      avatar: require("@src/assets/img/10.png").default,
      title: item.trainer.user.name + " " + item.trainer.user.surname,
      value: `${calcPercent(item)}%`,
      chart: {
        type: "radialBar",
        series: [calcPercent(item)],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [getRandomColor()],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: ["#364042"],
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  });

  // const statesArr = [
  //   {
  //     avatar: require("@src/assets/img/10.png").default,
  //     title: "Kaan Pekbilir",
  //     value: "54.4%",
  //     chart: {
  //       type: "radialBar",
  //       series: [54.4],
  //       height: 30,
  //       width: 30,
  //       options: {
  //         grid: {
  //           show: false,
  //           padding: {
  //             left: -15,
  //             right: -15,
  //             top: -12,
  //             bottom: -15,
  //           },
  //         },
  //         colors: [getRandomColor()],
  //         plotOptions: {
  //           radialBar: {
  //             hollow: {
  //               size: "22%",
  //             },
  //             track: {
  //               background: ["#364042"],
  //             },
  //             dataLabels: {
  //               showOn: "always",
  //               name: {
  //                 show: false,
  //               },
  //               value: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         },
  //         stroke: {
  //           lineCap: "round",
  //         },
  //       },
  //     },
  //   },
  //   {
  //     avatar: require("@src/assets/img/10.png").default,
  //     title: "Yasemin Bal",
  //     value: "6.1%",
  //     chart: {
  //       type: "radialBar",
  //       series: [6.1],
  //       height: 30,
  //       width: 30,
  //       options: {
  //         grid: {
  //           show: false,
  //           padding: {
  //             left: -15,
  //             right: -15,
  //             top: -12,
  //             bottom: -15,
  //           },
  //         },
  //         colors: [getRandomColor()],
  //         plotOptions: {
  //           radialBar: {
  //             hollow: {
  //               size: "22%",
  //             },
  //             track: {
  //               background: ["#364042"],
  //             },
  //             dataLabels: {
  //               showOn: "always",
  //               name: {
  //                 show: false,
  //               },
  //               value: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         },
  //         stroke: {
  //           lineCap: "round",
  //         },
  //       },
  //     },
  //   },
  //   {
  //     avatar: require("@src/assets/img/10.png").default,
  //     title: "Baran Erdemli",
  //     value: "14.6%",
  //     chart: {
  //       type: "radialBar",
  //       series: [14.6],
  //       height: 30,
  //       width: 30,
  //       options: {
  //         grid: {
  //           show: false,
  //           padding: {
  //             left: -15,
  //             right: -15,
  //             top: -12,
  //             bottom: -15,
  //           },
  //         },
  //         colors: [getRandomColor()],
  //         plotOptions: {
  //           radialBar: {
  //             hollow: {
  //               size: "22%",
  //             },
  //             track: {
  //               background: ["#364042"],
  //             },
  //             dataLabels: {
  //               showOn: "always",
  //               name: {
  //                 show: false,
  //               },
  //               value: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         },
  //         stroke: {
  //           lineCap: "round",
  //         },
  //       },
  //     },
  //   },
  //   {
  //     avatar: require("@src/assets/img/10.png").default,
  //     title: "Ayşe Doğu",
  //     value: "4.2%",
  //     chart: {
  //       type: "radialBar",
  //       series: [4.2],
  //       height: 30,
  //       width: 30,
  //       options: {
  //         grid: {
  //           show: false,
  //           padding: {
  //             left: -15,
  //             right: -15,
  //             top: -12,
  //             bottom: -15,
  //           },
  //         },
  //         colors: [getRandomColor()],
  //         plotOptions: {
  //           radialBar: {
  //             hollow: {
  //               size: "22%",
  //             },
  //             track: {
  //               background: ["#364042"],
  //             },
  //             dataLabels: {
  //               showOn: "always",
  //               name: {
  //                 show: false,
  //               },
  //               value: {
  //                 show: false,
  //               },
  //             },
  //           },
  //         },
  //         stroke: {
  //           lineCap: "round",
  //         },
  //       },
  //     },
  //   },
  // ];

  const renderStates = () => {
    return tempData.map((state: any) => {
      return (
        <div key={state.title} className="browser-states">
          <div className="d-flex align-items-start">
            <img
              className="rounded me-1"
              src={state.avatar}
              height="40"
              alt={state.title}
            />
            <div className="d-flex flex-column">
              <h6
                style={{ marginBottom: "3px" }}
                className="align-self-center mb-0"
              >
                {state.title}
              </h6>
              <p className="text-muted mb-0">Antrenör</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="text-muted text-body-heading me-1">
              {state.value} oranında dolu
            </div>
            <Chart
              options={state.chart.options}
              series={state.chart.series}
              type={state.chart.type}
              height={state.chart.height}
              width={state.chart.width}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <Card className="card-browser-states">
      <CardHeader>
        <div>
          <CardTitle tag="h4">Antrenör Bazında Doluluk Oranı</CardTitle>
        </div>
      </CardHeader>
      <CardBody style={{ maxHeight: "369px", overflowY: "auto" }}>
        {renderStates()}
      </CardBody>
    </Card>
  );
};

export default OccupancyRatebyTrainer;
