import { yupResolver } from "@hookform/resolvers/yup";
import { FC, Fragment, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import { selectThemeColors } from "../../../utility/Utils";
import LoadingButton from "../../common/LoadingButton";
import SwitchLabel from "../../common/SwitchLabel";
import { Actives } from "./mockData";
import SwalFire from "../../common/Swal";
import { updatePaymentMethodService } from "../../../services/paymentMethod";
import toast from "react-hot-toast";
import {
  IEditPaymentMethodModel,
  IPaymentMethodDBModel,
} from "../../../model/DbMdel/paymentMethodDBModel";

interface IProps {
  setShowEdit: (value: SetStateAction<boolean>) => void;
  showEdit: boolean;
  paymentMethod: IPaymentMethodDBModel;
  onReload: () => void;
}

const EditPaymentMethod: FC<IProps> = ({
  setShowEdit,
  showEdit,
  onReload,
  paymentMethod,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const EditPaymentMethodSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "İsim en az 2 karakter olmalıdır.")
      .max(64, "İsim en fazla 64 karakter olmalıdır.")
      .matches(/^[^\d]*$/, "Geçerli bir isim giriniz.")
      .required("İsim zorunlu bir alan."),
    description: yup
      .string()
      .max(500, "Açıklama en fazla 500 karakter olmalıdır."),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
    register,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(EditPaymentMethodSchema),
    defaultValues: {
      name: paymentMethod.name,
      isActive: paymentMethod.isActive,
      description: paymentMethod.description || "",
    } as any,
  });

  useEffect(() => {
    reset();
  }, [showEdit]);

  const handleSwal = async (values: IEditPaymentMethodModel) => {
    await SwalFire({
      title: "Emin misiniz?",
      text: "Ödeme yöntemini düzenlemek üzeresiniz. İşleme devam etmek istiyor musunuz?",
      onOk: () => {
        onSubmit(values);
      },
    });
  };

  const onSubmit = async (values: IEditPaymentMethodModel) => {
    await updatePaymentMethodService(values, paymentMethod.id)
      .then((e) => {
        onReload();
        toast.success(`Ödeme Yöntemi Başarıyla Düzenlendi.`);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleCancel = () => {
    setShowEdit(!showEdit);
    reset();
    clearErrors();
  };

  return (
    <Fragment>
      <Modal
        isOpen={showEdit}
        toggle={handleCancel}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader
          className="bg-transparent"
          toggle={handleCancel}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <div className="text-center mb-2">
            <h1 className="mb-1">Ödeme Yöntemi Düzenle</h1>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row tag="form" className="gy-1 pt-75">
              <Col md={6} xs={12}>
                <Label className="form-label" for="name">
                  İsim
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        maxLength={64}
                        placeholder="İsim"
                        defaultValue={paymentMethod.name}
                        invalid={errors.name && true}
                        onKeyDown={(e) =>
                          e.key === "Enter" && e.preventDefault()
                        }
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormFeedback>{errors.name.message?.toString()}</FormFeedback>
                )}
              </Col>
              <Col lg={12} md={12} xs={12}>
                <Label className="form-label" for="description">
                  Açıklama
                </Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        innerRef={register("description").ref}
                        id="description"
                        type="textarea"
                        maxLength={500}
                        invalid={errors.description && true}
                        defaultValue={paymentMethod.description}
                        placeholder="Ödeme Yöntemi Açıklaması"
                        style={{ maxHeight: "200px", minHeight: "80px" }}
                      />
                    );
                  }}
                />
                {errors.description && (
                  <FormFeedback>
                    {errors.description.message?.toString()}
                  </FormFeedback>
                )}
              </Col>
              <Row className="justify-content-between mt-2">
                <Col xs={12} md={6}>
                  <div className="d-flex align-items-center">
                    <div className="form-switch">
                      <Controller
                        name="isActive"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Input
                              type="switch"
                              id="isActive"
                              defaultChecked={paymentMethod.isActive}
                              {...field}
                              onChange={(event) => {
                                setValue("isActive", event.target.checked);
                              }}
                            />
                          );
                        }}
                      />
                      <SwitchLabel htmlFor={"isActive"} />
                    </div>
                    <Label
                      className="form-check-label fw-bolder"
                      htmlFor="isActive"
                    >
                      Aktif Yöntem
                    </Label>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-flex align-items-center">
                    <div className="form-switch">
                      <Controller
                        name="isCoachMoneySafe"
                        control={control}
                        render={({ field }) => (
                          <Input
                            type="switch"
                            id="isCoachMoneySafe"
                            {...field}
                            defaultChecked={paymentMethod?.isCoachMoneySafe}
                            onChange={(event) => {
                              setValue(
                                "isCoachMoneySafe",
                                event.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <SwitchLabel htmlFor={"isCoachMoneySafe"} />
                    </div>
                    <Label
                      className="form-check-label fw-bolder"
                      htmlFor="isCoachMoneySafe"
                    >
                      Antrenör Kasası
                    </Label>
                  </div>
                </Col>
              </Row>

              <Col xs={12} className="text-center mt-2 pt-50">
                <LoadingButton
                  className="me-1"
                  color="warning"
                  onClick={handleSubmit(handleSwal)}
                  form="hook-form"
                  loading={isLoading}
                >
                  Kaydet
                </LoadingButton>
                <Button
                  type="reset"
                  color="secondary"
                  outline
                  onClick={handleCancel}
                >
                  İptal
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default EditPaymentMethod;
