import { Breadcrumb, BreadcrumbItem, Card, Col, Input, Row } from "reactstrap";
import { useEffect, useState } from "react";
import ExportDropdown from "../common/ExportDropdown";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import { Link, useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";
import moment from "moment";
import dateFormat from "../../helper/dateFormat";
//@ts-ignore
import Avatar from "@components/avatar";
import {
  ICoachDBModel,
  ICoachSummaryDBModel,
  ITrainerSummary,
} from "../../model/DbMdel/coachDBModel";
import { getSummaryCoachs } from "../../services/coach";
import { ISelectDate } from "../../model/commonModel/selectDate";
import { search } from "ss-search";
import DataTable from "../common/DataTable";
import { defaultCount, options } from "../common/DataTable/Constants";
const InvoiceList = () => {
  const [searchValue, setSearchValue] = useState("");
  let [searchParams] = useSearchParams();
  const [maxResultCount, setMaxResultCount] = useState(defaultCount);
  const [detailData, setDetailData] = useState<ICoachSummaryDBModel | null>(
    null
  );

  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const fetchDetail = async () => {
    const selectedDate: ISelectDate = {
      startDate: moment(startDate).format(dateFormat),
      endDate: moment(endDate).format(dateFormat),
    };

    if (selectedDate) {
      await getSummaryCoachs(selectedDate)
        .then((response: ICoachSummaryDBModel) => {
          setDetailData(response);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const handleFilter = (event: any): void => {
    _debounce(() => {
      setSearchValue(event.target.value);
    }, 500)();
  };

  const getSummaryName = (str: string): string => {
    const results: string[] = [];
    const wordArray = str.split(" ");
    wordArray.forEach((e) => {
      if (e[0] !== undefined) {
        results.push(e[0].toUpperCase());
      }
    });
    return results.join("");
  };

  const renderClient = (row: ICoachDBModel) => {
    if (row.user.photo) {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
          <Avatar
            className="me-1"
            img={row.user.photo}
            width="32"
            height="32"
          />
        </Link>
      );
    } else {
      return (
        <Link to={`/usersandpermissions/users/profile-detail/${row.id}`}>
          <Avatar
            className="me-1"
            color={"light-warning"}
            content={getSummaryName(`${row.user.name} ${row.user.surname}`)}
            title={row.user.name}
          />
        </Link>
      );
    }
  };

  const caseInsensitiveSort = (
    rowA: ITrainerSummary,
    rowB: ITrainerSummary
  ) => {
    const a = rowA.trainer.user.name.toLowerCase();
    const b = rowB.trainer.user.name.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const time = `${moment(startDate).format(dateFormat)} / ${moment(
    endDate
  ).format(dateFormat)}`;

  const filterData = () => {
    if (!detailData) {
      return [];
    }

    if (detailData && detailData.trainerSummaries === null) {
      return [];
    }
    if (searchValue) {
      return search(
        detailData!.trainerSummaries,
        Object.keys(detailData!.trainerSummaries[0]),
        searchValue
      );
    }

    return detailData;
  };

  const columns = [
    {
      name: "Antrenör İsmi",
      sortable: true,
      minWidth: "100px",
      sortField: "",
      sortFunction: caseInsensitiveSort,
      selector: (row: ITrainerSummary) => {
        return (
          <div className="d-flex justify-content-left align-items-center">
            {renderClient(row.trainer)}

            <div className="d-flex flex-column">
              <h6 className="user-name text-truncate mb-0 text-wrap">
                {row.trainer.user.name + " " + row.trainer.user.surname}
              </h6>
              <small className="text-truncate text-muted mb-0">Antrenör</small>
            </div>
          </div>
        );
      },
    },
    {
      name: "Doğrulanan Dersler",
      minWidth: "150px",
      sortField: "confirmed",
      selector: (row: ITrainerSummary) => row.confirmedLessonSum,
    },
    {
      minWidth: "150px",
      name: "Tahsil Edilen Dersler",
      sortField: "paid",
      selector: (row: ITrainerSummary) => row.encashedAndWaitingForConfirm,
    },
    {
      minWidth: "150px",
      name: "Tahsil Edilmeyi Bekleyen Dersler",
      sortField: "unpaid",
      selector: (row: ITrainerSummary) => row.waitingForEncashLessonSum,
    },
  ];

  const ToDetails = () => (
    <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
      <Row
        className="d-flex pb-2 "
        style={{ borderBottom: "1px solid #4B4B4B" }}
      >
        <Col
          lg="6"
          md="4"
          className="d-flex justify-content-center align-items-center justify-content-md-start "
        >
          <Breadcrumb className="breadcrumb-pipes">
            <BreadcrumbItem>
              <span>Ders Onay Detayı</span>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <span className="text-primary">
                {startDate + " | " + endDate}
              </span>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col
          lg="6"
          md="8"
          className="d-flex justify-content-center   justify-content-md-end "
        >
          <div className="d-flex align-items-center table-header-actions">
            <ExportDropdown<ITrainerSummary>
              className="me-1"
              title={time}
              //@ts-ignore
              array={filterData().trainerSummaries}
              sheetName={`${moment(startDate).format(dateFormat)}_${moment(
                endDate
              ).format(dateFormat)}`}
              fileName={`ders_onay_detay_${moment(new Date()).format(
                dateFormat
              )}`}
              headers={[
                {
                  key: "trainer.user.name",
                  label: "ANTRENOR ISMI",
                  onRender: (item: string, element) => {
                    return `${element.trainer.user.name} ${element.trainer.user.surname}`;
                  },
                },
                {
                  key: "confirmedLessonSum",
                  label: "DOGRULANAN DERSLER",
                  onRender: (item: string, element) => {
                    return `${element.confirmedLessonSum}`;
                  },
                },
                {
                  key: "encashedLessonSum",
                  label: "TAHSIL EDILEN DERSLER",
                  onRender: (item: string, element) => {
                    return `${element.encashedAndWaitingForConfirm}`;
                  },
                },
                {
                  key: "waitingForEncashLessonSum",
                  label: "TAHSIL EDILMEYI BEKLEYEN DERSLER",
                  onRender: (item: string, element) => {
                    return `${element.waitingForEncashLessonSum}`;
                  },
                },
              ]}
            />
          </div>
        </Col>
      </Row>

      <Row className="d-flex pb-0 pt-2 justify-content-between gap-1">
        <Col lg="2" md="3" sm="3" className="d-flex align-items-center ">
          <label className="mb-0 pe-1">
            Göster:
          </label>
          <Input defaultValue={maxResultCount} type="select" onChange={(e) => setMaxResultCount(parseInt(e.target.value)) } >
            {options?.map((option: number) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Input>
        </Col>
        <Col lg="4" sm="12" className="d-flex align-items-center ">
          <label className="mb-0 pe-1" htmlFor="search-invoice">
            Ara
          </label>
          <Input
            id="search"
            type="text"
            placeholder="Ara"
            aria-label="Search Input"
            onChange={handleFilter}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="invoice-list-wrapper">
      <Breadcrumbs
        title="Ders Onay Detayı"
        data={[
          { title: "Tahsilat takibi", link: "/" },
          { title: "Ders Onayı Detay" },
        ]}
      />
      <Card>
        <div className="invoice-list-dataTable react-dataTable">
          <DataTable.CSR
            subHeader
            columns={columns as any}
            data={detailData?.trainerSummaries}
            penging={detailData === null}
            subHeaderComponent={ToDetails()}
            filterText={searchValue}
            totalCount={detailData?.trainerSummaries.length}
            perPage={maxResultCount}
          />
        </div>
      </Card>
    </div>
  );
};

export default InvoiceList;
