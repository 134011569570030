import { Check, X } from "react-feather";
import { Label } from "reactstrap";

  const SwitchLabel = ({ htmlFor }: any) => {
    return (
      <Label className="form-check-label" htmlFor={htmlFor}>
        <span className="switch-icon-left">
          <Check size={14} />
        </span>
        <span className="switch-icon-right">
          <X size={14} />
        </span>
      </Label>
    );
  };


  export default SwitchLabel